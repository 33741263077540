import dayjs from "dayjs";
import "dayjs/locale/es";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import first from "lodash/first";
import get from "lodash/get";
import { useTranslation } from "react-i18next";

import CardTitle from "../CardTitle";
import BotType from "../../../bots/components/BotType";
import { BeatLoader } from "react-spinners";
import ChannelsFilter from "./components/ChannelsFilters";

const ChannelsActivity = (props) => {
    const { companies, metrics, formatBigNumber, getCompanyChannels } = props;
    const [companyId, setCompanyId] = useState(props.companyId);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const [loadingCards, setLoadingCards] = useState(true);
    const [showInformation, setShowInformation] = useState(false);
    const [channelMetrics, setChannelMetrics] = useState([]);
    const [companySelected, setCompanySelected] = useState([]);

    const [initialDate, setInitialDate] = useState(dayjs().day(1).startOf("day").format());
    const [finalDate, setFinalDate] = useState(dayjs().endOf("day").format());

    const controller = new AbortController();

    useEffect(() => {
        if (!isEmpty(get(metrics, "company_channels", []))) {
            setLoadingCards(false);
            setShowInformation(true);
            setChannelMetrics(get(metrics, "company_channels", []));
        }
    }, [metrics]);

    useEffect(() => {
        if (!isEmpty(companies) && isNil(companyId)) {
            setCompanySelected(first(companies));
            setCompanyId(first(companies).id);
        }
    }, [companyId, companies]);

    const handleChange = async (company) => {
        setCompanySelected(company);
        setCompanyId(company.id);
    };

    const execute = async (initialDateSent, finalDateSent) => {
        const initDate = !isEmpty(initialDateSent) ? initialDateSent : initialDate;
        const finDate = !isEmpty(finalDateSent) ? finalDateSent : finalDate;

        setLoadingCards(true);
        setShowInformation(false);
        dispatch(getCompanyChannels(companyId, initDate, finDate, controller)).then(() => {
            setLoadingCards(false);
            setShowInformation(true);
        });
    };

    useEffect(() => {
        if (!isEmpty(companySelected)) {
            execute();
        } else {
            setLoadingCards(false);
            setShowInformation(true);
        }
        return () => controller.abort();
    }, [companySelected]);

    return (
        <div>
            <ChannelsFilter
                initialDate={initialDate}
                setInitialDate={setInitialDate}
                finalDate={finalDate}
                setFinalDate={setFinalDate}
                companies={companies}
                handleChange={handleChange}
                companyId={companyId}
                companySelected={companySelected}
            />
            {!isEmpty(channelMetrics) ? (
                <div className="flex flex-col justify-between">
                    <div>
                        <div className="row-gap-4 column-gap-4 grid-5 h-75 grid w-full flex-1">
                            {channelMetrics.map((metric, i) => {
                                if (loadingCards) {
                                    return (
                                        <div key={i} className="flex h-38 w-full items-center justify-center rounded-lg bg-white p-4 shadow-input">
                                            <BeatLoader color="#00B3C7" size={13} />
                                        </div>
                                    );
                                }
                                return (
                                    <div key={i} className="h-38 w-full rounded-lg bg-white p-4 shadow-input">
                                        {metric.source === "voiceassistance" ? (
                                            <CardTitle>
                                                <BotType type={metric.source} className="mr-2" />
                                                Voice Assistance
                                            </CardTitle>
                                        ) : (
                                            <CardTitle>
                                                <BotType type={metric.source} className="mr-2" />
                                                {metric.source}
                                            </CardTitle>
                                        )}
                                        <div className="relative mb-4 mt-4 flex items-center">
                                            <p className="relative text-4xl text-gray-400">{formatBigNumber(metric.amount)}</p>
                                        </div>
                                        <p className="font-bold capitalize text-gray-400">{t("Usuarios Únicos")}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            ) : isEmpty(channelMetrics) && !showInformation ? (
                <div className="flex flex-col justify-between">
                    <div className="flex h-32 w-full items-center justify-center rounded-lg p-4">
                        <BeatLoader color="#00B3C7" size={13} />
                    </div>
                </div>
            ) : (
                isEmpty(channelMetrics) &&
                showInformation && (
                    <div className="mt-4 flex items-center justify-center p-4 text-center font-sans font-bold uppercase text-gray-450">
                        {t("common.Sin información actual")}
                    </div>
                )
            )}
        </div>
    );
};

export default ChannelsActivity;
