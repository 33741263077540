import { SET_PERMISSIONS, LOADING_PERMISSIONS } from "../constants";

export const setPermissions = (permissions) => ({
    type: SET_PERMISSIONS,
    payload: permissions,
});

export const setLoadingPermissions = (bool) => ({
    type: LOADING_PERMISSIONS,
    payload: bool,
});
