export const App_es = {
    bubbles: {
        Texto: "Texto",
        Aleatorio: "Aleatorio",
        Imagen: "Imagen",
        Video: "Video",
        Documento: "Documento",
        Carrusel: "Carrusel",
        Pregunta: "Pregunta",
        "Ir a flujo": "Ir a Flujo",
        Recurso: "Recurso",
        Sandbox: "Sandbox",
        "Respuestas rápidas": "Respuestas rápidas",
        Contacto: "Contacto",
        Operador: "Operador",
        Marcador: "Marcador",
        Pegatina: "Pegatina",
        Audio: "Audio",
        "Ingresa el texto que deseas que la persona vea cuando invoca el flujo seleccionado.":
            "Ingresa el texto que deseas que la persona vea cuando invoca el flujo seleccionado.",
        "¿Renderizar botones?": "¿Renderizar botones?",
        "¿Usar userCache?": "Usar memoria de",
        userCache: "userCache",
        Ajustes: "Ajustes",
        Ruta: "Ruta",
        "Intentos máximos": "Intentos máximos",
        "Mensaje intento máximos": "Mensaje intento máximos",
        "Has excedido el máximo de intentos": "Has excedido el máximo de intentos",
        "Vocablos para cancelar": "Vocablos para cancelar",
        "Cancelar, salir": "Cancelar, salir",
        "Mensaje al cancelar": "Mensaje al cancelar",
        "Has cancelado tu solicitud": "Has cancelado tu solicitud",
        "Al volver a preguntar": "Al volver a preguntar",
        "Mensaje de volver a preguntar por defecto": "Mensaje de volver a preguntar por defecto",
        "Ingresa un texto para la respuesta rápida": "Ingresa un texto para la respuesta rápida",
        "Respuesta rápida": "Respuesta rápida",
        "El tamaño del archivo es demasiado grande": "El tamaño del archivo es demasiado grande",
        "Subir audio": "Subir audio",
        "Subir video": "Subir video",
        "Subir pegatina": "Subir pegatina",
        "Ocurrió un error al intentar subir este archivo": "Ocurrió un error al intentar subir este archivo",
        Etiqueta: "Etiqueta",
        "Nombre de la etiqueta": "Nombre de la etiqueta",
        "Nombre del marcador": "Nombre del marcador",
        Configurar: "Configurar",
        "Acción fallida": "Acción fallida",
        "Cargar audio": "Cargar audio",
        Validación: "Validación",
        "Acción guardada": "Acción guardada",
        "Recuerda completar todos los campos o eliminar tu validación para poder salir":
            "Recuerda completar todos los campos o eliminar tu validación para poder salir",
        Mensajes: "Mensajes",
        Acciones: "Acciones",
        Título: "Título",
        Subtítulo: "Subtítulo",
        oneTimeButtons: "Respuestas rápidas de un solo uso",
        "Burbuja no soportada": "Burbuja no soportada",
        "Tipo de burbuja": "Tipo de burbuja",
        "Tipo de máscara": "Tipo de máscara",
        Máscara: "Máscara",
        "Con Burbujas": "Con Burbujas",
        "Sin Burbujas": "Sin Burbujas",
        Probar: "Probar",
    },
    pageNotFound: {
        errorMessage: "¡Oops! algo salió mal",
        page: "Parece que no tienes acceso a",
        backToHome: "Regresar a Apps",
    },
    metrics: {
        "Métricas por Compañía": "Métricas por Compañía",
        "Métricas por Bot": "Métricas por Bot",
        "Top 10 Flujos": "Top 10 Flujos",
        "Top 10 Vocablos": "Top 10 Vocablos",
        "Usuarios Únicos": "Usuarios Únicos",
        "Interacciones con el Bot": "Interacciones con el Bot",
        "Métricas de Sesión": "Métricas de Sesión",
        Hoy: "Hoy",
        "Promedio Diario": "Promedio Diario",
        Sesiones: "Sesiones",
    },

    datePicker: {
        date: "Fecha",
        apply: "Aplicar",
        today: "Hoy",
        yesterday: "Ayer",
        last24hours: "Últimas 24 horas",
        thisWeek: "Esta semana",
        lastWeek: "Semana pasada",
        lastSevenDays: "Últimos 7 días",
        thisMonth: "Este mes",
        lastMonth: "Mes pasado",
        ranges: "Rangos",
    },
    login: {
        hello: "¡Hola!",
        credentials: "Ingresa tus credenciales",
        email: "Correo",
        password: "Contraseña",
        initsesion: "Iniciar sesión",
        createAccount: "Crear una cuenta",
        forgotpassword: "¿Olvidaste tu contraseña?",
        o: "o",
        "Ingresando...": "Ingresando...",
        "¡Oops! Creemos que estás un poco perdido": "¡Oops! Creemos que estás un poco perdido",
        "¡Oops! Creemos que no puedes estar aquí": "¡Oops! Creemos que no puedes estar aquí",
        "Parece que no tienes accesos a": "Parece que no tienes accesos a",
        "Regresar a APPS": "Regresar a APPS",
        "Regresar a Home": "Regresar a Home",
        pageNotFound: "La página {{pathname}} no fue encontrada, esto es todo lo que sabemos",
    },

    home: {
        "Tu sesión expiró debido a tu inactividad. No te preocupes, inicia sesión nuevamente":
            "Tu sesión expiró debido a tu inactividad. No te preocupes, inicia sesión nuevamente",
        "tu sesión ha expirado": "tu sesión ha expirado",
        Ups: "Ups!",
        "Iniciar sesión": "Iniciar sesión",
        "No te preocupes, inicia sesión nuevamente": "No te preocupes, inicia sesión nuevamente",
        "Parece que no tienes sesión": "Parece que no tienes sesión",
    },
    common: {
        description: "Descripción",
        Editar: "Editar",
        Borrar: "Borrar",
        "Por defecto": "Por defecto",
        Compañía: "Compañía",
        "Escoja una empresa": "Escoja una empresa",
        "No hay datos para mostrar": "No hay datos para mostrar",
        "Sin información actual": "Sin información actual",
        Guardar: "Guardar",
        Limpiar: "Limpiar",
        filas: "filas",
        de: "de",
        "Buscar por mensaje": "Buscar por mensaje",
        error: "Algo ha ocurrido, intente más tarde.",
        Ingles: "Ingles",
        Español: "Español",
        No: "No",
        Portugues: "Portugues",
        Idioma: "Idioma",
        Métricas: "Métricas",
        Recursos: "Recursos",
        Reportes: "Reportes",
        "Cerrar sesión": "Cerrar sesión",
        "¿Estás seguro que deseas continuar?": "¿Estás seguro que deseas continuar?",
        Sí: "Sí",
        botonName: "Botón {{botonName}}",
        optionName: "Opción {{optionName}}",
        Nombre: "Nombre",
        "Nombre del botón": "Nombre del botón",
        "Nombre de la opción": "Nombre de la opción",
        "Ingrese nombre del botón": "Ingrese nombre del botón",
        Teléfono: "Teléfono",
        Url: "Url",
        Cancelar: "Cancelar",
        ej: "ej",
        Agregar: "Agregar",
        Dirección: "Dirección",
        "Ingresa la dirección del contacto": "Ingresa la dirección del contacto",
        Validar: "Validar",
        "Seleccionar validación": "Seleccionar validación",
        "Seleccione recurso": "Seleccione recurso",
        "¡Copiado al portapapeles!": "¡Copiado al portapapeles!",
        "Ver recurso": "Ver recurso",
        Builder: "Builder",
        "Nuevo mensaje aleatorio": "Nuevo mensaje aleatorio",
        errorLimitDescription: "El límite de caracteres es de 72",
        "Eliminar flujo": "Eliminar flujo",
        "Seleccionar tipo de máscara": "Seleccionar tipo de máscara",
        "Probar vocablos": "Probar vocablos",
    },
    filters: {
        "Seleccione Compañía": "Seleccione Compañía",
        "Seleccione Bot": "Seleccione Bot",
        "Seleccione flujo": "Seleccione flujo",
        "Seleccione Vocablo": "Seleccione Vocablo",
        "Seleccione recurso": "Seleccione recurso",
        Vocablo: "Vocablo",
        Flujo: "Flujo",
        "Seleccionar flujo": "Seleccionar flujo",
        Desvinculación: "Desvinculación",
        Informativa: "Informativa",
        Transacción: "Transacción",
        Ubicación: "Ubicación",
        Vinculación: "Vinculación",
        "Vocablos Entendidos": "Vocablos Entendidos",
        "Vocablos no Entendidos": "Vocablos no Entendidos",
        "Vocablos Entrenados": "Vocablos Entrenados",
        "Ingrese texto": "Ingrese texto",
        Condición: "Condición",
        "Seleccionar operador": "Seleccionar operador",
        "Seleccionar condición": "Seleccionar condición",
        "Seleccionar opción": "Seleccionar opción",
        Compañía: "Compañía",
        Estado: "Estado",
        Exitoso: "Exitoso",
        "No exitoso": "No exitoso",
        "Id o número de teléfono": "Id o número de teléfono",
    },
    resources: {
        Recursos: "Recursos",
        "Seleccionar flujo": "Seleccionar flujo",
        "Agregar recurso": "Agregar recurso",
        "para consumir servicios": "para consumir servicios",
        "Buscar recurso": "Buscar recurso",
        "Ingresa el nombre con el que quieres identificar tu recurso": "Ingresa el nombre con el que quieres identificar tu recurso",
        Nombre: "Nombre",
        Url: "Url",
        Tipo: "Tipo",
        Método: "Método",
        "Nombre del recurso": "Nombre del recurso",
        "Url del recurso": "Url del recurso",
        "Seleccionar protocolo": "Seleccionar protocolo",
        "Seleccionar método": "Seleccionar método",
        "Escoge un recurso para empezar a configurarlo": "Escoge un recurso para empezar a configurarlo",
        "Por favor ingrese el nombre del recurso para poder guardar": "Por favor ingrese el nombre del recurso para poder guardar",
    },

    reports: {
        "Reporte de Vocablos": "Reporte de Vocablos",
        "Reporte de Recursos": "Reporte de Recursos",
        Mensaje: "Mensaje",
        Entendido: "Entendido",
        Flujo: "Flujo",
        Tipo: "Tipo",
        Fecha: "Fecha",
        Entrenar: "Entrenar",
        "Error al entrenar": "Error al entrenar",
        "Reporte generado. Le llegará al correo en unos minutos": "Reporte generado. Le llegará al correo en unos minutos",
        Recurso: "Recurso",
        Estado: "Estado",
        Bot: "Bot",
        "La solicitud no puede ser procesada debido a que el número de registros excede el número permitido por Excel":
            "La solicitud no puede ser procesada debido a que el número de registros excede el número permitido por Excel",
    },

    flows: {
        Flujo: "Flujo",
        Flujos: "Flujos",
        deleteFlow: "¿Estás seguro que deseas eliminar el flujo {{flowName}}?",
        "Este flujo está siendo utilizado en los siguientes bots": "Este flujo está siendo utilizado en los siguientes bots",
        "Este flujo no está siendo utilizado en ningún bot.": "Este flujo no está siendo utilizado en ningún bot.",
        "Flujo eliminado exitosamente": "Flujo eliminado exitosamente",
        "Error al eliminar el flujo. Intente más tarde": "Error al eliminar el flujo. Intente más tarde",
        "Error al actualizar el flujo por defecto": "Error al actualizar el flujo por defecto",
        "Flujo por defecto actualizado exitosamente": "Flujo por defecto actualizado exitosamente",
        "Nombre del flujo ya existe": "Nombre del flujo ya existe",
        "Agregar vocablo": "Agregar vocablo",
        "Agregar un nuevo flujo": "Agregar un nuevo flujo",
        "Puedes agregar un flujo con una o varias burbujas": "Puedes agregar un flujo con una o varias burbujas",
        "Ingresa el nombre con el que quieres identificar tu flujo": "Ingresa el nombre con el que quieres identificar tu flujo",
        "Nombre del flujo": "Nombre del flujo",
        "Buscar flujo": "Buscar flujo",
        "Nombre del flujo no puede estar vacío": "Nombre del flujo no puede estar vacío",
        "Ver vocablo": "Ver vocablo:",
        "Cambios guardados exitosamente": "Cambios guardados exitosamente",
        "Esta base de conocimiento no se puede editar": "Esta base de conocimiento no se puede editar",
        "No se puede guardar el flujo, falta Nombre de la etiqueta o está incompleta la configuración":
            "No se puede guardar el flujo, falta Nombre de la etiqueta o está incompleta la configuración",
        "No se puede guardar el flujo, seleccionar recurso": "No se puede guardar el flujo, seleccionar recurso",
        "No se puede guardar el flujo, por favor validar la configuracion en las burbujas toolkit":
            "No se puede guardar el flujo, por favor validar la configuración en las burbujas toolkit",
        "Nombre del flujo no puede contener mayúsculas o caracteres acentuados":
            "Nombre del flujo no puede contener mayúsculas o caracteres acentuados",
        "Nombre del flujo no puede contener caracteres acentuados": "Nombre del flujo no puede contener caracteres acentuados",
        "Flujo creado exitosamente": "Flujo creado exitosamente",
        "Flujo actualizado exitosamente": "Flujo actualizado exitosamente",
        "Este bot fue creado en Brain, no se pueden agregar vocablos": "Este bot fue creado en Brain, no se pueden agregar vocablos",
    },
    toolkit: {
        selecciona_opcion: "Selecciona una opción",
    },
};
