import { ADD_BUBBLE, ADD_BUBBLES, UPDATE_BUBBLE, DELETE_BUBBLES, UPDATE_BUBBLES, MERGE_BUBBLES, DELETE_ALL_BUBBLES } from "../constants";
import {
    updateById,
    // keyById,
    deleteByIds,
    mergeById,
} from "../helpers";

function bubbles(state = [], action) {
    switch (action.type) {
        case ADD_BUBBLES:
            return [...state, ...action.payload];
        case ADD_BUBBLE:
            return [...state, action.payload];
        case UPDATE_BUBBLE:
            return updateById(state, action.payload);
        case MERGE_BUBBLES:
            return mergeById(state, action.payload);
        // case UPDATE_BUBBLES:
        //     return keyById(state).merge(keyById(action.payload)).toList().toJS();
        case UPDATE_BUBBLES:
            return action.payload;
        case DELETE_BUBBLES:
            return deleteByIds(state, action.payload);
        case DELETE_ALL_BUBBLES:
            return [];
        default:
            return state;
    }
}

export default bubbles;
