import Http from "../services/Http";
import isEmpty from "lodash/isEmpty";

export default function report() {
    function report(body, controller = null) {
        return Http.post(`/metrics/reports`, body, {
            ...(!isEmpty(controller) ? { signal: controller.signal } : {}),
        });
    }
    function reportFile(body) {
        return Http({
            url: "/metrics/reports/file",
            method: "POST",
            responseType: "blob",
            data: body,
            timeout: 0,
        });
    }
    return { report, reportFile };
}
