import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { App_en } from "../translations/en/index";
import { App_es } from "../translations/es/index";
import { App_pt } from "../translations/pt/index";

import store from "../services/Store";

const { lang = "es" } = store.getState().user;

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: App_en,
        },
        es: {
            translation: App_es,
        },
        pt: {
            translation: App_pt,
        },
    },
    lng: lang,
    fallbackLng: "es",
});

export default i18n;
