import React from "react";

import Icon from "./Icon";

const MicIcon = (props) => (
    <Icon viewBox="0 0 14 20" className={props.className} width={props.width} height={props.height}>
        <path
            d="M3.13479 19.7922C3.12785 19.7793 3.1186 19.769 3.11166 19.7561C2.9775 19.4977 3.04458 18.5029 3.21112 18.2781C3.32215 18.1256 3.46556 18.0739 3.63903 18.0739C4.50642 18.0791 5.37381 18.0765 6.2412 18.0765C6.31522 18.0765 6.38692 18.0765 6.4725 18.0765V15.7923C6.11167 15.6967 5.73927 15.6321 5.38538 15.5003C2.77396 14.5287 1.21035 12.5236 0.715358 9.49518C0.715358 9.49259 0.715356 9.48743 0.713043 9.48484C0.629773 8.95513 0.99986 8.47193 1.48329 8.47968L1.55962 8.48227C1.9112 8.49002 2.2142 8.76133 2.29747 9.14118C2.48252 9.98355 2.86648 11.4331 3.43549 12.1334C4.85106 13.8724 6.61823 14.5081 8.64213 13.8827C10.6984 13.2497 11.922 11.6683 12.3407 9.32464C12.3476 9.29105 12.3522 9.25229 12.3569 9.20836C12.4101 8.79751 12.72 8.48743 13.0924 8.47451H13.1248C13.5874 8.4616 13.9575 8.9112 13.8997 9.42541C13.8997 9.43058 13.8997 9.43316 13.8974 9.43833C13.5643 12.0869 11.8966 14.2858 9.67838 15.3117C9.15794 15.552 8.58894 15.6631 8.03149 15.8362V18.0739C8.11708 18.0739 8.19109 18.0739 8.2628 18.0739C9.15794 18.0739 10.0508 18.0765 10.9459 18.0713C11.1194 18.0713 11.2628 18.123 11.3715 18.2755C11.5357 18.5003 11.6028 19.4925 11.471 19.7535C11.464 19.7664 11.4548 19.7793 11.4478 19.7897H3.13479V19.7922Z"
            fill={props.fill}
        />
        <path
            d="M11.0744 6.26698C11.0744 7.0607 11.077 7.85693 11.0744 8.65066C11.0694 10.6112 9.64077 12.1511 7.63516 12.3614C5.75411 12.5592 3.98999 11.2622 3.60361 9.3868C3.55531 9.15394 3.53752 8.90856 3.53497 8.67069C3.52989 7.0607 3.52989 5.44821 3.53243 3.83823C3.53751 1.88521 4.98643 0.332814 6.98187 0.137512C8.88326 -0.047774 10.6474 1.26676 11.0109 3.14466C11.0541 3.37251 11.0694 3.60787 11.0719 3.84073C11.0795 4.64948 11.0744 5.45823 11.0744 6.26698ZM5.2203 6.22942C5.2203 7.03817 5.20504 7.84692 5.22792 8.65567C5.23555 8.94611 5.29656 9.25409 5.41095 9.52201C5.79987 10.4084 6.8014 10.8741 7.78006 10.6613C8.71041 10.4585 9.38149 9.64469 9.38403 8.69072C9.38911 7.06571 9.38657 5.4407 9.38403 3.81569C9.38403 3.72055 9.37895 3.6254 9.3637 3.53276C9.18068 2.44357 8.22236 1.70493 7.11914 1.79758C6.0261 1.89022 5.2203 2.76407 5.21775 3.86827C5.21775 4.65449 5.2203 5.4407 5.2203 6.22942Z"
            fill={props.fill}
        />
    </Icon>
);

export default MicIcon;
