import castArray from "lodash/castArray";
import Immutable, { List, Map } from "immutable";
import toUpper from "lodash/toUpper";

export const keyById = function keyById(data, key = null) {
    if (!Array.isArray(data)) {
        data = [data];
    }

    data = data.map((item) => {
        return {
            ...item,
        };
    });

    return Immutable.fromJS(data)
        .toMap()
        .mapEntries(function ([, value]) {
            return [value.get(key || "id"), value];
        });
};

export const updateById = function updateById(arr = [], obj) {
    return List(arr)
        .map((item) => {
            if (item.id === obj.id) {
                return { ...item, ...obj };
            }
            return item;
        })
        .toJS();
};

export const deleteById = function deleteById(arr = [], id) {
    return List(arr)
        .filter((item) => item.id !== id)
        .toJS();
};

export const deleteByIds = function deleteByIds(arr = [], data = []) {
    return List(arr)
        .filter((item) => {
            return !includesId(data, item.id);
        })
        .toJS();
};

// Check if an id is present on array of objects
function includesId(arr = [], id) {
    return !!arr.find((item) => item.id === id);
}

// Extract options from array
export const arrayByKey = function extractOptions(arr = [], key) {
    return List(arr)
        .flatMap((item) => item[key])
        .toJS();
};

export const objectByKey = function extractOptions(arr = [], key) {
    return List(arr)
        .map((item) => {
            return { ...item[key] };
        })
        .filter((i) => !isEmpty(i))
        .toJS();
};

// Delete keys from obj
export const omitKeys = function omitKeys(obj, keysToDelete = []) {
    return Map(obj).deleteAll(keysToDelete).toJS();
};

export const omitKeysInArray = function omitKeysInArray(arr, keysToDelete = []) {
    return List(arr)
        .map((item) => omitKeys(item, keysToDelete))
        .toJS();
};

export const mergeById = function mergeById(state, payload, key = null) {
    const arr = castArray(payload);
    return keyById(state, key).merge(keyById(arr, key)).toList().toJS();
};

export const isEmpty = function isEmpty(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
};

/* Sort by order */
export const byOrder = function byOrder(a, b) {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
};

/* Returns a regExp to test. */
export const escapedRegEXp = (string) => {
    const regExp = string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    return new RegExp(toUpper(regExp));
};
