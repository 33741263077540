import Icon from "./Icon";

const FlowIcon = (props) => (
    <Icon viewBox="0 0 44.1 44.2" className={props.className} width={props.width} height={props.height}>
        <path
            d="M6.5,13C2.9,13,0,10.1,0,6.5C0,2.9,2.9,0,6.5,0S13,2.9,13,6.5C13,10.1,10.1,13,6.5,13z M6.5,1.9
			C4,1.9,1.9,4,1.9,6.5c0,2.5,2,4.6,4.6,4.6s4.6-2,4.6-4.6C11.1,4,9,1.9,6.5,1.9z"
        />
        <path
            d="M37.6,44.2c-3.6,0-6.5-2.9-6.5-6.5c0-3.6,2.9-6.5,6.5-6.5s6.5,2.9,6.5,6.5C44.1,41.3,41.2,44.2,37.6,44.2z
			 M37.6,33.1c-2.5,0-4.6,2-4.6,4.6c0,2.5,2,4.6,4.6,4.6s4.6-2,4.6-4.6C42.2,35.2,40.2,33.1,37.6,33.1z"
        />
        <path
            d="M34.4,5.2H12.8v2.3h21.7c3.6,0,6.6,3,6.6,6.6c0,3.6-3,6.6-6.6,6.6h-1.7v0H9.6c-4.9,0-8.9,4-8.9,8.9
		s4,8.9,8.9,8.9h22.5v-2.3H9.6c-3.6,0-6.6-3-6.6-6.6c0-3.6,3-6.6,6.6-6.6h0.8v0h24c4.9,0,8.9-4,8.9-8.9S39.4,5.2,34.4,5.2z"
        />
    </Icon>
);

export default FlowIcon;
