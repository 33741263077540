import initialState from "./initialState";
import { updateById, mergeById } from "../helpers";
import { ADD_INPUTS, ADD_INPUT, UPDATE_INPUT, UPDATE_INPUTS, DELETE_ALL_INPUTS } from "../constants";

function inputs(state = initialState.inputs, action) {
    switch (action.type) {
        case ADD_INPUTS:
            return [...action.payload];
        case ADD_INPUT:
            return [...state, action.payload];
        case UPDATE_INPUT:
            return updateById(state, action.payload);
        case UPDATE_INPUTS:
            return mergeById(state, action.payload);
        case DELETE_ALL_INPUTS:
            return [];
        default:
            return state;
    }
}

export default inputs;
