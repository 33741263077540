import { Transition } from "@headlessui/react";
import debounce from "lodash/debounce";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { Fragment, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { updateBubble } from "../../../../actions/bubbles";
import { NEW, UPDATE } from "../../../../constants";
import Checkbox from "../../../common/FormComponent/Checkbox";
import { InputSelector } from "../../../common/Selector";
import {
    INPUTS_TEXT_PLACEHOLDERS,
    ONE_TIMES_KEYS,
    TYPE_OPTIONS,
    deleteKeys,
    validateInputForm,
    validateSelectForm,
} from "./constanst.oneTimeButtons";

export const BUBBLES_SETTINGS_CHECKBOX_NAMES = {
    CACHE_DATA_PATH: "path",
    ONE_TIME_BUTTONS: "oneTimeUseButtons",
};

export function OneTimeUseButtons({ properties, bubble }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const formRef = useRef(null);

    const redirectPayload = get(properties, "redirectPayload", {});
    const type = get(redirectPayload, "type");
    const inputValue = get(redirectPayload, "text", get(redirectPayload, "flowId", ""));
    const defaultChecked = get(properties, "oneTimeUseButtons", false);

    const [isCheckedOneTimeBtns, setIsCheckedOneTimeBtns] = useState(defaultChecked);
    const [errors, setErrors] = useState({});

    const updateProperties = (newProperties) => {
        const action = get(bubble, "action") === NEW ? NEW : UPDATE;

        const updatedBubbles = {
            ...bubble,
            properties: newProperties,
            action,
        };

        dispatch(updateBubble(updatedBubbles));
    };

    function handleCheckboxOneTimeBtns({ target }) {
        const value = target.checked;
        setIsCheckedOneTimeBtns(value);

        const newProperties = {
            ...(value ? { ...properties, oneTimeUseButtons: true } : deleteKeys(properties)),
        };

        updateProperties(newProperties);
    }

    const handleInputChange = debounce((evt) => {
        const { value } = evt.target;
        const key = ONE_TIMES_KEYS[type];

        const newErrors = validateInputForm({ key, value });
        if (!isEmpty(newErrors)) {
            setErrors(newErrors);
            return;
        }

        setErrors({});

        const newProperties = {
            ...properties,
            redirectPayload: {
                ...properties.redirectPayload,
                [key]: value,
            },
        };

        updateProperties(newProperties);
    }, 500);

    const handleSelectTypeChange = (value) => {
        const { value: type } = value;

        const newErrors = validateSelectForm({ type });
        if (!isEmpty(newErrors)) {
            setErrors(newErrors);
            return;
        }

        setErrors({});

        const newProperties = {
            ...properties,
            redirectPayload: {
                type,
            },
        };

        updateProperties(newProperties);
    };

    return (
        <div className="font-sans text-15 leading-tight text-gray-400">
            <div className="mb-3 grid items-center gap-3">
                <label className="flex w-fit items-center text-left font-sans">
                    <Checkbox
                        className="flex"
                        type="checkbox"
                        onChange={handleCheckboxOneTimeBtns}
                        checked={isCheckedOneTimeBtns}
                        name={BUBBLES_SETTINGS_CHECKBOX_NAMES.ONE_TIME_BUTTONS}
                    />
                    <span className="mr-2 w-52 select-none whitespace-nowrap">{t("bubbles.oneTimeButtons")}</span>
                </label>
            </div>

            <CustomTransition show={isCheckedOneTimeBtns}>
                <form ref={formRef} className="grid w-full gap-3">
                    <div className="">
                        <InputSelector
                            onChange={handleSelectTypeChange}
                            defatulValue={type}
                            options={TYPE_OPTIONS}
                            placeholder="Selecciona un tipo"
                            name={ONE_TIMES_KEYS.TYPE}
                            hasError={errors[ONE_TIMES_KEYS.TYPE]}
                            labelClassName="block mb-2 font-semibold text-xs"
                            label="Si el usuario usa más de una respuesta rápida, enviar:"
                        />
                    </div>
                    {type && (
                        <>
                            <input
                                onChange={handleInputChange}
                                className={`border-1 w-ful h-8 rounded-10 bg-white px-2 py-6 pl-3 text-gray-400 placeholder:text-13 placeholder:font-semibold focus-within:shadow-none focus-within:ring-0 disabled:cursor-not-allowed disabled:bg-opacity-50 ${
                                    !isEmpty(errors[ONE_TIMES_KEYS.ONE_TIME_USE_BUTTONS_INPUT])
                                        ? "border-red-200 placeholder:text-red-200/50 focus-within:border-red-200"
                                        : "border-[#DCDEE4] placeholder:text-[#DCDEE4] focus-within:border-[#DCDEE4]"
                                }`}
                                type="text"
                                placeholder={INPUTS_TEXT_PLACEHOLDERS[type]}
                                defaultValue={inputValue}
                                name={ONE_TIMES_KEYS.ONE_TIME_USE_BUTTONS_INPUT}
                            />
                            {errors[ONE_TIMES_KEYS.ONE_TIME_USE_BUTTONS_INPUT] && (
                                <small className="text-12 font-normal text-red-200">*{errors[ONE_TIMES_KEYS.ONE_TIME_USE_BUTTONS_INPUT]}</small>
                            )}
                        </>
                    )}
                </form>
            </CustomTransition>
        </div>
    );
}

export function CustomTransition({ children, show }) {
    return (
        <Transition
            as={Fragment}
            show={show}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <div className="mb-4 flex items-center">{children}</div>
        </Transition>
    );
}
