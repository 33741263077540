import React from "react";

const ToolkitIcon = ({ width = 18, height = 18, fill = "currentColor" } = {}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0  18 18">
        <path
            fill={fill}
            d="m15.747 3.686-3.606 3.606h.954l-2.414-2.414v.954l3.427-3.427c.358-.358.596-.775.447-1.281C14.317.229 13.155.11 12.38.02a6.06 6.06 0 0 0-4.768 1.758c-1.46 1.46-2.027 3.576-1.64 5.573.06-.209.12-.447.18-.656L3.35 9.498l-1.728 1.728c-.418.417-.835.805-1.133 1.311a3.704 3.704 0 0 0 .268 4.083c.865 1.103 2.325 1.61 3.725 1.281.805-.178 1.401-.685 1.967-1.221l1.58-1.58 3.248-3.248c-.208.06-.447.12-.655.179 2.562.506 5.215-.596 6.526-2.861.626-1.043.924-2.325.805-3.517-.06-.566-.12-1.37-.507-1.818-.477-.596-1.192-.566-1.699-.149-.268.239-.238.716 0 .954.268.268.656.238.954 0-.12.09-.328-.06-.268-.09 0 0 .119.388.119.418.06.268.09.566.12.864.029.596-.06 1.162-.24 1.729-.387 1.162-1.25 2.115-2.324 2.682a4.534 4.534 0 0 1-2.563.506c-.328-.03-.745-.208-1.072 0-.299.18-.537.507-.746.715l-3.188 3.19c-.745.744-1.55 1.877-2.682 1.996-.984.12-1.997-.477-2.355-1.4-.357-.865-.119-1.819.507-2.474.775-.805 1.58-1.58 2.384-2.384.894-.894 1.788-1.759 2.652-2.653.239-.238.298-.476.239-.834a4.981 4.981 0 0 1 .03-1.758 4.764 4.764 0 0 1 1.966-2.98 4.743 4.743 0 0 1 3.279-.775c.268.03.536.179.804.179.12 0-.03.03-.03-.06 0-.03-.03-.179 0-.179-.06-.06.06-.03 0-.03-.119.03-.298.298-.357.358l-2.98 2.98-.239.238a.666.666 0 0 0 0 .954l2.414 2.414a.666.666 0 0 0 .954 0L16.73 4.64c.566-.596-.358-1.55-.984-.954Z"
        />
        <path fill={fill} d="M3.618 15.577a1.222 1.222 0 1 0 0-2.444 1.222 1.222 0 0 0 0 2.444Z" />
    </svg>
);

export default ToolkitIcon;

export function SkillIcon({color= "currentColor", width = 35, height = 35} = {}) {
    return (
        <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.5 34.2083V21.75C17.5 24.3359 18.5272 26.8158 20.3557 28.6443C22.1842 30.4728 24.6641 31.5 27.25 31.5"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                />
            <rect
                x="14.25"
                y="15.25"
                width="6.5"
                height="6.5"
                stroke={color}
                strokeWidth="2"
                strokeLinejoin="round"/>
            <rect
                x="27.25"
                y="28.25"
                width="6.5"
                height="6.5"
                stroke={color}
                strokeWidth="2"
                strokeLinejoin="round"/>
        </svg>
    );
}