import initialState from "./initialState";
import { updateById, mergeById } from "../helpers";
import { ADD_SANDBOX, ADD_SANDBOXES, DELETE_ALL_SANDBOXES, UPDATE_SANDBOX, UPDATE_SANDBOXES } from "../constants";

function sandboxes(state = initialState.sandboxes, action) {
    switch (action.type) {
        case ADD_SANDBOXES:
            return [...action.payload];
        case ADD_SANDBOX:
            return [...state, action.payload];
        case UPDATE_SANDBOX:
            return updateById(state, action.payload);
        case UPDATE_SANDBOXES:
            return mergeById(state, action.payload);
        case DELETE_ALL_SANDBOXES:
            return [];
        default:
            return state;
    }
}

export default sandboxes;
