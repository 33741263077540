import axios from "axios";
import isEmpty from "lodash/isEmpty";

const BASE_URL = "https://workflows.jelou.ai/v1";

export class ToolkitRepository {
    appToken = "";

    constructor(app_token) {
        if (isEmpty(app_token)) {
            throw new Error("app_token is required");
        }

        this.appToken = app_token;
    }

    async getToolkits() {
        try {
            const { data, status } = await axios.get(`${BASE_URL}/toolkits/`, {
                headers: {
                    "X-Api-Key": this.appToken,
                },
            });

            if (status === 200) return data.data;

            throw new Error("Tuvimos un problema al obtener los toolkits");
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error("error desconocido al obtener los toolkits", { error });
                throw new Error("Tuvimos un problema al obtener los toolkits");
            }

            throw error;
        }
    }

    async getVersions(toolkitId, toolId) {
        if (isNaN(toolkitId)) throw new Error("toolkitId is required");
        if (isNaN(toolId)) throw new Error("toolId is required");

        try {
            const { status, data } = await axios.get(`${BASE_URL}/toolkits/${toolkitId}/tools/${toolId}/versions?privacy=private`, {
                headers: {
                    "X-Api-Key": this.appToken,
                },
            });

            if (status === 200) return data.data;

            throw new Error("Tuvimos un problema al obtener las versiones");
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error("error desconocido al obtener las versiones", { error });
                throw new Error("Tuvimos un problema al obtener las versiones");
            }

            throw error;
        }
    }
}

export class ToolkitAdpter {
    static toolkitToOptions(toolkits) {
        return toolkits.map((toolkit) => ({
            name: toolkit.name,
            id: toolkit.id,
            tools: toolkit.Tools,
        }));
    }

    static versionToOptions(versions) {
        return versions.map((version) => ({
            ...version,
            name: version.version,
            id: version._id,
        }));
    }
}
