import get from "lodash/get";
import AceEditor from "react-ace";
import Select from "react-select";
import orderBy from "lodash/orderBy";
import toUpper from "lodash/toUpper";
import { BeatLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import "brace/mode/json";
import "brace/theme/xcode";

/* Components */
import settings from "../../../img/settings.svg";
import Input from "../../common/FormComponent/Input";
import Label from "../../common/FormComponent/Label";
import CanRenderOption from "../../common/CanRenderOption";

import { ResourceMethods, ResourceTypes, SAVING_RESOURCE } from "../../../constants";

const customStyles = {
    control: (base) => ({
        ...base,
        border: "1px solid #bacad6",
        background: "white",
        minHeight: "36px",
        height: "36px",
        borderRadius: ".5rem",
        fontFamily: "Nunito",
    }),
    placeholder: (base) => ({
        ...base,
        fontWeight: 400,
    }),
    multiValue: (base) => ({
        ...base,
        borderRadius: ".5rem",
        background: "red",
    }),
    menu: (base) => ({
        ...base,
        fontSize: "14px",
        zIndex: 99999,
        background: "white",
    }),
};

const Resource = (props) => {
    const { resource, saveResource, resourceState, handleChange, handleInputs, companies, handleJSON } = props;

    const dispatch = useDispatch();

    const { t } = useTranslation();
    let response = "";

    if (props.resource.response) {
        response = props.resource.response;
    }

    const company = companies.find((company) => company?.id === resource.companyId);
    const isHttp = toUpper(get(resource, "type", "")) === "HTTP";

    const type = ResourceTypes.find((type) => type.value === resource.type);
    const method = ResourceMethods.find((type) => type.value === resource.method);

    const onSubmitResource = (evt, resource) => {
        evt.preventDefault();
        dispatch(saveResource(resource));
    };

    if (!resource?.name)
        return (
            <div className="flex flex-1 flex-col items-center justify-center">
                <img src={settings} className="h-2/3" alt={"settings"} loading="lazy" />
                <div className="flex h-[3.75rem] items-center justify-center  ">
                    <p className={`font-bold text-[#A9A9A9] md:text-2xl`}>{t("resources.Escoge un recurso para empezar a configurarlo")}</p>
                </div>
            </div>
        );

    return (
        <form action="" className="relative z-20 flex flex-1 flex-col overflow-y-scroll" onSubmit={(e) => onSubmitResource(e, resource)}>
            <div className="sticky left-0 right-0 top-0 z-200 flex min-h-[3.25rem] w-full items-center justify-between border-b border-l border-[#DCDEE4] bg-white px-6">
                <h1 className="font-sans text-lg text-gray-400">{resource.name}</h1>
                <CanRenderOption option="update resource">
                    <button
                        type="submit"
                        className="rounded-full bg-primary-200 px-4 py-1 text-sm font-semibold text-white disabled:cursor-not-allowed disabled:bg-gray-250 disabled:text-gray-75"
                        disabled={resourceState === SAVING_RESOURCE}>
                        {resourceState === SAVING_RESOURCE ? <BeatLoader color={"#ffffff"} size={"0.375rem"} /> : t("common.Guardar")}
                    </button>
                </CanRenderOption>
            </div>

            <div className="mb-8 px-6 pt-8">
                <div className="w-2/3">
                    <div className="mb-6 flex items-center">
                        <Label name={t("resources.Nombre")} />
                        <Input
                            type="text"
                            name="name"
                            value={resource.name}
                            onChange={handleInputs}
                            placeholder={t("resources.Nombre del recurso")}
                            required
                        />
                    </div>
                    <div className="mb-6 flex items-center">
                        <Label name={t("resources.Url")} />
                        <Input
                            name="url"
                            type="text"
                            value={resource.url || ""}
                            placeholder={t("Url del recurso")}
                            onChange={handleInputs}
                            required
                        />
                    </div>
                    <div className="mb-6 flex items-center">
                        <Label name={t("resources.Tipo")} />
                        <Select
                            className="flex-1"
                            styles={customStyles}
                            options={ResourceTypes}
                            onChange={handleChange}
                            placeholder={t("resources.Seleccionar protocolo")}
                            name="type"
                            value={type}
                            required
                        />
                    </div>
                    {isHttp && (
                        <div className="mb-6 flex items-center">
                            <Label name={t("resources.Método")} />
                            <Select
                                className="flex-1"
                                styles={customStyles}
                                options={ResourceMethods}
                                onChange={handleChange}
                                placeholder={t("resources.Seleccionar método")}
                                value={method}
                                name="input"
                                required
                            />
                        </div>
                    )}
                    <div className="mb-6 flex items-center">
                        <Label name={t("common.Compañía")} />
                        <Select
                            className="flex-1"
                            styles={customStyles}
                            getOptionValue={(o) => o.id}
                            getOptionLabel={(o) => o.name}
                            onChange={(e) => handleChange({ ...e, name: "companyId" })}
                            name="companyId"
                            options={orderBy(companies, ["name"], ["asc"])}
                            placeholder={t("common.Escoja una empresa")}
                            value={company}
                            required
                        />
                    </div>
                </div>
                <div className="relative w-full">
                    <AceEditor
                        mode="json"
                        value={response}
                        theme="xcode"
                        width={"100%"}
                        height={"31.25rem"}
                        onChange={handleJSON}
                        wrapEnabled={true}
                        fontSize={14}
                        editorProps={{
                            $blockScrolling: Infinity,
                        }}
                        setOptions={{
                            showLineNumbers: true,
                            fontFamily: "Source Code Pro",
                        }}
                        className="overflow-hidden rounded-lg border border-input shadow-input"
                        //showGutter={false}
                        showPrintMargin={false}
                    />
                </div>
            </div>
        </form>
    );
};

export default Resource;
