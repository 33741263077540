import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import first from "lodash/first";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";

import { useDispatch } from "react-redux";
import { useGetBots } from "../../actions/bots";
import { getCompanies } from "../../actions/companies";
import { getFlows } from "../../actions/flows";
import { trainExpression } from "../../actions/metrics";
import { getReport, getReportFile } from "../../actions/reports";
import { updateById } from "../../helpers";
import Checkbox from "../common/FormComponent/Checkbox";
import Layout from "../common/Layout";
import Table from "../common/SelectableTable";
import { notify, notifyError } from "../common/Toasts";
import ReportsFilters from "./components/ReportsFilters";
import TrainFlow from "./components/TrainFlow";

const Reports = () => {
    const companies = useSelector((state) => state.companies);
    const flows = useSelector((state) => state.flows);
    const [sorting, setSorting] = useState([]);
    const [initialDate, setInitialDate] = useState(dayjs().day(1).startOf("month").format());
    const [finalDate, setFinalDate] = useState(dayjs().endOf("day").format());
    const [botSelected, setBotSelected] = useState([]);
    const [filteredBots, setFilteredBots] = useState("");
    const [companySelected, setCompanySelected] = useState([]);
    const [pageLimit, setPageLimit] = useState(1);
    const [rows, setRows] = useState(10);
    const [message, setMessage] = useState(null);
    const [flowType, setFlowType] = useState(null);
    const [flowIsDefault, setFlowIsDefault] = useState(null);
    const [selected, setSelected] = useState([]);
    const maxPage = 1000;

    const [currentTrain, setCurrentTrain] = useState([]);
    const [error, setError] = useState([]);
    const [flowTrain, setFlowTrain] = useState([]);
    const [data, setData] = useState([]);
    const [downloadingFile, setDownloadingFile] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const controller = new AbortController();

    const { t } = useTranslation();

    const { data: bots = [] } = useGetBots();

    useEffect(() => {
        dispatch(getCompanies());
    }, []);

    useEffect(() => {
        if (!isEmpty(companies) && !isEmpty(bots)) {
            setCompanySelected(first(orderBy(companies, ["name"], ["asc"])));
            handleChange(first(orderBy(companies, ["name"], ["asc"])));
        }
    }, [companies, bots]);

    useEffect(() => {
        getReports();
        return () => controller.abort();
    }, [pageLimit, rows, initialDate, finalDate, botSelected, companySelected, flowType, flowIsDefault, message]);

    useEffect(() => {
        if (!isEmpty(companySelected) && !isEmpty(botSelected)) {
            getReports();
        }
    }, [companySelected, botSelected]);

    const selectRow = (id) => {
        let selected = data.find((row) => {
            return row.id === id;
        });
        if (!get(selected, "select")) {
            selected = { ...selected, select: true };
        } else {
            selected.select = false;
        }
        setData(updateById(data, selected));
    };

    useEffect(() => {
        if (!isEmpty(data)) {
            handleSelected2();
        }
    }, [data]);

    const cleanSelected = () => {
        setSelected([]);
    };

    const handleSelected2 = () => {
        const selected = data.filter((row) => {
            return row.select;
        });
        setSelected(selected);
    };

    const handleSelected = (row) => {
        selectRow(row.id);
    };

    const setFlow = (target) => {
        const value = target;
        const flow = flows.find((flow) => {
            return flow.id === parseInt(value.id, 10);
        });
        setCurrentTrain({ ...currentTrain, intentId: flow.intentId, intentSlug: flow.intent, flowTitle: flow.title });
        setError("");
        setFlowTrain(value);
    };

    const handleChange = (company) => {
        setCompanySelected(company);
        setFilteredBots(bots.filter((bot) => bot.companyId === company.id));
        setBotSelected(first(bots.filter((bot) => bot.companyId === company.id)));
    };

    useEffect(() => {
        if (!isEmpty(botSelected)) {
            dispatch(getFlows(botSelected.id));
        }
    }, [botSelected]);

    useEffect(() => {
        if (isEmpty(selected)) {
            setFlowTrain(null);
        }
    }, [selected]);

    const handleChangeBot = (bot) => {
        setBotSelected(bot);
    };

    const handleChangeFlow = (flow) => {
        setFlowType(flow);
    };

    const handleChangeWord = (word) => {
        setFlowIsDefault(word);
    };

    const execute = (initialDateSent, finalDateSent) => {
        getReports(initialDateSent, finalDateSent);
    };

    const cleanBots = () => {
        setBotSelected({ id: -1 });
    };

    const columnHelper = createColumnHelper();

    const parseType = (type) => {
        switch (type) {
            case "safe":
                return "Transacción";
            case "location":
                return "Ubicación";
            default:
                return "Informativa";
        }
    };

    const handleInput = (evt) => {
        const { target } = evt;
        const { value } = target;
        setMessage(value);
        if (isEmpty(value)) {
            setMessage(null);
        }
    };

    const downLoadExcel = async () => {
        setDownloadingFile(true);
        const botId = get(botSelected, "id", null);
        const companyId = get(companySelected, "id", null);
        const flowTypeS = get(flowType, "value", null);
        const flowIsDefaultS = get(flowIsDefault, "value", null);
        await dispatch(getReportFile(initialDate, finalDate, null, message, null, botId, companyId, flowIsDefaultS, null, flowTypeS))
            .then(({ message }) => {
                setDownloadingFile(false);
                notify(t(message));
            })
            .catch(({ message }) => {
                setDownloadingFile(false);
                notifyError(t(message));
            });
    };

    const columns = [
        {
            Header: " ",
            id: "select",
            cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original.flowIsDefault && !original.isTrained && (
                            <Checkbox className="align-center flex h-full w-full" checked={original.select} />
                        )}
                    </div>
                );
            },
        },
        columnHelper.accessor("referenceId", {
            cell: (info) => info.getValue(),
            header: () => <span>Id</span>,
        }),
        columnHelper.accessor("message", {
            cell: (info) => (
                <div className="w-72">
                    <p className="truncate">{info.getValue()}</p>
                </div>
            ),
            header: () => <span>{t("reports.Mensaje")}</span>,
        }),
        columnHelper.accessor("flowIsDefault", {
            header: () => <span className="flex w-full justify-center">{t("reports.Entendido")}</span>,
            cell: ({ row: { original } }) => (
                <div className="w-full">
                    {original.isTrained ? (
                        <div className="mx-auto h-3 w-3 rounded-full bg-blue opacity-75" />
                    ) : !original.flowIsDefault ? (
                        <div className="mx-auto h-3 w-3 rounded-full bg-green opacity-75" />
                    ) : (
                        <div className="mx-auto h-3 w-3 rounded-full bg-red opacity-75" />
                    )}
                </div>
            ),
        }),
        columnHelper.accessor("flowName", {
            header: () => <span>{t("reports.Flujo")}</span>,
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor("flowType", {
            header: () => <span>{t("reports.Tipo")}</span>,
            cell: (info) => parseType(info.getValue()),
        }),
        columnHelper.accessor("createdAt", {
            header: t("reports.Fecha"),
            cell: (info) => {
                return <div>{dayjs(info.getValue()).add(5, "hour").format("DD/MM/YYYY / HH:mm:ss")}</div>;
            },
        }),
    ];

    const getReports = async (initialDateSent, finalDateSent) => {
        setLoadingData(true);
        const initDate = !isEmpty(initialDateSent) ? initialDateSent : initialDate;
        const finlDate = !isEmpty(finalDateSent) ? finalDateSent : finalDate;

        const botId = get(botSelected, "id", null);
        const companyId = get(companySelected, "id", null);
        const flowTypeS = get(flowType, "value", null);
        const flowIsDefaultS = get(flowIsDefault, "value", null);
        return await dispatch(
            getReport(pageLimit, rows, initDate, finlDate, null, message, botId, companyId, flowIsDefaultS, null, true, flowTypeS, controller)
        ).then((res) => {
            setLoadingData(false);
            setData(res);
        });
    };

    const trainWord = async (array) => {
        const { intentId, intentSlug, flowTitle } = currentTrain;
        const companyId = get(companySelected, "id", null);
        if (!isEmpty(intentId) || !isEmpty(intentSlug)) {
            dispatch(trainExpression(array, companyId, flowTitle))
                .then(() => {
                    getReports();
                    setFlowTrain(null);
                    setError("");
                    setLoading(false);
                })
                .catch((err) => {
                    setError("reports.Error al entrenar");
                    setLoading(false);
                });
        }
    };

    const cleanWords = () => {
        setFlowIsDefault(null);
    };

    const cleanFlows = () => {
        setFlowType(null);
    };

    return (
        <Layout showSidebar={false} screen={false}>
            <div className="my-24 flex w-full flex-col px-10">
                <div className="rounded-2xl bg-white p-5 shadow-lg">
                    <h1 className="pb-5 text-lg font-medium text-primary-200">{t("reports.Reporte de Vocablos")}</h1>
                    <ReportsFilters
                        companies={companies}
                        companySelected={companySelected}
                        filteredBots={filteredBots}
                        botSelected={botSelected}
                        initialDate={initialDate}
                        setInitialDate={setInitialDate}
                        finalDate={finalDate}
                        setFinalDate={setFinalDate}
                        handleChange={handleChange}
                        handleChangeBot={handleChangeBot}
                        cleanBots={cleanBots}
                        execute={execute}
                        flowType={flowType}
                        flowIsDefault={flowIsDefault}
                        cleanFlows={cleanFlows}
                        cleanWords={cleanWords}
                        handleChangeFlow={handleChangeFlow}
                        handleChangeWord={handleChangeWord}
                        handleInput={handleInput}
                        downloadingFile={downloadingFile}
                        loadingRefresh={loadingData}
                        downLoadExcel={downLoadExcel}
                        getReports={getReports}
                    />

                    <TrainFlow
                        rows={data}
                        flowIsDefault={flowIsDefault}
                        flowTrain={flowTrain}
                        selected={selected}
                        flows={flows}
                        trainWord={trainWord}
                        setFlow={setFlow}
                        cleanSelected={cleanSelected}
                        error={error}
                        loading={loading}
                        setLoading={setLoading}
                    />

                    <Table
                        columns={columns}
                        data={data}
                        pageLimit={pageLimit}
                        setPageLimit={setPageLimit}
                        rows={rows}
                        setRows={setRows}
                        maxPage={maxPage}
                        sorting={sorting}
                        setSorting={setSorting}
                        handleSelected={handleSelected}
                        loading={loadingData}
                    />
                </div>
            </div>
        </Layout>
    );
};

export default Reports;
