import { useRef } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import ReconnectIcon from "../icons/ReconnectIcon";
import { APPS_URL } from "../../constants";

const ReconnectModal = (props) => {
    const { t } = useTranslation();
    const salesModal = useRef();

    const login = () => {
        const lang = localStorage.getItem("lang") || "es";
        localStorage.clear();
        localStorage.setItem("lang", lang);

        window.location = APPS_URL;
    };

    return ReactDOM.createPortal(
        <div className="absolute inset-x-0 top-0 z-120 sm:inset-0 sm:flex sm:items-center sm:justify-center">
            <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 z-20 bg-gray-490/75" />
            </div>
            <div
                ref={salesModal}
                className="border-3 hover:shadow-data-card relative z-[125] flex w-[40rem] flex-col items-center overflow-hidden rounded-3xl border-white bg-white py-10 pr-6 text-center text-lg font-medium text-gray-400">
                <div className="flex items-center space-x-8">
                    <ReconnectIcon width="301" height="241" />
                    <div className="flex flex-col">
                        <p className="text-left text-[1.875rem] font-bold text-primary-200">{t(`home.Ups`)}</p>
                        <p className="text-left font-bold text-primary-200">{t("home.tu sesión ha expirado")}</p>
                        <div className="w-[12.3125rem]">
                            <p className="mt-2 text-left text-15 font-light leading-[1.375rem] text-gray-400">
                                {t("home.Tu sesión expiró debido a tu inactividad. No te preocupes, inicia sesión nuevamente")}
                            </p>
                            <div className="flex justify-end">
                                <button
                                    className="color-gradient mt-4 w-max rounded-20 px-4 py-2 text-sm font-bold text-white"
                                    onClick={() => login()}>
                                    {t("home.Iniciar sesión")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        document.getElementById("root")
    );
};

export default ReconnectModal;
