const DownloadIcon = (props) => {
    return (
        <svg className={props.className} width={props.width} height={props.height} viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.19723 10.0065C5.19723 9.909 5.19723 9.84953 5.19723 9.79007C5.19723 6.94175 5.19844 4.09343 5.19602 1.24512C5.19561 0.780248 5.38713 0.411472 5.83401 0.167434C6.65545 -0.281117 7.73548 0.221463 7.7872 1.0823C7.81144 1.48734 7.79649 1.89455 7.79649 2.30068C7.7969 4.81177 7.7969 7.32285 7.7969 9.83358C7.7969 9.87963 7.7969 9.92568 7.7969 9.97174C7.81912 9.9866 7.84175 10.0015 7.86397 10.0163C7.88741 9.97391 7.90074 9.92242 7.9363 9.89051C8.88137 9.03946 9.82403 8.1866 10.7768 7.34244C11.46 6.73687 12.5809 6.95444 12.909 7.75727C13.1009 8.22648 12.9865 8.65255 12.5926 9.00828C11.7505 9.76831 10.9045 10.5247 10.0596 11.2826C9.19451 12.0589 8.33025 12.8353 7.46436 13.6109C6.89223 14.1233 6.13624 14.1302 5.54916 13.6283C5.39925 13.5003 5.2546 13.3672 5.10834 13.236C3.75961 12.0259 2.41089 10.8159 1.06257 9.6055C0.834278 9.40062 0.605584 9.19683 0.380931 8.98906C-0.130194 8.51585 -0.126153 7.79135 0.388205 7.33446C0.895694 6.88337 1.70258 6.88228 2.22058 7.34352C3.16768 8.18623 4.10831 9.03475 5.05177 9.88072C5.08773 9.91408 5.12612 9.94563 5.19723 10.0065Z"
                fill={props.fill ? props.fill : "white"}
            />
        </svg>
    );
};
export default DownloadIcon;
