import dayjs from "dayjs";
import "dayjs/locale/es";
import DebounceInput from "react-debounce-input";

import orderBy from "lodash/orderBy";
import DateRangePicker from "../../common/DateRangePicker";
import ComboboxSelect from "../../common/Combobox";
import DateIcon from "../../icons/DateIcon";
import DownloadIcon from "../../icons/DownloadIcon";
import { useTranslation } from "react-i18next";
import RefreshIcon from "../../icons/RefreshIcon";
import { BeatLoader } from "react-spinners";

const ReportsFilters = (props) => {
    const {
        initialDate,
        setInitialDate,
        finalDate,
        setFinalDate,
        handleChange,
        handleChangeBot,
        cleanBots,
        companySelected,
        filteredBots,
        botSelected,
        companies,
        execute,
        flowType,
        flowIsDefault,
        cleanWords,
        cleanFlows,
        handleChangeFlow,
        handleChangeWord,
        message,
        handleInput,
        downloadingFile,
        downLoadExcel,
        loadingRefresh,
        getReports,
    } = props;
    const { t } = useTranslation();

    const FLOW_TYPES = [
        {
            id: 1,
            value: "safe",
            name: t("filters.Transacción"),
        },
        {
            id: 2,
            value: "location",
            name: t("filters.Ubicación"),
        },
        {
            id: 3,
            value: "default",
            name: t("filters.Informativa"),
        },
        {
            id: 4,
            value: "vinculacion",
            name: t("filters.Vinculación"),
        },
        {
            id: 5,
            value: "desvinculacion",
            name: t("filters.Desvinculación"),
        },
    ];

    const WORDS_OPTIONS = [
        { id: 0, value: "0", name: t("filters.Vocablos Entendidos") },
        { id: 1, value: "1", name: t("filters.Vocablos no Entendidos") },
        { id: 2, value: "2", name: t("filters.Vocablos Entrenados") },
    ];

    const dateChange = (range) => {
        let [startDate, endDate] = range;
        setInitialDate(dayjs(startDate).format());
        setFinalDate(dayjs(endDate).endOf("day").format());
        execute(dayjs(startDate).format(), dayjs(endDate).endOf("day").format());
    };

    return (
        <div className="mb-3 flex flex-col space-y-5">
            <div className="flex w-full flex-col space-x-4 sm:flex-row sm:items-end">
                {companies.length > 1 && (
                    <div className="flex w-64">
                        <ComboboxSelect
                            options={orderBy(companies, ["name"], ["asc"])}
                            value={companySelected}
                            placeholder={t("filters.Seleccione Compañía")}
                            label={t("filters.Compañía")}
                            handleChange={handleChange}
                            name={"Compañía"}
                        />
                    </div>
                )}
                <div className="flex w-64">
                    <ComboboxSelect
                        options={orderBy(filteredBots, ["name"], ["asc"])}
                        value={botSelected}
                        placeholder={t("filters.Seleccione Bot")}
                        label={"Bot"}
                        handleChange={handleChangeBot}
                        name={"Bot"}
                        hasCleanFilter
                        clearFilter={cleanBots}
                    />
                </div>
                <div className="flex w-64">
                    <ComboboxSelect
                        options={orderBy(FLOW_TYPES, ["name"], ["asc"])}
                        value={flowType}
                        placeholder={t("filters.Seleccione flujo")}
                        label={t("filters.Flujo")}
                        handleChange={handleChangeFlow}
                        name={"Flujo"}
                        hasCleanFilter
                        clearFilter={cleanFlows}
                    />
                </div>
                <div className="flex w-64">
                    <ComboboxSelect
                        options={orderBy(WORDS_OPTIONS, ["name"], ["asc"])}
                        value={flowIsDefault}
                        placeholder={t("filters.Seleccione Vocablo")}
                        label={t("filters.Vocablo")}
                        handleChange={handleChangeWord}
                        name={"Vocablo"}
                        hasCleanFilter
                        clearFilter={cleanWords}
                    />
                </div>
                <div className="flex">
                    <DateRangePicker
                        icon={<DateIcon width="1rem" height="1.0625rem" fill="#A6B4D0" />}
                        dateValue={[initialDate, finalDate]}
                        dateChange={dateChange}
                        right
                    />
                </div>
                <button onClick={() => getReports()} className="flex items-center justify-center rounded-full bg-gray-20 text-gray-425 lg:h-8 lg:w-8">
                    <RefreshIcon
                        width="1.25rem"
                        height="1.25rem"
                        fill="currentColor"
                        className={`fill-current text-gray-100 ${loadingRefresh ? "animate-spinother" : ""}`}
                    />
                </button>
                <button
                    onClick={downLoadExcel}
                    className="color-gradient flex h-8 w-8 items-center justify-center rounded-full bg-primary-600 font-semibold text-white">
                    {downloadingFile ? (
                        <BeatLoader color={"#fff"} size={"0.25rem"} />
                    ) : (
                        <DownloadIcon className="fill-current text-white" width="0.8125rem" height="0.9375rem" />
                    )}
                </button>
            </div>
            <div className="w-64">
                <DebounceInput
                    type="search"
                    // ref={input}
                    value={message}
                    className="input"
                    placeholder={t("common.Buscar por mensaje")}
                    minLength={2}
                    debounceTimeout={500}
                    onChange={handleInput}
                    autoFocus
                />
            </div>
        </div>
    );
};

export default ReportsFilters;
