import shortid from "shortid";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-autosize-textarea";

import LeftIcon from "../../../icons/LeftIcon";
import PlusIcon from "../../../icons/PlusIcon";
import RightIcon from "../../../icons/RightIcon";
import TrashIcon from "../../../icons/TrashIcon";

import RandomIcon from "../../../icons/RandomIcon";

import { NEW, UPDATE } from "../../../../constants";
import BubbleRoot from "../BubbleRoot";
/* Styles */
const textArea =
    "rounded-10 shadow-textArea w-full px-3 py-2 text-gray-400 text-15 focus:outline-none border-transparent focus:ring-primary-200 focus:border-primary-200 placeholder:text-gray-400 placeholder:text-opacity-70";

const arrowStyle =
    "absolute top-0 right-0 h-9 w-6 flex items-center justify-center cursor-pointer text-primary-200 hover:text-primary-200 -mt-9 z-10";
const arrowIconStyles = "fill-current";
const arrowSize = "18px";

function NextArrow(props) {
    const { onClick } = props;
    return (
        <div className={arrowStyle} onClick={onClick}>
            <RightIcon className={arrowIconStyles} width={arrowSize} height={arrowSize} />
        </div>
    );
}

function PrevArrow(props) {
    const { onClick } = props;
    return (
        <div className={`${arrowStyle} mr-8`} onClick={onClick}>
            <LeftIcon className={arrowIconStyles} width={arrowSize} height={arrowSize} />
        </div>
    );
}

const RandomBubble = (props) => {
    const { t } = useTranslation();

    const renderRandom = (random) => {
        return (
            <div className={`${props.bubbleStyles} relative`}>
                <button
                    className="absolute -right-[0.75rem] top-0 flex items-center justify-center rounded-md bg-white p-1 text-gray-400 shadow-element hover:bg-red hover:text-white"
                    onClick={() => props.deleteRandom(random)}>
                    <TrashIcon className="fill-current" width="16px" height="16px" />
                </button>
                <div className="flex items-center rounded-t-xl bg-white p-3">
                    <RandomIcon className="mx-2 fill-current text-gray-400" width="19" height="16" />
                    <span className="font-medium text-gray-400">{t("bubbles.Aleatorio")}</span>
                </div>
                <div className="bg-gray-13">
                    <div className="my-3 flex flex-col items-start px-2">
                        <div className="flex min-h-20 w-full items-center">
                            <TextareaAutosize
                                className={textArea}
                                placeholder={t("Ingresa el texto que deseas que la persona vea cuando invoca el flujo seleccionado.")}
                                style={{ minHeight: 80 }}
                                onChange={({ target }) => {
                                    props.updateRandom({
                                        ...random,
                                        text: target.value,
                                        action: random.action === NEW ? NEW : UPDATE,
                                    });
                                }}
                                value={random.text}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    // Set initial Slide to always show the latest two slides
    //const initialSlide = this.props.randoms.size - 1; // Get the last index of the randoms array
    const settings = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        variableWidth: true,
        swipe: true,
        adaptiveHeight: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        //initialSlide: initialSlide > 3 ? initialSlide - 1 : initialSlide
    };

    const { bubble } = props;

    return (
        <BubbleRoot {...props} bubbleStyles={props.bubbleStyles}>
            <div className="flex flex-col">
                <button
                    className="mb-2 flex h-9 w-fit items-center justify-center rounded-8 bg-white px-2 text-center text-sm font-medium text-primary-200 shadow-element"
                    onClick={() =>
                        props.addRandom({
                            id: shortid.generate(),
                            text: "",
                            bubbleId: bubble.id,
                            action: NEW,
                        })
                    }>
                    <PlusIcon className="fill-current text-primary-200" width="18px" height="18px" />
                    {t("common.Nuevo mensaje aleatorio")}
                </button>
                <div className="relative">
                    <Slider {...settings} className="slider-size">
                        {props.randoms.map((random) => {
                            return <div className="relative mr-6">{renderRandom(random)}</div>;
                        })}
                    </Slider>
                </div>
            </div>
        </BubbleRoot>
    );
};

export default RandomBubble;
