import get from "lodash/get";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { updateRunActionBubble } from "../../../../actions/runAction";
import { UPDATE } from "../../../../constants";
import FormComboboxSelect from "../../../common/FormCombobox";
import BubbleContainer from "../BubbleContainer";

const RunActionBubble = (props) => {
    const { runActionBubble } = props;
    const { t } = useTranslation();
    const [url, setUrl] = useState(get(runActionBubble, "payload.url", ""));

    const dispatch = useDispatch();

    const action = get(runActionBubble, "action", "");

    const variableUrl = (evt) => {
        const value = evt.target.value;
        setUrl(value);
        update({ payload: { url: value } });
    };

    const runActionsOptions = [
        {
            id: "1",
            name: "Redireccionar",
            value: "REDIRECT",
        },
    ];

    function update(value) {
        const bubble = props.bubble;
        const bubbleAction = get(props, "bubble.action", UPDATE);

        const updateBubble = {
            ...bubble,
            action: bubbleAction,
        };

        const runA = {
            ...runActionBubble,
            ...value,
            action: "REDIRECT",
        };

        props.updateBubble(updateBubble);
        dispatch(updateRunActionBubble(runA));
    }

    return (
        <div className="flex text-gray-400">
            <BubbleContainer
                {...props}
                bubbleStyles={props.bubbleStyles}
                icon={
                    <span className="mx-2 text-gray-400">
                        <svg width="18" height="18" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L15 10L1 19V1Z" stroke="#727C94" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </span>
                }
                title="Run Action">
                <div className="mb-4 grid gap-4 px-4 pt-4">
                    <label htmlFor="title">
                        Accionable
                        <FormComboboxSelect
                            handleChange={() => console.log("handleChange")}
                            options={runActionsOptions}
                            placeholder={t("toolkit.selecciona_opcion")}
                            value={runActionsOptions.find((option) => option.value === action)}
                            hasCleanFilter={false}
                            className={"bubble-form-input"}
                        />
                    </label>
                    <label htmlFor="title">
                        Url
                        <input
                            type="text"
                            name="variable"
                            className="form-input !bg-white"
                            value={url}
                            placeholder="Ej: mi_respuesta"
                            onChange={variableUrl}
                        />
                    </label>
                </div>
            </BubbleContainer>
        </div>
    );
};
export default RunActionBubble;
