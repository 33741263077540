import initialState from "./initialState";
import { TOGGLE_UNSAVED_CHANGES, SET_UNSAVED_CHANGES, UNSET_UNSAVED_CHANGES } from "../constants";

function unSavedChanges(state = initialState.unSavedChanges, action) {
    switch (action.type) {
        case TOGGLE_UNSAVED_CHANGES:
            return !state;
        case SET_UNSAVED_CHANGES:
            return true;
        case UNSET_UNSAVED_CHANGES:
            return false;
        default:
            return state;
    }
}

export default unSavedChanges;
