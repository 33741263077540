import { useTranslation } from "react-i18next";
import WarningIcon from "../../../icons/WarningIcon";
import BubbleContainer from "../BubbleContainer";

const UnsupportedBubble = (props) => {
    const bubbleStyle = "relative px-2 w-full my-2";
    const { bubble } = props;
    const { t } = useTranslation();

    return (
        <BubbleContainer
            {...props}
            bubbleStyles={props.bubbleStyles}
            icon={<WarningIcon className="mr-2 fill-current text-yellow-darker" width="0.9rem" />}
            title={t("bubbles.Burbuja no soportada")}>
            <div className="relative flex-1 rounded-lg">
                <div className={bubbleStyle}>
                    <div className="my-2 flex w-full items-center justify-center">
                        <div className="flex w-full items-center justify-center">
                            <div className="border-default flex min-h-7 w-full items-center rounded-lg border-gray-100 border-opacity-25 bg-white px-4 py-1 text-center text-xs text-gray-450">
                                <p>{`${t("bubbles.Tipo de burbuja")}${": "}`}</p>
                                <span className="ml-2 block italic">{bubble?.type}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BubbleContainer>
    );
};

export default UnsupportedBubble;
