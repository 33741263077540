import TrashIcon from "../../../../icons/TrashIcon";

const DeleteButton = (props) => (
    <button
        className="absolute right-0 mr-1 flex items-center justify-center rounded-md bg-white p-1 text-gray-400 shadow-element hover:bg-red hover:text-white"
        onClick={() => {
            props.deleteOption();
        }}>
        <TrashIcon className="fill-current" width="16px" height="16px" />
    </button>
);

export default DeleteButton;
