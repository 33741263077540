import get from "lodash/get";
import { NavLink } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

/* Components */
import { Modal } from "./GenericModal";
// import BotIcon from "../icons/BotIcon";
import CloseIcon from "../icons/CloseIcon";
// import ReportIcon from "../icons/ReportIcon";
import { SidebarRigth } from "./SidebarRight";
import EnglishIcon from "../icons/EnglishIcon";
import SpanishIcon from "../icons/SpanishIcon";
import LanguageIcon from "../icons/LanguageIcon";
import BackArrowIcon from "../icons/BackArrowIcon";
import HamburgerIcon from "../icons/HamburgerIcon";
import PortugueseIcon from "../icons/PortugueseIcon";

/* Actions */
import i18n from "../../utils/i18n";
import { APPS_URL } from "../../constants";
import { JelouAppsAPI } from "../../services/Http";
import { logOutUser } from "../../actions/sessionActions";
import { hasPermission } from "../../utils/utils";

const DropdownMenu = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [flag, setFlag] = useState();
    const [currentLangDescription, setCurrentLangDescription] = useState("");
    const [loadingLang, setLoadingLang] = useState("");
    const user = useSelector((state) => state.user);
    const userLang = get(user, "lang", "es");
    const unSavedChanges = useSelector((state) => state.unSavedChanges);
    const [currentLang, setCurrentLang] = useState(userLang);
    const { t } = useTranslation();
    const savingChanges = useSelector((state) => state.savingChanges);
    const permissions = useSelector((state) => state.permissions);

    const [showWarningModal, setShowWarningModal] = useState(false);
    const [langSelected, setLangSelected] = useState("");

    const showTab = (permission) => hasPermission(permissions, permission);

    const dispatch = useDispatch();

    const handleUserLanguage = (lang) => {
        if (unSavedChanges || savingChanges) {
            setShowWarningModal(true);
            setLangSelected(lang);
        } else {
            handleLanguage(lang);
        }
    };

    const handleLanguage = async (lang) => {
        setLoadingLang(true);
        try {
            await JelouAppsAPI.patch("user/profile", { lang }).then(() => {
                setLanguage(lang);
                setLoadingLang(false);
            });
        } catch (error) {
            setLoadingLang(false);
            console.log(error);
        }
    };

    const setLanguage = (lang) => {
        setCurrentLang(lang);
        i18n.changeLanguage(lang);
        setFlag(setFlagLang(lang));
    };

    const setFlagLang = (lang) => {
        switch (lang) {
            case "es":
                setCurrentLangDescription(t("common.Español"));
                return <SpanishIcon className="mr-2 cursor-pointer fill-current text-gray-450" width="1rem" height="1.15rem" />;
            case "en":
                setCurrentLangDescription(t("common.Ingles"));
                return <EnglishIcon className="mr-2 cursor-pointer fill-current text-gray-450" width="1rem" height="1.15rem" />;
            default:
                setCurrentLangDescription(t("common.Portugues"));
                return <PortugueseIcon className="mr-2 cursor-pointer fill-current text-gray-450" width="1rem" height="1.15rem" />;
        }
    };

    useEffect(() => {
        setFlag(setFlagLang(currentLang));
    }, []);

    const optionStyle = "text-gray-400 font-sans m-2 flex items-center outline-none";
    const segmentation = "text-center hover:bg-[#E5F7F9] pt-1 pb-1 group pl-3 flex items-center w-full";

    return (
        <>
            <button className="h-10 focus:outline-none" onClick={() => setShowMenu(!showMenu)}>
                <div className="mr-5">
                    <HamburgerIcon width="1.125rem" height="0.75rem" />
                </div>
            </button>
            <SidebarRigth open={showMenu} setOpen={setShowMenu} title={"Menu"}>
                <Menu as="div" className="relative inline-block w-full border-y text-left">
                    <div>
                        <Menu.Button className="relative flex w-full items-center px-2 py-5 text-sm text-gray-400 hover:bg-[#E5F7F9] focus:outline-none">
                            <span className="flex w-16 items-center">
                                <BackArrowIcon className="mx-2 inline fill-current text-blue-light hover:text-primary-200" width="12" height="12" />
                                <LanguageIcon width={30} height={22} />
                            </span>
                            <div className="ml-3 mr-10 flex flex-col space-y-1 text-left text-xs">
                                <span>{t("common.Idioma")}</span>
                                {loadingLang ? (
                                    <BeatLoader size={5} color="#727c94" />
                                ) : (
                                    <span className={`flex items-center font-bold`}>
                                        <span className="rounded-md">{flag}</span>
                                        <span className="ml-1 flex">{currentLangDescription}</span>
                                    </span>
                                )}
                            </div>
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-25"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95">
                        <Menu.Items className="divide-y-1 absolute left-0 top-0 z-50 -m-[14.375rem] mt-0 w-56 divide-gray-400/10 rounded-lg bg-white text-gray-400 shadow-lg focus:outline-none">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        onClick={() => handleUserLanguage("es")}
                                        className={`group relative flex w-full cursor-pointer items-center space-x-3 rounded-t-lg px-3 py-2 text-sm text-gray-400 ${
                                            currentLang === "es" ? "font-bold" : ""
                                        } ${active ? " hover:bg-[#E5F7F9]" : ""}`}>
                                        <SpanishIcon className="cursor-pointer fill-current text-gray-450" width="1.5rem" height="1.625rem" />
                                        <span className={`${"flex w-full items-center text-sm"}`}>{t("common.Español")}</span>
                                    </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        onClick={() => handleUserLanguage("en")}
                                        className={`group relative flex w-full cursor-pointer items-center space-x-3 px-3 py-2 text-sm text-gray-400 ${
                                            currentLang === "en" ? "font-bold" : ""
                                        } ${active ? " hover:bg-[#E5F7F9]" : ""}`}>
                                        <EnglishIcon className="cursor-pointer fill-current text-gray-450" width="1.5rem" height="1.625rem" />
                                        <span className={`${"flex w-full items-center text-sm"}`}>{t("common.Ingles")}</span>
                                    </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        onClick={() => handleUserLanguage("pt")}
                                        className={`group relative flex w-full cursor-pointer items-center space-x-3 rounded-b-lg px-3 py-2 text-sm text-gray-400 ${
                                            currentLang === "pt" ? "font-bold" : ""
                                        } ${active ? " hover:bg-[#E5F7F9]" : ""}`}>
                                        <PortugueseIcon className="cursor-pointer fill-current text-gray-450" width="1.5rem" height="1.625rem" />
                                        <span className={`${"flex w-full items-center text-sm"}`}>{t("common.Portugues")}</span>
                                    </button>
                                )}
                            </Menu.Item>
                        </Menu.Items>
                    </Transition>
                </Menu>
                {showTab("view bot") && (
                    <NavLink to="/" className="group">
                        <div className={segmentation}>
                            <span className={optionStyle}>
                                <div className="text-15 font-medium text-gray-400">{t("Bots")}</div>
                            </span>
                        </div>
                    </NavLink>
                )}

                {showTab("view metrics") && (
                    <NavLink to="/metrics" className="group">
                        <div className={segmentation}>
                            <span className={optionStyle}>
                                <div className="text-15 font-medium text-gray-400">{t("common.Métricas")}</div>
                            </span>
                        </div>
                    </NavLink>
                )}
                {showTab("view report") && (
                    <NavLink to="/reports" className="group">
                        <div className={segmentation}>
                            <span className={optionStyle}>
                                <div className="text-15 font-medium text-gray-400">{t("common.Reportes")}</div>
                            </span>
                        </div>
                    </NavLink>
                )}
                {showTab("view resource view") && (
                    <NavLink to="/resources" className="group">
                        <div className={segmentation}>
                            <span className={optionStyle}>
                                <div className="text-15 font-medium text-gray-400">{t("common.Recursos")}</div>
                            </span>
                        </div>
                    </NavLink>
                )}

                <NavLink to="/resource-report" className="group">
                    <div className={segmentation}>
                        <span className={optionStyle}>
                            <div className="text-15 font-medium text-gray-400">{t("reports.Reporte de Recursos")}</div>
                        </span>
                    </div>
                </NavLink>

                {/* <Renderize permission="view resource report">
                            <NavLink to="/reports/resources" className="group">
                                <div className={segmentation}>
                                    <span className={optionStyle}>{t("Reporte de Recursos")}</span>
                                </div>
                            </NavLink>
                        </Renderize> 
                        <Renderize permission="view context">
                            <NavLink to="/reports/contexts" className="group">
                                <div className={segmentation}>
                                    <span className={optionStyle}>{t("Reporte de Contextos")}</span>
                                </div>
                            </NavLink>
                        </Renderize> */}

                <button
                    className={segmentation}
                    onClick={() => {
                        window.location.href = APPS_URL;
                        localStorage.clear();
                        dispatch(logOutUser());
                    }}>
                    <span className={optionStyle}>
                        <span className="flex justify-center">
                            <BackArrowIcon className="mr-2 fill-current" width="10" height="10" />
                        </span>
                        <button className="font-medium text-gray-400">{t("login.Regresar a APPS")}</button>
                    </span>
                </button>
            </SidebarRigth>
            <Modal
                openModal={showWarningModal}
                closeModal={() => null}
                title={t("Tienes cambios sin guardar")}
                className="h-min w-96 rounded-20 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)]">
                <div className="h-full flex-row">
                    <header className="relative flex items-center justify-between bg-primary-350 px-10 py-5">
                        <div className="flex items-center gap-x-4">
                            <div className="text-base font-semibold text-primary-200">{"Tienes cambios sin guardar"}</div>
                        </div>
                        <button className="absolute inset-y-0 right-0 mr-3" onClick={() => setShowWarningModal(false)}>
                            <CloseIcon width="32px" height="32px" className="stroke-current text-primary-200" />
                        </button>
                    </header>
                    <div className="px-10 py-6">
                        <p className="text-sm font-medium text-gray-400">{t("¿Estás seguro que deseas continuar?")}</p>

                        <footer className="mb-0 mt-6 flex items-center justify-end gap-x-3">
                            <button
                                type="reset"
                                onClick={() => setShowWarningModal(false)}
                                className="h-9 w-28 rounded-3xl bg-gray-10 font-bold text-gray-400">
                                Omitir
                            </button>
                            <button
                                onClick={() => {
                                    handleLanguage(langSelected);
                                    setShowWarningModal(false);
                                }}
                                className="flex h-9 w-28 min-w-fit items-center justify-center rounded-3xl bg-primary-200 px-5 font-bold text-white">
                                {t("common.Sí")}
                            </button>
                        </footer>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default DropdownMenu;
