import React from "react";

import Icon from "./Icon";

const PlusIcon = (props) => (
    <Icon viewBox="0 0 20 20" className={props.className} width={props.width} height={props.height}>
        <path
            d="M15.8333 9.16665H10.8333V4.16665C10.8333 3.94563 10.7455 3.73367 10.5893 3.57739C10.433 3.42111 10.221 3.33331 10 3.33331C9.779 3.33331 9.56703 3.42111 9.41075 3.57739C9.25447 3.73367 9.16668 3.94563 9.16668 4.16665V9.16665H4.16668C3.94566 9.16665 3.7337 9.25444 3.57742 9.41072C3.42114 9.567 3.33334 9.77897 3.33334 9.99998C3.33334 10.221 3.42114 10.433 3.57742 10.5892C3.7337 10.7455 3.94566 10.8333 4.16668 10.8333H9.16668V15.8333C9.16668 16.0543 9.25447 16.2663 9.41075 16.4226C9.56703 16.5788 9.779 16.6666 10 16.6666C10.221 16.6666 10.433 16.5788 10.5893 16.4226C10.7455 16.2663 10.8333 16.0543 10.8333 15.8333V10.8333H15.8333C16.0544 10.8333 16.2663 10.7455 16.4226 10.5892C16.5789 10.433 16.6667 10.221 16.6667 9.99998C16.6667 9.77897 16.5789 9.567 16.4226 9.41072C16.2663 9.25444 16.0544 9.16665 15.8333 9.16665Z"
            fill={props.fill}
        />
    </Icon>
);

export default PlusIcon;
