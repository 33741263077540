import React from "react";

import Icon from "./Icon";

const CleanIcon = (props) => (
    <Icon viewBox="0 0 14 19" className={props.className} width={props.width} height={props.height} fill="none">
        <path
            d="M10.6266 18.836C8.21398 18.2186 5.93075 17.3563 3.86703 15.9471C4.42755 14.928 4.98415 13.9207 5.53878 12.9133C5.52898 12.9074 5.51919 12.8996 5.50939 12.8937C4.68233 13.6972 3.85527 14.5008 3.0145 15.318C2.19332 14.6772 1.46033 13.9599 0.782227 13.1583C0.839062 13.1132 0.890018 13.072 0.942934 13.0309C1.6524 12.4802 2.37754 11.9471 3.06937 11.3768C4.04146 10.5752 4.85088 9.62662 5.51722 8.55655C5.55642 8.49383 5.59758 8.43112 5.64657 8.35272C7.88276 9.64426 10.1092 10.9299 12.3512 12.2234C11.2635 14.2989 10.4345 16.4293 10.6266 18.836Z"
            fill={props.fill}
        />
        <path
            d="M13.4998 10.1538C13.3117 10.5732 13.1216 11.0828 12.6982 11.4375C12.4709 11.6276 12.1945 11.5629 11.9398 11.416C10.6423 10.6634 9.34296 9.91471 8.04358 9.16605C7.54382 8.87795 7.0421 8.58985 6.54429 8.29979C6.13272 8.06069 6.03473 7.68832 6.26991 7.27675C6.40906 7.03177 6.54821 6.78483 6.6952 6.54377C6.90883 6.191 7.29688 6.0832 7.65553 6.29095C9.48211 7.34339 11.3087 8.39779 13.1333 9.45219C13.3685 9.58742 13.492 9.7932 13.4998 10.1538Z"
            fill={props.fill}
        />
        <path
            d="M13.6647 2.98469C13.4727 2.86514 13.2747 2.75539 13.0787 2.64368C12.6456 2.39477 12.2811 2.49277 12.0302 2.92785C11.3991 4.01361 10.77 5.09937 10.1409 6.18513C10.0155 6.40071 9.89986 6.62021 9.77051 6.85147C10.382 7.20621 10.9797 7.54918 11.5971 7.90783C12.3791 6.54966 13.1532 5.21304 13.9156 3.87054C14.0959 3.55697 13.9763 3.18067 13.6647 2.98469Z"
            fill={props.fill}
        />
        <path
            d="M2.00178 3C2.25222 4.36523 2.89698 5.17735 4 5.48683C2.91119 5.81607 2.24334 6.63038 2.00178 8C1.76377 6.63477 1.10657 5.82924 0 5.48903C1.11368 5.15979 1.77442 4.36084 2.00178 3Z"
            fill={props.fill}
        />
        <path
            d="M4.49776 1C4.71301 1.8051 5.1861 2.2931 6 2.5045C5.19283 2.71364 4.71301 3.20165 4.49776 4C4.28475 3.1904 3.80269 2.70915 3 2.50675C3.79821 2.29985 4.28475 1.82759 4.49776 1Z"
            fill={props.fill}
        />
        <path
            d="M1.99024 2C1.82927 1.47975 1.51463 1.16564 1 1.00614C1.50488 0.83681 1.83415 0.530061 1.99024 0C2.15854 0.510429 2.46585 0.834356 3 1.00368C2.46585 1.16074 2.16098 1.49693 1.99024 2Z"
            fill={props.fill}
        />
    </Icon>
);
export default CleanIcon;
