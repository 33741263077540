import initialState from "./initialState";
import { updateById, mergeById } from "../helpers";
import { ADD_CONDITIONALS, ADD_CONDITIONAL, UPDATE_CONDITIONAL, UPDATE_CONDITIONALS, DELETE_ALL_CONDITIONALS } from "../constants";

function conditionals(state = initialState.conditionals, action) {
    switch (action.type) {
        case ADD_CONDITIONALS:
            return [...action.payload];
        case ADD_CONDITIONAL:
            return [...state, action.payload];
        case UPDATE_CONDITIONAL:
            return updateById(state, action.payload);
        case UPDATE_CONDITIONALS:
            return mergeById(state, action.payload);
        case DELETE_ALL_CONDITIONALS:
            return [];
        default:
            return state;
    }
}

export default conditionals;
