import React from "react";

import Icon from "./Icon";

const BlocksIcon = (props) => (
    <Icon viewBox="0 0 100 100" className={props.className} width={props.width} height={props.height}>
        <path
            d="M91.52,60.02c0-5.47,0-10.94,0-16.42c0-3.27,0.1-6.56,0-9.83c-0.12-3.86-3.08-6.42-6.85-6.43c-1.77-0.01-3.54,0-5.32,0
		c-0.44-2.6-2.09-4.83-4.6-5.86c-1.18-0.49-2.4-0.58-3.66-0.58c-7.32,0-14.64,0-21.97,0c-6.88,0-13.77-0.03-20.65,0
		c-4.01,0.02-7.15,2.66-7.81,6.45c-1.59,0-3.19,0-4.78,0c-1.21,0-2.36,0.04-3.5,0.52c-2.03,0.86-3.48,2.71-3.81,4.89
		c-0.16,1.04-0.09,2.12-0.09,3.17c0,2.01,0,4.02,0,6.02c0,5.47,0,10.94,0,16.42c0,3.27-0.1,6.56,0,9.83
		c0.12,3.86,3.08,6.42,6.85,6.43c2,0.01,4,0,6.01,0c1.19,2.59,3.63,4.38,6.87,4.45c6.39,0.14,12.79,0,19.18,0
		c7.71,0,15.41,0,23.12,0c0.46,0,0.92,0,1.38,0c3.01-0.1,5.67-1.82,6.88-4.45c1.78,0,3.57,0,5.35,0c1.21,0,2.36-0.04,3.5-0.52
		c2.03-0.86,3.48-2.71,3.81-4.89c0.16-1.04,0.09-2.12,0.09-3.17C91.52,64.03,91.52,62.03,91.52,60.02z M25.32,27.16
		c1.02-2.22,3.72-1.77,5.72-1.77c3.44,0,6.88,0,10.31,0c8.39,0,16.78,0,25.17,0c1.78,0,3.57-0.07,5.35,0
		c1.79,0.08,3.03,1.36,3.1,3.14c0.02,0.6,0,1.2,0,1.8c0,7.58,0,15.16,0,22.74c0,4.51,0.05,9.02,0.04,13.53
		c-1.48-2.35-2.97-4.7-4.45-7.05c-1.12-1.78-2.2-4.07-4.17-5.03c-2.29-1.12-5.16-0.72-6.95,1.15c-0.65,0.68-1.17,1.55-1.72,2.32
		c-0.93,1.31-1.85,2.62-2.78,3.94c-1.57-2.56-3.14-5.12-4.71-7.68c-0.98-1.6-1.96-3.19-2.94-4.79c-0.58-0.94-1.12-2.01-1.92-2.78
		c-1.43-1.39-3.51-1.96-5.45-1.54c-1.96,0.42-3.19,1.77-4.21,3.4c-1.85,2.96-3.7,5.93-5.54,8.91c-1.74,2.81-3.46,5.63-5.16,8.46
		c0-7.44,0-14.89,0-22.33c0-3.69,0-7.39,0-11.08C25.03,30.95,24.64,28.64,25.32,27.16z M12.98,68.2c-0.18-2.81,0-5.7,0-8.51
		c0-8.53-0.05-17.06,0-25.59c0.01-1.7,1.09-2.26,2.65-2.26c1.63,0,3.26,0,4.89,0c0,1.39,0.01,2.78,0.01,4.17c0,8.85,0,17.69,0,26.54
		c0,2.52,0,5.05,0,7.57c-1.33,0-2.67,0-4,0C14.99,70.12,13.12,70.26,12.98,68.2z M31.45,74.56c-1.76,0-4.28,0.41-5.6-1.01
		c0.01-0.03,0.02-0.06,0.03-0.09c0.02-0.03,0.05-0.07,0.09-0.15c0.15-0.28,0.29-0.56,0.45-0.84c0.5-0.91,1.03-1.81,1.57-2.71
		c1.53-2.59,3.1-5.15,4.67-7.71c1.78-2.9,3.58-5.8,5.38-8.69c0.81-1.3,2.62-5.52,4.41-3.2c1.51,1.97,2.67,4.36,3.97,6.47
		c2.53,4.13,5.06,8.26,7.59,12.39c1.13,1.85,2.26,3.69,3.4,5.54c-0.65,0-1.3,0-1.96,0C47.44,74.56,39.45,74.56,31.45,74.56z
		 M71.89,74.56c-3.07,0-6.14,0-9.21,0c-1.73-2.82-3.45-5.64-5.18-8.45c1.05-1.49,2.1-2.98,3.15-4.47c0.6-0.85,1.17-1.76,1.82-2.58
		c0.57-0.72,1.2-0.86,1.95-0.51c-0.02-0.01,0.32,0.24,0.48,0.46c-0.04-0.05,0,0.03,0,0.03c0.13,0.17,0.23,0.37,0.34,0.55
		c0.5,0.8,1.01,1.6,1.51,2.39c1.5,2.38,3.01,4.76,4.51,7.14c0.94,1.49,1.88,2.98,2.83,4.48C73.55,74.17,72.79,74.53,71.89,74.56z
		 M87.02,67.85c-0.01,1.7-1.09,2.26-2.65,2.26c-1.63,0-3.27,0-4.9,0c0-3.02,0-6.04,0-9.05c0-8.79,0-17.58,0-26.37
		c0-0.95,0.01-1.9,0.02-2.85c1.33,0,2.65,0,3.98,0c1.55,0,3.41-0.15,3.55,1.91c0.18,2.81,0,5.7,0,8.51
		C87.02,50.79,87.06,59.32,87.02,67.85z"
        />
        <path
            d="M63.65,47.2c3.97-0.72,6.79-4.41,6.85-8.37c-0.05-3.62-2.29-6.76-5.66-8.03c-3.23-1.22-7.19-0.17-9.38,2.5
		c-2.28,2.79-2.72,6.75-0.85,9.89C56.49,46.33,60.1,47.84,63.65,47.2z M58.29,41.69C58.45,41.88,58.35,41.77,58.29,41.69
		L58.29,41.69z M60.13,43.11C60.05,43.08,59.98,43.04,60.13,43.11L60.13,43.11z M66.58,39.46C66.57,39.67,66.57,39.55,66.58,39.46
		L66.58,39.46z M60.16,34.54C60.07,34.58,59.9,34.63,60.16,34.54L60.16,34.54z M57.37,38.14c0.01-0.07,0.03-0.15,0.04-0.22
		c0.06-0.29,0.16-0.56,0.25-0.84c0.01-0.04,0.02-0.06,0.03-0.09c0.01-0.02,0.03-0.05,0.05-0.09c0.09-0.15,0.16-0.31,0.25-0.46
		c0.07-0.12,0.15-0.24,0.23-0.36c0.02-0.03,0.06-0.08,0.1-0.12c0.18-0.2,0.35-0.4,0.55-0.58c-0.01,0.01,0.12-0.11,0.2-0.17
		c0.08-0.06,0.22-0.16,0.21-0.16c0.29-0.19,0.59-0.35,0.89-0.5c0.01,0,0.01,0,0.03-0.01c0.17-0.06,0.33-0.11,0.5-0.16
		c0.11-0.03,0.23-0.06,0.34-0.09c-0.01,0,0.16-0.03,0.27-0.05c0.11-0.01,0.29-0.03,0.28-0.03c0.15-0.01,0.31-0.01,0.46-0.01
		c0.15,0,0.3,0.01,0.46,0.02c0.05,0,0.07,0,0.1,0c0.02,0.01,0.05,0.02,0.1,0.03c0.35,0.09,0.68,0.17,1.02,0.28
		c0.02,0.01,0.03,0.01,0.05,0.02c0.01,0.01,0.02,0.01,0.05,0.03c0.18,0.09,0.36,0.18,0.53,0.29c0.1,0.06,0.2,0.12,0.29,0.18
		c0.06,0.04,0.11,0.08,0.17,0.12c0.01,0.01,0.05,0.04,0.12,0.1c0.2,0.2,0.41,0.4,0.61,0.61c0.03,0.04,0.05,0.05,0.08,0.07
		c0.01,0.02,0.02,0.04,0.05,0.07c0.1,0.14,0.19,0.28,0.28,0.43c0.09,0.15,0.16,0.3,0.25,0.46c0.02,0.04,0.04,0.07,0.05,0.09
		c0.01,0.02,0.01,0.05,0.03,0.09c0.11,0.34,0.2,0.67,0.28,1.02c0,0.02,0.01,0.05,0.02,0.08c0.02,0.22,0.03,0.44,0.04,0.66
		c0,0.18-0.01,0.37-0.02,0.55c0,0.02,0,0.03,0,0.04c-0.05,0.28-0.11,0.57-0.2,0.84c-0.05,0.15-0.1,0.3-0.16,0.45
		c-0.01,0.02-0.02,0.03-0.03,0.06c-0.17,0.3-0.34,0.6-0.53,0.89c-0.01,0.02-0.01,0.02-0.02,0.04c-0.01,0.01-0.02,0.02-0.04,0.04
		c-0.1,0.11-0.19,0.21-0.29,0.32c-0.07,0.07-0.42,0.38-0.46,0.43c-0.2,0.15-0.42,0.29-0.64,0.42c-0.1,0.06-0.21,0.1-0.31,0.16
		c-0.07,0.03-0.1,0.05-0.13,0.06c-0.04,0.02-0.08,0.03-0.13,0.05c-0.28,0.09-0.56,0.18-0.85,0.24c-0.05,0.01-0.18,0.03-0.24,0.04
		c-0.11,0.01-0.21,0.02-0.32,0.02c-0.31,0.01-0.61,0-0.92-0.03c-0.02,0-0.02,0-0.03,0c-0.02-0.01-0.04-0.01-0.08-0.02
		c-0.17-0.04-0.35-0.07-0.52-0.12c-0.17-0.05-0.33-0.11-0.5-0.16c-0.01,0-0.03-0.01-0.04-0.02c-0.25-0.12-0.5-0.25-0.73-0.4
		c-0.12-0.08-0.24-0.16-0.36-0.24c-0.01-0.01-0.01-0.01-0.02-0.01c-0.02-0.02-0.03-0.03-0.06-0.06c-0.24-0.24-0.49-0.47-0.71-0.73
		c0-0.01-0.01-0.02-0.02-0.02c-0.1-0.14-0.19-0.28-0.28-0.43c-0.05-0.09-0.26-0.52-0.29-0.56c-0.09-0.24-0.18-0.49-0.24-0.74
		c-0.03-0.12-0.05-0.23-0.08-0.35c-0.01-0.05-0.02-0.09-0.02-0.12c-0.01-0.06-0.01-0.11-0.01-0.17c-0.02-0.28-0.02-0.55-0.01-0.83
		C57.33,38.42,57.39,38.02,57.37,38.14z"
        />
    </Icon>
);

export default BlocksIcon;
