import get from "lodash/get";
import trim from "lodash/trim";
import isEmpty from "lodash/isEmpty";

import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import BubbleContainer from "../BubbleContainer";
import CheckpointIcon from "../../../icons/CheckpointIcon";
import { UPDATE, NEW } from "../../../../constants";
import CharacterCounter from "../../../common/CharacterCounter";

import useOnClickOutside from "../../../../hooks/useOnClickOutside";

const CheckpointBubble = (props) => {
    const { bubble, updateBubble } = props;
    const [showCheckpointCreated, setShowCheckpointCreated] = useState(!isEmpty(trim(bubble.text)));
    const ref = useRef();

    const { t } = useTranslation();

    const bubbleRef = useRef(bubble);
    bubbleRef.current = bubble;

    const handleChange = ({ target }) => {
        updateBubble({
            ...bubble,
            text: target.value,
            action: props.bubble.action === NEW ? NEW : UPDATE,
        });
    };

    const handleEnter = (event) => {
        if (event.key === "Enter" && !isEmpty(trim(event.target.value))) {
            if (event.target.value.length > 21) {
                return;
            }
            updateBubble({ ...bubble, text: event.target.value, action: props.bubble.action === NEW ? NEW : UPDATE });
            setShowCheckpointCreated(true);
        }
    };

    const createCheckpoint = () => {
        if (!isEmpty(trim(bubbleRef.current.text))) {
            if (bubbleRef.current.text.length > 21) {
                return;
            }
            updateBubble({ ...bubbleRef.current, text: bubbleRef.current.text, action: props.bubble.action === NEW ? NEW : UPDATE });
            setShowCheckpointCreated(true);
        }
    };

    const editCheckpoint = () => {
        setShowCheckpointCreated(false);
    };

    useOnClickOutside(ref, () => createCheckpoint());

    return (
        <BubbleContainer
            {...props}
            bubbleStyles={props.bubbleStyles}
            icon={<CheckpointIcon className="mx-2 fill-current text-gray-400" width="1.5rem" height="1.3rem" />}
            title={t("bubbles.Marcador")}
            canOverflow>
            {!showCheckpointCreated ? (
                <div className="relative items-center rounded-b-xl border border-yellow-200 shadow-checkpoint" ref={ref}>
                    <div className="relative flex-1">
                        <div className="relative flex min-h-20 w-full flex-col justify-center space-y-2 px-3">
                            <div className="flex items-center space-x-2">
                                <input
                                    type="text"
                                    name="checkpoint"
                                    className="bubble-form-input"
                                    placeholder={t("bubbles.Nombre del marcador")}
                                    onKeyDown={handleEnter}
                                    onChange={handleChange}
                                    defaultValue={bubble.text}
                                />
                            </div>
                            <div className="flex items-end justify-end">
                                <CharacterCounter length={get(bubble, "text", 0).length} max={21} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <button
                    className="relative w-full items-center bg-yellow-50 shadow-checkpoint-created focus:outline-none"
                    onClick={() => editCheckpoint()}>
                    <div className="relative w-full">
                        <div className="relative flex min-h-12 w-full items-center justify-center space-x-2 px-3">
                            <label className="cursor-pointer text-left text-sm font-bold text-yellow-darker">{bubble.text}</label>
                        </div>
                    </div>
                </button>
            )}
        </BubbleContainer>
    );
};

export default CheckpointBubble;
