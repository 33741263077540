import initialState from "./initialState";
import { updateById, mergeById, deleteByIds } from "../helpers";
import { ADD_RANDOM, ADD_RANDOMS, UPDATE_RANDOM, UPDATE_RANDOMS, DELETE_RANDOMS, DELETE_ALL_RANDOMS } from "../constants";

function randoms(state = initialState.randoms, action) {
    switch (action.type) {
        case ADD_RANDOMS:
            return action.payload;
        case ADD_RANDOM:
            return [...state, action.payload];
        case UPDATE_RANDOM:
            return updateById(state, action.payload);
        case UPDATE_RANDOMS:
            return mergeById(state, action.payload);
        case DELETE_RANDOMS:
            return deleteByIds(state, action.payload);
        case DELETE_ALL_RANDOMS:
            return [];
        default:
            return state;
    }
}

export default randoms;
