// import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import "./scss/index.scss";
import "react-phone-input-2/lib/style.css";
import App from "./App";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { App_en } from "./translations/en/index";
import { App_es } from "./translations/es/index";
import { App_pt } from "./translations/pt/index";
import LanguageDetector from "i18next-browser-languagedetector";
import { ToastContainer } from "react-toastify";

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: {
            en: {
                translation: App_en,
            },
            es: {
                translation: App_es,
            },
            pt: {
                translation: App_pt,
            },
        },
        lng: `${localStorage.getItem("lang") || "es"}`,
        fallbackLng: "es",
    });

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
    // <StrictMode>
    <>
        <BrowserRouter>
            <App />
        </BrowserRouter>
        <ToastContainer />
    </>
    // </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
