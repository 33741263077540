import { IS_SAVING_CHANGES, IS_NOT_SAVING_CHANGES } from "../constants";
import initialState from "./initialState";

function savingChanges(state = initialState.savingChanges, action) {
    switch (action.type) {
        case IS_SAVING_CHANGES:
            return true;
        case IS_NOT_SAVING_CHANGES:
            return false;
        default:
            return state;
    }
}

export default savingChanges;
