import React from "react";

import Icon from "./Icon";

const AlexaIcon = (props) => (
    <Icon viewBox="0 0 358.1 358.1" className={props.className} width={props.width} height={props.height}>
        <path
            d="M179.1,0C80,0,0,80.8,0,179.1s80,179.1,179.1,179.1s179.1-80.8,179.1-179.1S278.1,0,179.1,0z
		 M151,355.9v-35.5c0-12.6-7.7-23.9-19.5-28.8C87.7,272.9,57,229.3,57,179.1C57,112,111.5,57,179.1,57
		c67.6,0,122.1,55.1,122.1,122.1C301.2,258.1,240,332.9,151,355.9z"
        />
    </Icon>
);

export default AlexaIcon;
