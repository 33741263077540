import ErrorValidation from "../../../../common/ErrorValidation";

const Buttons = (props) => {
    const { errorList, bot, options, setOptions, setErrorList, updateBubble, allOptions, pma, updatePMABubbles } = props;

    return (
        <div className="space-y-2 px-2">
            {errorList.map((error) => {
                return (
                    <ErrorValidation
                        key={error.error}
                        error={error}
                        bot={bot}
                        intents={props.intents}
                        setFlow={props.setFlow}
                        flows={props.flows}
                        bubble={props.bubble}
                        deleteOption={props.deleteOption}
                        updateOption={props.updateOption}
                        buttonCounter={props.buttonCounter}
                        options={options}
                        setOptions={setOptions}
                        errorList={errorList}
                        setErrorList={setErrorList}
                        updateBubble={updateBubble}
                        allOptions={allOptions}
                        pma={pma}
                        updatePMABubbles={updatePMABubbles}
                    />
                );
            })}
        </div>
    );
};

export default Buttons;
