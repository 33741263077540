import isEmpty from "lodash/isEmpty";

import BubbleContainer from "./BubbleContainer";
import { formatMessage } from "../../../utils/utils";

const TextBubble = (props) => {
    const { text } = props;

    let names = null;

    return (
        <BubbleContainer {...props}>
            <div className="relative flex flex-col text-13">
                {!isEmpty(names) && <small className="mb-2 h-4 text-left text-13 font-bold text-primary-200">{names}</small>}

                {formatMessage(text)}

                <div className="mt-1 flex h-4 items-center justify-end">
                    {/* {toUpper(by) !== "USER" && (
                        <div className="flex items-center justify-center pl-1">
                            <StatusTick status={status} color={"text-gray-400"} />
                        </div>
                    )} */}
                </div>
            </div>
        </BubbleContainer>
    );
};

export default TextBubble;
