import { useEffect, useLayoutEffect } from "react";
import isEmpty from "lodash/isEmpty";
import { Provider } from "react-redux";
import ReactPolling from "react-polling";
import { Routes, Route, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

/* Sentry */
import jwtDecode from "jwt-decode";
import * as Sentry from "@sentry/react";

import { APPS_URL } from "./constants";
import Tracker from "./services/Tracker";
import { authUser } from "./actions/user";
import { JelouAPI } from "./services/Http";
import { setUnauthorization } from "./actions/unathorization";

/* Components */
import Bots from "./views/bots/Bots";
import Page404 from "./views/common/Page404";
import Metrics from "./views/metrics/Metrics";
import Reports from "./views/reports/Reports";
import Intents from "./views/intents/containers/Intents";
import Resources from "./views/resources/Resources";
import Impersonate from "./views/impersonate/Impersonate";
import Transactions from "./views/transactions/Transactions";

// import Contexts from "./views/contexts/containers/Contexts";

import store from "./services/Store";
const { REACT_APP_POLLING_URL } = process.env;
const inProd = process.env.NODE_ENV === "production";

export function App() {
    const { unauthorized } = store.getState();
    const queryClient = new QueryClient();

    if (process.env.NODE_ENV !== "development") {
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            ignoreErrors: [
                "Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
                "Network Error",
                "NetworkError",
                "Request failed with status code 401",
            ],
            integrations: [
                Sentry.reactRouterV6BrowserTracingIntegration({
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                }),
            ],
            normalizeDepth: 10,

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for tracing.
            tracesSampleRate: 1.0,

            // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
            tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],
        });
    }

    useLayoutEffect(() => {
        const { user, session } = store.getState();
        if (isEmpty(user) && session) {
            store.dispatch(authUser());
        }
    }, []);

    const fetchData = () => {
        return JelouAPI.get("/users/ping").catch((err) => {
            store.dispatch(setUnauthorization(true));
            console.log(err);
        });
    };

    useEffect(() => {
        const jwt = localStorage.getItem("jwt");
        const jwtToDecode = jwt;
        if (!unauthorized) {
            try {
                const userSession = jwtDecode(jwtToDecode);
                if (Tracker && inProd) {
                    Tracker.start();
                    Tracker.setUserID(userSession.email);
                    Tracker.setMetadata("Id", userSession.id);
                    Tracker.setMetadata("Name", userSession.names);
                }
            } catch (error) {
                console.log(error);
            }
        }
    }, [unauthorized]);

    return (
        <QueryClientProvider client={queryClient}>
            <div id="app">
                <Provider store={store}>
                    <ReactPolling
                        url={`${REACT_APP_POLLING_URL}`}
                        interval={300000}
                        onSuccess={() => {
                            return true;
                        }}
                        promise={fetchData}
                        render={({ startPolling, stopPolling, isPolling }) => {
                            if (isPolling) {
                                return null;
                            } else {
                                return null;
                            }
                        }}
                    />
                    <Routes>
                        <Route exact path="/" element={<AuthRoute component={Bots} />} />
                        <Route path="/flows/:botId" element={<AuthRoute component={Intents} />} />
                        <Route exact path="/metrics" element={<AuthRoute component={Metrics} />} />
                        <Route exact path="/reports" element={<AuthRoute component={Reports} />} />
                        <Route path="/resources" element={<AuthRoute component={Resources} />} />
                        <Route path="/impersonate/:jwt/:botId" element={<Impersonate />} />
                        <Route path="/impersonate" element={<Impersonate />} />

                        <Route path="/resource-report" element={<AuthRoute component={Transactions} />} />
                        <Route path="*" element={<Page404 />} />
                    </Routes>
                </Provider>
            </div>
        </QueryClientProvider>
    );
}

const AuthRoute = ({ component: Component, ...rest }) => (localStorage.jwt ? <Component {...rest} /> : window.location.assign(APPS_URL));

export default App;
