export default {
    user: {},
    permissions: [],
    bot: {},
    flow: {},
    intent: {},
    expressions: [],
    flows: [],
    companies: [],
    errors: [],
    inputs: [],
    reports: [],
    randoms: [],
    metrics: {},
    bubbles: [],
    options: [],
    intents: [],
    sandboxes: [],
    pmaBubbles: [],
    runActionBubbles: [],
    elements: [],
    resources: [],
    attachments: [],
    quickReplies: [],
    conditionals: [],
    resourceState: null,
    expressionState: null,
    bubbleResources: [],
    contactsCards: [],
    operators: [],
    transactions: [],
    contexts: [],
    contextDetail: [],
    session: !!localStorage.jwt,
    loadingPermissions: false,
    unSavedChanges: false,
    isLoading: false,
    isLoadingFlow: false,
    unauthorized: false,
    impersonate: false,
    savingChanges: false,
    isOpenBotTester: false,
    resourcesLight: [],
    savedErrors: [],
};
