import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { useToolkitCredentials } from "../Toolkit/credentials.hook";

const SKILLS_QUERY_KEY = "skill";
export function useQuerySkills(brainId) {
    const { app_token } = useToolkitCredentials();
    const { data, isLoading, isError, error } = useQuery([SKILLS_QUERY_KEY], () => getSkills({ appToken: app_token }, brainId), {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: Boolean(app_token),
    });

    return {
        data,
        isLoading,
        isError,
        error,
    };
}

async function getSkills({ appToken }, brainId) {
    try {
        let params = { disablePagination: true };
        if (brainId) {
            params = { brainId: brainId, disablePagination: true };
        }
        const { data, status } = await axios.get("https://workflows.jelou.ai/v1/skills", {
            headers: {
                "X-Api-Key": appToken,
            },
            params,
        });

        if (status === 200) return data.data;

        throw new Error("Tuvimos un problema al obtener las skills");
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error("error desconocido al obtener las skills", { error });
            throw new Error("Tuvimos un problema al obtener las skills");
        }

        throw error;
    }
}
