import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import FormComboboxSelect from "../../../common/FormCombobox";
import ToolkitIcon from "../../../icons/ToolkitIcon";

import { UPDATE } from "../../../../constants";
import BubbleContainer from "../BubbleContainer";
import { Inputs } from "./Inputs";
import { Output } from "./Outputs";
import { useToolkit, useToolVersions } from "./toolkit.service";

const ToolkitBubble = (props) => {
    const { t } = useTranslation();
    const { toolkits, isLoading: toolkitLoading } = useToolkit();
    const { getVersions, toolVersions, loading: toolVersionLoading } = useToolVersions();

    const currentBubble = get(props, "bubble.BubbleTool", {});

    const [toolkitSelected, setToolkitSelected] = useState(null);
    const [toolSelected, setToolSelected] = useState(null);
    const [versionSelected, setVersionSelected] = useState(null);
    const [variableInput, setVariableInput] = useState(get(currentBubble, "variable", ""));

    useEffect(() => {
        if (toolkits.length === 0) return;

        const defaultToolkitId = get(currentBubble, "toolkitId", null);
        const defatultToolId = get(currentBubble, "toolId", null);
        const defaultVersionId = get(currentBubble, "version", null);

        if (defaultToolkitId !== null && defatultToolId !== null && defaultVersionId !== null) {
            getVersions(defaultToolkitId, defatultToolId).then((versions) => {
                const defaultVersion = versions.find((version) => version.id === defaultVersionId);
                setVersionSelected(defaultVersion);
            });
        }

        const defaultToolkit = toolkits.find((toolkit) => toolkit.id === defaultToolkitId);
        setToolkitSelected(defaultToolkit);

        const defaultToolsList = get(defaultToolkit, "tools", []);
        const defaultTools = defaultToolsList.find((tool) => tool.id === defatultToolId);
        setToolSelected(defaultTools);
    }, [toolkits]);

    const [isOpenInputs, setIsOpenInputs] = useState(false);
    const [isOpenOutput, setIsOpenOutput] = useState(false);

    const variableInputChange = (evt) => {
        const value = evt.target.value;
        setVariableInput(value);
        update({ variable: value });
    };

    const versionChange = (versionOption) => {
        setVersionSelected(versionOption);

        const inputs = get(versionOption, "snapshot.Inputs", []);
        const hasInputs = !isEmpty(inputs);

        update({ version: versionOption.id, hasInputs });
    };

    const toolsOptions = get(toolkitSelected, "tools", []);
    const toolChange = (toolOption) => {
        setToolSelected(toolOption);
        setVersionSelected(null);

        getVersions(toolkitSelected.id, toolOption.id);

        update({
            toolId: toolOption.id,
            version: null,
            toolName: toolOption.name,
        });
    };

    const toolkitChange = (toolkitOption) => {
        setToolkitSelected(toolkitOption);
        setToolSelected(null);
        setVersionSelected(null);

        update({
            toolkitId: toolkitOption.id,
            toolId: null,
            version: null,
            toolName: null,
        });
    };

    function update(value) {
        const bubble = props.bubble;
        const bubbleAction = get(props, "bubble.action", UPDATE);

        const updateBubble = {
            ...bubble,
            action: bubbleAction,
            BubbleTool: {
                ...bubble.BubbleTool,
                ...value,
                action: bubbleAction,
            },
        };

        props.updateBubble(updateBubble);
    }

    return (
        <div className="flex text-gray-400">
            <BubbleContainer
                {...props}
                bubbleStyles={props.bubbleStyles}
                icon={
                    <span className="mx-2 text-gray-400">
                        <ToolkitIcon />
                    </span>
                }
                title="Toolkit">
                <div className="mb-4 grid gap-4 px-4 pt-4">
                    <label htmlFor="title">
                        Toolkit
                        <FormComboboxSelect
                            handleChange={toolkitChange}
                            options={toolkits}
                            loading={toolkitLoading}
                            placeholder={t("toolkit.selecciona_opcion")}
                            value={toolkitSelected}
                            hasCleanFilter={false}
                            className={"bubble-form-input"}
                        />
                    </label>
                    <label htmlFor="title">
                        Tool
                        <FormComboboxSelect
                            handleChange={toolChange}
                            options={toolsOptions}
                            placeholder={t("toolkit.selecciona_opcion")}
                            value={toolSelected}
                            hasCleanFilter={false}
                            className={"bubble-form-input"}
                        />
                    </label>
                    <label htmlFor="title">
                        Versión
                        <FormComboboxSelect
                            handleChange={versionChange}
                            options={toolVersions}
                            loading={toolVersionLoading}
                            placeholder={t("toolkit.selecciona_opcion")}
                            value={versionSelected}
                            hasCleanFilter={false}
                            className={"bubble-form-input"}
                        />
                    </label>
                    <label htmlFor="title">
                        Guardar resultado como
                        <input
                            type="text"
                            name="variable"
                            className="form-input !bg-white"
                            value={variableInput ?? ""}
                            placeholder="Ej: mi_respuesta"
                            onChange={variableInputChange}
                        />
                    </label>

                    <button
                        onClick={() => setIsOpenInputs(true)}
                        className="grid h-16 place-content-center rounded-xl border-[1px] bg-white p-2 text-sm font-medium text-primary-200 transition-all duration-300 ease-out hover:border-primary-200">
                        <span>Configura los campos de entrada</span>
                    </button>

                    <button
                        onClick={() => setIsOpenOutput(true)}
                        className="grid h-16 place-content-center rounded-xl border-[1px] bg-white p-2 text-sm font-medium text-[#952F23] transition-all duration-300 ease-out hover:border-[#FFD0CB]">
                        <span>Configura los casos de error</span>
                    </button>
                </div>
            </BubbleContainer>

            <Inputs
                update={update}
                isOpenInputs={isOpenInputs}
                closeModal={() => setIsOpenInputs(false)}
                inputsTool={get(versionSelected, "snapshot.Inputs", [])}
                inputsBubble={get(props, "bubble.BubbleTool.input", {})}
            />

            <Output
                update={update}
                closeModal={() => setIsOpenOutput(false)}
                isOpenOutput={isOpenOutput}
                outputTool={get(versionSelected, "snapshot.Outputs", [])}
                outputBubble={get(props, "bubble.BubbleTool.output", {})}
                defaultFlowIdBubble={get(props, "bubble.BubbleTool.defaultFlowId", null)}
            />
        </div>
    );
};
export default ToolkitBubble;
