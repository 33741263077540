import shortid from "shortid";

import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import toUpper from "lodash/toUpper";

import Tippy from "@tippyjs/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import BlocksIcon from "../../icons/BlocksIcon";
import CheckpointIcon from "../../icons/CheckpointIcon";
import CodeIcon from "../../icons/CodeIcon";
import ContactCardIcon from "../../icons/ContactCardIcon";
import { DoubleArrowIcon } from "../../icons/DoubleArrowIcon";
import FileIcon from "../../icons/FileIcon";
import ForwardIcon from "../../icons/ForwardIcon";
import GoToFlowIcon from "../../icons/GoToFlowIcon";
import ImageIcon from "../../icons/ImageIcon";
import JCXIcon from "../../icons/JCXIcon";
import MicIcon from "../../icons/MicIcon";
import QuestionIcon from "../../icons/QuestionIcon";
import RandomIcon from "../../icons/RandomIcon";
import SandboxIcon from "../../icons/SandboxIcon";
import StickerIcon from "../../icons/StickerIcon";
import TextIcon from "../../icons/TextIcon";
import ToolkitIcon, { SkillIcon } from "../../icons/ToolkitIcon";
import VideoIcon from "../../icons/VideoIcon";

import {
    addAudioBubble,
    addCheckpointBubble,
    addPMABubble,
    addRunActionBubble,
    addSkillBubble,
    addStickerBubble,
    addToolkitBubble,
} from "../../../actions/bubbles";
import { ALL_BUBBLES, BUBBLES, BubbleTypes, SUPPORTED_BUBBLES } from "../../../constants";
import { useToolkitCredentials } from "../../bubbles/components/Toolkit/credentials.hook";
import CanRenderOption from "../../common/CanRenderOption";

const listStyle = "text-13 gap-2 cursor-pointer items-center flex font-semibold text-gray-400 hover:text-primary-200 h-8";

const textStyle = "flex items-center h-5 rounded-10";

const size = 18;

const ToolBox = (props) => {
    const { t } = useTranslation();
    const bot = props.bot;
    const bubbles = useSelector((state) => state.bubbles);
    const [isOpenToolbar, setIsOpenToolbar] = useState(false);
    const [showText, setShowText] = useState(false);

    const { isAuthorized } = useToolkitCredentials();

    const [id, setId] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        const idToScroll = document.getElementById(id);

        if (idToScroll) {
            idToScroll.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
        }
    }, [bubbles, id]);

    if (isEmpty(bot)) {
        return <div />;
    }

    let botType = toUpper(bot.type);

    if (!includes(BUBBLES, botType)) {
        botType = "DEFAULT";
    }

    const handleChangeStateToolbarClick = () => {
        if (!showText) {
            setTimeout(() => {
                setShowText((preState) => !preState);
            }, 150);
        } else {
            setShowText((preState) => !preState);
        }
        setIsOpenToolbar((preState) => !preState);
    };

    //map allBubbles array and return a new array with the bubbles that are supported by the botType
    const supportedBubbles = ALL_BUBBLES.filter((bubble) => SUPPORTED_BUBBLES[botType][bubble]);

    const renderMessageBubble = (bubble, showText) => {
        switch (bubble) {
            case "TEXT":
                return (
                    <Tippy content={showText ? "" : t("bubbles.Texto")} theme="jelou" arrow={false} placement={"left"} touch={false}>
                        <li
                            className={listStyle}
                            onClick={() => {
                                const id = shortid.generate();
                                props.addTextBubble(id);
                                setId(id);
                            }}>
                            <div className={`${textStyle}`}>
                                <TextIcon className="m-auto fill-current" width="18" height="18" />
                            </div>
                            {showText ? t("bubbles.Texto") : ""}
                        </li>
                    </Tippy>
                );
            case "IMAGE":
                return (
                    <Tippy content={showText ? "" : t("bubbles.Imagen")} theme="jelou" arrow={false} placement={"left"} touch={false}>
                        <li
                            className={listStyle}
                            onClick={() => {
                                const id = shortid.generate();
                                props.addImageBubble(id);
                                setId(id);
                            }}>
                            <div className={`${textStyle}`}>
                                <ImageIcon className="m-auto fill-current" width="20" height="16" />
                            </div>
                            {showText ? t("bubbles.Imagen") : ""}
                        </li>
                    </Tippy>
                );

            case "FILE":
                return (
                    <Tippy content={showText ? "" : t("bubbles.Documento")} theme="jelou" arrow={false} placement={"left"} touch={false}>
                        <li
                            className={listStyle}
                            onClick={() => {
                                const id = shortid.generate();
                                props.addFileBubble(id);
                                setId(id);
                            }}>
                            <div className={`${textStyle}`}>
                                <FileIcon className="m-auto fill-current" width="21" height="18" />
                            </div>
                            {showText ? t("bubbles.Documento") : ""}
                        </li>
                    </Tippy>
                );
            case "VIDEO":
                return (
                    <Tippy content={showText ? "" : t("bubbles.Video")} theme="jelou" arrow={false} placement={"left"} touch={false}>
                        <li
                            className={listStyle}
                            onClick={() => {
                                const id = shortid.generate();
                                props.addVideoBubble(id);
                                setId(id);
                            }}>
                            <div className={`${textStyle}`}>
                                <VideoIcon className="m-auto fill-current" width="22" height="16" />
                            </div>
                            {showText ? t("bubbles.Video") : " "}
                        </li>
                    </Tippy>
                );
            case "AUDIO":
                return (
                    <Tippy content={showText ? "" : t("bubbles.Audio")} theme="jelou" arrow={false} placement={"left"} touch={false}>
                        <li
                            className={listStyle}
                            onClick={() => {
                                const id = shortid.generate();
                                dispatch(addAudioBubble(id));
                                setId(id);
                            }}>
                            <div className={`${textStyle}`}>
                                <MicIcon className="m-auto fill-current" width="14" height="20" />
                            </div>
                            {showText ? t("bubbles.Audio") : ""}
                        </li>
                    </Tippy>
                );
            case "STICKER":
                return (
                    <Tippy content={showText ? "" : t("bubbles.Pegatina")} theme="jelou" arrow={false} placement={"left"} touch={false}>
                        <li
                            className={listStyle}
                            onClick={() => {
                                const id = shortid.generate();
                                dispatch(addStickerBubble(id));
                                setId(id);
                            }}>
                            <div className={`${textStyle}`}>
                                <StickerIcon className="m-auto fill-current" width="21" height="21" />
                            </div>
                            {showText ? t("bubbles.Pegatina") : ""}
                        </li>
                    </Tippy>
                );
            case "RANDOM":
                return (
                    <Tippy content={showText ? "" : t("bubbles.Aleatorio")} theme="jelou" arrow={false} placement={"left"} touch={false}>
                        <li
                            className={listStyle}
                            onClick={() => {
                                const id = shortid.generate();
                                props.addRandomBubble(id);
                                setId(id);
                            }}>
                            <div className={`${textStyle}`}>
                                <RandomIcon className="m-auto fill-current" width="19" height="16" />
                            </div>
                            {showText ? t("bubbles.Aleatorio") : ""}
                        </li>
                    </Tippy>
                );
            case "QUICK_REPLY":
                return (
                    <Tippy content={showText ? "" : t("bubbles.Respuestas rápidas")} theme="jelou" arrow={false} placement={"left"} touch={false}>
                        <li
                            className={listStyle}
                            onClick={() => {
                                const id = shortid.generate();
                                props.addQuickReplyBubble(id);
                                setId(id);
                            }}>
                            <div className={`${textStyle}`}>
                                <ForwardIcon className="m-auto fill-current" width="19" height="20" />
                            </div>
                            {showText ? <div className="flex-1">{t("bubbles.Respuestas rápidas")}</div> : ""}
                        </li>
                    </Tippy>
                );
            case "INPUT":
                return (
                    <Tippy content={showText ? "" : t("bubbles.Pregunta")} theme="jelou" arrow={false} placement={"left"} touch={false}>
                        <li
                            className={listStyle}
                            onClick={() => {
                                const id = shortid.generate();
                                props.addInputBubble(id);
                                setId(id);
                            }}>
                            <div className={`${textStyle}`}>
                                <QuestionIcon className="m-auto fill-current" width="21" height="21" />
                            </div>
                            {showText ? t("bubbles.Pregunta") : ""}
                        </li>
                    </Tippy>
                );
            case "CONTACT_CARD":
                return (
                    <Tippy content={showText ? "" : t("bubbles.Contacto")} theme="jelou" arrow={false} placement={"left"} touch={false}>
                        <li
                            className={listStyle}
                            onClick={() => {
                                const id = shortid.generate();
                                props.addContactCardBubble(id);
                                setId(id);
                            }}>
                            <div className={`${textStyle}`}>
                                <ContactCardIcon className="m-auto fill-current" width="20" height="20" />
                            </div>
                            {showText ? t("bubbles.Contacto") : ""}
                        </li>
                    </Tippy>
                );
            case "GENERIC":
                return (
                    <Tippy content={showText ? "" : t("bubbles.Carrusel")} theme="jelou" arrow={false} placement={"left"} touch={false}>
                        <li
                            className={listStyle}
                            onClick={() => {
                                const id = shortid.generate();
                                props.addGenericBubble(id);
                                setId(id);
                            }}>
                            <div className={`${textStyle}`}>
                                <BlocksIcon className="-ml-1 -mr-[2px] fill-current " width="30" />
                            </div>
                            {showText ? t("bubbles.Carrusel") : ""}
                        </li>
                    </Tippy>
                );
            default:
                return null;
        }
    };

    const renderActionBubble = (bubble, showText) => {
        switch (toUpper(bubble)) {
            case toUpper(BubbleTypes.CONDITIONAL):
                return (
                    <Tippy content={showText ? "" : t("bubbles.Ir a flujo")} theme="jelou" arrow={false} placement={"left"} touch={false}>
                        <li
                            className={listStyle}
                            onClick={() => {
                                const id = shortid.generate();
                                props.addConditionalBubble(id);
                                setId(id);
                            }}>
                            <div className={textStyle}>
                                <GoToFlowIcon className="m-auto fill-current" width="22" />
                            </div>
                            {showText ? t("bubbles.Ir a flujo") : ""}
                        </li>
                    </Tippy>
                );
            case toUpper(BubbleTypes.PMA):
                return (
                    <Tippy content={showText ? "" : t("bubbles.Operador")} theme="jelou" arrow={false} placement={"left"} touch={false}>
                        <li
                            className={listStyle}
                            onClick={() => {
                                const id = shortid.generate();
                                dispatch(addPMABubble(id));
                                setId(id);
                            }}>
                            <div className={textStyle}>
                                <JCXIcon className="m-auto fill-current" width="23" />
                            </div>
                            {showText ? t("bubbles.Operador") : ""}
                        </li>
                    </Tippy>
                );
            case toUpper(BubbleTypes.RESOURCE):
                return (
                    <Tippy content={showText ? "" : t("bubbles.Recurso")} theme="jelou" arrow={false} placement={"left"} touch={false}>
                        <li
                            className={listStyle}
                            onClick={() => {
                                const id = shortid.generate();
                                props.addResourceBubble(id);
                                setId(id);
                            }}>
                            <div className={textStyle}>
                                <SandboxIcon className="m-auto fill-current" width="16" />
                            </div>
                            {showText ? t("bubbles.Recurso") : ""}
                        </li>
                    </Tippy>
                );
            case toUpper(BubbleTypes.CHECKPOINT):
                return (
                    <Tippy content={showText ? "" : t("bubbles.Marcador")} theme="jelou" arrow={false} placement={"left"} touch={false}>
                        <li
                            className={listStyle}
                            onClick={() => {
                                const id = shortid.generate();
                                dispatch(addCheckpointBubble(id));
                                setId(id);
                            }}>
                            <div className={textStyle}>
                                <CheckpointIcon className="m-auto fill-current" width="16" height="20" />
                            </div>
                            {showText ? t("bubbles.Marcador") : ""}
                        </li>
                    </Tippy>
                );
            case toUpper(BubbleTypes.SANDBOX):
                return (
                    <CanRenderOption option="create sandbox">
                        <Tippy content={showText ? "" : t("bubbles.Sandbox")} theme="jelou" arrow={false} placement={"left"} touch={false}>
                            <li
                                className={listStyle}
                                onClick={() => {
                                    const id = shortid.generate();
                                    props.addSandboxBubble(id);
                                    setId(id);
                                }}>
                                <div className={textStyle}>
                                    <CodeIcon className="m-auto fill-current" width="21" height="13" />
                                </div>
                                {showText ? t("bubbles.Sandbox") : ""}
                            </li>
                        </Tippy>
                    </CanRenderOption>
                );
            case toUpper(BubbleTypes.TOOLKIT):
                if (!isAuthorized) return null;

                return (
                    <Tippy content={showText ? "" : "Toolkit"} theme="jelou" arrow={false} placement={"left"} touch={false}>
                        <li
                            className={listStyle}
                            onClick={() => {
                                const id = shortid.generate();
                                dispatch(addToolkitBubble(id));
                                setId(id);
                            }}>
                            <div className={textStyle}>
                                <ToolkitIcon />
                            </div>
                            {showText ? "Toolkit" : ""}
                        </li>
                    </Tippy>
                );

            case toUpper(BubbleTypes.SKILL):
                if (!isAuthorized) return null;

                return (
                    <Tippy content={showText ? "" : "Skill"} theme="jelou" arrow={false} placement={"left"} touch={false}>
                        <li
                            className={listStyle}
                            onClick={() => {
                                const id = shortid.generate();
                                dispatch(addSkillBubble(id));
                                setId(id);
                            }}>
                            <div className={textStyle}>
                                <SkillIcon />
                            </div>
                            {showText ? "Skill" : ""}
                        </li>
                    </Tippy>
                );

            case toUpper(BubbleTypes.RUN_ACTION):
                return (
                    <Tippy content={showText ? "" : "Accionable"} theme="jelou" arrow={false} placement={"left"} touch={false}>
                        <li
                            className={listStyle}
                            onClick={() => {
                                const id = shortid.generate();
                                dispatch(addRunActionBubble(id));
                                setId(id);
                            }}>
                            <div className={textStyle}>
                                <svg width="18" height="18" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1L15 10L1 19V1Z" stroke="#727C94" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            {showText ? "Accionable" : ""}
                        </li>
                    </Tippy>
                );

            default:
                return null;
        }
    };

    return (
        <ul
            className={`absolute right-[0.6rem] top-[4rem] z-10 grid h-[77vh] overflow-hidden rounded-10 bg-white shadow-[4px_4px_5px_rgba(184,_189,_201,_0.25)] transition-all duration-200 ease-out ${
                isOpenToolbar ? "w-80 grid-rows-[auto_4rem]" : "w-12 grid-rows-[auto_2rem] py-2"
            }`}>
            <div className={isOpenToolbar ? "" : "flex w-12 flex-col items-center"}>
                {isOpenToolbar && <h3 className="mb-4 bg-[#F0FEFF] p-4 font-semibold text-primary-200 ">{t("bubbles.Mensajes")}</h3>}

                <div className="space-y-4">
                    <div
                        className={`${isOpenToolbar ? "grid grid-flow-row grid-cols-2 gap-4" : "flex flex-col items-center"} px-4`}
                        id="ToolBoxContainer">
                        {supportedBubbles.map((bubble) => {
                            return renderMessageBubble(bubble, showText);
                        })}
                    </div>
                </div>

                {!isOpenToolbar && <span className="my-4 block w-full border-b border-[#E1E1E1]" />}
                {isOpenToolbar && <h3 className="my-4 bg-[#F0FEFF] px-4 py-4 font-semibold text-primary-200 ">{t("bubbles.Acciones")}</h3>}
                <div className="mb-4 space-y-4">
                    <div
                        className={`${isOpenToolbar ? "grid grid-flow-row grid-cols-2 gap-4" : "flex flex-col items-center"} px-4`}
                        id="ToolBoxContainer">
                        {supportedBubbles.map((bubble) => {
                            return renderActionBubble(bubble, showText);
                        })}
                    </div>
                </div>
            </div>

            <div className={`flex ${isOpenToolbar ? "" : "justify-center"}`}>
                <button
                    onClick={handleChangeStateToolbarClick}
                    className={`rounded-xs grid h-8 cursor-pointer place-content-center bg-[#F2FBFC] text-primary-200 ${
                        isOpenToolbar ? "w-10 rounded-r-full shadow-[0px_4px_4px_rgba(220,_220,_220,_0.5)]" : "w-8 rounded-7"
                    }`}>
                    <span className={isOpenToolbar ? "" : "rotate-180"}>
                        <DoubleArrowIcon width={size} height={size} />
                    </span>
                </button>
            </div>
        </ul>
    );
};

export default ToolBox;
