// import get from "lodash/get";
import includes from "lodash/includes";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// import { useLocation } from "react-router-dom";
import { GridLoader } from "react-spinners";
import Astronauts from "../icons/Astronauts";
import Figures from "../icons/Figures";

import { APPS_URL } from "../../constants";
import ReconnectModal from "./ReconnectModal";

export const Renderize = (props) => {
    const { permission, children } = props;
    const permissions = useSelector((state) => state.permissions);
    const loadingPermissions = useSelector((state) => state.loadingPermissions);
    const unauthorized = useSelector((state) => state.unauthorized);

    const hasPermission = (permission) => {
        return permissions && includes(permissions, permission);
    };

    return (
        <>
            {unauthorized && <ReconnectModal />}
            {loadingPermissions ? (
                <div className="flex h-screen w-screen items-center justify-center">
                    <GridLoader color="#00B3C7" size={20} />
                </div>
            ) : hasPermission(permission) ? (
                children
            ) : (
                <UnauthorizedMessage />
            )}
        </>
    );
};

export const UnauthorizedMessage = () => {
    // const location = useLocation();
    // const pathname = get(location, "pathname", "");
    const { t } = useTranslation();

    return (
        <div className="h-[calc(100vh - 3.25rem] fixed w-screen bg-white sm:block lg:flex">
            <div className="lg:rounded-default  lg:flex lg:w-2/4 lg:flex-col  lg:items-end lg:justify-center xl:items-center">
                <div className=" lg:w-78 xl:w-112 mt-16 grid place-items-center lg:mt-0 lg:place-items-start">
                    <p className="flex flex-col items-center justify-center text-center text-3xl font-bold text-primary-200 lg:items-start lg:text-left lg:leading-[2rem] base:text-[3rem]  base:leading-[3rem]">
                        {t("pageNotFound.errorMessage")}
                    </p>
                    <p className="flex flex-col items-center justify-center text-center text-lg font-semibold text-gray-400 sm:py-2 lg:items-start lg:text-left">{` ${t(
                        "pageNotFound.page"
                    )} Builder`}</p>

                    <button
                        className="mt-6 rounded-[3rem] bg-primary-200 px-6 py-3 font-semibold text-white"
                        onClick={() => {
                            window.location.href = APPS_URL;
                        }}>
                        {t("pageNotFound.backToHome")}
                    </button>
                </div>
            </div>
            <div className="lg:relative lg:flex  lg:w-2/3 lg:items-end lg:justify-center">
                <div className="animate-fadeOut opacity-0">
                    <Astronauts
                        className="absolute left-[-60%] top-[10%] w-[210%] scale-[0.5] sm:left-[-35%] lg:left-[50%] lg:top-[50%] lg:-translate-x-2/4 lg:-translate-y-1/2 xl:w-[110%] xl:scale-[0.8]  2xl:w-full 2xl:scale-[1]"
                        width="100%"
                    />
                </div>
                <Figures className="opacity-0 sm:opacity-100" width="100%" />
            </div>
        </div>
    );
};
