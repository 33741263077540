import Metrics from "../resources/Metrics";
import {
    ADD_METRIC_COMPANY_CHANNELS,
    ADD_METRIC_BOT_ACTIVITY,
    ADD_METRIC_TOP_FLOWS,
    ADD_METRIC_UNIQUE_USERS,
    ADD_DAILY_SESSIONS,
    ADD_METRIC_TOP_INTENTS,
    UPDATE_BY_INTENT,
    ADD_TODAY_SESSIONS,
    ADD_SESSIONS,
} from "../constants";
import Http from "../services/Http";

import get from "lodash/get";
import compact from "lodash/compact";
import toUpper from "lodash/toUpper";

const { companyChannels, topFlows, topIntents, uniqueUsers, botActivity, todaySessions, dailySessions, sessions, uniqUsersFile, sessionsFile } =
    Metrics();

export const addCompanyChannels = (metrics) => {
    return {
        type: ADD_METRIC_COMPANY_CHANNELS,
        payload: metrics,
    };
};

export const addMetricBotActivity = (metrics) => {
    return {
        type: ADD_METRIC_BOT_ACTIVITY,
        payload: metrics,
    };
};

export const addTopFlows = (metrics) => {
    return {
        type: ADD_METRIC_TOP_FLOWS,
        payload: metrics,
    };
};

export const addUniqueUsers = (metrics) => {
    return {
        type: ADD_METRIC_UNIQUE_USERS,
        payload: metrics,
    };
};

export const addDailySessions = (metrics) => {
    return {
        type: ADD_DAILY_SESSIONS,
        payload: metrics,
    };
};

export const addTodaySessions = (metrics) => {
    return {
        type: ADD_TODAY_SESSIONS,
        payload: metrics,
    };
};

export const addSessions = (metrics) => {
    return {
        type: ADD_SESSIONS,
        payload: metrics,
    };
};

export const addTopIntents = (metrics) => {
    return {
        type: ADD_METRIC_TOP_INTENTS,
        payload: metrics,
    };
};
export const updateReportsByMessage = (messages, flowName) => {
    return {
        type: UPDATE_BY_INTENT,
        payload: { messages, flowName },
    };
};

export const parseCompanies = (companyId, getState) => {
    const bots = getState().bots;
    const bots_OF = bots.map((bot) => {
        const { properties, type } = bot;
        let isOfficial = false;
        if (get(properties, "provider") && toUpper(type) === "WHATSAPP") {
            isOfficial = true;
        }
        if (isOfficial) {
            return { slug: bot.Company.slug };
        }
        return null;
    });
    const customCompanies = getState().companies;
    const companies = compact(
        customCompanies.map((company) => {
            for (let i = 0; i < bots_OF.length; i += 1) {
                if (get(bots_OF[i], "slug")) {
                    if (company.slug === bots_OF[i].slug) {
                        return company;
                    }
                }
            }
            return null;
        })
    );
    let companiesIds = companies.reduce((acc, value) => {
        acc.push(value.id);
        return acc;
    }, []);
    if (companyId) {
        companiesIds = [];
        companiesIds.push(parseInt(companyId, 10));
    }
    return companiesIds;
};

export function getCompanyChannels(companyId, initDate, endDate, controller) {
    return function (dispatch, getState) {
        const { companies } = getState();
        let companiesIds = companies.reduce((acc, value) => {
            acc.push(value.id);
            return acc;
        }, []);
        if (companyId) {
            companiesIds = [];
            companiesIds.push(parseInt(companyId, 10));
        }
        return companyChannels(companiesIds, initDate, endDate, controller)
            .then(({ data }) => {
                dispatch(addCompanyChannels(data));
            })
            .catch(console.error);
    };
}

export function getTopFlows(botId, initDate, endDate, controller) {
    return function (dispatch, getState) {
        return topFlows(botId, initDate, endDate, controller)
            .then(({ data }) => {
                dispatch(addTopFlows(data));
            })
            .catch(console.error);
    };
}

export function getTopIntents(botId, initDate, endDate, controller) {
    return function (dispatch, getState) {
        return topIntents(botId, initDate, endDate, controller)
            .then(({ data }) => {
                dispatch(addTopIntents(data));
            })
            .catch(console.error);
    };
}

export function getUniqueUsers(botId, initDate, endDate, controller) {
    return function (dispatch, getState) {
        return uniqueUsers(botId, initDate, endDate, controller)
            .then(({ data }) => {
                dispatch(addUniqueUsers(data));
            })
            .catch(console.error);
    };
}

export function getTodaySessions(companyId, botId, controller) {
    return function (dispatch, getState) {
        const dateInit = new Date();
        const dateEnd = new Date();
        dateInit.setHours(0, 0, 0, 0);
        dateEnd.setHours(23, 59, 59, 999);
        return todaySessions(companyId, botId, dateInit, dateEnd, controller)
            .then(({ data }) => {
                dispatch(addTodaySessions(data));
            })
            .catch(console.error);
    };
}

export function getSessions(companyId, botId, initDate, endDate, controller) {
    return function (dispatch, getState) {
        const companiesIds = parseCompanies(companyId, getState);
        return sessions(companiesIds, botId, initDate, endDate, controller)
            .then(({ data }) => {
                dispatch(addSessions(data));
            })
            .catch(console.error);
    };
}

export function getDailySessions(companyId, botId, initDate, endDate, controller) {
    return function (dispatch, getState) {
        const companiesIds = parseCompanies(companyId, getState);
        return dailySessions(companiesIds, botId, initDate, endDate, controller)
            .then(({ data }) => {
                dispatch(addDailySessions(data));
            })
            .catch(console.error);
    };
}

export function getSessionsFile(companyId, botId, startDate, endDate, companyName) {
    return function (dispatch, getState) {
        const companiesIds = parseCompanies(companyId, getState);
        const body = {
            startDate: startDate,
            endDate: endDate,
            companyId: companiesIds,
            botId: botId,
            companyName,
            cols: "clientId:Cliente,botId:ID_Bot,botName:Bot,companyId:ID_Compañía,companyName:Compañía,startedAt:Fecha",
        };
        return sessionsFile(body)
            .then((res) => {
                const today = new Date().toISOString().slice(0, 10);
                const url = window.URL.createObjectURL(new Blob([res.data], { type: res.config.headers["Content-Type"] }));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `Sesiones_${companyId === undefined ? "TODAS" : companyName}_${today}.xls`);
                document.body.appendChild(link);
                link.click();
            })
            .catch(console.error);
    };
}

export function getUniqUsersFile(botId, startDate, endDate, botName) {
    return function (dispatch, getState) {
        const body = {
            startDate: startDate,
            endDate: endDate,
            botId: botId,
            columns: "referenceId:Id,clientName:Cliente,legalId:Cedula,source:Canal,botName:Bot,companyName:Compañía,clientCreatedAt:Cliente desde",
        };
        return uniqUsersFile(body)
            .then(({ data }) => {
                if (data.type === "text/csv") {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const today = new Date().toISOString().slice(0, 10);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `reporte-usuarios-unicos-${botName}-${today}.csv`);
                    document.body.appendChild(link);
                    link.click();
                }
            })
            .catch(console.error);
    };
}

export function getBotActivity(initDate, endDate, botId, controller) {
    return function (dispatch, getState) {
        return botActivity(botId, initDate, endDate, controller)
            .then(({ data }) => {
                dispatch(addMetricBotActivity(data));
            })
            .catch(console.error);
    };
}

/**
 * This will change the trained status.
 */
export function trainExpression(messages, companyId, flowName) {
    return function (dispatch) {
        return Http.post(`/metrics/training`, { messages, companyId, flowName })
            .then(({ data }) => {
                //should update by expression here.
                messages.map((message) => {
                    dispatch(updateReportsByMessage(message, flowName));
                    return [];
                });
                return data;
            })
            .catch(console.error);
    };
}
