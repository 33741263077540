import { ADD_CONDITIONALS, UPDATE_CONDITIONAL, SET_UNSAVED_CHANGES } from "../constants";

export const addConditionals = (conditionals) => ({
    type: ADD_CONDITIONALS,
    payload: conditionals,
});

export function updateConditional(conditional) {
    return function(dispatch) {
        dispatch({
            type: SET_UNSAVED_CHANGES,
        });
        dispatch({
            type: UPDATE_CONDITIONAL,
            payload: conditional,
        });
    };
}
