import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import first from "lodash/first";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";

import UniqueUsers from "./components/UniqueUsers";
import TopFlows from "./components/TopFlows";
import TopIntents from "./components/TopIntents";

import dayjs from "dayjs";

import BotMetricsFilters from "./components/BotMetricsFilters";

const BotMetrics = (props) => {
    const { metrics, companies, getTopFlows, getTopIntents, getUniqueUsers, getUniqUsersFile, bots } = props;
    const [loadingCards, setLoadingCards] = useState(true);
    const [isDownloadFileUniqUsers, setIsDownloadFileUniqUsers] = useState(false);
    const [filteredBots, setFilteredBots] = useState([]);
    const [botSelected, setBotSelected] = useState([]);

    const [companyId, setCompanyId] = useState(props.companyId);
    const [companySelected, setCompanySelected] = useState([]);

    const [initialDate, setInitialDate] = useState(dayjs().day(1).startOf("day").format());
    const [finalDate, setFinalDate] = useState(dayjs().endOf("day").format());

    const controller = new AbortController();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isEmpty(companies) && isNil(companyId) && !isEmpty(bots)) {
            setCompanySelected(first(orderBy(companies, ["name"], ["asc"])));
            setCompanyId(first(orderBy(companies, ["name"], ["asc"])).id);
            handleChange(first(orderBy(companies, ["name"], ["asc"])));
            setLoadingCards(false);
        }
    }, [companyId, companies, bots]);

    useEffect(() => {
        execute();
        return () => controller.abort();
    }, [botSelected]);

    const handleChange = (company) => {
        setFilteredBots(bots.filter((bot) => bot.companyId === company.id));
        setBotSelected(first(bots.filter((bot) => bot.companyId === company.id)));
    };

    const handleChangeBot = (bot) => {
        setBotSelected(bot);
        execute(bot.id);
    };

    const execute = async (botIdSent, initialDateSent, finalDateSent) => {
        const botId = !isEmpty(botIdSent) ? botIdSent : botSelected.id;
        const initDate = !isEmpty(initialDateSent) ? initialDateSent : initialDate;
        const finDate = !isEmpty(finalDateSent) ? finalDateSent : finalDate;

        setLoadingCards(true);
        dispatch(getUniqueUsers(botId, initDate, finDate, controller)).then(() => {
            setLoadingCards(false);
        });
        dispatch(getTopFlows(botId, initDate, finDate, controller));
        dispatch(getTopIntents(botId, initDate, finDate, controller));
    };

    const downLoadUniqUsers = async () => {
        setIsDownloadFileUniqUsers(true);

        await dispatch(getUniqUsersFile(botSelected.id, initialDate, finalDate, botSelected.name))
            .then(() => {
                setIsDownloadFileUniqUsers(false);
            })
            .catch((error) => {
                console.log(error);
                setIsDownloadFileUniqUsers(false);
            });
    };

    return (
        <div className="mb-6">
            <div className="mb-3 flex items-end justify-start">
                <BotMetricsFilters
                    companies={companies}
                    companySelected={companySelected}
                    filteredBots={filteredBots}
                    botSelected={botSelected}
                    initialDate={initialDate}
                    setInitialDate={setInitialDate}
                    finalDate={finalDate}
                    setFinalDate={setFinalDate}
                    handleChange={handleChange}
                    handleChangeBot={handleChangeBot}
                    execute={execute}
                />
            </div>
            <div className="mb-16">
                <div className="flex w-full">
                    <UniqueUsers
                        downLoadUniqUsers={downLoadUniqUsers}
                        isDownloadFileUniqUsers={isDownloadFileUniqUsers}
                        metrics={metrics}
                        loadingCards={loadingCards}
                    />
                    <TopFlows loadingCards={loadingCards} metrics={metrics} />
                    <TopIntents loadingCards={loadingCards} metrics={metrics} />
                </div>
            </div>
        </div>
    );
};

export default BotMetrics;
