import React from "react";

const labelStyle = "text-15 text-gray-400 leading-tight w-full block font-sans text-left pr-1";

const Label = (props) => (
    <div className={`mr-2 w-1/4 ${props.className}`}>
        <label className={labelStyle}>{props.name}</label>
    </div>
);

export default Label;
