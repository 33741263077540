import Companies from "../resources/Companies";
import { ADD_COMPANIES, GET_COMPANY } from "../constants";

const { get } = Companies();

export const popCompany = (company) => ({
    type: GET_COMPANY,
    payload: company,
});

export function getCompanies() {
    return function (dispatch) {
        return get()
            .then(({ data }) => {
                dispatch({
                    type: ADD_COMPANIES,
                    payload: data.rows,
                });
            })
            .catch((err) => {
                console.log("error getting companies:", err);
            });
    };
}
