import isEmpty from "lodash/isEmpty";
import { useState } from "react";
import { BeatLoader } from "react-spinners";

import SlideOver from "../../../common/SlideOver";

export const Inputs = ({ isOpenInputs, closeModal, inputsTool, inputsBubble, update }) => {
    const [loading, setLoading] = useState(false);
    const hasInputs = !isEmpty(inputsTool);

    const handleSaveInputs = (evt) => {
        evt.preventDefault();
        setLoading(true);

        const form = evt.target;
        const inputsList = form.querySelectorAll("input");

        const inputObj = {};

        inputsList.forEach((input) => {
            const { name, value } = input;
            const type = input.getAttribute("customtype");

            inputObj[name] = {
                value,
                type,
            };
        });

        update({ input: inputObj });
        setLoading(false);
        closeModal();
    };

    return (
        <SlideOver isOpen={isOpenInputs} closeModal={closeModal} title="Campos de entrada">
            <form onSubmit={handleSaveInputs} className="space-y-4 p-3 text-gray-400">
                {hasInputs ? (
                    inputsTool.map((input) => {
                        const { id, name, required, displayName, description, type } = input;

                        return (
                            <label key={id} className="block space-y-2">
                                <p>
                                    {displayName} {required && "*"}
                                </p>
                                <p className="text-xs">
                                    <span className="mr-2 font-semibold">Descripcion:</span>
                                    {description}
                                </p>
                                <input
                                    defaultValue={inputsBubble[name]?.value ?? ""}
                                    type="text"
                                    required={required}
                                    name={name}
                                    customtype={type}
                                    className="form-input"
                                    placeholder="Recuperar este input"
                                />

                                <span className="block border-b border-gray-200" />
                            </label>
                        );
                    })
                ) : (
                    <p>Este Tool no tiene campos de entrada</p>
                )}

                {hasInputs && (
                    <footer className="flex w-full justify-end py-4">
                        <button className="button-gradient-xl grid place-content-center disabled:cursor-not-allowed disabled:bg-opacity-60">
                            {loading ? <BeatLoader color={"#a6b4d03f"} size={10} /> : "Guardar"}
                        </button>
                    </footer>
                )}
            </form>
        </SlideOver>
    );
};
