export const App_pt = {
    bubbles: {
        Texto: "Texto",
        Aleatorio: "Aleatório",
        Imagen: "Imagem",
        Carrusel: "Carousel",
        Pregunta: "Pergunta",
        "Ir a flujo": "Ir para o fluxo",
        Recurso: "Recurso",
        Sandbox: "Sandbox",
        "Respuestas rápidas": "Resposta rápida",
        "Ingresa el texto que deseas que la persona vea cuando invoca el flujo seleccionado.":
            "Insira o texto que você deseja que a pessoa veja quando invocar o fluxo selecionado.",
        "¿Renderizar botones?": "Renderizar botões?",
        "¿Usar userCache?": "Usar memória de",
        userCache: "userCache",
        Ruta: "Caminho",
        "Intentos máximos": "Tentativas máximas",
        "Mensaje intento máximos": "Mensagem de tentativa máxima",
        "Has excedido el máximo de intentos": "Você excedeu o número máximo de tentativas",
        "Vocablos para cancelar": "Palavras para cancelar",
        "Cancelar, salir": "Cancelar, sair",
        "Mensaje al cancelar": "Mensagem ao cancelar",
        "Has cancelado tu solicitud": "Você cancelou sua solicitação",
        "Al volver a preguntar": "Ao voltar a perguntar",
        "Mensaje de volver a preguntar por defecto": "Mensagem padrão para perguntar novamente",
        "Ingresa un texto para la respuesta rápida": "Insira um texto para a resposta rápida",
        "Respuesta rápida": "Resposta rápida",
        "El tamaño del archivo es demasiado grande": "O tamanho do arquivo é muito grande",
        "Subir audio": "Carregar áudio",
        "Subir video": "Carregar vídeo",
        "Subir pegatina": "Carregar adesivo",
        "Ocurrió un error al intentar subir este archivo": "Ocorreu um erro ao tentar carregar este arquivo",
        Etiqueta: "Etiqueta",
        "Nombre de la etiqueta": "Nome da etiqueta",
        "Nombre del marcador": "Nome do marcador",
        Configurar: "Configurar",
        "Acción fallida": "Ação falhou",
        "Cargar audio": "Carregar áudio",
        Validación: "Validação",
        "Acción guardada": "Ação salva",
        "Recuerda completar todos los campos o eliminar tu validación para poder salir":
            "Lembre-se de preencher todos os campos ou excluir sua validação para poder sair",
        Mensajes: "Mensagens",
        Acciones: "Ações",
        Título: "Título",
        Subtítulo: "Subtítulo",
        oneTimeButtons: "Botões de uso único",
        "Burbuja no soportada": "Bolha não suportada",
        "Tipo de burbuja": "Tipo de bolha",
        "Tipo de máscara": "Tipo de máscara",
        Máscara: "Máscara",
        "Con Burbujas": "Com bolhas",
        "Sin Burbujas": "Sem bolhas",
        Probar: "Testar",
    },
    pageNotFound: {
        errorMessage: "Ops! algo deu errado",
        page: "Parece que você não tem acesso a",
        backToHome: "Voltar para aplicativos",
    },
    metrics: {
        "Métricas por Compañía": "Métricas por Companhia",
        "Métricas por Bot": "Métricas por Bot",
        "Top 10 Flujos": "Top 10 fluxos",
        "Top 10 Vocablos": "Top 10 palavras",
        "Usuarios Únicos": "Usuários únicos",
        "Interacciones con el Bot": "Interações com o Bot",
        "Métricas de Sesión": "Métricas de Sessão",
        Hoy: "Hoje",
        "Promedio Diario": "Média Diária",
        Sesiones: "Sessões",
        Ajustes: "Configurações",
        Cancelar: "Cancelar",
    },

    datePicker: {
        date: "data",
        erase: "Apagar",
        apply: "Aplicar",
        today: "Hoje",
        yesterday: "Ontem",
        last24hours: "Últimas 24 horas",
        thisWeek: "Esta semana",
        lastWeek: "Semana passada",
        lastSevenDays: "Últimos 7 dias",
        thisMonth: "Este mês",
        lastMonth: "Mês passado",
        ranges: "Ranges",
    },
    login: {
        hello: "Olá!",
        credentials: "Insira suas credenciais",
        email: "Email",
        password: "Senha",
        initsesion: "Iniciar sessão",
        forgotpassword: "Esqueceu sua senha?",
        o: "ou",
        "Ingresando...": "Entrando...",
        "¡Oops! Creemos que estás un poco perdido": "Oops! Acreditamos que você está um pouco perdido",
        "¡Oops! Creemos que no puedes estar aquí": "Oops! Acreditamos que você não pode estar aqui",
        "Parece que no tienes accesos a": "Parece que você não tem acesso a",
        "Regresar a APPS": "Voltar para APPS",
        "Regresar a Home": "Voltar para Home",
        pageNotFound: "A página {{pathname}} não foi encontrada, esta é tudo o que sabemos",
    },

    home: {
        "Tu sesión expiró debido a tu inactividad. No te preocupes, inicia sesión nuevamente":
            "Sua sessão expirou devido à sua inatividade. Não se preocupe, faça login novamente",
        "tu sesión ha expirado": "sua sessão expirou",
        Ups: "Ups!",
        "Iniciar sesión": "Iniciar sessão",
        "No te preocupes, inicia sesión nuevamente": "Não se preocupe, faça login novamente",
        "Parece que no tienes sesión": "Parece que você não tem sessão",
    },
    common: {
        description: "Descrição",
        Editar: "Editar",
        Borrar: "Apagar",
        "Por defecto": "Definido como padrão",
        Compañía: "Companhia",
        "Escoja una empresa": "Escolha uma empresa",
        "No hay datos para mostrar": "Não há dados para mostrar",
        "Sin información actual": "Sem informação atual",
        Guardar: "Salvar",
        Limpiar: "Limpar",
        filas: "linhas",
        de: "de",
        "Buscar por mensaje": "Buscar por mensagem",
        error: "Algo aconteceu, tente mais tarde.",
        Ingles: "Inglês",
        Español: "Espanhol",
        Portugues: "Português",
        Idioma: "Idioma",
        Métricas: "Métricas",
        Recursos: "Recursos",
        Reportes: "Relatórios",
        "Cerrar sesión": "Sair",
        "¿Estás seguro que deseas continuar?": "Você tem certeza que deseja continuar?",
        Sí: "Sim",
        No: "Não",
        botonName: "Botão {{botonName}}",
        optionName: "Opção {{optionName}}",
        Nombre: "Nome",
        "Nombre del botón": "Nome do botão",
        "Nombre de la opción": "Nome da opção",
        "Ingrese nombre del botón": "Insira o nome do botão",
        Teléfono: "Telefone",
        Url: "Url",
        Cancelar: "Cancelar",
        ej: "ex",
        Agregar: "Adicionar",
        Dirección: "Endereço",
        "Ingresa la dirección del contacto": "Insira o endereço do contato",
        Validar: "Validar",
        "Seleccionar validación": "Selecione a validação",
        "Seleccione recurso": "Selecione o recurso",
        "¡Copiado al portapapeles!": "Copiado para a área de transferência!",
        "Ver recurso": "Ver recurso",
        Builder: "Builder",
        "Nuevo mensaje aleatorio": "Nova mensagem aleatória",
        errorLimitDescription: "O limite de caracteres é de 72",
        "Eliminar flujo": "Excluir fluxo",
        "Seleccionar tipo de máscara": "Selecione o tipo de máscara",
        "Probar vocablos": "Testar palavras",
    },
    filters: {
        "Seleccione Compañía": "Selecione a Companhia",
        "Seleccione Bot": "Selecione o Bot",
        "Seleccione flujo": "Selecione o fluxo",
        "Seleccione Vocablo": "Selecione a palavra",
        "Seleccione recurso": "Selecione o recurso",
        Vocablo: "Palavra",
        Flujo: "Fluxo",
        "Seleccionar flujo": "Selecione o fluxo",
        Desvinculación: "Desvinculação",
        Informativa: "Informativa",
        Transacción: "Transação",
        Ubicación: "Localização",
        Vinculación: "Vinculação",
        "Vocablos Entendidos": "Palavras entendidas",
        "Vocablos no Entendidos": "Palavras não entendidas",
        "Vocablos Entrenados": "Palavras treinadas",
        "Ingrese texto": "Insira o texto",
        Condición: "Condição",
        "Seleccionar operador": "Selecione o operador",
        "Seleccionar condición": "Selecione a condição",
        "Seleccionar opción": "Selecione a opção",
        Compañía: "Companhia",
        Estado: "Estado",
        Exitoso: "Exitoso",
        "No exitoso": "Não exitoso",
        "Id o número de teléfono": "Id ou número de telefone",
    },
    resources: {
        Recursos: "Recursos",
        "Seleccionar flujo": "Selecione o fluxo",
        "Agregar recurso": "Adicionar recurso",
        "para consumir servicios": "para consumir serviços",
        "Buscar recurso": "Buscar recurso",
        "Ingresa el nombre con el que quieres identificar tu recurso": "Insira o nome com o qual você deseja identificar seu recurso",
        Nombre: "Nome",
        Url: "Url",
        Tipo: "Tipo",
        Método: "Método",
        "Nombre del recurso": "Nome do recurso",
        "Url del recurso": "Url do recurso",
        "Seleccionar protocolo": "Selecione o protocolo",
        "Seleccionar método": "Selecione o método",
        "Por favor ingrese el nombre del recurso para poder guardar": "Por favor, insira o nome do recurso para poder salvar",
        "Escoge un recurso para empezar a configurarlo": "Escolha um recurso para começar a configurá-lo",
    },
    reports: {
        "Reporte de Vocablos": "Relatório de Palavras",
        "Reporte de Recursos": "Relatório de Recursos",
        Mensaje: "Mensagem",
        Entendido: "Entendido",
        Flujo: "Fluxo",
        Tipo: "Tipo",
        Fecha: "Data",
        Entrenar: "Treinar",
        "Error al entrenar": "Erro ao treinar",
        "Reporte generado. Le llegará al correo en unos minutos": "Relatório gerado. Você receberá o e-mail em alguns minutos",
        Recurso: "Recurso",
        Estado: "Estado",
        Bot: "Bot",
        "La solicitud no puede ser procesada debido a que el número de registros excede el número permitido por Excel":
            "A solicitação não pode ser processada porque o número de registros excede o permitido pelo Excel",
    },
    flows: {
        Flujo: "Fluxo",
        Flujos: "Fluxos",
        deleteFlow: "Você tem certeza que deseja excluir o fluxo {{flowName}}?",
        "Este flujo está siendo utilizado en los siguientes bots": "Este fluxo está sendo usado nos seguintes bots",
        "Este flujo no está siendo utilizado en ningún bot.": "Este fluxo não está sendo usado em nenhum bot.",
        "Flujo eliminado exitosamente": "Fluxo excluído com sucesso",
        "Error al eliminar el flujo. Intente más tarde": "Erro ao excluir o fluxo. Tente mais tarde",
        "Error al actualizar el flujo por defecto": "Erro ao atualizar o fluxo padrão",
        "Flujo por defecto actualizado exitosamente": "Fluxo padrão atualizado com sucesso",
        "Nombre del flujo ya existe": "Nome do fluxo já existe",
        "Agregar vocablo": "Adicionar palavra",
        "Agregar un nuevo flujo": "Adicionar um novo fluxo",
        "Puedes agregar un flujo con una o varias burbujas": "Você pode adicionar um fluxo com uma ou várias bolhas",
        "Ingresa el nombre con el que quieres identificar tu flujo": "Insira o nome com o qual você deseja identificar seu fluxo",
        "Nombre del flujo": "Nome do fluxo",
        "Buscar flujo": "Buscar fluxo",
        "Nombre del flujo no puede estar vacío": "Nome do fluxo não pode estar vazio",
        "Ver vocablo": "Ver palavra",
        "Cambios guardados exitosamente": "Alterações salvas com sucesso",
        "Esta base de conocimiento no se puede editar": "Este banco de conhecimento não pode ser editado",
        "No se puede guardar el flujo, falta Nombre de la etiqueta o está incompleta la configuración":
            "Não é possível salvar o fluxo, falta o nome da etiqueta ou a configuração está incompleta",
        "No se puede guardar el flujo, por favor validar la configuracion en las burbujas toolkit":
            "Não é possível salvar o fluxo, por favor validar a configuração nas bolhas toolkit",
        "No se puede guardar el flujo, seleccionar recurso": "Não é possível salvar o fluxo, selecione o recurso",
        "Nombre del flujo no puede contener mayúsculas o caracteres acentuados": "Nome do fluxo não pode conter maiúsculas ou caracteres acentuados",
        "Nombre del flujo no puede contener caracteres acentuados": "Nome do fluxo não pode conter caracteres acentuados",
        "Flujo creado exitosamente": "Fluxo criado com sucesso",
        "Flujo actualizado exitosamente": "Fluxo atualizado com sucesso",
        "Este bot fue creado en Brain, no se pueden agregar vocablos": "Este bot foi criado no Brain, não é possível adicionar palavras",
    },
    toolkit: {
        selecciona_opcion: "Selecione uma opção",
    },
};
