import { useState } from "react";
import { useDispatch } from "react-redux";
import Tippy from "@tippyjs/react";
import { useTranslation } from "react-i18next";

import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";

import { NEW, UPDATE } from "../../../../constants";
import { updateConditional } from "../../../../actions/conditionals";

import FlowIcon from "../../../icons/FlowIcon";
import BubbleContainer from "../BubbleContainer";
import SlideOver from "../../../common/SlideOver";
import SettingsIcon from "../../../icons/SettingsIcon";
import GoToFlowIcon from "../../../icons/GoToFlowIcon";
import FormCombobox from "../../../common/FormCombobox";

const GoToFlowBubble = (props) => {
    const { flows, conditional, setFlow } = props;
    const [isOpen, setIsOpen] = useState(false);
    const currentFlow = flows.find((flow) => flow.id === get(conditional, "flowId", get(conditional, "payload.flowId", "")));
    const [toFlow, setToFlow] = useState(currentFlow || "");
    const { t } = useTranslation();
    const dispatch = useDispatch();

    let operators = [
        {
            id: 1,
            name: "Mayor a",
            value: ">",
        },
        {
            id: 2,
            name: "Menor a",
            value: "<",
        },
        {
            id: 3,
            name: "Igual a",
            value: "=",
        },
        {
            id: 4,
            name: "Diferente a",
            value: "!=",
        },
    ];

    const conditionalValue = operators.find((operator) => operator.value === get(conditional, "operator", ""));

    const closeModal = () => {
        setIsOpen(false);
    };

    const openModal = () => {
        setIsOpen(!isOpen);
    };

    const handleChange = ({ target }) => {
        const { value, name } = target;
        updateCondition({
            [name]: value,
        });
    };

    const handleOperatorChange = (operator) => {
        const { value } = operator;
        updateCondition({
            operator: value,
        });
    };

    const handleSelectChange = ({ id }) => {
        const payload = {
            ...conditional.payload,
            type: "flow",
            flowId: id,
        };

        updateCondition({
            ...conditional,
            flowId: id,
            payload,
            action: conditional.action === NEW ? NEW : UPDATE,
        });
    };

    const updateCondition = (obj) => {
        dispatch(
            updateConditional({
                ...conditional,
                ...obj,
                action: conditional.action === NEW ? NEW : UPDATE,
            })
        );
    };

    const handleFlowChange = (flow) => {
        setToFlow(flow);
    };

    const handleGoToFlow = () => {
        const intent = props.intents.find((intent) => intent.slug === toFlow.intent);
        setFlow(intent);
    };

    let flows_intents = [];
    flows_intents = flows;

    const flowOptions = flows_intents.map((flow) => {
        return {
            ...flow,
            id: flow.id,
            name: flow.title,
        };
    });

    return (
        <BubbleContainer
            {...props}
            bubbleStyles={props.bubbleStyles}
            icon={<GoToFlowIcon className="mx-2 fill-current text-gray-400" width="1.4375rem" height="1.0625rem" />}
            title={t("bubbles.Ir a flujo")}
            openModal={openModal}>
            <div className="relative flex-1 rounded-lg">
                <div className="m-2">
                    <input
                        type="text"
                        name="value"
                        className="bubble-form-input"
                        value={get(conditional, "value", "")}
                        placeholder={t("bubbles.Nombre de la etiqueta")}
                        onChange={handleChange}
                    />
                    <div className="mt-3 flex justify-end">
                        <Tippy content={t("bubbles.Ir a flujo")} theme="jelou" arrow={false} placement={"bottom"} touch={false}>
                            <button
                                className="mr-1 flex items-center justify-center rounded-lg bg-white px-2 py-1 text-primary-200 shadow-input hover:bg-primary-200 hover:text-white disabled:cursor-not-allowed disabled:text-grey-100 disabled:hover:bg-white"
                                onClick={handleGoToFlow}
                                disabled={isEmpty(toFlow)}>
                                <div className="flex items-center justify-center">
                                    <FlowIcon className="fill-current" width="16px" height="16px" />
                                </div>
                            </button>
                        </Tippy>
                        <Tippy content={t("bubbles.Configurar")} theme="jelou" arrow={false} placement={"bottom"} touch={false}>
                            <button
                                className="flex w-7 cursor-pointer items-center justify-center rounded-lg bg-white py-2 text-primary-200 shadow-input hover:bg-primary-200 hover:text-white"
                                onClick={openModal}>
                                <SettingsIcon className="fill-current" width="0.9375rem" height="0.9375rem" />
                            </button>
                        </Tippy>
                    </div>
                </div>
            </div>
            <SlideOver isOpen={isOpen} title={t("bubbles.Ir a flujo")} closeModal={closeModal}>
                <div className="w-full px-5 py-4">
                    <div className="grid gap-4 text-sm font-semibold text-gray-400 text-opacity-75">
                        <label htmlFor="title">
                            {t("bubbles.Etiqueta")}
                            <input
                                type="text"
                                name="value"
                                className="form-input"
                                value={get(conditional, "value", "")}
                                placeholder={t("bubbles.Nombre de la etiqueta")}
                                onChange={handleChange}
                            />
                        </label>
                        <label htmlFor="title">
                            {t("filters.Condición")}
                            <FormCombobox
                                options={orderBy(operators, ["name"], ["asc"])}
                                value={conditionalValue}
                                handleChange={handleOperatorChange}
                                placeholder={t("filters.Seleccionar condición")}
                                name={t("flows.Conditional")}
                                background={"#fff"}
                                hasCleanFilter={false}
                            />
                        </label>
                        <label>
                            {t("bubbles.Texto")}
                            <input
                                type="text"
                                name="against"
                                className="form-input"
                                value={conditional.against}
                                placeholder={t("filters.Ingrese texto")}
                                onChange={handleChange}
                            />
                        </label>
                        <label htmlFor="flujo" className="flex flex-col">
                            {t("flows.Flujo")}
                            <FormCombobox
                                options={orderBy(flowOptions, ["name"], ["asc"])}
                                value={flowOptions.find((flow) => flow.id === get(conditional, "flowId", get(conditional, "payload.flowId", "")))}
                                handleChange={(evt) => {
                                    handleFlowChange(evt);
                                    handleSelectChange(evt);
                                }}
                                placeholder={t("filters.Seleccionar flujo")}
                                name={t("flows.Flujo")}
                                background={"#fff"}
                                hasCleanFilter={false}
                            />
                        </label>
                    </div>
                    <div className="mt-3 flex justify-end">
                        <div>
                            <button
                                className="mr-1 flex items-center justify-center rounded-lg border border-input bg-white px-2 py-1 text-primary-200 disabled:cursor-not-allowed disabled:text-grey-100"
                                onClick={handleGoToFlow}
                                disabled={isEmpty(toFlow)}>
                                <div className="flex items-center justify-center">
                                    <FlowIcon className="fill-current" width="16px" height="16px" />
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </SlideOver>
        </BubbleContainer>
    );
};

export default GoToFlowBubble;
