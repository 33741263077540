import { ADD_FLOW, UPDATE_FLOW, DELETE_FLOW } from "../constants";
export const flow = function flow(state = {}, action) {
    switch (action.type) {
        case ADD_FLOW:
            return { ...action.payload };
        case UPDATE_FLOW:
            return { ...state, ...action.payload };
        case DELETE_FLOW:
            return {};
        default:
            return state;
    }
};
