import React from "react";

import Icon from "./Icon";

const UnlockIcon = (props) => (
    <Icon viewBox="0 0 24 24" className={props.className} width={props.width} height={props.height}>
        <path d="M5 12h14c0.137 0 0.266 0.027 0.382 0.075 0.122 0.050 0.232 0.125 0.325 0.218s0.167 0.203 0.218 0.325c0.048 0.116 0.075 0.245 0.075 0.382v7c0 0.137-0.027 0.266-0.075 0.382-0.050 0.122-0.125 0.232-0.218 0.325s-0.203 0.167-0.325 0.218c-0.116 0.048-0.245 0.075-0.382 0.075h-14c-0.137 0-0.266-0.027-0.382-0.075-0.122-0.050-0.232-0.125-0.325-0.218s-0.167-0.203-0.218-0.325c-0.048-0.116-0.075-0.245-0.075-0.382v-7c0-0.137 0.027-0.266 0.075-0.382 0.050-0.122 0.125-0.232 0.218-0.325s0.203-0.167 0.325-0.218c0.116-0.048 0.245-0.075 0.382-0.075zM8 10v-3c-0.001-0.545 0.107-1.061 0.301-1.531 0.202-0.489 0.499-0.93 0.868-1.3s0.81-0.667 1.298-0.87c0.47-0.195 0.985-0.304 1.529-0.304 0.5-0.001 0.977 0.090 1.416 0.257 0.454 0.172 0.87 0.425 1.23 0.744 0.322 0.285 0.598 0.622 0.816 0.998 0.214 0.369 0.373 0.776 0.463 1.211 0.113 0.541 0.642 0.888 1.183 0.775s0.888-0.642 0.775-1.183c-0.135-0.647-0.371-1.255-0.691-1.807-0.326-0.562-0.739-1.065-1.221-1.491-0.538-0.477-1.163-0.857-1.847-1.117-0.663-0.251-1.38-0.387-2.126-0.386-0.811 0.001-1.586 0.163-2.294 0.457-0.735 0.305-1.395 0.752-1.947 1.305s-0.997 1.212-1.301 1.948c-0.292 0.708-0.453 1.484-0.452 2.294v3h-1c-0.405 0-0.793 0.081-1.148 0.228-0.368 0.152-0.698 0.375-0.974 0.651s-0.499 0.606-0.651 0.974c-0.146 0.354-0.227 0.742-0.227 1.147v7c0 0.405 0.081 0.793 0.228 1.148 0.152 0.368 0.375 0.698 0.651 0.974s0.606 0.499 0.974 0.651c0.354 0.146 0.742 0.227 1.147 0.227h14c0.405 0 0.793-0.081 1.148-0.228 0.368-0.152 0.698-0.375 0.974-0.651s0.499-0.606 0.651-0.974c0.146-0.354 0.227-0.742 0.227-1.147v-7c0-0.405-0.081-0.793-0.228-1.148-0.152-0.368-0.375-0.698-0.651-0.974s-0.606-0.499-0.974-0.651c-0.354-0.146-0.742-0.227-1.147-0.227z" />
    </Icon>
);

export default UnlockIcon;
