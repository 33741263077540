import React from "react";

import WebIcon from "../../icons/WebIcon";
import MicIcon from "../../icons/MicIcon";
import AlexaIcon from "../../icons/AlexaIcon";
import GoogleIcon from "../../icons/GoogleIcon";
import WidgetIcon from "../../icons/WidgetIcon";
import TwitterIcon from "../../icons/TwitterIcon";
import WhatsappIcon from "../../icons/WhatsappIcon";
import FacebookIcon from "../../icons/FacebookIcon";
import TicketIcon from "../../icons/TicketIcon";
import InstagramIcon from "../../icons/InstagramIcon";
import DefaultWebIcon from "../../icons/DefaultWebIcon";

function getColor(type) {
    switch (type) {
        case "facebook":
        case "facebook_feed":
            return "#3b5998";
        case "whatsapp":
            return "#25d366";
        case "twitter":
        case "twitter_replies":
            return "#00aced";
        case "google":
            return "#f3f3f3";
        case "alexa":
            return "#31C4F3";
        case "voiceassistance":
            return "#ffa500";
        case "instagram":
        case "web":
        case "widget":
            return "#00000000";
        case "email":
            return "#3498db";
        default:
            return "#3498db";
    }
}

function getIcon(type) {
    switch (type) {
        case "whatsapp":
            return <WhatsappIcon className="fill-current text-white" width="18px" height="18px" />;
        case "facebook":
        case "facebook_feed":
            return <FacebookIcon className="fill-current text-white" width="18px" height="18px" />;
        case "twitter":
        case "twitter_replies":
            return <TwitterIcon className="fill-current text-white" width="18px" height="18px" />;
        case "google":
            return <GoogleIcon className="fill-current text-white" width="18px" height="18px" />;
        case "alexa":
            return <AlexaIcon className="fill-current text-white" width="18px" height="18px" />;
        case "voiceassistance":
            return <MicIcon className="fill-current text-white" width="18px" height="18px" />;
        case "instagram":
            return <InstagramIcon className="fill-current text-white" width="28px" height="28px" />;
        case "widget":
            return <WidgetIcon className="overflow-hidden rounded-lg fill-current text-white" width="28px" height="28px" />;
        case "web":
            return <WebIcon className="overflow-hidden rounded-lg fill-current text-white" width="28px" height="28px" />;
        case "email":
        case "zendesk":
            return <TicketIcon className="overflow-hidden rounded-lg fill-current text-white" width="28px" height="28px" />;
        default:
            return <DefaultWebIcon className="fill-current text-white" width="18px" height="18px" />;
    }
}

const BotType = (props) => {
    const type = props.type.toLowerCase();
    const style = {
        backgroundColor: getColor(type),
    };
    return (
        <div className={`flex h-8 w-8 items-center justify-center rounded-lg text-center ${props.className}`} style={style}>
            {getIcon(type)}
        </div>
    );
};

export default BotType;
