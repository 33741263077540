import OpenReplay from "@openreplay/tracker";
import trackerAssist from "@openreplay/tracker-assist";

const { REACT_APP_OPEN_REPLAY_TRACKER_ID } = process.env;

const tracker = new OpenReplay({
    projectKey: REACT_APP_OPEN_REPLAY_TRACKER_ID,
    obscureTextEmails: false,
    obscureTextNumbers: false,
    obscureInputEmails: false,
    respectDoNotTrack: process.env.NODE_ENV !== "production",
    __DISABLE_SECURE_MODE: process.env.NODE_ENV !== "production",
    defaultInputMode: 0,
    network: {
        capturePayload: true, // Capture HTTP payload
        sessionTokenHeader: true,
        ignoreHeaders: false,
    },
});

tracker.use(
    trackerAssist({
        callConfirm: {
            text: "Soporte técnico desea comunicarse contigo",
            confirmBtn: "Contestar",
            declineBtn: "Rechazar",
        },
        controlConfirm: {
            text: "El agente solicitó el control remoto",
            confirmBtn: "Aceptar",
            declineBtn: "Rechazar",
        },
    })
);

export default tracker;
