import { useState } from "react";

import isEmpty from "lodash/isEmpty";
import Fuse from "fuse.js";

import { useTranslation } from "react-i18next";

/* Components */
import Bot from "./Bot";
import Input from "../../common/FormComponent/Input";

const BotList = (props) => {
    const { bots } = props;
    const { t } = useTranslation();
    const [query, setQuery] = useState("");

    const fuseOptions = {
        threshold: 0.1,
        keys: ["name", "type", "id"],
    };

    const handleSearch = ({ target }) => {
        setQuery(target.value);
    };

    const searchBots = () => {
        if (isEmpty(query)) {
            return bots;
        }

        const fuse = new Fuse(bots, fuseOptions);
        const result = fuse.search(query);

        let botsSearch = [];
        result.map((bot) => {
            return botsSearch.push(bot.item);
        });
        return botsSearch;
    };

    const botList = searchBots();

    return (
        <div className="scroll container mx-auto mb-2 w-full items-start overflow-y-auto p-8">
            <div className="mb-3 flex items-center justify-between">
                <h1 className="font-sans text-2xl font-normal text-gray-500">{t("Bots")}</h1>
            </div>
            <div className="mb-3 w-1/3">
                <Input onChange={handleSearch} autoFocus={true} placeholder={`${t("Buscar bot")}...`} />
            </div>
            <div className="row-gap-4 column-gap-4 grid-3 grid w-full flex-1">
                {botList.map((bot) => {
                    const company = props.companies.find((company) => {
                        return company.id === bot.companyId;
                    });
                    return (
                        <Bot
                            bot={bot}
                            key={bot.id}
                            currentId={props.currentId}
                            isLoading={props.isLoading}
                            handleClick={props.handleClick}
                            company={company}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default BotList;
