export const App_en = {
    bubbles: {
        Texto: "Text",
        Aleatorio: "Random",
        Imagen: "Image",
        Video: "Video",
        Documento: "File",
        Carrusel: "Carousel",
        Pregunta: "Question",
        "Ir a flujo": "Go to flow",
        Recurso: "Resource",
        Sandbox: "Sandbox",
        "Respuestas rápidas": "Quick replies",
        Contacto: "Contact",
        Operador: "Operator",
        Marcador: "Marker",
        Pegatina: "Sticker",
        Audio: "Audio",
        "Ingresa el texto que deseas que la persona vea cuando invoca el flujo seleccionado.":
            "Enter the text you want the person to see when invoking the selected flow.",
        "¿Renderizar botones?": "Render buttons?",
        "¿Usar userCache?": "Use memory of",
        userCache: "userCache",
        Ajustes: "Settings",
        Ruta: "Path",
        "Intentos máximos": "Maximum attempts",
        "Mensaje intento máximos": "Maximum attempts message",
        "Has excedido el máximo de intentos": "You have exceeded the maximum number of attempts",
        "Vocablos para cancelar": "Words to cancel",
        "Cancelar, salir": "Cancel, exit",
        "Mensaje al cancelar": "Cancel message",
        "Has cancelado tu solicitud": "You have canceled your request",
        "Al volver a preguntar": "When asking again",
        "Mensaje de volver a preguntar por defecto": "Default message to ask again",
        "Ingresa un texto para la respuesta rápida": "Enter a text for the quick reply",
        "Respuesta rápida": "Quick reply",
        "El tamaño del archivo es demasiado grande": "The file size is too large",
        "Subir audio": "Upload audio",
        "Subir video": "Upload video",
        "Subir pegatina": "Upload sticker",
        "Ocurrió un error al intentar subir este archivo": "An error occurred while trying to upload this file",
        Etiqueta: "Tag",
        "Nombre de la etiqueta": "Tag name",
        "Nombre del marcador": "Marker name",
        Configurar: "Configure",
        "Acción fallida": "Failed action",
        "Cargar audio": "Load audio",
        Validación: "Validation",
        "Acción guardada": "Action saved",
        "Recuerda completar todos los campos o eliminar tu validación para poder salir":
            "Remember to complete all fields or delete your validation to be able to exit",
        Mensajes: "Messages",
        Acciones: "Actions",
        Título: "Title",
        Subtítulo: "Subtitle",
        oneTimeButtons: "Quickly reply one time",
        "Burbuja no soportada": "Unsupported bubble",
        "Tipo de burbuja": "Bubble type",
        "Tipo de máscara": "Mask type",
        Máscara: "Mask",
        "Con Burbujas": "With Bubbles",
        "Sin Burbujas": "Without Bubbles",
        Probar: "Test",
    },
    pageNotFound: {
        errorMessage: "Oops! something went wrong",
        page: "It looks like you don't have access to",
        backToHome: "Back to Apps",
    },
    metrics: {
        "Métricas por Compañía": "Metrics by Company",
        "Métricas por Bot": "Metrics by Bot",
        "Top 10 Flujos": "Top 10 Flows",
        "Top 10 Vocablos": "Top 10 Words",
        "Usuarios Únicos": "Unique users",
        "Interacciones con el Bot": "Bot Interactions",
        "Métricas de Sesión": "Session Metrics",
        Hoy: "Today",
        "Promedio Diario": "Daily Average",
        Sesiones: "Sessions",
    },

    datePicker: {
        date: "Date",
        erase: "Erase",
        apply: "Apply",
        today: "Today",
        yesterday: "Yesterday",
        last24hours: "Last 24 hours",
        thisWeek: "This week",
        lastWeek: "Last week",
        lastSevenDays: "Last 7 days",
        thisMonth: "This month",
        lastMonth: "Last month",
        ranges: "Ranges",
    },
    login: {
        hello: "Hello!",
        credentials: "Enter your credentials",
        email: "Email",
        password: "password",
        initsesion: "Login",
        createAccount: "Create New Account",
        forgotpassword: "Forgot your password?",
        o: "or",
        "Ingresando...": "Logging in...",
        "¡Oops! Creemos que estás un poco perdido": "Oops! We think you're a little lost",
        "¡Oops! Creemos que no puedes estar aquí": "Oops! We think you can't be here",
        "Parece que no tienes accesos a": "It seems you don't have access to",
        "Regresar a APPS": "Back to APPS",
        "Regresar a Home": "Back to Home",
        pageNotFound: "The page {{pathname}} was not found, this is all we know",
    },

    home: {
        "Tu sesión expiró debido a tu inactividad. No te preocupes, inicia sesión nuevamente":
            "Your session expired due to inactivity. Don't worry, log in again",
        "tu sesión ha expirado": "your session has expired",
        Ups: "Whoops!",
        "Iniciar sesión": "Log in",
        "No te preocupes, inicia sesión nuevamente": "Don't worry, log in again",
        "Parece que no tienes sesión": "It seems you don't have a session",
    },
    common: {
        description: "Description",
        Editar: "Edit",
        Borrar: "Delete",
        "Por defecto": "Set as default",
        Compañía: "Company",
        "Escoja una empresa": "Choose a company",
        "No hay datos para mostrar": "No data to display",
        "Sin información actual": "No current information",
        Guardar: "Save",
        Limpiar: "Clear",
        filas: "rows",
        de: "of",
        "Buscar por mensaje": "Search by message",
        error: "Something went wrong, try again later.",
        Ingles: "English",
        Español: "Spanish",
        Portugues: "Portuguese",
        Idioma: "Language",
        Métricas: "Metrics",
        Recursos: "Resources",
        Reportes: "Reports",
        "Cerrar sesión": "Log out",
        "¿Estás seguro que deseas continuar?": "Are you sure you want to continue?",
        Sí: "Yes",
        No: "No",
        botonName: "Button {{botonName}}",
        optionName: "Option {{optionName}}",
        Nombre: "Name",
        "Nombre del botón": "Button name",
        "Nombre de la opción": "Option name",
        "Ingrese nombre del botón": "Enter button name",
        Teléfono: "Phone",
        Url: "Url",
        Cancelar: "Cancel",
        ej: "e.g.",
        Agregar: "Add",
        Dirección: "Address",
        "Ingresa la dirección del contacto": "Enter the contact address",
        Validar: "Validate",
        "Seleccionar validación": "Select validation",
        "Seleccione recurso": "Select resource",
        "¡Copiado al portapapeles!": "Copied to clipboard!",
        "Ver recurso": "View resource",
        Builder: "Builder",
        "Nuevo mensaje aleatorio": "New random message",
        errorLimitDescription: "The limit of characters is 72",
        "Eliminar flujo": "Delete flow",
        "Seleccionar tipo de máscara": "Select mask type",
        "Probar vocablos": "Test training",
    },
    filters: {
        "Seleccione Compañía": "Select Company",
        "Seleccione Bot": "Select Bot",
        "Seleccione flujo": "Select flow",
        "Seleccione Vocablo": "Select word",
        "Seleccione recurso": "Select resource",
        Vocablo: "Word",
        Flujo: "Flow",
        "Seleccionar flujo": "Select flow",
        Desvinculación: "Unlink",
        Informativa: "Informational",
        Transacción: "Transaction",
        Ubicación: "Location",
        Vinculación: "Link",
        "Vocablos Entendidos": "Known words",
        "Vocablos no Entendidos": "Unknown words",
        "Vocablos Entrenados": "Trained words",
        "Ingrese texto": "Enter text",
        Condición: "Condition",
        "Seleccionar operador": "Select operator",
        "Seleccionar opción": "Select option",
        "Seleccionar condición": "Select condition",
        Compañía: "Company",
        Estado: "State",
        Exitoso: "Successful",
        "No exitoso": "Unsuccessful",
        "Id o número de teléfono": "Id or phone number",
    },
    resources: {
        Recursos: "Resources",
        "Seleccionar flujo": "Select flow",
        "Agregar recurso": "Add new resource",
        "para consumir servicios": "to consume services",
        "Buscar recurso": "Search resource",
        "Ingresa el nombre con el que quieres identificar tu recurso": "Enter the name by which you wish to identify your resource.",
        Nombre: "Name",
        Url: "Url",
        Tipo: "Type",
        Método: "Method",
        "Nombre del recurso": "Resource name",
        "Url del recurso": "Resource url",
        "Seleccionar protocolo": "Select protocol",
        "Seleccionar método": "Select method",
        "Por favor ingrese el nombre del recurso para poder guardar": "Please enter the resource name to be able to save",
        "Escoge un recurso para empezar a configurarlo": "Choose a resource to start configuring it",
    },
    reports: {
        "Reporte de Vocablos": "Words Report",
        "Reporte de Recursos": "Resources Report",
        Mensaje: "Message",
        Entendido: "Understood",
        Flujo: "Flow",
        Tipo: "Type",
        Fecha: "Date",
        Entrenar: "Train",
        "Error al entrenar": "Training error",
        "Reporte generado. Le llegará al correo en unos minutos": "Report generated. It will arrive to your email in a few minutes",
        Recurso: "Resource",
        Estado: "State",
        Bot: "Bot",
        "La solicitud no puede ser procesada debido a que el número de registros excede el número permitido por Excel":
            "The request cannot be processed because the number of records exceeds the number allowed by Excel",
    },

    flows: {
        Flujo: "Flow",
        Flujos: "Flows",
        deleteFlow: "Are you sure you want to delete the flow? {{flowName}}?",
        "Este flujo está siendo utilizado en los siguientes bots": "This flow is being used in the following bots",
        "Este flujo no está siendo utilizado en ningún bot.": "This flow is not being used in any bot.",
        "Flujo eliminado exitosamente": "Flow deleted successfully",
        "Error al eliminar el flujo. Intente más tarde": "Error deleting flow. Try again later",
        "Error al actualizar el flujo por defecto. Intente más tarde": "Error updating default flow. Try again later",
        "Flujo por defecto actualizado exitosamente": "Default flow updated successfully",
        "Nombre del flujo ya existe": "Flow name already exists",
        "Agregar vocablo": "Add word",
        "Agregar un nuevo flujo": "Add new flow",
        "Puedes agregar un flujo con una o varias burbujas": "You can add a flow with one or more bubbles",
        "Ingresa el nombre con el que quieres identificar tu flujo": "Enter the name by which you wish to identify your flow",
        "Nombre del flujo": "Flow name",
        "Buscar flujo": "Search flow",
        "Nombre del flujo no puede estar vacío": "Flow name cannot be empty",
        "Ver vocablo": "View word",
        "Cambios guardados exitosamente": "Changes saved successfully",
        "Esta base de conocimiento no se puede editar": "This knowledge base cannot be edited",
        "No se puede guardar el flujo, falta Nombre de la etiqueta o está incompleta la configuración":
            "The flow cannot be saved, the tag name is missing or the configuration is incomplete",
        "No se puede guardar el flujo, seleccionar recurso": "The flow cannot be saved, select resource",
        "No se puede guardar el flujo, por favor validar la configuracion en las burbujas toolkit":
            "The flow cannot be saved, please validate the configuration in the toolkit bubbles",
        "Nombre del flujo no puede contener mayúsculas o caracteres acentuados": "Flow name cannot contain uppercase or accented characters",
        "Nombre del flujo no puede contener caracteres acentuados": "Flow name cannot contain accented characters",
        "Flujo creado exitosamente": "Flow created successfully",
        "Flujo actualizado exitosamente": "Flow updated successfully",
        "Este bot fue creado en Brain, no se pueden agregar vocablos": "This bot was created in Brain, words cannot be added",
    },
    toolkit: {
        selecciona_opcion: "Select an option",
    },
};
