import isEmpty from "lodash/isEmpty";

import { useEffect, useRef } from "react";
import { Chart, BarController, LineElement, PointElement, LinearScale, Title, BarElement, CategoryScale } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(BarController, LineElement, PointElement, LinearScale, Title, BarElement, CategoryScale);

const BarChart = (props) => {
    const { data, labels } = props;
    const chartEl = useRef(null);

    const datad = {
        labels: labels,
        datasets: [
            {
                label: "Total Interacciones",
                data: data,
                backgroundColor: [
                    "rgba(255, 99, 132, 0.2)",
                    "rgba(255, 159, 64, 0.2)",
                    "rgba(255, 205, 86, 0.2)",
                    "rgba(75, 192, 192, 0.2)",
                    "rgba(54, 162, 235, 0.2)",
                    "rgba(153, 102, 255, 0.2)",
                    "rgba(201, 203, 207, 0.2)",
                ],
                borderColor: [
                    "rgb(255, 99, 132)",
                    "rgb(255, 159, 64)",
                    "rgb(255, 205, 86)",
                    "rgb(75, 192, 192)",
                    "rgb(54, 162, 235)",
                    "rgb(153, 102, 255)",
                    "rgb(201, 203, 207)",
                ],
                borderWidth: 1,
            },
        ],
    };
    useEffect(() => {
        let myBarChart;
        const ctx = chartEl.current.getContext("2d");

        Chart.defaults.font.family = "Manrope";
        Chart.defaults.color = "#7e89a2";

        const config = {
            plugins: [ChartDataLabels],
            type: "bar",
            data: datad,
            options: {
                scales: {
                    x: {
                        display: true,
                        grid: {
                            display: false,
                        },
                    },
                    y: {
                        display: true,
                        grid: {
                            display: false,
                        },
                    },
                },
                plugins: {
                    tooltip: {
                        enabled: false,
                    },
                    legend: {
                        display: false,
                        position: "top",
                        fontFamily: "Montserrat",
                    },
                    datalabels: {
                        backgroundColor: "rgba(0,0,0,.75)",
                        borderRadius: 4,
                        color: "white",
                        rotation: 0,
                        display: (context) => {
                            const dataset = context.dataset;
                            // const count = dataset.data.length;
                            const value = dataset.data[context.dataIndex];
                            // value > count * 1.5
                            return value > 0;
                        },
                        offset: 16,
                        align: "end",
                        clip: false,
                        font: {
                            weight: "bold",
                            family: "Montserrat",
                        },
                        formatter: function (value, context) {
                            const dataTag = context.chart.data.labels[context.dataIndex];
                            return `${dataTag}: ${value}`;
                        },
                    },
                },
            },
        };
        myBarChart = new Chart(ctx, config);

        return () => {
            if (!isEmpty(myBarChart)) myBarChart.destroy();
        };
    }, [data]);

    return <canvas className="h-full w-full" ref={chartEl}></canvas>;
};

export default BarChart;
