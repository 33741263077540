import React from "react";

import Icon from "./Icon";

const ContactCardIcon = (props) => (
    <Icon viewBox="0 0 20 20" className={props.className} width={props.width} height={props.height}>
        <path
            d="M16.8868 17.8443C12.7056 17.8443 8.52436 17.8443 4.31766 17.8443C3.68028 17.8443 2.91542 17.9718 2.53299 17.3089C2.25255 16.85 2.38003 16.0852 2.38003 15.5753C2.38003 13.0257 2.38003 10.5017 2.38003 7.9522C2.38003 6.83041 2.38003 5.68313 2.38003 4.56134C2.38003 4.05144 2.25257 3.26109 2.584 2.82767C2.99193 2.24128 3.75678 2.36876 4.39416 2.36876C5.46495 2.36876 6.56123 2.36876 7.63203 2.36876C10.2325 2.36876 12.8076 2.36876 15.4081 2.36876C15.9435 2.36876 16.5044 2.31777 17.0143 2.36876C17.9576 2.47074 17.9831 3.33758 17.9831 4.07694C17.9831 6.44799 17.9831 8.84454 17.9831 11.2156C17.9831 13.0512 18.0596 14.9124 17.9831 16.748C17.9576 17.3599 17.5242 17.8189 16.8868 17.8443C15.867 17.8698 15.8415 19.476 16.8868 19.4505C18.0596 19.425 19.0794 18.6857 19.4618 17.5639C19.5893 17.207 19.5893 16.85 19.5893 16.4931C19.5893 15.5753 19.5893 14.6574 19.5893 13.7396C19.5893 10.9861 19.5893 8.23266 19.5893 5.47918C19.5893 4.8163 19.6148 4.12792 19.5893 3.46505C19.5638 1.85885 18.3145 0.762556 16.7338 0.737061C14.5158 0.737061 12.2976 0.737061 10.0796 0.737061C7.91247 0.737061 5.74538 0.737061 3.57829 0.737061C2.02308 0.737061 0.799321 1.88435 0.748331 3.46505C0.722836 4.15342 0.748331 4.86729 0.748331 5.55566C0.748331 8.30914 0.748331 11.0881 0.748331 13.8416C0.748331 14.7339 0.748331 15.6008 0.748331 16.4931C0.748331 18.0738 1.81912 19.374 3.45081 19.425C5.33746 19.476 7.24961 19.425 9.13626 19.425C11.5583 19.425 14.0058 19.425 16.4279 19.425C16.5808 19.425 16.7083 19.425 16.8613 19.425C17.9066 19.4505 17.9066 17.8443 16.8868 17.8443Z"
            fill={props.fill}
        />
        <path
            d="M12.5263 8.30918C12.5008 9.27799 11.8889 10.2468 10.9711 10.5528C10.8436 10.6037 10.6907 10.6292 10.5632 10.6802C10.5377 10.6802 10.3592 10.7057 10.4867 10.7057C10.4102 10.7057 10.3337 10.7312 10.2572 10.7312C9.9003 10.7567 9.77283 10.7312 9.46689 10.6547C9.16094 10.5783 9.08446 10.5273 8.80402 10.3488C8.57456 10.1958 8.42158 10.0684 8.21762 9.8134C8.2941 9.88988 8.19213 9.78789 8.19213 9.78789C8.14114 9.71141 8.09015 9.66042 8.06465 9.58394C8.03916 9.53295 8.01365 9.48196 7.98815 9.43097C7.98815 9.43097 7.88618 9.20151 7.93717 9.3035C7.88618 9.17602 7.83519 9.02304 7.8097 8.89556C7.75871 8.7171 7.75872 8.69161 7.75872 8.53864C7.73323 8.25819 7.75871 8.02872 7.8097 7.82476C7.88618 7.46783 7.93716 7.34036 8.11563 7.08541C8.60004 6.24407 9.64536 5.75966 10.5887 5.93813C11.7105 6.16758 12.5008 7.1364 12.5263 8.30918C12.5518 9.32898 14.1325 9.35448 14.1325 8.30918C14.107 6.62649 13.0617 5.14778 11.481 4.53589C9.9513 3.9495 8.09014 4.4594 7.07033 5.70866C5.99953 7.00892 5.79557 8.87007 6.6879 10.3488C7.55474 11.802 9.26291 12.5414 10.9201 12.2354C12.7812 11.904 14.107 10.1703 14.1325 8.30918C14.1325 7.28937 12.5263 7.28937 12.5263 8.30918Z"
            fill={props.fill}
        />
        <path
            d="M2.35391 17.7679C2.35391 17.9464 2.35391 18.1248 2.35391 18.2778C2.35391 19.2976 3.93463 19.3231 3.96012 18.2778C3.98562 15.6008 5.87224 13.3062 8.49825 12.7708C9.39058 12.5924 10.4359 12.6179 11.3537 12.6944C12.6285 12.8218 13.8522 13.4337 14.7446 14.3515C15.7644 15.3968 16.3253 16.7991 16.3508 18.2778C16.3508 19.2976 17.957 19.3231 17.957 18.2778C17.957 18.0993 17.957 17.9209 17.957 17.7679C17.957 16.7481 16.3508 16.7226 16.3508 17.7679C16.3508 17.9464 16.3508 18.1248 16.3508 18.2778C16.8862 18.2778 17.4216 18.2778 17.957 18.2778C17.9315 14.9634 15.5859 11.9295 12.297 11.2411C11.1753 11.0117 9.875 10.9862 8.72771 11.1391C7.09602 11.3431 5.56631 12.0825 4.39353 13.2807C3.09327 14.632 2.37941 16.4422 2.35391 18.3033C2.88931 18.3033 3.42472 18.3033 3.96012 18.3033C3.96012 18.1248 3.96012 17.9464 3.96012 17.7934C3.96012 16.7481 2.35391 16.7481 2.35391 17.7679Z"
            fill={props.fill}
        />
    </Icon>
);

export default ContactCardIcon;
