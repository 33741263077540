import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { useSelector } from "react-redux";

const unAuthorized = {
    app_id: null,
    app_token: null,
    isAuthorized: false,
};

/**
 * the current context is in the bot for flow selected
 * @returns {{
 *  app_id: string,
 * app_token: string,
 * isAuthorized: boolean
 * }}
 */
export function useToolkitCredentials() {
    const currentBoot = useSelector((state) => state.bot);
    const companies = useSelector((state) => state.companies);

    const currentBotId = get(currentBoot, "companyId", undefined);

    if (isNaN(currentBotId) || isEmpty(companies)) return unAuthorized;

    const currentCompany = companies.find((company) => company.id === currentBotId);

    if (isEmpty(currentCompany)) return unAuthorized;

    const { app_id, app_token } = get(currentCompany, "properties.builder", { app_id: null, app_token: null });

    const isAuthorized = !isEmpty(app_id) && !isEmpty(app_token);

    return { app_id, app_token, isAuthorized };
}
