import { useState, useEffect } from "react";
import { BeatLoader } from "react-spinners";
import { useDispatch } from "react-redux";

import head from "lodash/head";
import get from "lodash/get";
import first from "lodash/first";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";

import dayjs from "dayjs";

import BotInteractionsMetricsFilters from "./components/BotInteractionsMetricsFilters";
import BarChart from "../../../common/BarChart";

import { useTranslation } from "react-i18next";

import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(ChartDataLabels);

const BotInteractionsMetric = (props) => {
    const { metrics, companies, getBotActivity, bots } = props;
    const [loadingCards, setLoadingCards] = useState(true);
    const [filteredBots, setFilteredBots] = useState([]);
    const [botSelected, setBotSelected] = useState([]);

    const [companyId, setCompanyId] = useState(props.companyId);
    const [companySelected, setCompanySelected] = useState([]);
    const [data, setData] = useState([]);
    const [labels, setLabels] = useState([]);

    const [initialDate, setInitialDate] = useState(dayjs().day(1).startOf("day").format());
    const [finalDate, setFinalDate] = useState(dayjs().endOf("day").format());

    const controller = new AbortController();
    const dispatch = useDispatch();

    const hasData = !isEmpty(metrics) && !isEmpty(get(metrics, "bot_activity", []));

    const { t } = useTranslation();

    useEffect(() => {
        if (!isEmpty(companies) && isNil(companyId) && !isEmpty(bots)) {
            setCompanySelected(first(orderBy(companies, ["name"], ["asc"])));
            setCompanyId(first(orderBy(companies, ["name"], ["asc"])).id);
            handleChange(first(orderBy(companies, ["name"], ["asc"])));
            setLoadingCards(false);
        }
    }, [companyId, companies, bots]);

    useEffect(() => {
        execute();
        return () => controller.abort();
    }, [botSelected]);

    const hasDifferentYear = (activity) => {
        if (activity.length === 0) {
            return false;
        }
        let tmpDate = new Date(head(activity).date);
        let year = tmpDate.getFullYear();
        const dates = activity.reduce((acc, cur) => {
            const date = new Date(cur.date);
            acc.push(date.getFullYear());
            return acc;
        }, []);
        return dates.every((ye) => ye === year);
    };

    const dateFormat = (dateString) => {
        const date = dayjs(dateString).locale("es");

        const days = date.format("DD");
        const month = date.format("MMM");
        return month.charAt(0).toUpperCase() + month.slice(1, 3) + " " + days;
    };

    useEffect(() => {
        if (!isEmpty(get(metrics, "bot_activity", []))) {
            let data = metrics.bot_activity.reduce((acc, cur) => {
                acc.push(cur.amount);
                return acc;
            }, []);

            setData(data);

            const isYearRepeat = hasDifferentYear(metrics.bot_activity);

            let labels = metrics.bot_activity.reduce((acc, cur) => {
                acc.push(dateFormat(cur.date, isYearRepeat));
                return acc;
            }, []);
            setLabels(labels);
        }
    }, [metrics]);

    const handleChange = (company) => {
        setFilteredBots(bots.filter((bot) => bot.companyId === company.id));
        setBotSelected(first(bots.filter((bot) => bot.companyId === company.id)));
    };

    const handleChangeBot = (bot) => {
        setBotSelected(bot);
        execute(bot.id, null, null);
    };

    const execute = async (botIdSent, initialDateSent, finalDateSent) => {
        const botId = !isEmpty(botIdSent) ? botIdSent : botSelected.id;
        const initDate = !isEmpty(initialDateSent) ? initialDateSent : initialDate;
        const finDate = !isEmpty(finalDateSent) ? finalDateSent : finalDate;
        setLoadingCards(true);
        await dispatch(getBotActivity(initDate, finDate, botId, controller))
            .then(() => {
                setLoadingCards(false);
            })
            .catch((error) => {
                console.log(error);
                setLoadingCards(false);
            });
    };

    return (
        <div className="mb-6 rounded-lg bg-white px-4 py-10">
            <div className="mb-3 flex items-end justify-start">
                <BotInteractionsMetricsFilters
                    companies={companies}
                    companySelected={companySelected}
                    filteredBots={filteredBots}
                    botSelected={botSelected}
                    initialDate={initialDate}
                    setInitialDate={setInitialDate}
                    finalDate={finalDate}
                    setFinalDate={setFinalDate}
                    handleChange={handleChange}
                    handleChangeBot={handleChangeBot}
                    execute={execute}
                />
            </div>
            {loadingCards ? (
                <div className="pb-6 pt-2">
                    <BeatLoader color="#00B3C7" size={10} />
                </div>
            ) : hasData ? (
                <div className="mb-16">
                    <div className="flex w-full">
                        <BarChart data={data} labels={labels} translationsTitles={t("metrics.Interacciones con el Bot")} />
                    </div>
                </div>
            ) : (
                <div className="flex h-44 items-center justify-center font-sans font-bold uppercase text-gray-400">
                    {t("common.Sin información actual")}
                </div>
            )}
        </div>
    );
};

export default BotInteractionsMetric;
