import { ADD_RUN_ACTION_BUBBLE, UPDATE_RUN_ACTION_BUBBLES, SET_UNSAVED_CHANGES } from "../constants";

export const addRunActionBubble = (bubble) => ({
    type: ADD_RUN_ACTION_BUBBLE,
    payload: bubble,
});

export function updateRunActionBubble(bubble) {
    return function (dispatch) {
        dispatch({ type: SET_UNSAVED_CHANGES });
        dispatch({ type: UPDATE_RUN_ACTION_BUBBLES, payload: bubble });
    };
}
