import get from "lodash/get";
import toLower from "lodash/toLower";
import toUpper from "lodash/toUpper";
import isEmpty from "lodash/isEmpty";

import uniqid from "uniqid";
import { List } from "immutable";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { PulseLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from "react-pdf";

import AutoSizer from "react-virtualized-auto-sizer";

/* Components */
import FileIcon from "../../icons/FileIcon";
import ImageEmptyIcon from "../../icons/ImageEmptyIcon";
import DocumentFile from "../../common/DocumentFile";
import BubbleContainer from "./BubbleContainer";

import Http from "../../../services/Http";
import { UPDATE, NEW } from "../../../constants";
import { updateAttachment } from "../../../actions/attachments";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function getAttachment(attachments, bubbleId) {
    return List(attachments).find((a) => a.bubbleId === bubbleId);
}

const FileBubble = (props) => {
    const { attachments, bubble, flow, bot } = props;
    const dispatch = useDispatch();
    const [uploading, setUploading] = useState(false);
    const { t } = useTranslation();

    let attachment = getAttachment(attachments, bubble.id);

    const url = attachment && get(attachment, "url");

    const getExtention = (mediaName) => {
        try {
            return mediaName.match(/\.[0-9a-z]+$/i)[0].replace(".", "");
        } catch (error) {
            return "Unknown";
        }
    };

    const handleLoadLocalFile = (event, attachment) => {
        event.preventDefault();
        const { files } = event.target;
        const image = files[0];

        setUploading(true);

        const formData = new FormData();
        formData.append("image", image);
        let intent = flow.intent;
        const botId = bot.id;
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        let fileName = image.name.replace(/ /g, "_");
        fileName = uniqid() + `-${fileName}`;
        const path = toLower(`${botId}/flows/${intent}/images/${fileName}`);
        formData.append("path", path);
        Http.post(`/images/upload`, formData, config)
            .then(({ data }) => {
                dispatch(
                    updateAttachment({
                        ...attachment,
                        url: data,
                        action: attachment.action === NEW ? NEW : UPDATE,
                    })
                );
                setUploading(false);
            })
            .catch(console.error);
    };

    const documentAcceptance = () => {
        return ".xls,.xlsx,.doc,.docx,.ppt,.pptx,.pdf,application/pdf,.html,.csv,.svg,.txt,.plain,html,csv,svg,txt, plain,.svg,.dmg,.rar,.zip,.yml,dmg,.php";
    };

    // const renderFile = (url) => {
    //     if (url) {
    //         return <DocumentFile url={url} />;
    //     }
    //     return (
    //         <div className="flex h-32 flex-col items-center justify-center text-gray-450 hover:text-primary-200">
    //             <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
    //                 <path
    //                     fillRule="evenodd"
    //                     d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
    //                     clipRule="evenodd"
    //                 />
    //             </svg>
    //             <span className="font-sans text-sm font-extrabold">{t("Cargar documento")}</span>
    //         </div>
    //     );
    // };

    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <BubbleContainer
            {...props}
            bubbleStyles={props.bubbleStyles}
            icon={<FileIcon className="mx-2 fill-current text-gray-400" width="25" />}
            title={t("bubbles.Documento")}>
            <div className="relative my-2 flex-1 rounded-lg">
                <div className="p-2">
                    {uploading ? (
                        <div className="flex h-32 min-h-[202px] items-center justify-center overflow-hidden rounded-lg bg-[#E2E2E2]">
                            <PulseLoader color="#00b3c7" />
                        </div>
                    ) : (
                        <label
                            id={attachment.id}
                            className={`${
                                !url && "flex min-h-[202px] items-center justify-center bg-[#E2E2E2]"
                            }  w-full cursor-pointer overflow-auto rounded-lg`}>
                            {toUpper(getExtention(url)) === "PDF" ? (
                                <div className="max-h-[400px] overflow-auto">
                                    <AutoSizer disableHeight>
                                        {({ width }) => (
                                            <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
                                                {[...Array(numPages).keys()].map((i) => (
                                                    <Page key={i} pageNumber={i + 1} width={width - 15} />
                                                ))}
                                            </Document>
                                        )}
                                    </AutoSizer>
                                </div>
                            ) : !isEmpty(url) ? (
                                <DocumentFile url={url} />
                            ) : (
                                <ImageEmptyIcon width="116" height="112" />
                            )}
                            <input
                                type="file"
                                name="imageUrl"
                                className="hidden"
                                accept={documentAcceptance()}
                                onChange={(e) => handleLoadLocalFile(e, attachment)}
                                id={attachment.id}
                            />
                        </label>
                    )}
                </div>
            </div>
        </BubbleContainer>
    );
};

export default FileBubble;
