import { UPDATE_SANDBOX, SET_UNSAVED_CHANGES } from "../constants";

export function updateSandbox(sandbox) {
    return function (dispatch) {
        dispatch({
            type: UPDATE_SANDBOX,
            payload: sandbox,
        });

        dispatch({
            type: SET_UNSAVED_CHANGES,
        });
    };
}
