import React from "react";

const Checkbox = (props) => {
    return (
        <div className={props.className || "flex-1"}>
            <input
                className="border-1 mr-2 h-5 w-5 rounded border-gray-300 text-primary-200 checked:border-transparent checked:bg-primary-200 hover:checked:bg-primary-200 focus:ring-primary-200 focus:ring-opacity-25 focus:checked:bg-primary-200"
                type="checkbox"
                onChange={props.onChange}
                checked={props.checked}
                name={props.name}
                id={props.name}
            />
        </div>
    );
};

export default Checkbox;
