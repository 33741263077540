import { Styles } from "./table-container";

import { flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import DownIcon from "../icons/DownIcon";
import { useTranslation } from "react-i18next";
import TableSkeleton from "./TableSkeleton";

const TableRender = (props) => {
    const { loading, data, columns, pageLimit, setPageLimit, rows, setRows, maxPage, sorting, setSorting, handleSelected } = props;

    const { t } = useTranslation();

    const orderStyle = {
        fontSize: "0.813rem",
        color: "#00B3C7",
        textAlign: "left",
        margin: "0",
        paddingLeft: "1.5rem",
        paddingRight: "1rem",
        paddingBottom: "0.5rem",
        paddingTop: "0.75rem",
        lineHeight: "1rem",
        fontWeight: "bold",
        borderBottomWidth: "2px",
        borderColor: "#00B3C7",
        backgroundColor: "#fff",
        userSelect: "none",
    };

    const headerStyles = {
        fontSize: "0.813rem",
        color: "rgba(112, 124, 149, 0.7)",
        textAlign: "left",
        margin: "0",
        paddingLeft: "1.5rem",
        paddingRight: "1rem",
        paddingBottom: "0.5rem",
        paddingTop: "0.75rem",
        lineHeight: "1rem",
        fontWeight: 700,
        borderBottomWidth: "0.080rem",
        borderColor: "rgba(166, 180, 208, 0.25)",
        backgroundColor: "#fff",
        userSelect: "none",
    };

    const cellStyles = {
        fontSize: "0.875rem",
        whiteSpace: "nowrap",
        color: "#727C94",
        textAlign: "left",
        paddingLeft: "2rem",
        paddingRight: "1rem",
        paddingBottom: "0.5rem",
        paddingTop: "0.5rem",
        lineHeight: "1.25rem",
        fontWeight: 500,
        userSelect: "none",
        cursor: "pointer",
    };

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    });

    let loadingSkeleton = [];

    for (let i = 0; i < 10; i++) {
        loadingSkeleton.push(<TableSkeleton number={columns.length} key={i} />);
    }

    if (loading) {
        return (
            <div className="w-full bg-white py-5">
                <Styles>
                    <div className="p-2">
                        <table>
                            <thead>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => (
                                            <th
                                                className={header.column.getCanSort() ? "cursor-pointer" : ""}
                                                style={header.column.getIsSorted() ? orderStyle : headerStyles}
                                                key={header.id}
                                                onClick={() => {
                                                    header.column.toggleSorting();
                                                }}>
                                                <div className="flex">
                                                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                                    <div className="">
                                                        {header.column.getIsSorted() === "asc" ? (
                                                            <DownIcon className="ml-1 fill-current text-primary-200" width="0.938rem" />
                                                        ) : header.column.getIsSorted() === "desc" ? (
                                                            <DownIcon
                                                                className="ml-1 rotate-180 transform fill-current text-primary-200"
                                                                width="0.938rem"
                                                            />
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>{loadingSkeleton}</tbody>
                        </table>
                    </div>
                </Styles>
            </div>
        );
    }

    return (
        <div className="w-full bg-white py-5">
            <Styles>
                <div className="p-2">
                    <table>
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            className={header.column.getCanSort() ? "cursor-pointer" : ""}
                                            style={header.column.getIsSorted() ? orderStyle : headerStyles}
                                            key={header.id}
                                            onClick={() => {
                                                header.column.toggleSorting();
                                            }}>
                                            <div className="flex">
                                                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                                <div className="">
                                                    {header.column.getIsSorted() === "asc" ? (
                                                        <DownIcon className="ml-1 fill-current text-primary-200" width="0.938rem" />
                                                    ) : header.column.getIsSorted() === "desc" ? (
                                                        <DownIcon
                                                            className="ml-1 rotate-180 transform fill-current text-primary-200"
                                                            width="0.938rem"
                                                        />
                                                    ) : null}
                                                </div>
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map((row) => (
                                <tr
                                    className="hover:bg-primary-400 hover:bg-opacity-30 hover:text-primary-200"
                                    key={row.id}
                                    onClick={() => handleSelected(row.original)}>
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <td style={cellStyles} key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="flex w-full items-center justify-between rounded-b-xl border-t border-gray-400 border-opacity-25 bg-white px-4 py-3 sm:px-6">
                        <div className="flex items-center sm:w-1/3">
                            {pageLimit === 1 ? (
                                <button className="mr-2 cursor-not-allowed p-2">
                                    <div className="h-5 w-5 text-gray-400/75">{"<"}</div>
                                </button>
                            ) : (
                                <button
                                    className="mr-2 cursor-pointer p-2"
                                    onClick={() => {
                                        setPageLimit(pageLimit - 1);
                                    }}>
                                    <div className="h-4 w-4 text-gray-375">{"<"}</div>
                                </button>
                            )}
                            {/* <div className="relative text-xs text-gray-375">
                                {pageLimit} {t("common.de")} {maxPage}
                            </div> */}
                            {pageLimit === maxPage ? (
                                <button className="cursor-not-allowed p-2">
                                    <div className="h-4 w-4 text-gray-400/75">{">"}</div>
                                </button>
                            ) : (
                                <button className="p-2" onClick={() => setPageLimit(pageLimit + 1)}>
                                    <div className="h-4 w-4 text-gray-375"> {">"}</div>
                                </button>
                            )}
                        </div>
                        {/* <div className="flex justify-center space-x-1 text-13 text-gray-400 sm:w-1/3">
                            <span className="font-semibold text-primary-200">{totalResults}</span>
                            <span>{t("clients.results")}</span>
                        </div> */}
                        <div className="flex justify-end text-gray-400 sm:w-1/3">
                            {/* <div className="flex sm:w-1/5"> */}
                            <select
                                className="mr-5 flex cursor-pointer appearance-none justify-between border-transparent text-sm leading-5 text-gray-500"
                                value={rows}
                                onChange={(e) => {
                                    setRows(Number(e.target.value));
                                    setPageLimit(1);
                                }}>
                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize} {t("common.filas")}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </Styles>
        </div>
    );
};

export default TableRender;
