import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import first from "lodash/first";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";

import dayjs from "dayjs";

import SessionsActivityMetricsFilters from "./components/SessionsActivityMetricsFilters";
import DailyAvgSessions from "./components/DailyAvgSessions";
import TodaySessions from "./components/TodaySessions";
import Sessions from "./components/Sessions";

const SessionsActivityMetrics = (props) => {
    const { metrics, companies, getTodaySessions, getDailySessions, getSessions, getSessionsFile, formatBigNumber, bots } = props;

    const [loadingCards, setLoadingCards] = useState(true);
    const [isDownloadSessions, setIsDownloadSessions] = useState(false);
    const [filteredBots, setFilteredBots] = useState([]);
    const [botSelected, setBotSelected] = useState([]);

    const [companyId, setCompanyId] = useState(props.companyId);
    const [companySelected, setCompanySelected] = useState([]);

    const [initialDate, setInitialDate] = useState(dayjs().day(1).startOf("day").format());
    const [finalDate, setFinalDate] = useState(dayjs().endOf("day").format());

    const controller = new AbortController();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isEmpty(companies) && isNil(companyId) && !isEmpty(bots)) {
            setCompanySelected(first(orderBy(companies, ["name"], ["asc"])));
            setCompanyId(first(orderBy(companies, ["name"], ["asc"])).id);
            handleChange(first(orderBy(companies, ["name"], ["asc"])));
            setLoadingCards(false);
        }
    }, [companyId, companies, bots]);

    useEffect(() => {
        if (!isEmpty(companySelected) && !isEmpty(botSelected)) {
            execute();
            return () => controller.abort();
        }
    }, [botSelected, companySelected]);

    const handleChange = (company) => {
        setCompanySelected(company);
        setCompanyId(company.id);
        setFilteredBots(bots.filter((bot) => bot.companyId === company.id));
        setBotSelected(first(bots.filter((bot) => bot.companyId === company.id)));
    };

    const handleChangeBot = (bot) => {
        setBotSelected(bot);
        execute(bot.id);
    };

    const execute = async (botIdSent, initialDateSent, finalDateSent) => {
        const botId = !isEmpty(botIdSent) ? botIdSent : botSelected.id;

        const initDate = !isEmpty(initialDateSent) ? initialDateSent : initialDate;
        const finDate = !isEmpty(finalDateSent) ? finalDateSent : finalDate;

        setLoadingCards(true);
        dispatch(getTodaySessions([companyId], botId, controller)).then(() => {
            setLoadingCards(false);
        });
        dispatch(getDailySessions(companyId, botId, initDate, finDate, controller));
        dispatch(getSessions(companyId, botId, initDate, finDate, controller));
    };

    const downLoadSessionExcell = async (botIdSent) => {
        const botId = !isEmpty(botIdSent) ? botIdSent : botSelected.id;
        setIsDownloadSessions(true);

        await dispatch(getSessionsFile(companyId, botId, initialDate, finalDate, botSelected.name))
            .then(() => {
                setIsDownloadSessions(false);
            })
            .catch((error) => {
                console.log(error);
                setIsDownloadSessions(false);
            });
    };

    return (
        <div className="mb-6">
            <div className="mb-3 flex items-end justify-start">
                <SessionsActivityMetricsFilters
                    companies={companies}
                    companySelected={companySelected}
                    filteredBots={filteredBots}
                    botSelected={botSelected}
                    initialDate={initialDate}
                    setInitialDate={setInitialDate}
                    finalDate={finalDate}
                    setFinalDate={setFinalDate}
                    handleChange={handleChange}
                    handleChangeBot={handleChangeBot}
                    execute={execute}
                />
            </div>
            <div className="mb-16">
                <div className="flex w-full">
                    <TodaySessions metrics={metrics} loadingCards={loadingCards} formatBigNumber={formatBigNumber} />
                    <DailyAvgSessions metrics={metrics} loadingCards={loadingCards} formatBigNumber={formatBigNumber} />
                    <Sessions
                        metrics={metrics}
                        loadingCards={loadingCards}
                        formatBigNumber={formatBigNumber}
                        downLoadSessionExcell={downLoadSessionExcell}
                        isDownloadSessions={isDownloadSessions}
                    />
                </div>
            </div>
        </div>
    );
};

export default SessionsActivityMetrics;
