import get from "lodash/get";

/* Components */
import CardTitle from "../../CardTitle";
import { BeatLoader } from "react-spinners";
import { useTranslation } from "react-i18next";

import DownloadIcon from "../../../../icons/DownloadIcon";

const TodaySessions = (props) => {
    const { loadingCards, metrics, formatBigNumber, downLoadSessionExcell, isDownloadSessions } = props;
    const { t } = useTranslation();
    const sessions = get(metrics, "sessions.amount", 0);

    return (
        <div className="mx-1 h-38 w-1/3 rounded-lg bg-white p-4 shadow-input">
            <CardTitle>
                <div className="flex w-full items-center justify-between">
                    <span className="block w-1/2 text-base">{t("metrics.Sesiones")}</span>
                    <button
                        onClick={downLoadSessionExcell}
                        className="color-gradient flex h-8 w-8 items-center justify-center rounded-full bg-primary-600 font-semibold text-white">
                        {isDownloadSessions ? (
                            <BeatLoader color={"#fff"} size={"0.25rem"} />
                        ) : (
                            <DownloadIcon className="fill-current text-white" width="0.8125rem" height="0.9375rem" />
                        )}
                    </button>
                </div>
            </CardTitle>
            <div className="m-auto mt-4 flex h-full items-center justify-center">
                {loadingCards ? (
                    <div className="pb-6 pt-2 text-center">
                        <BeatLoader color="#00B3C7" size={10} />
                    </div>
                ) : (
                    <p className="pb-6 text-4xl text-gray-400">{formatBigNumber(sessions)}</p>
                )}
            </div>
        </div>
    );
};

export default TodaySessions;
