import Icon from "./Icon";

const ImageIcon = (props) => (
    <Icon viewBox="0 0 21 18" className={props.className} width={22} height={19}>
        <path
            d="M17.95 16.094H2.808c-.325 0-.65-.07-.905-.325-.232-.255-.255-.556-.255-.88V3.291c0-.255 0-.51.116-.765.348-.65 1.113-.533 1.74-.533H17.95c.58.023.974.44.997 1.02.07 1.971 0 3.966 0 5.96 0 2.041.046 4.059 0 6.1 0 .556-.418.997-.997 1.02-.928.023-.951 1.484 0 1.461 1.183-.046 2.226-.858 2.412-2.04.046-.28.046-.534.046-.813V3.037c-.023-.927-.51-1.786-1.345-2.203-.696-.348-1.484-.278-2.25-.278H8.976c-2.064 0-4.128-.023-6.216 0-1.438 0-2.55 1.09-2.55 2.55v10.646c0 .788-.094 1.693.254 2.435.418.858 1.276 1.368 2.227 1.391h15.26c.95-.023.95-1.484 0-1.484Z"
            fill={props.fill}
        />
        <path
            d="M2.018 15.398c-.023.21-.023.232 0 .14-.023.092-.093.185-.023.046.046-.093.093-.186.162-.301.163-.302.348-.603.534-.905.533-.904 1.09-1.809 1.646-2.713.603-.998 1.23-1.972 1.832-2.969.302-.487.951-1.948 1.578-1.09.51.696.904 1.484 1.345 2.203.88 1.415 1.739 2.853 2.62 4.268.557.904 1.113 1.809 1.67 2.736.487.789 1.763.07 1.252-.742-1.414-2.32-2.852-4.638-4.267-6.98-.348-.557-.696-1.114-1.02-1.694-.186-.324-.371-.672-.65-.95a1.981 1.981 0 0 0-1.832-.534c-.672.14-1.09.58-1.438 1.136-.626.998-1.252 2.018-1.878 3.039-.673 1.09-1.368 2.203-2.018 3.316-.232.371-.44.765-.65 1.136-.138.256-.277.51-.324.812-.046.394.371.719.72.719.44.07.718-.278.741-.673Z"
            fill={props.fill}
        />
        <path
            d="M12.686 13.357c.418-.58.835-1.182 1.253-1.762.208-.302.394-.603.626-.881.209-.255.464-.325.719-.186.116.046.208.232.301.371.162.279.348.557.51.835.51.812 1.044 1.647 1.554 2.458.348.534.673 1.09 1.02 1.624.51.788 1.763.07 1.253-.742-.858-1.369-1.74-2.76-2.598-4.128-.37-.603-.742-1.392-1.414-1.717-.765-.37-1.74-.232-2.342.395-.232.232-.395.533-.58.788-.534.742-1.044 1.484-1.577 2.25-.232.324-.047.811.255.997.394.162.788.023 1.02-.302Z"
            fill={props.fill}
        />
    </Icon>
);

export default ImageIcon;
