import uniqid from "uniqid";
import get from "lodash/get";
import { List } from "immutable";
import { useState } from "react";
import toLower from "lodash/toLower";
import { PulseLoader } from "react-spinners";
import { useTranslation } from "react-i18next";

import Http from "../../../../services/Http";
import { UPDATE, NEW } from "../../../../constants";

/* Components */
import Buttons from "./components/Buttons";
import AddButton from "./components/AddButton";
import VideoIcon from "../../../icons/VideoIcon";
import BubbleContainer from "../BubbleContainer";

function getAttachment(attachments, bubbleId) {
    return List(attachments).find((a) => a.bubbleId === bubbleId);
}

const VideoBuble = (props) => {
    const { attachments, bubble, flow, bot, updateAttachment } = props;
    const { t } = useTranslation();
    const [uploading, setUploading] = useState(false);

    let attachment = getAttachment(attachments, bubble.id);
    const url = attachment && get(attachment, "url");
    const options = attachment && get(props.bubble, "text");

    const handleLoadLocalFile = (event, attachment) => {
        event.preventDefault();
        const { files } = event.target;
        const image = files[0];

        setUploading(true);

        const formData = new FormData();
        formData.append("image", image);
        let intent = flow.intent;
        const botId = bot.id;
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        let fileName = image.name.replace(/ /g, "_");
        fileName = uniqid() + `-${fileName}`;
        const path = toLower(`${botId}/flows/${intent}/images/${fileName}`);
        formData.append("path", path);
        Http.post(`/images/upload`, formData, config)
            .then(({ data }) => {
                updateAttachment({
                    ...attachment,
                    url: data,
                    action: attachment.action === NEW ? NEW : UPDATE,
                });
                setUploading(false);
            })
            .catch((error) => {
                console.error(error);
                setUploading(false);
            });
    };

    const videoAcceptance = () => {
        return "video/*";
    };

    return (
        <BubbleContainer
            {...props}
            bubbleStyles={props.bubbleStyles}
            icon={<VideoIcon className="mx-2 fill-current text-gray-400" width="23" />}
            title={t("bubbles.Video")}>
            <div className="relative my-2 flex-1 rounded-lg">
                <div className="p-2">
                    {uploading ? (
                        <div className="flex h-32 min-h-[202px] items-center justify-center overflow-hidden rounded-lg bg-[#E2E2E2]">
                            <PulseLoader color="#00b3c7" />
                        </div>
                    ) : (
                        <label
                            id={attachment.id}
                            className={`${
                                !url && "min-h-[202px] bg-[#E2E2E2]"
                            } flex w-full cursor-pointer items-center justify-center overflow-hidden rounded-lg text-gray-450 hover:text-blue`}>
                            {url ? (
                                <video src={url} alt={url} autoPlay loop className="image-bubble h-full object-cover" />
                            ) : (
                                <div className="flex h-32 w-full flex-col items-center justify-center">
                                    <VideoIcon className="mb-2 fill-current" width="18px" height="18px" />
                                    <span className="font-sans text-sm font-extrabold">{t("bubbles.Subir video")}</span>
                                </div>
                            )}
                            <input
                                type="file"
                                name="videoUrl"
                                className="hidden"
                                accept={videoAcceptance()}
                                onChange={(e) => handleLoadLocalFile(e, attachment)}
                                id={attachment.id}
                            />
                        </label>
                    )}
                </div>
                <Buttons
                    bot={props.bot}
                    intents={props.intents}
                    setFlow={props.setFlow}
                    buttons={options}
                    flows={props.flows}
                    bubble={props.bubble}
                />
            </div>
            <AddButton
                attachment={attachment}
                flows={props.flows}
                bubble={props.bubble}
                botType={props.bot.type}
                buttonCounter={props.buttonCounter}
                TotalButtons={props.buttons}
            />
        </BubbleContainer>
    );
};

export default VideoBuble;
