import { UPDATE_ATTACHMENT, SET_UNSAVED_CHANGES } from "../constants";

export function updateAttachment(attachment) {
    return function (dispatch) {
        dispatch({
            type: UPDATE_ATTACHMENT,
            payload: attachment,
        });

        dispatch({
            type: SET_UNSAVED_CHANGES,
        });
    };
}
