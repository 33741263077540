import { Navigate, useLocation, useParams } from "react-router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import * as Sentry from "@sentry/react";
// import { useTranslation } from "react-i18next";

import { authUser } from "../../actions/user.js";
import { setImpersonate } from "../../actions/impersonate.js";
// import Astronauts from "../icons/Astronauts";
// import Figures from "../icons/Figures";
// import { APPS_URL } from "../../constants";

const Impersonate = (props) => {
    const dispatch = useDispatch();
    const impersonate = useSelector((state) => state.impersonate);
    const [redirect, setRedirect] = useState(false);
    // const { t } = useTranslation();

    const location = useLocation().search;
    let { jwt: jwtAsUrlPath, botId: botIdAsUrlPath } = useParams();
    const urlParams = new URLSearchParams(location);

    const jwt = jwtAsUrlPath || urlParams.get("jwt");
    const botId = botIdAsUrlPath || urlParams.get("botId");
    // const referrer = botIdAsUrlPath || urlParams.get("referrer");

    useEffect(() => {
        if (jwt) {
            localStorage.setItem("jwt", jwt);
            localStorage.setItem("botId", botId);
            dispatch(setImpersonate(true));
        }
    }, [jwt]);

    useEffect(() => {
        if (impersonate === true && localStorage.getItem("jwt")) {
            setRedirect(true);
            dispatch(authUser());
        }

        // if (referrer !== process.env.REACT_APP_URL_ACCESS) {
        //     Sentry.withScope((scope) => {
        //         scope.setExtra("botId", JSON.stringify(botId));
        //         scope.setExtra("jwt", JSON.stringify(jwt));
        //         Sentry.captureException(new Error("Impersonate: This access is not allowed right now from unknown domain"));
        //     });
        // }
    }, [impersonate]);

    // if (referrer !== process.env.REACT_APP_URL_ACCESS) {
    //     return (
    //         <div className="fixed z-120 h-screen w-screen bg-white sm:block lg:flex">
    //             <div className=" lg:rounded-default lg:flex lg:w-2/4 lg:flex-col  lg:items-end lg:justify-center xl:items-center">
    //                 <div className=" lg:w-78 xl:w-112 mt-16 grid place-items-center lg:mt-0 lg:place-items-start">
    //                     <p className="flex flex-col items-center justify-center text-center text-3xl font-bold text-primary-200 lg:items-start lg:text-left lg:leading-[2rem] base:text-[3rem]  base:leading-[3rem]">
    //                         {t("pageNotFound.errorMessage")}
    //                     </p>
    //                     <p className="flex flex-col items-center justify-center text-center text-lg font-semibold text-gray-400 sm:py-2 lg:items-start lg:text-left">{` ${t(
    //                         "pageNotFound.page"
    //                     )} builder`}</p>

    //                     <button
    //                         className="mt-6 rounded-[3rem] bg-primary-200 px-6 py-3 font-semibold text-white"
    //                         onClick={() => {
    //                             window.location.href = APPS_URL;
    //                         }}>
    //                         {t("pageNotFound.backToHome")}
    //                     </button>
    //                 </div>
    //             </div>
    //             <div className="lg:relative lg:flex  lg:w-2/3 lg:items-end lg:justify-center">
    //                 <div className="animate-fadeOut opacity-0">
    //                     <Astronauts
    //                         className="absolute left-[-60%] top-[10%] w-[210%] scale-[0.5] sm:left-[-35%] lg:left-[50%] lg:top-[50%] lg:-translate-x-2/4 lg:-translate-y-1/2 xl:w-[110%] xl:scale-[0.8]  2xl:w-full 2xl:scale-[1]"
    //                         width="100%"
    //                     />
    //                 </div>
    //                 <Figures className="opacity-0 sm:opacity-100" width="100%" />
    //             </div>
    //         </div>
    //     );
    // }

    return (
        <div className="flex h-screen flex-col justify-center">
            {redirect && localStorage.getItem("jwt") && <Navigate push to={`/flows/${botId}`} />}
        </div>
    );
};

export default Impersonate;
