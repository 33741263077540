import { useTranslation } from "react-i18next";
import CloseIcon from "../../icons/CloseIcon";
import LoadingSpinner from "../../icons/LoadingSpinner";

import { Modal } from "../../common/GenericModal";

const DeleteIntent = ({ openModal, intent, deleteIntent, subtitle, closeModal, loadingExistingFlow, loading }) => {
    const { t } = useTranslation();
    return (
        <Modal
            closeModal={() => null}
            openModal={openModal}
            className="h-min w-auto rounded-20 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)]"
            classNameActivate="">
            <div className="h-full flex-row">
                <header className="relative flex items-center justify-between bg-[#FEEEEE] px-6 py-5">
                    <div className="flex items-center gap-x-2">
                        <svg width="28" height="28" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M25.333 9.33333L24.1766 25.5233C24.0769 26.9188 22.9157 28 21.5167 28H10.4827C9.08362 28 7.92245 26.9188 7.82277 25.5233L6.66634 9.33333M13.333 14.6667V22.6667M18.6663 14.6667V22.6667M19.9997 9.33333V5.33333C19.9997 4.59695 19.4027 4 18.6663 4H13.333C12.5966 4 11.9997 4.59695 11.9997 5.33333V9.33333M5.33301 9.33333H26.6663"
                                stroke="#F95A59"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>

                        <div className="text-base font-semibold text-red-500">{"Eliminar flujo"}</div>
                    </div>
                    <button disabled={loading} className="absolute inset-y-0 right-0 mr-3" onClick={() => closeModal()}>
                        <CloseIcon width="32px" height="32px" className="stroke-current text-red-500" />
                    </button>
                </header>
                <div className="px-6 py-6">
                    <div className="space-y-2">
                        <span className="text-sm text-gray-400">
                            ¿{t("Estás seguro que deseas eliminar el flujo")} <span className="font-bold">{`${intent?.name}`}</span>?
                        </span>
                        {loadingExistingFlow ? (
                            <span className="flex items-center justify-center space-x-1">
                                <span className="text-sm text-gray-400">Calculando flujos utilizados </span>
                                <LoadingSpinner color={"#F95A59"} />
                            </span>
                        ) : (
                            <p className="text-sm text-gray-400">{subtitle}</p>
                        )}
                    </div>
                    <footer className="mb-0 mt-6 flex items-center justify-end gap-x-3">
                        <button
                            disabled={loading}
                            onClick={closeModal}
                            id="cancelDeleteIntentButton"
                            className="min-w-[4rem] rounded-2xl bg-gray-5 px-3 py-2 text-sm font-semibold text-gray-400 disabled:cursor-not-allowed">
                            {t("common.Cancelar")}
                        </button>
                        <button
                            disabled={loading}
                            onClick={deleteIntent}
                            className="flex min-w-[4rem] justify-center rounded-2xl bg-red-500 px-3  py-2 text-sm font-semibold text-white 
                                    disabled:cursor-not-allowed disabled:bg-gray-300">
                            {loading && <LoadingSpinner color={"#F95A59"} />}
                            <span>{t("common.Eliminar flujo")}</span>
                        </button>
                    </footer>
                </div>
            </div>
        </Modal>
    );
};

export default DeleteIntent;
