import React from "react";

import Icon from "./Icon";

const QuestionIcon = (props) => (
    <Icon viewBox="0 0 21 21" className={props.className} width={props.width} height={props.height}>
        <path
            d="M18.9045 10.2904C18.8806 13.8683 16.6117 17.1839 13.2203 18.3765C9.78118 19.593 5.93604 18.5196 3.6194 15.7288C1.32664 12.9619 1.06392 8.90691 2.92679 5.85374C4.76577 2.80057 8.44374 1.15472 11.9306 1.84645C15.943 2.6336 18.8806 6.21152 18.9045 10.2904C18.9045 11.2445 20.4091 11.2683 20.4091 10.2904C20.3852 6.04455 17.7103 2.25195 13.7219 0.796919C9.78119 -0.658108 5.17178 0.606093 2.52078 3.85009C-0.177991 7.11793 -0.583998 11.8408 1.61323 15.5142C3.81046 19.1637 8.15714 21.048 12.3128 20.2132C16.349 19.4022 19.6209 16.0151 20.2658 11.9601C20.3613 11.4115 20.4091 10.8628 20.4091 10.3142C20.4091 9.33626 18.9283 9.33625 18.9045 10.2904Z"
            fill={props.fill}
        />
        <path
            d="M8.58703 8.0243C8.63479 8.35824 8.58703 8.04816 8.58703 7.90504C8.58703 7.80963 8.58703 7.69036 8.58703 7.59495C8.58703 7.54725 8.58703 7.49954 8.58703 7.42798C8.61091 7.2133 8.56314 7.54725 8.6109 7.33258C8.63479 7.18946 8.68255 7.04634 8.73032 6.92707C8.73032 6.90322 8.75421 6.87937 8.75421 6.85552C8.77809 6.76011 8.70644 6.97478 8.75421 6.87937C8.80198 6.80781 8.82586 6.73625 8.87363 6.64084C8.96916 6.47387 8.84973 6.64083 8.92138 6.54542C8.96915 6.47386 9.0408 6.42617 9.08856 6.37846C9.18409 6.28305 9.11245 6.35461 9.08856 6.37846C9.11245 6.35461 9.16021 6.33074 9.20798 6.30689C9.27963 6.25918 9.42293 6.18763 9.42293 6.18763C10.1155 5.90139 11.1903 5.9968 11.6202 6.66468C12.1456 7.47568 12.0978 8.66834 11.3574 9.43163C10.8559 9.95639 10.2588 10.3857 9.82894 10.9821C9.4707 11.483 9.56622 12.1031 9.56622 12.6756C9.56622 13.6297 11.0708 13.6536 11.0708 12.6756C11.0708 12.4371 11.0708 12.1986 11.0708 11.96C11.0708 11.9123 11.1186 11.5068 11.047 11.8169C10.9992 12.0793 11.0231 11.8646 11.0947 11.8169C11.0947 11.8169 11.2858 11.6022 11.3574 11.5307C11.9067 10.9582 12.5516 10.505 12.9576 9.83713C13.698 8.62063 13.6741 6.97478 12.8382 5.80599C12.0023 4.66105 10.5215 4.32711 9.1841 4.70876C8.22878 4.99499 7.53617 5.71057 7.24958 6.66468C7.0824 7.23715 7.05852 7.85733 7.15405 8.45365C7.2257 8.85915 7.72723 9.07383 8.08548 8.97842C8.49149 8.8353 8.65868 8.4298 8.58703 8.0243Z"
            fill={props.fill}
        />
        <path d="M10.3305 16.3013C11.6202 16.3013 11.6202 14.2976 10.3305 14.2976C9.0408 14.2976 9.0408 16.3013 10.3305 16.3013Z" fill={props.fill} />
    </Icon>
);

export default QuestionIcon;
