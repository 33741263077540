import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import toUpper from "lodash/toUpper";
import trim from "lodash/trim";

import Fuse from "fuse.js";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";

import { notifyError } from "../../common/Toasts";
import PlusIcon from "../../icons/PlusIcon";
import Expression from "./Expression";

import { addExpression, deleteExpression } from "../../../actions/expressions";
import { DELETING_EXPRESSION, LOADING_EXPRESSIONS, MAX_VALUE_CARACTER_INTENTS, SAVING_EXPRESSION } from "../../../constants";

const Expressions = (props) => {
    const bot = useSelector((state) => state.bot);
    const flow = useSelector((state) => state.flow);
    const intents = useSelector((state) => state.intents);
    const expressions = useSelector((state) => state.expressions);
    const expressionState = useSelector((state) => state.expressionState);
    const permissions = useSelector((state) => state.permissions);

    const inputRef = useRef(null);

    const [query, setQuery] = useState("");
    const hasCreatePermission = permissions.find((permission) => permission === "create expressions");

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const expressionMessage = hasCreatePermission ? t("flows.Agregar vocablo") : t("flows.Ver vocablo");
    const showHideClassName = "w-full flex shadow absolute bg-white rounded-lg mt-1 h-[18.75rem]";

    const fuseOptions = {
        threshold: 0.1,
        keys: ["source"],
    };

    const getIntentName = (expression) => {
        return intents.find((intent) => intent.id === expression.intentId).name;
    };

    const handleChange = (event) => {
        const target = event.target;

        if (target.value.match(/[A-Z]/g)) target.value = target.value.toLowerCase();

        setQuery(target.value);
    };

    const handleEnter = async (event) => {
        //source, Language
        if (event.key === "Enter") {
            if (!hasCreatePermission) return;
            //won't do anywhing if has nothing or whitespace.
            if (!query || !/\S/.test(query)) return;

            // validate that value not is grate than 280 characters
            if (query.length > MAX_VALUE_CARACTER_INTENTS) {
                notifyError(`El vocablo no puede tener más de ${MAX_VALUE_CARACTER_INTENTS} caracteres`);
                return;
            }

            const { language } = bot;
            const languageIsocode = isEmpty(language) ? { isocode: "es" } : { isocode: language };
            const payload = { source: query, language: languageIsocode };
            const exist = expressions.find((x) => toUpper(trim(x.source)) === toUpper(trim(query)));

            if (!exist) {
                await dispatch(addExpression(payload)).then(() => {
                    inputRef.current.value = "";
                    handleChange({ target: { value: "" } });
                });
            } else {
                const intentName = getIntentName(exist);
                notifyError("Esta palabra ya está siendo usada en el flujo '" + intentName + "'");
            }
        }
    };

    const addExpressionF = async () => {
        if (!hasCreatePermission) return;

        //won't do anywhing if has nothing or whitespace.
        if (!query || !/\S/.test(query)) return;

        // validate that value not is grate than 280 characters
        if (query.length > MAX_VALUE_CARACTER_INTENTS) {
            notifyError(`El vocablo no puede tener más de ${MAX_VALUE_CARACTER_INTENTS} caracteres`);
            return;
        }

        const { language } = bot;
        const languageIsocode = isEmpty(language) ? { isocode: "es" } : { isocode: language };
        const payload = { source: query, language: languageIsocode };
        const exist = expressions.find((x) => toUpper(trim(x.source)) === toUpper(trim(query)));

        if (!exist) {
            await dispatch(addExpression(payload)).then(() => {
                inputRef.current.value = "";
                handleChange({ target: { value: "" } });
            });
        } else {
            const intentName = getIntentName(exist);
            notifyError("Esta palabra ya está siendo usada en el flujo '" + intentName + "'");
        }
    };

    const filterExpressions = () => {
        if (isEmpty(query)) {
            return orderBy(
                expressions.filter((expression) => expression.intentId === flow.intentId),
                ["source"],
                ["asc"]
            );
        }
        const fuse = new Fuse(expressions, fuseOptions);
        const result = fuse.search(query);

        let expressionSearch = [];
        result.map((expression) => {
            return expressionSearch.push(expression.item);
        });

        return orderBy(
            expressionSearch.filter((expression) => expression.intentId === flow.intentId),
            ["source"],
            ["asc"]
        );
    };

    const filteredExpressions = filterExpressions();

    const renderLoading = () => {
        if (expressionState === LOADING_EXPRESSIONS || expressionState === SAVING_EXPRESSION || expressionState === DELETING_EXPRESSION) {
            return <ClipLoader size={20} color={"#fff"} />;
        }
        return <PlusIcon className="fill-current" width="1.5rem" height="1.5rem" />;
    };

    return (
        <div className={showHideClassName} id="modalVocablo">
            <ul className="w-full justify-around overflow-hidden rounded-lg p-2 text-grey-100 shadow-modal">
                <li className="mb-1 flex w-full items-center space-x-2 rounded rounded-b-none">
                    <input
                        type="search"
                        className="input w-full"
                        placeholder={expressionMessage}
                        onChange={handleChange}
                        onKeyDown={handleEnter}
                        ref={inputRef}
                        autoFocus
                    />
                    <button
                        className="flex h-7 w-7 items-center justify-center rounded-20 bg-primary-200 px-1 font-sans font-bold text-white"
                        disabled={
                            expressionState === LOADING_EXPRESSIONS ||
                            expressionState === SAVING_EXPRESSION ||
                            expressionState === DELETING_EXPRESSION
                        }
                        onClick={() => addExpressionF()}>
                        {renderLoading()}
                    </button>
                </li>
                <div className="scroll overflow-y-auto" style={{ height: "calc(100% - 52px)" }}>
                    {filteredExpressions.map((exp, index) => (
                        <Expression expression={exp} key={index} deleteExpression={deleteExpression} flow={flow} />
                    ))}
                </div>
            </ul>
        </div>
    );
};

export default Expressions;
