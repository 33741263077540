import { deleteById, mergeById, updateById } from "../helpers";
import initialState from "./initialState";
import { ADD_FLOWS, ADD_TO_FLOWS, DELETE_FROM_FLOWS, UPDATE_FROM_FLOWS, SET_DEFAULT_FLOW, DELETE_FLOWS } from "../constants";

export const flows = function flows(state = initialState.flows, action) {
    switch (action.type) {
        case ADD_FLOWS:
            return [...action.payload];
        case ADD_TO_FLOWS:
            return mergeById(state, action.payload);
        case DELETE_FROM_FLOWS:
            return deleteById(state, action.payload.id);
        case UPDATE_FROM_FLOWS:
            return updateById(state, action.payload);
        case SET_DEFAULT_FLOW:
            return updateById(state, action.payload);
        case DELETE_FLOWS:
            return [];
        default:
            return state;
    }
};

export default flows;
