import get from "lodash/get";
import trim from "lodash/trim";
import isEmpty from "lodash/isEmpty";
import styles from "../common/Bubbles/bubbles.module.scss";

import { v4 as uuid } from "uuid";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-autosize-textarea";

import CloseIcon from "../icons/CloseIcon";
import Bubble from "../common/Bubbles/Bubble";
import { Modal } from "../common/GenericModal";
import { BarLoader } from "react-spinners";
import { HttpV2 } from "../../services/Http";
import PlayIcon from "../icons/PlayIcon";

const Tester = (props) => {
    const { isOpenBotTester, onClose, setFlow } = props;
    const { t } = useTranslation();

    const inputRef = useRef(null);

    const [messages, setMessages] = useState([]);
    const [generatingResponse, setGeneratingResponse] = useState(false);
    const bot = useSelector((state) => state.bot);

    const enterFunc = (e) => {
        if (isEmpty(trim(inputRef.current.value))) return;
        if (e.key === "Enter") {
            e.preventDefault();
            testBot(e.target.value);
        }
    };

    const testBot = async (expression) => {
        if (isEmpty(trim(inputRef.current.value))) return;
        setGeneratingResponse(true);
        if (!expression) {
            expression = {
                id: uuid(),
                message: `Vocablo: ${inputRef.current.value}`,
                type: "user",
            };
        }

        const { data: resp } = await HttpV2.get(`/nlp/${bot.Company.id}/analyze`, {
            params: {
                botId: bot.id,
                text: inputRef.current.value,
            },
        });

        const data = get(resp, "data", {});

        const botResponse = {
            ...data,
            type: "bot",
        };

        setMessages([botResponse]);
        setGeneratingResponse(false);
    };

    return (
        <Modal openModal={isOpenBotTester} closeModal={() => null} title={t("common.Probar vocablos")} className="w-[30rem] rounded-20">
            <div className="h-full flex-row">
                <header className="relative flex items-center justify-between bg-primary-350 p-5">
                    <div className="flex items-center gap-x-2">
                        <PlayIcon width="20" height="20" className="text-primary-200" />
                        <div className="text-base font-semibold text-primary-200">{t("common.Probar vocablos")}</div>
                    </div>
                    <button
                        className="absolute inset-y-0 right-0 mr-3"
                        onClick={() => {
                            setMessages([]);
                            onClose(false);
                        }}>
                        <CloseIcon width="28" className="stroke-current text-primary-200" />
                    </button>
                </header>
                <div className="flex h-full items-center gap-x-3 px-4 py-6">
                    <div className="w-full rounded-xl border border-gray-100/50">
                        <div className="relative my-auto flex w-full flex-row items-center pr-2">
                            <TextareaAutosize
                                ref={inputRef}
                                spellCheck={true}
                                autoFocus
                                name="message"
                                className="h-11 w-full resize-none overflow-y-auto border-transparent bg-transparent align-middle text-base font-medium leading-normal text-gray-400 outline-none placeholder:text-gray-400 placeholder:text-opacity-65 focus:border-transparent focus:ring-transparent md:text-15"
                                maxRows={5}
                                onKeyDown={(e) => {
                                    enterFunc(e);
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex items-center rounded-full border-transparent focus:outline-none">
                        <button
                            className="flex h-8 items-center justify-center border-transparent text-primary-200 focus:outline-none disabled:text-gray-300"
                            disabled={generatingResponse}
                            onClick={() => testBot()}>
                            <div className="hover:font-medium hover:underline">{t("Probar")}</div>
                        </button>
                    </div>
                </div>
                {generatingResponse ? (
                    <div className="flex w-full justify-center px-2 pb-6">
                        <BarLoader color="#00b3c7" height={5} width={300} />
                    </div>
                ) : (
                    !isEmpty(messages) && (
                        <div className="max-h-40 overflow-y-auto px-4 pb-6">
                            {messages.map((message) => (
                                <Bubble
                                    key={message.id}
                                    message={message}
                                    styles={styles}
                                    type={get(message, "type", "user")}
                                    setFlow={setFlow}
                                    setMessages={setMessages}
                                />
                            ))}
                        </div>
                    )
                )}
            </div>
        </Modal>
    );
};

export default Tester;
