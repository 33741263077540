import { ADD_CURRENT_INTENT, DELETE_INTENT } from "../constants";

export const addCurrentIntent = (intent) => ({
    type: ADD_CURRENT_INTENT,
    payload: intent,
});

export const deleteIntent = (intent) => ({
    type: DELETE_INTENT,
    payload: intent,
});
