import initialState from "./initialState";
import { mergeById } from "../helpers";
import { ADD_PMA_BUBBLE, ADD_PMA_BUBBLES, DELETE_ALL_PMA_BUBBLES, UPDATE_PMA_BUBBLES } from "../constants";

function pmaBubbles(state = initialState.pmaBubbles, action) {
    switch (action.type) {
        case ADD_PMA_BUBBLES:
            return [...action.payload];
        case ADD_PMA_BUBBLE:
            return [...state, action.payload];
        case UPDATE_PMA_BUBBLES:
            return mergeById(state, action.payload);
        case DELETE_ALL_PMA_BUBBLES:
            return [];
        default:
            return state;
    }
}

export default pmaBubbles;
