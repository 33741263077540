import { SET_IMPERSONATE, SET_RETURNING_TO_APPS } from "../constants";

export const setImpersonate = (impersonate) => ({
    type: SET_IMPERSONATE,
    payload: impersonate,
});

export const setReturningToApps = (bool) => ({
    type: SET_RETURNING_TO_APPS,
    payload: bool,
});
