import Header from "./Header";

const Layout = ({ children, showSidebar = true, showHeader = true, botId, screen = true, unSavedChanges }) => {
    return (
        <div>
            {showHeader && unSavedChanges && <Header unSavedChanges={unSavedChanges} />}
            {showHeader && <Header />}
            <div className={`flex ${screen ? "h-screen overflow-hidden" : null} ${showHeader ? "pt-[3.25rem]" : null}`}>
                <main className="flex flex-1 bg-gray-13">{children}</main>
            </div>
        </div>
    );
};

export default Layout;
