import { useState } from "react";
import isEmpty from "lodash/isEmpty";
import Tippy from "@tippyjs/react";
import { BeatLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { NEW, UPDATE } from "../../../../constants";
import { updateBubbleResource } from "../../../../actions/bubbleResources";

/* Components */
import JSONPretty from "../JSONPretty";
import Modal from "../../../common/Modal";
import EyesIcon from "../../../icons/EyesIcon";
import CopyIcon from "../../../icons/CopyIcon";
import SandboxIcon from "../../../icons/SandboxIcon";
import BubbleContainer from "../BubbleContainer";
import FormCombobox from "../../../common/FormCombobox";
import { getResourceResponseById } from "../../../../actions/resources";

import get from "lodash/get";
import orderBy from "lodash/orderBy";

const ResourceBubble = (props) => {
    const { bubbleResource } = props;
    const { t } = useTranslation();
    const [showResponse, setShowResponse] = useState(false);
    const [visible, setVisible] = useState(false);
    const [response, setResponse] = useState({});
    const [loadingResourceResponse, setLoadingResourceResponse] = useState(false);
    const resources = useSelector((state) => state.resourcesLight);

    const dispatch = useDispatch();

    const resource = resources.find((resource) => resource.id === get(bubbleResource, "resourceId"));
    const name = get(resource, "name", "Unknown");

    const handleChange = async (resource) => {
        const data = await dispatch(getResourceResponseById(resource?.id));
        setResponse(get(data, "response", {}));
        dispatch(
            updateBubbleResource({
                ...props.bubbleResource,
                resourceId: resource.id,
                action: get(props, "bubbleResource.action") === NEW ? NEW : UPDATE,
            })
        );
    };

    const toggleResponse = async () => {
        setShowResponse(!showResponse);
        if (isEmpty(response)) {
            getResourceResponse(resource);
        }
    };

    const getResourceResponse = async (resource) => {
        setLoadingResourceResponse(true);
        const data = await dispatch(getResourceResponseById(resource?.id));
        setResponse(get(data, "response", {}));
        setLoadingResourceResponse(false);
    };

    const copyToClipboardResourceName = (resourceName) => {
        setVisible(true);
        setTimeout(() => {
            setVisible(false);
        }, 1500);
        const input = document.createElement("input");
        input.setAttribute("value", resourceName);
        input.classList.add("input-hidden");
        document.body.appendChild(input);
        input.select();
        document.execCommand("copy");
        document.body.removeChild(input);
    };

    return (
        <>
            <BubbleContainer
                {...props}
                bubbleStyles={props.bubbleStyles}
                icon={<SandboxIcon className="mx-2 fill-current text-gray-400" width="18" height="18" />}
                title={t("bubbles.Recurso")}>
                <div className="relative flex-1 rounded-lg">
                    <div className="relative my-1 flex w-full flex-col p-2">
                        <div className="grid gap-4 text-sm font-semibold text-gray-400 text-opacity-75">
                            <FormCombobox
                                handleChange={handleChange}
                                options={orderBy(resources, ["name"], ["asc"])}
                                placeholder={t("filters.Seleccione recurso")}
                                value={resource}
                                className="h-34 w-full flex-1 rounded-10 border-transparent bg-white px-2 text-15 font-normal text-gray-400 outline-none ring-transparent focus:border-transparent focus:ring-transparent"
                                hasCleanFilter={false}
                            />
                        </div>
                        <div className="flex justify-end pb-2 pt-4">
                            <Tippy
                                content={t("common.¡Copiado al portapapeles!")}
                                theme="jelou"
                                arrow={false}
                                placement={"bottom"}
                                visible={visible}
                                touch={false}>
                                <button
                                    className="mr-1 flex cursor-pointer items-center justify-center rounded-lg border border-input bg-white px-2 py-1 text-primary-200"
                                    onClick={() => copyToClipboardResourceName(resource.name)}
                                    disabled={!resource}>
                                    <CopyIcon className="stroke-current" width="1.125rem" height="1.125rem" />
                                </button>
                            </Tippy>
                            <Tippy content={t("common.Ver recurso")} theme="jelou" arrow={false} placement={"bottom"} touch={false}>
                                <button
                                    className="flex cursor-pointer items-center justify-center rounded-lg border border-input bg-white px-2 py-1 text-primary-200"
                                    onClick={toggleResponse}>
                                    <EyesIcon className="fill-current" width="1.125rem" height="1.125rem" />
                                </button>
                            </Tippy>
                        </div>
                    </div>
                </div>
            </BubbleContainer>

            <Modal
                loading={loadingResourceResponse}
                className="max-h-[90vh] max-w-[40rem]"
                isShowModal={showResponse}
                titleModal={`${t("bubbles.Recurso")} ${name}`}
                closeModal={toggleResponse}
                minHeight="max-h-[90vh] overflow-auto">
                {loadingResourceResponse ? (
                    <div className="flex h-32 items-center justify-center">
                        <BeatLoader color="#00B3C7" size={10} />
                    </div>
                ) : (
                    <JSONPretty json={!isEmpty(response) ? JSON.parse(JSON.stringify(response)) : {}} />
                )}
            </Modal>
        </>
    );
};

export default ResourceBubble;
