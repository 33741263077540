import Icon from "./Icon";

const ContainerRigthIcon = (props) => (
    <Icon viewBox="0 0 39 32" className={props.className} width={props.width} height={props.height}>
        <g filter="url(#filter0_d_8_1205)">
            <path d="M4 24L23 24C29.6274 24 35 18.6274 35 12V12C35 5.37258 29.6274 3.33071e-07 23 -2.46316e-07L4 -1.90735e-06L4 24Z" fill="#F2FBFC" />
        </g>
        <defs>
            <filter id="filter0_d_8_1205" x="0" y="0" width="39" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.8625 0 0 0 0 0.8625 0 0 0 0 0.8625 0 0 0 0.5 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8_1205" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8_1205" result="shape" />
            </filter>
        </defs>
    </Icon>
);

export default ContainerRigthIcon;
