import shortid from "shortid";
import { ADD_ERRORS } from "../constants";

function errors(state = [], action) {
    switch (action.type) {
        case ADD_ERRORS:
            return action.payload.map((err) => {
                console.log(err);
                return { ...err, id: shortid.generate() };
            });
        default:
            return state;
    }
}

export default errors;
