import isEmpty from "lodash/isEmpty";

const RADIO_BTNS_NAMES = {
    TEXT: "text",
    FLOW: "flow",
};

export const ONE_TIMES_KEYS = {
    TYPE: "type",
    TEXT: "text",
    FLOW_ID: "flowId",
    [RADIO_BTNS_NAMES.TEXT]: "text",
    [RADIO_BTNS_NAMES.FLOW]: "flowId",
    ONE_TIME_USE_BUTTONS_INPUT: "oneTimeUseButtonsInput",
    ONE_TIME_USE_BUTTONS: "oneTimeUseButtons",
    REDIRECT_PAYLOAD: "redirectPayload",
};

export const TYPE_OPTIONS = [
    { value: RADIO_BTNS_NAMES.FLOW, label: "Flujo" },
    { value: RADIO_BTNS_NAMES.TEXT, label: "Texto" },
];

export const INPUTS_TEXT_PLACEHOLDERS = {
    [RADIO_BTNS_NAMES.FLOW]: "Ingrese un flow ID ej: 123",
    [RADIO_BTNS_NAMES.TEXT]: "Ingrese el texto que será enviado al usuario",
};

export const deleteKeys = (obj) => {
    const { [ONE_TIMES_KEYS.ONE_TIME_USE_BUTTONS]: _, [ONE_TIMES_KEYS.REDIRECT_PAYLOAD]: __, ...rest } = obj;
    return rest;
};

export const validateInputForm = ({ key, value }) => {
    let newErrors = {};

    if (key === ONE_TIMES_KEYS.FLOW_ID && isNaN(value)) {
        newErrors = { ...newErrors, [ONE_TIMES_KEYS.ONE_TIME_USE_BUTTONS_INPUT]: "El el flowId debe ser un número" };
    }

    if (key === ONE_TIMES_KEYS.TEXT && !value) {
        newErrors = { ...newErrors, [ONE_TIMES_KEYS.ONE_TIME_USE_BUTTONS_INPUT]: "El texto no puede ser vacío" };
    }

    return newErrors;
};

export const validateSelectForm = ({ type }) => {
    let newErrors = {};

    if (!type || isEmpty(type)) {
        newErrors = { ...newErrors, [ONE_TIMES_KEYS.TYPE]: "Debe seleccionar un tipo" };
    }

    return newErrors;
};
