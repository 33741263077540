import { useTranslation } from "react-i18next";
// import { useDispatch } from "react-redux";
import TextareaAutosize from "react-autosize-textarea";

import { UPDATE, NEW } from "../../../../constants";

import BubbleContainer from "../BubbleContainer";
import ContactCardIcon from "../../../icons/ContactCardIcon";

const inputStyle = "form-input";

const ContactCardBubble = (props) => {
    const { bubble, getContactCard, updateBubble } = props;
    const { t } = useTranslation();
    const contact = getContactCard(bubble.id);
    const { name, phone, address, email } = contact;

    // const dispatch = useDispatch();

    return (
        <BubbleContainer
            {...props}
            bubbleStyles={props.bubbleStyles}
            icon={<ContactCardIcon className="mx-2 fill-current text-gray-400" width="18" height="18" />}
            title={t("bubbles.Contacto")}>
            <div className="relative flex-1 rounded-lg">
                <div className="my-2 w-full px-2">
                    <div className="grid gap-4 rounded-lg bg-white px-3 py-2 text-sm font-semibold text-gray-400 text-opacity-75">
                        <label htmlFor="title" className="flex flex-col">
                            {t("common.Nombre")}
                            <input
                                type="text"
                                name="name"
                                className={inputStyle}
                                placeholder={t("common.Nombre")}
                                onChange={({ target }) => {
                                    updateBubble({
                                        ...props.bubble,
                                        name: target.value,
                                        action: props.bubble.action === NEW ? NEW : UPDATE,
                                    });
                                }}
                                defaultValue={name}
                            />
                        </label>

                        <label htmlFor="title">
                            {t("common.Teléfono")}
                            <input
                                type="text"
                                name="phone"
                                className={inputStyle}
                                placeholder={t("common.Teléfono")}
                                onChange={({ target }) => {
                                    updateBubble({
                                        ...props.bubble,
                                        phone: target.value,
                                        action: props.bubble.action === NEW ? NEW : UPDATE,
                                    });
                                }}
                                defaultValue={phone}
                            />
                        </label>

                        <label htmlFor="title">
                            {t("login.email")}
                            <input
                                type="text"
                                name="email"
                                className={inputStyle}
                                placeholder={t("login.email")}
                                onChange={({ target }) => {
                                    updateBubble({
                                        ...props.bubble,
                                        email: target.value,
                                        action: props.bubble.action === NEW ? NEW : UPDATE,
                                    });
                                }}
                                defaultValue={email}
                            />
                        </label>

                        <label htmlFor="title">
                            {t("common.Dirección")}
                            <TextareaAutosize
                                className={inputStyle + " p-2"}
                                style={{ minHeight: 60 }}
                                placeholder={t("common.Ingresa la dirección del contacto")}
                                onChange={({ target }) => {
                                    updateBubble({
                                        ...props.bubble,
                                        address: target.value,
                                        action: props.bubble.action === NEW ? NEW : UPDATE,
                                    });
                                }}
                                defaultValue={address}
                            />
                        </label>
                    </div>
                </div>
            </div>
        </BubbleContainer>
    );
};

export default ContactCardBubble;
