import React from "react";

const Input = (props) => {
    const inputStyle = `${props.className ? props.className : "input w-full"}`;

    return (
        <div className={"flex-1"}>
            <input
                id={props.id}
                autoFocus={props.autoFocus || false}
                className={inputStyle}
                type={props.type}
                defaultValue={props.defaultValue}
                value={props.value}
                disabled={props.disabled || false}
                name={props.name}
                onChange={props.onChange}
                placeholder={props.placeholder}
                ref={props.ref}
                onKeyPress={props.onKeyPress || null}
                min={props.min}
                max={props.max}
                required={props.required}
            />
            {props.error ? <small className="float-right mt-2 text-red-light">{props.errorText}</small> : null}
        </div>
    );
};

export default Input;
