import { useState } from "react";

import Select from "react-select";
import { withTranslation } from "react-i18next";
import orderBy from "lodash/orderBy";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const customStyles = {
    control: (base) => ({
        ...base,
        border: "1px solid #bacad6",
        background: "white",
        minHeight: "32px",
        height: "32px",
        borderRadius: "0.8125rem",
        fontFamily: "Nunito",
    }),
    placeholder: (base) => ({
        ...base,
        fontWeight: 400,
        fontSize: "14px",
    }),
    dropdownIndicator: (base) => ({
        ...base,
        padding: "6px",
    }),
    multiValue: (base) => ({
        ...base,
        borderRadius: ".5rem",
    }),
};

const AddResource = (props) => {
    const { toggleAddingResource, createResource, handleResourceName, companies } = props;
    const [companySelected, setCompanySelected] = useState(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleChange = (company) => {
        setCompanySelected(company);
    };

    return (
        <div>
            <div className="flex w-full cursor-pointer items-center px-4" onClick={() => dispatch(toggleAddingResource())}>
                <div className="color-gradient flex h-9 w-full items-center justify-center rounded-8 text-center text-2xl text-white shadow-element">
                    <div className="flex-1 select-none">
                        <h5 className="text-sm font-medium text-white">{`+ ${t("resources.Agregar recurso")}`}</h5>
                    </div>
                </div>
            </div>
            {props.addingResource && (
                <div className="mt-4 border-b border-t border-gray-400 border-opacity-20 p-4">
                    <p className="mb-2 font-sans text-xs font-medium text-gray-400">
                        {t("Ingresa el nombre con el que quieres identificar tu recurso")}
                    </p>
                    <input
                        type="text"
                        onChange={handleResourceName}
                        name="name"
                        className="input mb-2 w-full text-gray-400"
                        placeholder={t("Nombre del recurso")}
                    />
                    <Select
                        className="resource-select relative mb-2 inline-block w-full flex-1"
                        styles={customStyles}
                        getOptionValue={(o) => o.id}
                        getOptionLabel={(o) => o.name}
                        onChange={handleChange}
                        options={orderBy(companies, ["name"], ["asc"])}
                        placeholder={t("Escoja una empresa")}
                        value={companySelected}
                        name="companyId"
                    />
                    <button onClick={() => createResource(companySelected.id)} className="button button-primary button--small w-full">
                        {t("common.Guardar")}
                    </button>
                </div>
            )}
        </div>
    );
};

export default withTranslation()(AddResource);
