import reduxThunk from "redux-thunk";
import { applyMiddleware, createStore, compose } from "redux";
import rootReducer from "../reducers/index";
import initialState from "../reducers/initialState";
const middlewares = [];
middlewares.push(reduxThunk);

const createStoreWithMiddleware = compose(applyMiddleware(...middlewares))(createStore);

const store = createStoreWithMiddleware(rootReducer, initialState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;
