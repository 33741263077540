export const DoubleArrowIcon = ({ width = 16, height = 12, color = "currentColor" } = {}) => {
    return (
        <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 12">
            <path
                d="m9.64 11.423 5.797-4.41c.39-.303.425-1.075.063-1.411L10.114.697C9.729.333 9.16.242 8.74.637c-.353.33-.463 1.046-.063 1.41 1.8 1.64 3.586 3.265 5.385 4.905l.063-1.41c-1.937 1.474-3.86 2.949-5.797 4.41-.417.328-.397 1.05-.062 1.41.384.431.957.39 1.375.061Z"
                fill={color}
            />
            <path
                d="m2.504 11.423 5.797-4.41c.39-.303.424-1.075.062-1.411-1.8-1.64-3.586-3.265-5.385-4.905C2.59.333 2.023.242 1.603.637c-.353.33-.462 1.046-.062 1.41 1.799 1.64 3.586 3.265 5.385 4.905l.062-1.41c-1.937 1.474-3.86 2.949-5.796 4.41-.418.328-.398 1.05-.063 1.41.384.431.958.39 1.375.061Z"
                fill={color}
            />
        </svg>
    );
};
