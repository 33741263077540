import isEmpty from "lodash/isEmpty";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import FormComboboxSelect from "../../../common/FormCombobox";
import SlideOver from "../../../common/SlideOver";
import { useFlowsIntents } from "./toolkit.service";

const OUTPUTS_TYPES = {
    SUCCESS: "SUCCESS",
    FAILED: "FAILED",
};

const DEFAULT_FLOW_KEY = "defaultFlowId";

const initialStateSelections = (outputBubble, flowOptions, defaultFlowIdBubble) => () => {
    const state = {};

    if (defaultFlowIdBubble) {
        state[DEFAULT_FLOW_KEY] = flowOptions.find((option) => option.id === defaultFlowIdBubble);
    }

    Object.entries(outputBubble).forEach(([key, value]) => {
        state[key] = flowOptions.find((option) => option.id === value);
    });

    return state;
};

export const Output = ({ isOpenOutput, closeModal, outputTool, outputBubble, update, defaultFlowIdBubble }) => {
    const { t } = useTranslation();
    const { flowOptions } = useFlowsIntents();

    const [selectionOptionts, setSelectionOptionts] = useState(initialStateSelections(outputBubble, flowOptions, defaultFlowIdBubble));

    const outputError = outputTool.filter((output) => output.type === OUTPUTS_TYPES.FAILED);
    const hasOutput = !isEmpty(outputError);

    const onChange = (nameOptionSelected) => (optionSelected) => {
        const newOutput = {
            ...outputBubble,
            [nameOptionSelected]: optionSelected.id,
        };

        setSelectionOptionts({ ...selectionOptionts, [nameOptionSelected]: optionSelected });

        update({ output: newOutput });
    };

    const defaultFlow = (optionSelected) => {
        setSelectionOptionts({ ...selectionOptionts, defaultFlowId: optionSelected });
        update({ defaultFlowId: optionSelected.id });
    };

    return (
        <SlideOver isOpen={isOpenOutput} closeModal={closeModal} title="Casos de error">
            <div className="p-3 space-y-4 text-gray-400">
                {
                    <>
                        {hasOutput &&
                            outputError.map((output) => {
                                const { id, name, displayName, description } = output;

                                return (
                                    <div key={id} className="block space-y-2">
                                        <p className="text-[#952F23]">{displayName}</p>
                                        <p className="text-xs text-gray-400">{description}</p>
                                        <FormComboboxSelect
                                            handleChange={onChange(name)}
                                            options={flowOptions}
                                            name={name}
                                            placeholder={t("toolkit.selecciona_opcion")}
                                            value={selectionOptionts[name] ?? ""}
                                            hasCleanFilter={false}
                                            className="bubble-form-input !bg-gray-13"
                                        />
                                        <span className="block border-b border-gray-200" />
                                    </div>
                                );
                            })}

                        <div className="block space-y-2">
                            <p className="text-[#952F23]">Defatul flow</p>
                            <p className="text-xs text-gray-400">Selecciona un flow por defecto en caso de que pase cualquier error inesperado</p>
                            <FormComboboxSelect
                                handleChange={defaultFlow}
                                options={flowOptions}
                                name={DEFAULT_FLOW_KEY}
                                placeholder={t("toolkit.selecciona_opcion")}
                                value={selectionOptionts[DEFAULT_FLOW_KEY] ?? ""}
                                hasCleanFilter={false}
                                className="bubble-form-input !bg-gray-13"
                            />
                            <span className="block border-b border-gray-200" />
                        </div>
                    </>
                }

                <footer className="flex justify-end w-full py-4">
                    <button onClick={closeModal} className="button-gradient-xl disabled:cursor-not-allowed disabled:bg-opacity-60">
                        Guardar
                    </button>
                </footer>
            </div>
        </SlideOver>
    );
};
