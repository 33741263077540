import React from "react";
import ReactJson from "react-json-view";

const Pretty = (props) => (
    <div className="pb-4">
        <ReactJson src={props.json} enableClipboard={false} sortKeys={true} />
    </div>
);

export default Pretty;
