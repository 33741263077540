import shortid from "shortid";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { NEW } from "../../../../../constants";
import PlusIcon from "../../../../icons/PlusIcon";
import { addQuickReply } from "../../../../../actions/quickReplies";

const itemStyle = "px-4 text-center py-2 flex items-center justify-center bg-white rounded-10 border-2 border-transparent";

const AddButton = (props) => {
    const { canAdd, QuickReplyModel } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    return (
        <div className="relative">
            <div className="relative my-2 flex flex-col justify-center space-y-3 px-3">
                <button
                    className={`${itemStyle} disabled:cursor-not-allowed disabled:opacity-60`}
                    disabled={!canAdd}
                    onClick={() => {
                        props.buttonCounter(1);
                        dispatch(
                            addQuickReply({
                                id: shortid.generate(),
                                ...QuickReplyModel,
                                repliableId: props.bubble.id,
                                action: NEW,
                            })
                        );
                    }}>
                    {props.botType !== "Twitter" && (
                        <Fragment>
                            <PlusIcon className="mr-1 fill-current text-gray-400" width="18px" height="18px" />
                            <span className={`font-sans text-sm font-bold text-gray-400`}>{t("bubbles.Respuesta rápida")}</span>
                        </Fragment>
                    )}
                </button>
            </div>
        </div>
    );
};

export default AddButton;
