import initialState from "./initialState";
import { ADD_METRIC_REPORT, FILTER_METRIC, UPDATE_BY_INTENT } from "../constants";
import compact from "lodash/compact";

export const reports = function reports(state = initialState.reports, action) {
    switch (action.type) {
        case ADD_METRIC_REPORT:
            return [...state, ...action.payload];
        case FILTER_METRIC:
            return [...action.payload];
        case UPDATE_BY_INTENT:
            const upd = compact(
                state.map((st) => {
                    console.log(st);
                    if (st.message === action.payload.messages) {
                        return null;
                    }
                    return st;
                })
            );
            return [...upd];
        default:
            return state;
    }
};

export default reports;
