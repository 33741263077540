import initialState from "./initialState";
import { updateById, mergeById, deleteByIds } from "../helpers";
import {
    ADD_QUICK_REPLY,
    ADD_QUICK_REPLIES,
    UPDATE_QUICK_REPLY,
    UPDATE_QUICK_REPLIES,
    DELETE_QUICK_REPLIES,
    DELETE_ALL_QUICK_REPLIES,
} from "../constants";

function quickReplies(state = initialState.quickReplies, action) {
    switch (action.type) {
        case ADD_QUICK_REPLIES:
            return [...action.payload];
        case ADD_QUICK_REPLY:
            return [...state, action.payload];
        case UPDATE_QUICK_REPLY:
            return updateById(state, action.payload);
        case UPDATE_QUICK_REPLIES:
            return [...action.payload];
        case DELETE_QUICK_REPLIES:
            return deleteByIds(state, action.payload);
        case DELETE_ALL_QUICK_REPLIES:
            return [];
        default:
            return state;
    }
}

export default quickReplies;
