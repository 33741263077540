import get from "lodash/get";
import trim from "lodash/trim";
import toUpper from "lodash/toUpper";
import isEmpty from "lodash/isEmpty";
import { useRef, useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import PinIcon from "../../icons/PinIcon";
import SaveIcon from "../../icons/SaveIcon";
import IntentActions from "./IntentActions";
import { notify, notifyError } from "../../common/Toasts";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

import { setFlowDefault } from "../../../actions/flows";
import { updateIntent } from "../../../actions/intents";

import Tippy from "@tippyjs/react";
import { ADD_TO_FLOWS } from "../../../constants";

const IntentItem = (props) => {
    const { intent, defaultFlow, handleGetFlow, handleDelete } = props;
    const dispatch = useDispatch();
    const intents = useSelector((state) => state.intents);
    const bot = useSelector((state) => state.bot);
    const flows = useSelector((state) => state.flows);
    const currIntent = useSelector((state) => state.intent);

    const botId = get(bot, "id", "");

    const [isEditing, setIsEditing] = useState(false);
    const [intentToEdit, setIntentToEdit] = useState("");
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [currentValue, setCurrentValue] = useState(get(currIntent, "name", ""));
    const [error, setError] = useState(false);
    const [isCurrent, setIsCurrent] = useState(false);

    const inputRef = useRef(null);
    const ref = useRef(null);
    const currentValueRef = useRef(currentValue);

    const editButtonStyles = `cursor-pointer h-full items-center justify-center ${!isEditing && "group-hover:flex"} ${isCurrent ? "flex" : "hidden"}`;

    const isDefaultIntent = get(defaultFlow, "intent", "") === intent.slug;

    const nlpDriver = get(intent, "nlpDriver", "");
    const canEditIntent = toUpper(nlpDriver) === "DIALOGFLOW";

    const { t } = useTranslation();

    useOnClickOutside(ref, () => setOpen(false));

    useEffect(() => {
        if (!isEmpty(currIntent)) {
            setIsCurrent(intent.id === currIntent.id);
        } else {
            setIsCurrent(false);
        }
    }, [currIntent]);

    const handleChange = (event) => {
        const target = event.target;
        setCurrentValue(target.value);
        currentValueRef.current = target.value;
    };

    // const resetsIntent = () => {
    //     setError(false);
    //     setIsEditing(false);
    //     setCurrentValue(get(currIntent, "name", ""));
    //     currentValueRef.current = get(currIntent, "name", "");
    // };

    useOnClickOutside(inputRef, () => {
        setIsEditing(false);
        // console.log(isEditingRef.current);
        // if (isEditingRef.current) {
        //     updatingIntent();
        //     return;
        // }
        // resetsIntent();
    });

    const alreadyExist = (currentValue, currentIntent) => {
        return !!intents.find((intent) => {
            if (currentIntent !== currentValue) {
                return toUpper(trim(intent.name)) === toUpper(trim(currentValue));
            }
            return false;
        });
    };

    const handleEnter = () => {
        const currentIntentName = currIntent.name;
        if (currentIntentName === currentValue) {
            setIsEditing(false);
            return;
        } else {
            updatingIntent();
        }
        setOpen(false);
    };

    const updatingIntent = async () => {
        setLoading(true);
        const botSlug = get(bot, "slug", "");
        const intent = {
            slug: get(intentToEdit, "slug", ""),
            name: currentValueRef.current,
            description: get(intentToEdit, "description", ""),
        };
        if (currentValueRef.current === intentToEdit.name) {
            setIsEditing(false);
            setError(false);
        } else if (!alreadyExist(currentValueRef.current, intentToEdit.name)) {
            await dispatch(updateIntent(botSlug, intent))
                .then(() => {
                    notify(t("flows.Flujo actualizado exitosamente"));
                })
                .catch(() => {
                    notifyError(t("flows.Error al actualizar el flujo"));
                });
            setIsEditing(false);
            setError(false);
        } else {
            setError(true);
        }
        setLoading(false);
    };

    const editMode = (intentToEdit) => {
        setIntentToEdit(intentToEdit);
        setIsEditing(!isEditing);
        setOpen(false);

        if (!isEditing) {
            setCurrentValue(intentToEdit.name);
            currentValueRef.current = intentToEdit.name;
            setError(false);
        }
    };

    const handleEsc = (event) => {
        if (event.keyCode === 13) {
            handleEnter();
        }
        if (event.keyCode === 27) {
            setIsEditing(false);
        }
    };

    const setToDefault = async (intent) => {
        const flow = flows.find((flow) => {
            return flow.intent === intent.slug;
        });

        await dispatch(setFlowDefault(flow?.id, botId, intent)).then((data) => {
            if (get(data, "response.status", 0) === 500) {
                notifyError(t("flows.Error al actualizar el flujo por defecto"));
            } else {
                dispatch({
                    type: ADD_TO_FLOWS,
                    payload: [
                        { ...flow, isDefault: 1 },
                        { ...defaultFlow, isDefault: 0 },
                    ],
                });
                notify(t("flows.Flujo por defecto actualizado exitosamente"));
            }
        });
    };

    return (
        <Tippy content={intent.name ?? intent.slug} theme="jelou" placement={"right"}>
            <li
                className={`group relative flex h-[40px] items-center ${
                    isDefaultIntent ? "" : "justify-between"
                } bg-menu-hover border border-transparent ${isCurrent ? "bg-menu text-primary-200" : "font-base text-[#959DAF]"}`}>
                <div
                    className="flex-1 cursor-pointer select-none flex-wrap truncate py-2 pl-4 pr-2 text-sm font-semibold leading-normal"
                    onClick={() => {
                        handleGetFlow(intent);
                    }}>
                    {!isEditing ? (
                        intent.name ?? intent.slug
                    ) : (
                        <div>
                            <input
                                value={currentValue}
                                onChange={handleChange}
                                onKeyDown={handleEsc}
                                autoFocus
                                className="input h-full w-full flex-1"
                            />
                            {error && (
                                <div className="mr-1 mt-2 h-2 text-right font-sans text-xs font-bold text-red">
                                    {t("flows.Nombre del flujo ya existe")}
                                </div>
                            )}
                        </div>
                    )}
                    {isDefaultIntent && !isEditing && (
                        <div className="absolute -right-1 bottom-0 top-0 flex place-items-center">
                            <PinIcon className="mr-7 fill-current text-primary-200" width="0.875rem" />
                        </div>
                    )}
                </div>

                <div className="w-4" ref={ref}>
                    {!isEditing && (
                        <div className={editButtonStyles}>
                            <IntentActions
                                intent={intent}
                                flows={flows}
                                editMode={editMode}
                                isDefault={isDefaultIntent}
                                handleDelete={handleDelete}
                                setToDefault={setToDefault}
                                openMenu={open}
                                setOpen={setOpen}
                                canEditIntent={canEditIntent}
                            />
                        </div>
                    )}
                </div>

                {isEditing && (
                    <>
                        {loading ? (
                            <ClipLoader size={13} color={"#00b3c7"} />
                        ) : (
                            <button disabled={loading} onClick={updatingIntent} ref={inputRef}>
                                <SaveIcon
                                    className="mr-2 fill-current text-gray-400 hover:text-gray-500 group-hover:font-medium group-hover:text-gray-500"
                                    width="1rem"
                                    height="1rem"
                                />
                            </button>
                        )}
                    </>
                )}
            </li>
        </Tippy>
    );
};

export default IntentItem;
