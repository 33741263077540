import { ADD_PMA_BUBBLE, UPDATE_PMA_BUBBLES, SET_UNSAVED_CHANGES } from "../constants";

export const addPMABubble = (bubble) => ({ type: ADD_PMA_BUBBLE, payload: bubble });

export function updatePMABubble(bubble) {
    return function(dispatch) {
        dispatch({ type: SET_UNSAVED_CHANGES });
        dispatch({ type: UPDATE_PMA_BUBBLES, payload: bubble });
    };
}
