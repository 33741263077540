import toUpper from "lodash/toUpper";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-autosize-textarea";

/* Components */
import AddButton from "./components/AddButton";
import QuickReply from "./components/QuickReply";
import ForwardIcon from "../../../icons/ForwardIcon";
import BubbleContainer from "../BubbleContainer";

import useValidateObject from "../../../../hooks/useValidateObject";
import { UPDATE, NEW, MAX_QR_ELEMENTS } from "../../../../constants";

const QuickReplyModel = {
    title: "",
    contentType: "text",
    payload: {},
    imageUrl: "",
    repliable: "Bubble",
};

const QuickReplyBubble = (props) => {
    const { bubble, quickReplies, bot } = props;

    const [isValid] = useValidateObject(bubble, { text: "required|max:2000" });

    const bubbleStyle = `relative px-2 pt-3 pb-1 w-full min-h-20`;

    const textAreaError = "border-2 border-error focus:ring-transparent focus:border-error";
    const textArea = `rounded-10 shadow-textArea w-full px-3 py-2 text-gray-400 text-15 focus:outline-none border-transparent focus:ring-primary-200 focus:border-primary-200 placeholder:text-gray-400 placeholder:text-opacity-70 ${
        !isValid && textAreaError
    }`;

    const type = toUpper(props.bot.type);
    const canAdd = quickReplies.toJS().length < MAX_QR_ELEMENTS[type];
    const { t } = useTranslation();

    return (
        <>
            <BubbleContainer
                {...props}
                bubbleStyles={props.bubbleStyles}
                icon={<ForwardIcon className="mx-2 fill-current text-gray-400" width="19" height="20" />}
                className="relative mb-4 flex items-start"
                title={t("bubbles.Respuestas rápidas")}>
                <div className="relative flex-1 rounded-lg">
                    <div className={bubbleStyle}>
                        <TextareaAutosize
                            className={textArea}
                            style={{ minHeight: 80 }}
                            placeholder={t("bubbles.Ingresa un texto para la respuesta rápida")}
                            onChange={({ target }) => {
                                props.updateBubble({
                                    ...props.bubble,
                                    text: target.value,
                                    action: props.bubble.action === NEW ? NEW : UPDATE,
                                });
                            }}
                            defaultValue={props.bubble.text}
                        />
                    </div>
                    <AddButton bubble={props.bubble} canAdd={canAdd} QuickReplyModel={QuickReplyModel} buttonCounter={props.buttonCounter} />
                </div>
            </BubbleContainer>
            <div className="mb-4 ml-12 flex w-full max-w-[47rem] flex-wrap items-end space-x-3 space-y-3">
                {quickReplies.map((quickReply) => (
                    <QuickReply
                        flows={props.flows}
                        intents={props.intents}
                        setFlow={props.setFlow}
                        type={bot.type}
                        updateQuickReply={props.updateQuickReply}
                        deleteQuickReply={props.deleteQuickReply}
                        key={quickReply.id}
                        quickReply={quickReply}
                        canAdd={canAdd}
                        buttonCounter={props.buttonCounter}
                    />
                ))}
            </div>
        </>
    );
};

export default QuickReplyBubble;
