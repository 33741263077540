import React from "react";

const CardList = (props) => (
    <div className="flex h-9 items-center">
        <div className="w-32 flex-1 truncate pr-2 font-sans text-gray-400">{props.label}</div>
        <div className="flex text-right font-sans text-gray-400">{props.value}</div>
    </div>
);

export default CardList;
