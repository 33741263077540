import { Fragment, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Popover, Transition } from "@headlessui/react";
import PlusIcon from "../../icons/PlusIcon";

import Expressions from "./Expressions";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import CanRenderOption from "../../common/CanRenderOption";
import Tippy from "@tippyjs/react";

const TrainingWord = (props) => {
    const { t } = useTranslation();
    const popoverRef = useRef(null);
    const { showTraining, setShowTraining, canCreateExpression } = props;
    const [showTippyDisabled, setShowTippyDisabled] = useState(false);

    useOnClickOutside(popoverRef, () => setShowTraining(false));

    const showTippy = () => {
        if (!canCreateExpression) {
            setShowTippyDisabled(true);
        }
    };

    const hideTippy = () => {
        if (!canCreateExpression) {
            setShowTippyDisabled(false);
        }
    };

    const showTrainingAble = () => {
        if (canCreateExpression) {
            setShowTraining(!showTraining);
        }
    };

    return (
        <Popover className="relative" ref={popoverRef}>
            <div>
                <Tippy content={t("flows.Este bot fue creado en Brain, no se pueden agregar vocablos")} visible={showTippyDisabled}>
                    <Popover.Button
                        onMouseEnter={() => showTippy()}
                        onMouseLeave={() => hideTippy()}
                        className={`flex items-center rounded-8 px-4 py-2 font-sans text-sm font-medium ${
                            !canCreateExpression
                                ? "cursor-not-allowed select-none bg-[#E5E5E5] text-[#BDBDBD] opacity-50"
                                : "bg-[#BFECF1] text-[#009EAF]"
                        }`}
                        onClick={() => showTrainingAble()}>
                        <PlusIcon className="fill-current" width="20px" />
                        {t("flows.Agregar vocablo")}
                    </Popover.Button>
                </Tippy>
            </div>
            <Transition
                as={Fragment}
                show={showTraining}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Popover.Panel className="absolute z-100 w-[21.875rem]">
                    <CanRenderOption option="update flows">
                        <Expressions />
                    </CanRenderOption>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
};

export default TrainingWord;
