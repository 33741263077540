import React from "react";
import last from "lodash/last";
import toUpper from "lodash/toUpper";
import { FileIcon } from "react-file-icon";

const DocumentFile = (props) => {
    const { url } = props;

    const getExtention = (mediaName) => {
        try {
            return mediaName.match(/\.[0-9a-z]+$/i)[0].replace(".", "");
        } catch (error) {
            return "Unknown";
        }
    };

    const getName = (mediaUrl) => {
        let media = mediaUrl.split("/");
        let file = last(media);

        const name = getExtention(file);

        return file.replace(`.${name}`, "");
    };

    const setType = (type) => {
        switch (toUpper(type)) {
            case "PDF":
                return "acrobat";
            case "DOC":
            case "DOCX":
                return "document";
            case "PPT":
            case "PPTX":
                return "presentation";
            case "XLS":
            case "XLSX":
                return "spreadsheet";
            default:
                return "acrobat";
        }
    };

    const setLabelColor = (type) => {
        switch (toUpper(type)) {
            case "PDF":
                return "#F15642";
            case "DOC":
            case "DOCX":
                return "#2A8BF2";
            case "PPT":
            case "PPTX":
                return "#D14423";
            case "XLS":
            case "XLSX":
            case "CSV":
                return "#1A754C";
            case "JS":
                return "#ffc700";
            default:
                return "#F15642";
        }
    };

    return (
        <div className="flex cursor-pointer items-center p-5">
            <div className="h-auto w-10">
                <FileIcon
                    color={"#E2E5E7"}
                    labelTextColor={"white"}
                    size={30}
                    labelColor={setLabelColor(toUpper(getExtention(url)))}
                    foldColor="#B0B7BD"
                    type={setType(toUpper(getExtention(url)))}
                    labelUppercase
                    extension={getExtention(url)}
                />
            </div>
            <div className="flex max-w-4xl truncate break-words">
                <span className="max-w-full whitespace-pre-wrap break-words px-5 text-13 sm:text-15">{getName(url)}</span>
            </div>
        </div>
    );
};

export default DocumentFile;
