import React from "react";

import Icon from "./Icon";

const ForwardIcon = (props) => (
    <Icon viewBox="0 0 19 20" className={props.className} width={props.width} height={props.height}>
        <path
            d="M16.5506 4.48838C14.3986 4.48838 12.2466 4.48838 10.069 4.48838C7.40463 4.48838 4.74027 4.48838 2.10152 4.48838C1.89657 4.48838 1.71725 4.53961 1.66601 4.30904C1.58916 4.02723 1.66601 3.64295 1.66601 3.36114C1.66601 3.13057 1.61477 2.79753 1.66601 2.56696C1.71725 2.33639 1.94783 2.38762 2.15278 2.38762C2.56268 2.38762 2.99818 2.38762 3.40808 2.38762C6.48235 2.38762 9.58226 2.38762 12.6565 2.38762C13.8094 2.38762 14.9366 2.38762 16.0894 2.38762C16.2432 2.38762 16.4737 2.33639 16.6275 2.38762C16.8324 2.46448 16.7556 2.72067 16.7556 2.9C16.7556 3.28429 16.7556 3.66856 16.7556 4.07847C16.7043 4.23218 16.7555 4.48838 16.5506 4.48838C15.5258 4.53962 15.5258 6.1536 16.5506 6.10237C17.3192 6.05113 18.0109 5.61561 18.2414 4.82142C18.3439 4.46276 18.3183 4.05285 18.3183 3.69418C18.3183 2.72066 18.4464 1.61906 17.4473 1.02983C16.7043 0.594304 15.5002 0.773636 14.6804 0.773636C11.3243 0.773636 7.96826 0.773636 4.6378 0.773636C3.71552 0.773636 2.79323 0.748017 1.87094 0.773636C0.82057 0.799255 0.0776277 1.59344 0.0520088 2.64381C0.0263899 3.38676 -0.101686 4.3859 0.23136 5.07761C0.794976 6.2817 2.15276 6.10237 3.25437 6.10237C4.79151 6.10237 6.35425 6.10237 7.89139 6.10237C10.7095 6.10237 13.5532 6.1536 16.3969 6.10237C16.4481 6.10237 16.4994 6.10237 16.5506 6.10237C17.5753 6.10237 17.5753 4.48838 16.5506 4.48838Z"
            fill={props.fill}
        />
        <path
            d="M16.5506 11.2262C14.3986 11.2262 12.2466 11.2262 10.069 11.2262C7.40466 11.2262 4.7403 11.2262 2.10155 11.2262C1.8966 11.2262 1.71728 11.2774 1.66604 11.0468C1.58919 10.765 1.66604 10.3807 1.66604 10.0989C1.66604 9.86836 1.61481 9.53532 1.66604 9.30475C1.71728 9.07418 1.94786 9.12542 2.15281 9.12542C2.56271 9.12542 2.99821 9.12542 3.40811 9.12542C6.48238 9.12542 9.58229 9.12542 12.6566 9.12542C13.8094 9.12542 14.9366 9.12542 16.0895 9.12542C16.2432 9.12542 16.4738 9.07418 16.6275 9.12542C16.8324 9.20227 16.7556 9.45846 16.7556 9.6378C16.7556 10.0221 16.7556 10.4064 16.7556 10.8163C16.7043 10.97 16.7556 11.2006 16.5506 11.2262C15.5259 11.2774 15.5259 12.8914 16.5506 12.8402C17.3192 12.7889 18.0109 12.3534 18.2415 11.5592C18.3439 11.2005 18.3183 10.7906 18.3183 10.432C18.3183 9.45846 18.4464 8.35685 17.4473 7.76762C16.7043 7.3321 15.5002 7.51143 14.6804 7.51143C11.3244 7.51143 7.96829 7.51143 4.63783 7.51143C3.71555 7.51143 2.79326 7.48581 1.87098 7.51143C0.8206 7.53705 0.0776583 8.33123 0.0520394 9.38161C0.0264204 10.1246 -0.101655 11.1237 0.23139 11.8154C0.795006 13.0195 2.15279 12.8402 3.2544 12.8402C4.79154 12.8402 6.35428 12.8402 7.89142 12.8402C10.7095 12.8402 13.5532 12.8914 16.3969 12.8402C16.4482 12.8402 16.4994 12.8402 16.5506 12.8402C17.5754 12.8402 17.5754 11.2262 16.5506 11.2262Z"
            fill={props.fill}
        />
        <path
            d="M16.5506 17.964C14.3986 17.964 12.2466 17.964 10.069 17.964C7.40466 17.964 4.7403 17.964 2.10155 17.964C1.8966 17.964 1.71728 18.0152 1.66604 17.7846C1.58919 17.5028 1.66604 17.1185 1.66604 16.8367C1.66604 16.6062 1.61481 16.2731 1.66604 16.0425C1.71728 15.812 1.94786 15.8632 2.15281 15.8632C2.56271 15.8632 2.99821 15.8632 3.40811 15.8632C6.48238 15.8632 9.58229 15.8632 12.6566 15.8632C13.8094 15.8632 14.9366 15.8632 16.0895 15.8632C16.2432 15.8632 16.4738 15.812 16.6275 15.8632C16.8324 15.9401 16.7556 16.1963 16.7556 16.3756C16.7556 16.7599 16.7556 17.1442 16.7556 17.5541C16.7043 17.7078 16.7556 17.9383 16.5506 17.964C15.5259 18.0152 15.5259 19.6292 16.5506 19.578C17.3192 19.5267 18.0109 19.0912 18.2415 18.297C18.3439 17.9383 18.3183 17.5284 18.3183 17.1698C18.3183 16.1963 18.4464 15.0946 17.4473 14.5054C16.7043 14.0699 15.5002 14.2492 14.6804 14.2492C11.3244 14.2492 7.96829 14.2492 4.63783 14.2492C3.71555 14.2492 2.79326 14.2236 1.87098 14.2492C0.8206 14.2748 0.0776583 15.069 0.0520394 16.1194C0.0264204 16.8623 -0.101655 17.8615 0.23139 18.5532C0.795006 19.7573 2.15279 19.578 3.2544 19.578C4.79154 19.578 6.35428 19.578 7.89142 19.578C10.7095 19.578 13.5532 19.6292 16.3969 19.578C16.4482 19.578 16.4994 19.578 16.5506 19.578C17.5754 19.578 17.5754 17.964 16.5506 17.964Z"
            fill={props.fill}
        />
    </Icon>
);

export default ForwardIcon;
