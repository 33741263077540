import initialState from "./initialState";
import { updateById, deleteById } from "../helpers";
import {
    ADD_RESOURCE,
    ADD_RESOURCES,
    UPDATE_RESOURCE,
    DELETE_RESOURCE,
    ADDING_RESOURCE,
    SAVING_RESOURCE,
    CREATING_RESOURCE,
    RESET_RESOURCE_STATE,
    TOGGLE_ADDING_RESOURCE,
    DELETE_ALL_RESOURCES,
    ADD_RESOURCES_LIGTH,
} from "../constants";

export const resources = function resources(state = initialState.resources, action) {
    switch (action.type) {
        case ADD_RESOURCES:
            return [...action.payload];
        case ADD_RESOURCE:
            return [...state, action.payload];
        case UPDATE_RESOURCE:
            return updateById(state, action.payload);
        case DELETE_RESOURCE:
            return deleteById(state, action.payload);
        case DELETE_ALL_RESOURCES:
            return [];
        default:
            return state;
    }
};

export const resourcesLight = function resources(state = initialState.resourcesLight, action) {
    switch (action.type) {
        case ADD_RESOURCES_LIGTH:
            return [...action.payload];
        default:
            return state;
    }
};

export const resourceState = function resourceState(state = null, action) {
    switch (action.type) {
        case ADDING_RESOURCE:
            return ADDING_RESOURCE;
        case CREATING_RESOURCE:
            return CREATING_RESOURCE;
        case SAVING_RESOURCE:
            return SAVING_RESOURCE;
        case RESET_RESOURCE_STATE:
            return null;
        default:
            return state;
    }
};

export const addingResource = function addingResource(state = false, action) {
    if (action.type === TOGGLE_ADDING_RESOURCE) {
        return !state;
    } else {
        return state;
    }
};
