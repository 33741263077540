import AceEditor from "react-ace";
import { NEW, UPDATE } from "../../../constants";

import "brace/mode/javascript";
import "brace/theme/xcode";
import "brace/ext/language_tools";

import BubbleContainer from "./BubbleContainer";
import CodeIcon from "../../icons/CodeIcon";
import { useTranslation } from "react-i18next";
import CanRenderOption from "../../common/CanRenderOption";

const SandboxBubble = (props) => {
    const { sandbox } = props;
    const { code } = sandbox;

    const { t } = useTranslation();

    const handleChange = (event) => {
        props.updateSandbox({
            ...sandbox,
            code: event,
            action: sandbox.action === NEW ? NEW : UPDATE,
        });
    };

    if (sandbox) {
        return (
            <CanRenderOption option="view sandbox" noPermission={<div {...props.dragHandleProps} />}>
                <BubbleContainer
                    {...props}
                    bubbleStyles={props.bubbleStyles}
                    icon={<CodeIcon className="mx-2 mr-2 flex items-center rounded-10 fill-current text-gray-400" width="21" height="13" />}
                    title={t("bubbles.Sandbox")}>
                    <div className="relative w-full items-center rounded-b-lg">
                        <AceEditor
                            debounceChangePeriod={0}
                            mode="javascript"
                            value={code}
                            theme="xcode"
                            width={"100%"}
                            height={"400px"}
                            onChange={handleChange}
                            wrapEnabled={true}
                            fontSize={15}
                            enableLiveAutocompletion={true}
                            setOptions={{
                                fontFamily: "Source Code Pro",
                            }}
                            editorProps={{
                                $blockScrolling: Infinity,
                            }}
                            className="overflow-hidden rounded-lg border-none shadow-input"
                            //showGutter={false}
                            showPrintMargin={false}
                        />
                    </div>
                </BubbleContainer>
            </CanRenderOption>
        );
    }
    return <div {...props.dragHandleProps} />;
};

export default SandboxBubble;
