import { SET_LOADING, SET_LOADING_FLOW, UNSET_LOADING, UNSET_LOADING_FLOW } from "../constants";

export const setLoading = () => ({
    type: SET_LOADING,
});

export const unsetLoading = () => ({
    type: UNSET_LOADING,
});

export const setLoadingFlow = () => ({
    type: SET_LOADING_FLOW,
});

export const unsetLoadingFlow = () => ({
    type: UNSET_LOADING_FLOW,
});
