import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

/* Components */
import CardTitle from "../../CardTitle";
import CardList from "../../CardList";
import { BeatLoader } from "react-spinners";
import { useTranslation } from "react-i18next";

const TopFlows = (props) => {
    const { metrics, loadingCards } = props;
    const topFlows = get(metrics, "top_flows", []);
    const { t } = useTranslation();

    return (
        <div className="mx-1 w-1/3 rounded-lg bg-white p-4 shadow-input">
            <CardTitle>
                <div className="flex w-full items-center justify-between">
                    <span className="block w-1/2 text-base">{t("metrics.Top 10 Flujos")}</span>
                </div>
            </CardTitle>
            <div className="row-gap-4 flex h-full flex-col justify-center">
                {loadingCards ? (
                    <div className="pb-6 pt-2 text-center">
                        <BeatLoader color="#00B3C7" size={10} />
                    </div>
                ) : isEmpty(topFlows) ? (
                    <div className="text-center text-gray-400">{t("common.No hay datos para mostrar")}</div>
                ) : (
                    topFlows.map((metric, i) => {
                        return <CardList label={metric.flowName} value={metric.amount} key={i} />;
                    })
                )}
            </div>
        </div>
    );
};

export default TopFlows;
