import Select from "react-select";

const defaultProps = {
    hasError: "",
    label: "",
    name: "",
    placeholder: "",
    options: [],
    styles: () => {},
    isControlled: false,
};

export const InputSelector = ({
    hasError,
    label,
    name,
    placeholder,
    multiSelect = false,
    options,
    defatulValue = null,
    disabled = false,
    onChange = undefined,
    value = undefined,
    isSearchable = false,
    inline = false,
    color = "#00B3C7",
    styles = handleStyles,
    isControlled = false,
    labelClassName = "block",
} = defaultProps) => {
    const defautlOptions =
        options && options.length > 0 && defatulValue ? options.filter((option) => String(defatulValue).includes(String(option.value))) : null;

    const parsedValue = options && options.length > 0 && value ? options.filter((option) => String(value).includes(String(option.value))) : null;

    return (
        <label className="font-medium">
            <div className={inline ? "flex items-center gap-3" : ""}>
                <span className={labelClassName}>{label}</span>
                <Select
                    name={name}
                    options={options}
                    isDisabled={disabled}
                    isMulti={multiSelect}
                    placeholder={placeholder}
                    isSearchable={isSearchable}
                    defaultValue={defautlOptions}
                    {...(onChange && { onChange })}
                    className={inline ? "flex-1" : ""}
                    value={isControlled ? parsedValue : value}
                    // @ts-ignore
                    styles={styles({ inputHasError: hasError, color })}
                />
            </div>
            {hasError && <InputErrorMessage hasError={hasError} />}
        </label>
    );
};

export const handleStyles = ({ inputHasError = false, menuListTop = false, color = "#00B3C7" } = {}) => {
    const selectForm = {
        valueContainer: (style) => {
            return {
                ...style,
                padding: 0,
                display: "flex",
                width: "100%",
            };
        },
        multiValue: (base) => {
            return {
                ...base,
                color: "#00B3C7",
                cursor: "pointer",
                backgroundColor: "transparent",
                padding: 0,
                borderColor: "#00B3C7",
                borderRadius: "999999999px",
                borderWidth: "1px",
            };
        },
        multiValueLabel: (base) => {
            return {
                ...base,
                padding: 0,
                color: "#00B3C7",
                fontSize: "0.9375rem",
                fontWeight: "400",
            };
        },
        multiValueRemove: (base) => {
            return {
                ...base,
                fontSize: "0.9375rem",
                fontWeight: "300",
                paddingLeft: 0,
                backgroundColor: "transparent",
                borderRadius: "999999999px",
                "&:hover": {
                    color: "#00B3C7",
                    cursor: "pointer",
                    backgroundColor: "transparent",
                },
            };
        },
        clearIndicator: (style) => ({
            ...style,
            display: "none",
        }),
        control: () => {
            return {
                backgroundColor: "#FFF",
                display: "flex",
                border: "1px solid #DCDEE4",
                borderRadius: "0.625rem",
                textOverflow: "ellipsis",
                padding: "0.9rem 0.5rem",
                "&:hover": {
                    cursor: "pointer",
                },
            };
        },
        menu: () => {
            return {
                borderRadius: "0.625rem",
                boxShadow: "0rem 0rem 0.925rem rgba(127, 128, 156, 0.15)",
                top: menuListTop ? "0" : "130%",
                position: "absolute",
                width: "100%",
                boxSizing: "border-box",
                marginLeft: "4px",
                zIndex: 1,
                overflow: "hidden",
                backgroundColor: "white",
                maxHeight: "13rem",
            };
        },
        menuList: () => {
            return { top: "100%", backgroundColor: "white", maxHeight: "13rem", overflowY: "scroll" };
        },
        option: (_, { isSelected }) => {
            return {
                backgroundColor: "white",
                borderBottomWidth: "0.0313rem",
                borderColor: "rgba(166, 180, 208, 0.25)",
                color: isSelected ? "#00B3C7" : "#707C95",
                cursor: "pointer",
                fontWeight: isSelected ? 600 : 500,
                padding: "0.5rem",
                // paddingLeft: '2rem',
                "&:hover": {
                    color: "#00B3C7",
                },
            };
        },
        input: () => ({
            color: "#707C95",
        }),
        placeholder: () => ({
            color: "#DCDEE4",
            fontWeight: "500",
            fontSize: "0.9375rem",
        }),
        singleValue: () => ({
            color: "#727C94",
            fontSize: "0.9375rem",
            fontWeight: "500",
            textOverflow: "ellipsis",
        }),
        dropdownIndicator: () => ({
            color: "#727C94",
            padding: "0 8px 0 0",
            "&:hover": {
                color: "rgba(166, 180, 208, 1)",
                cursor: "pointer",
            },
            "& svg": {
                width: "1.5rem",
                height: "1.5rem",
            },
        }),
        indicatorSeparator: () => ({ display: "none" }),
    };

    const selectFormError = {
        clearIndicator: (style) => ({
            ...style,
            display: "none",
        }),
        control: (styles) => {
            return {
                ...styles,
                backgroundColor: "rgba(214, 128, 111, 0.10)",
                width: "100%",
                borderColor: "#A83927",
                borderRadius: "0.625rem",
                borderWidth: "0.125rem",
                boxShadow: "none",
                minHeight: "2.25rem",

                "&:hover": {
                    cursor: "text",
                },
            };
        },
        menu: () => {
            return {
                borderRadius: "0.625rem",
                boxShadow: "0rem 0rem 0.625rem rgba(127, 128, 156, 0.15)",
                top: "130%",
                position: "absolute",

                boxSizing: "border-box",
                width: "98%",
                marginLeft: "4px",
                zIndex: 1,
                overflow: "hidden",
                backgroundColor: "white",
                maxHeight: "13rem",
            };
        },
        menuList: () => {
            return { top: "100%", backgroundColor: "white", maxHeight: "13rem", overflowY: "scroll" };
        },
        option: ({ isSelected }) => {
            return {
                backgroundColor: "white",
                borderBottomWidth: "0.0313rem",
                borderColor: "rgba(166, 180, 208, 0.25)",
                color: isSelected ? "#00B3C7" : "#707C95",
                cursor: "pointer",
                fontWeight: isSelected ? 600 : 500,
                padding: "0.5rem",
                "&:hover": {
                    color: "#00B3C7",
                },
            };
        },
        input: (styles) => ({ ...styles, color: "rgba(166, 180, 208, 1)" }),
        placeholder: (styles) => ({
            ...styles,
            color: "#A6B4D0",
            fontWeight: "400",
        }),
        singleValue: (styles) => ({
            ...styles,
            color: "#A6B4D0",
            fontSize: "0.9375rem",
            fontWeight: "400",
        }),
        dropdownIndicator: (styles) => ({
            ...styles,
            color: "rgba(166, 180, 208, 0.5)",
            padding: "0 8px 0 0",
            "&:hover": {
                color: "rgba(166, 180, 208, 1)",
                cursor: "pointer",
            },
        }),
        indicatorSeparator: () => ({ display: "none" }),
    };

    return inputHasError ? selectFormError : selectForm;
};

function InputErrorMessage({ hasError }) {
    return (
        <div className="mt-1 flex items-center gap-2 font-medium">
            <span className="text-12 text-red-1010">{hasError}</span>
        </div>
    );
}
