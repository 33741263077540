import React from "react";

import Icon from "./Icon";

const RandomIcon = (props) => (
    <Icon viewBox="0 0 19 16" className={props.className} width={props.width} height={props.height}>
        <path
            d="M16.201 15.7859C16.9393 15.0475 17.6776 14.3092 18.4159 13.5709C18.718 13.2689 18.718 12.799 18.4159 12.497C17.6776 11.7587 16.9393 11.0204 16.201 10.2821C15.9325 10.0136 15.3956 9.98002 15.1271 10.2821C14.8586 10.5841 14.8251 11.0539 15.1271 11.356C15.8654 12.0943 16.6037 12.8326 17.342 13.5709C17.342 13.2018 17.342 12.8662 17.342 12.497C16.6037 13.2353 15.8654 13.9736 15.1271 14.7119C14.8586 14.9804 14.8251 15.5174 15.1271 15.7859C15.4291 16.0543 15.899 16.0879 16.201 15.7859Z"
            fill={props.fill}
        />
        <path
            d="M16.201 5.71793C16.9393 4.97962 17.6776 4.2413 18.4159 3.50298C18.718 3.20095 18.718 2.73112 18.4159 2.42908C17.6776 1.69076 16.9393 0.952444 16.201 0.214128C15.9325 -0.0543499 15.3956 -0.0879097 15.1271 0.214128C14.8586 0.516167 14.8251 0.985998 15.1271 1.28804C15.8654 2.02635 16.6037 2.76467 17.342 3.50298C17.342 3.13383 17.342 2.79823 17.342 2.42908C16.6037 3.16739 15.8654 3.9057 15.1271 4.64402C14.8586 4.91249 14.8251 5.44945 15.1271 5.71793C15.4291 5.98641 15.899 6.01997 16.201 5.71793Z"
            fill={props.fill}
        />
        <path
            d="M0.729926 3.83854C1.87096 3.83854 3.11268 3.70431 4.22015 4.07347C5.12627 4.34194 5.93169 4.91246 6.53577 5.65077C6.8378 6.01993 7.03917 6.42265 7.27409 6.85893C7.8446 7.93284 8.44868 9.00676 9.0192 10.1142C9.79107 11.5573 10.865 12.6983 12.4087 13.336C13.8182 13.94 15.362 13.8058 16.8386 13.8058C17.8119 13.8058 17.8119 12.2956 16.8386 12.2956C15.6976 12.2956 14.4559 12.4299 13.3484 12.0607C12.4423 11.7922 11.6369 11.2217 11.0328 10.4834C10.7307 10.1142 10.5294 9.71151 10.2945 9.27523C9.72395 8.20132 9.11987 7.1274 8.54935 6.01993C7.77748 4.57686 6.70357 3.43582 5.15982 2.79819C3.75031 2.19411 2.20656 2.32835 0.729926 2.32835C-0.243308 2.32835 -0.243308 3.83854 0.729926 3.83854Z"
            fill={props.fill}
        />
        <path
            d="M16.8722 2.32839C15.664 2.32839 14.3888 2.22772 13.2142 2.52976C11.4691 2.99959 10.0595 4.20775 9.18699 5.78506C8.61648 6.82541 8.04596 7.86576 7.509 8.90612C7.34121 9.20815 7.17341 9.54375 7.00561 9.84579C6.50221 10.6848 5.79745 11.3224 4.9249 11.7587C3.64962 12.3963 2.17301 12.2621 0.763495 12.2621C-0.209739 12.2621 -0.209739 13.7723 0.763495 13.7723C1.97165 13.7723 3.24691 13.873 4.4215 13.5709C6.16661 13.1011 7.57612 11.8929 8.44868 10.3156C9.01919 9.27528 9.58971 8.23492 10.1267 7.19456C10.2945 6.89253 10.4623 6.55693 10.6301 6.25489C11.1335 5.4159 11.8382 4.77826 12.7108 4.34198C13.986 3.70435 15.4627 3.83859 16.8722 3.83859C17.8454 3.83859 17.8454 2.32839 16.8722 2.32839Z"
            fill={props.fill}
        />
    </Icon>
);

export default RandomIcon;
