import { List } from "immutable";
import { byOrder } from "../../../helpers";
import { connect } from "react-redux";
import { Component } from "react";
import { NEW, UPDATE } from "../../../constants";
import { updateFlow } from "../../../actions/flow";
import { setFlowDefault } from "../../../actions/flows";
import { updateOptions } from "../../../actions/options";
import { getResources } from "../../../actions/resources";
import { DragDropContext } from "react-beautiful-dnd";

import {
    saveBubbles,
    updateBubble,
    deleteBubble,
    addTextBubble,
    updateBubbles,
    addImageBubble,
    addVideoBubble,
    addFileBubble,
    addInputBubble,
    addRandomBubble,
    addGenericBubble,
    addSandboxBubble,
    addResourceBubble,
    addQuickReplyBubble,
    addConditionalBubble,
    addContactCardBubble,
} from "../../../actions/bubbles";

import FlowBubbles from "./../components/FlowBubbles";
import { DELETE } from "../../../constants";

const mapStateToProps = (state) => {
    return {
        bot: state.bot,
        flow: state.flow,
        bubbles: state.bubbles,
        intents: state.intents,
        options: state.options,
        resources: state.resources,
        isLoading: state.isLoading,
        expressions: state.expressions,
        unSavedChanges: state.unSavedChanges,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getResources: (companyId) => dispatch(getResources(companyId)),
        saveBubbles: () => dispatch(saveBubbles()),
        updateFlow: (flow) => dispatch(updateFlow(flow)),
        deleteBubble: (id) => dispatch(deleteBubble(id)),
        addTextBubble: (flow) => dispatch(addTextBubble(flow)),
        updateBubble: (bubble) => dispatch(updateBubble(bubble)),
        addQuickReplyBubble: (bubble) => dispatch(addQuickReplyBubble(bubble)),
        addImageBubble: (bubble) => dispatch(addImageBubble(bubble)),
        addVideoBubble: (bubble) => dispatch(addVideoBubble(bubble)),
        addFileBubble: (bubble) => dispatch(addFileBubble(bubble)),
        addInputBubble: (bubble) => dispatch(addInputBubble(bubble)),
        updateOptions: (options) => dispatch(updateOptions(options)),
        updateBubbles: (bubbles) => dispatch(updateBubbles(bubbles)),
        addRandomBubble: (bubble) => dispatch(addRandomBubble(bubble)),
        addGenericBubble: (bubble) => dispatch(addGenericBubble(bubble)),
        addResourceBubble: (bubble) => dispatch(addResourceBubble(bubble)),
        addConditionalBubble: (bubble) => dispatch(addConditionalBubble(bubble)),
        addContactCardBubble: (bubble) => dispatch(addContactCardBubble(bubble)),
        addSandboxBubble: (bubble) => dispatch(addSandboxBubble(bubble)),
        setFlowDefault: (flowId, botId) => dispatch(setFlowDefault(flowId, botId)),
    };
};

function move(items, oldIndex, newIndex) {
    const itemRemovedArray = [...items.slice(0, oldIndex), ...items.slice(oldIndex + 1, items.length)];
    return [...itemRemovedArray.slice(0, newIndex), items[oldIndex], ...itemRemovedArray.slice(newIndex, itemRemovedArray.length)];
}

class ConnectedFlow extends Component {
    state = {
        showVocablo: false,
        modalPosX: 0,
    };

    // componentDidUpdate(prevProps) {
    //     console.log("componentDidUpdate");
    //     if (!isEmpty(this.props.bot) && prevProps.bot !== this.props.bot) {
    //         console.log(this.props.bot?.Company?.id, "companyId");
    //         this.props.getResources(this.props.bot?.Company?.id);
    //     }
    // }

    onDragEnd = (result) => {
        this.setClass("carrousel", "fixTransform", "REMOVE");

        const { destination, source } = result;
        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (result.type !== "bubbles") {
            return this.moveOptions(result);
        }

        // log reordered bubbles
        const bubbles = this.getBubbles();

        let movedBubbles = move(bubbles, source.index, destination.index);

        // Bubble order is equal to bubble index
        const reOrderedBubbles = movedBubbles.map((bubble, index) => {
            const action = bubble.action === NEW ? NEW : bubble.action === DELETE ? DELETE : UPDATE;
            if (bubble.order !== index) {
                return {
                    ...bubble,
                    order: index,
                    action,
                };
            }
            return bubble;
        });

        this.props.updateBubbles(reOrderedBubbles);
    };

    reOrderByIndex = (items) => {
        return items.map((item, index) => {
            const action = item.action === NEW ? NEW : item.action === DELETE ? DELETE : UPDATE;
            if (item.order !== index) {
                return {
                    ...item,
                    order: index,
                    action,
                };
            }
            return item;
        });
    };

    moveOptions = (result) => {
        // get options
        const { destination, source, type } = result;

        const options = this.getOptions(type, String(source.droppableId));
        let movedOptions = move(options, source.index, destination.index);

        try {
            const reOrderedOptions = this.reOrderByIndex(movedOptions);

            this.props.updateOptions(reOrderedOptions);
        } catch (error) {
            console.log("Something happend D:");
            alert("Error moviendo las burbujas, intente nuevamente.");
        }
    };

    getOptions = (optionable, optionableId) => {
        return List(this.props.options)
            .sort(byOrder)
            .filter((o) => o.optionable === optionable && String(o.optionableId) === optionableId)
            .filter((o) => o.action !== DELETE)
            .toJS();
    };

    getBubbles = () => {
        return List(this.props.bubbles)
            .sort(byOrder)
            .filter((b) => b.flowId === this.props.flow.id)
            .filter((b) => b.action !== DELETE)
            .toJS();
    };

    closeModal = (evt) => {
        if (this.state.showVocablo) {
            this.setState({
                showVocablo: false,
            });
        }
    };

    onDragStart = () => {
        this.setClass("carrousel", "fixTransform", "ADD");
    };

    // A function that let add or remove classes to an specific class.
    setClass = (srcClass, className, action) => {
        let oldClass = List(document.getElementsByClassName(srcClass));
        try {
            oldClass.map((classes) => {
                return action === "ADD" ? classes.classList.add(className) : classes.classList.remove(className);
            });
        } catch (err) {
            console.error("ClassName not found.");
        }
    };

    render() {
        const bubbles = this.getBubbles(this.props.bubbles);
        const intents = this.props.intents;

        return (
            <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
                <FlowBubbles
                    {...this.props}
                    bubbles={bubbles}
                    intents={intents}
                    setFlow={this.props.setFlow}
                    showVocablo={this.state.showVocablo}
                    closeModal={this.closeModal}
                    isLoading={this.props.isLoading}
                />
            </DragDropContext>
        );
    }
}

const Flow = connect(mapStateToProps, mapDispatchToProps)(ConnectedFlow);

export default Flow;
