import ImageEmptyIcon from "../../icons/ImageEmptyIcon";
import BotType from "./BotType";
import get from "lodash/get";

const Bot = (props) => {
    const { handleClick, bot } = props;

    const botName = get(bot, "name", "");
    const botType = get(bot, "type", "");
    const botCompanyName = get(bot, "Company.name", "");
    const botImageUrl = get(bot, "imageUrl", "");

    const botCard = "text-left h-auto right-0 top-0 resize-none relative rounded-lg bg-white flex shadow-input w-full h-full";

    const renderImage = (url) => {
        if (url) {
            return <img src={url} className="h-full w-full rounded-lg object-cover shadow-input" alt="" />;
        }
        return (
            <div className="flex h-full w-full items-center justify-center rounded-lg bg-[#E2E2E2] shadow-input">
                <ImageEmptyIcon className="fill-current text-gray-400" width="3.75rem" height="3.75rem" />
            </div>
        );
    };

    return (
        <button
            onClick={() => {
                handleClick(bot);
            }}
            className={botCard}>
            <div className="flex h-32 w-32 items-center justify-center p-4">{renderImage(botImageUrl)}</div>
            <div className="flex h-full flex-1 select-none flex-col items-start justify-center pl-2">
                <h4 className="mb-2 font-sans text-lg font-extrabold text-gray-500">{botName}</h4>
                <h4 className="mb-2 block font-sans text-sm font-semibold text-gray-400">{botCompanyName}</h4>
                <BotType className="absolute bottom-0 right-0 m-2" type={botType} />
            </div>
        </button>
    );
};

export default Bot;
