import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

import CloseIcon from "../icons/CloseIcon";

const SlideOver = (props) => {
    const { isOpen, closeModal, children, title } = props;

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 z-20" onClose={closeModal}>
                <div className="absolute inset-0 overflow-hidden">
                    <Dialog.Overlay className="fixed inset-0" />

                    <div className="pointer-events-none fixed inset-y-0 right-0 z-40 flex max-w-full pl-10">
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity duration-300 ease-in"
                            enterFrom="opacity-0 -right-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-300 ease-in"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0 -right-28">
                            <div className="pointer-events-auto w-screen max-w-[21.25rem]">
                                <div className="flex h-full flex-col bg-white py-3 shadow-xl">
                                    <Dialog.Title
                                        as="h3"
                                        className="break-word flex justify-between border-b border-gray-200 py-4 pl-4 pr-2 text-2xl font-bold leading-6 text-primary-200">
                                        <span>{title}</span>
                                        <button className="mr-2" onClick={closeModal}>
                                            <CloseIcon className="stroke-current text-gray-300" width="2rem" />
                                        </button>
                                    </Dialog.Title>
                                    <Dialog.Description as="div" className="h-full overflow-auto">
                                        {children}
                                    </Dialog.Description>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};
export default SlideOver;
