import React from "react";
import Flow from "../../../resources/Flow";

import LockIcon from "../../icons/LockIcon";
import UnlockIcon from "../../icons/UnlockIcon";
import { useTranslation } from "react-i18next";
import Tippy from "@tippyjs/react";

const { update } = Flow();

const LockFlow = (props) => {
    const flow = props.flow;
    const isSafe = flow.type === "safe" ? true : false;
    const toggleType = flow.type === "safe" ? "default" : "safe";

    const { t } = useTranslation();

    function handleClick() {
        update(flow.id, flow.botId, { type: toggleType }).then(() => {
            props.updateFlow({
                ...flow,
                type: toggleType,
            });
        });
    }

    return (
        <Tippy
            content={
                <p>
                    {t("Controla si un flujo puede o no")} <br /> {t("ser consultado por un")} <br /> {t("usuario bloqueado.")}
                </p>
            }
            arrow={false}
            theme="jelou"
            placement={"bottom"}>
            <button onClick={handleClick}>
                {isSafe ? (
                    <LockIcon className="fill-current text-gray-400" width="1rem" height="1rem" />
                ) : (
                    <UnlockIcon className="fill-current text-gray-400" width="1rem" height="1rem" />
                )}
            </button>
        </Tippy>
    );
};

export default LockFlow;
