import initialState from "./initialState";
import { updateById, mergeById, deleteByIds } from "../helpers";
import { ADD_ELEMENTS, ADD_ELEMENT, UPDATE_ELEMENT, UPDATE_ELEMENTS, DELETE_ELEMENTS } from "../constants";

function bubbles(state = initialState.elements, action) {
    switch (action.type) {
        case ADD_ELEMENTS:
            return [...action.payload];
        case ADD_ELEMENT:
            return [...state, action.payload];
        case UPDATE_ELEMENT:
            return updateById(state, action.payload);
        case UPDATE_ELEMENTS:
            return mergeById(state, action.payload);
        case DELETE_ELEMENTS:
            return deleteByIds(state, action.payload);
        default:
            return state;
    }
}

export default bubbles;
