import Icon from "./Icon";

const ImageEmptyIcon = (props) => (
    <Icon viewBox="0 0 116 112" stroke={props.stroke} className={props.className} width={props.width} height={props.height}>
        <path
            d="M110.079 10.5531L44.2361 0.852688C40.8331 0.357237 37.6648 2.70411 37.1563 6.10708L25.8392 82.9542C25.3437 86.3571 27.6906 89.5254 31.0936 90.0339L96.9364 99.7343C100.339 100.23 103.508 97.8829 104.016 94.4799L115.346 17.6198C115.842 14.2168 113.482 11.0486 110.079 10.5531ZM95.0459 75.0791C94.8112 76.6958 93.2988 77.8301 91.669 77.5824L42.5411 70.3462C40.9244 70.1115 39.7901 68.5991 40.0378 66.9693L47.4435 16.6941C47.6782 15.0774 49.1906 13.943 50.8204 14.1908L99.9483 21.4269C101.565 21.6616 102.699 23.1741 102.452 24.8038L95.0459 75.0791Z"
            fill="#F4F4F4"
        />
        <path
            d="M45.3575 62.2883L46.4657 54.7653C46.6352 53.6049 47.248 52.5618 48.1737 51.8317L59.6604 42.8484C61.1467 41.688 63.3111 42.1052 64.2628 43.7219L69.6476 52.953L93.2859 38.8065C94.694 37.9721 96.4411 39.1325 96.1934 40.7492L92.0211 69.0943C91.7865 70.6588 90.3392 71.728 88.7877 71.4672C80.4172 70.1112 55.775 66.1346 47.6131 65.2089C46.1658 65.0524 45.1489 63.7225 45.3575 62.2883Z"
            fill="#F4F4F4"
        />
        <path
            d="M27.5076 41.1408L4.48215 47.8424C1.61375 48.6769 -0.0551417 51.6757 0.740188 54.5571L15.4212 107.192C16.2556 110.191 19.437 111.886 22.3966 110.921L59.5163 98.7305L28.5506 94.6235C24.6131 94.1019 21.8099 90.5164 22.2662 86.5659L27.5076 41.1408Z"
            fill="#F4F4F4"
        />
    </Icon>
);

export default ImageEmptyIcon;
