import get from "lodash/get";

import axios from "axios";
import { API } from "../utils/utils";
import { authUser, setUser } from "../actions/user";
import { setLoadingPermissions } from "./permissions";
import { setLoading, unsetLoading } from "../actions/isLoading";
import { LOG_IN_SUCCESS, ADD_ERRORS } from "../constants";

import { setUnauthorization } from "./unathorization";

export const login = () => ({
    type: LOG_IN_SUCCESS,
});

export function logInUser(credentials) {
    return function (dispatch) {
        dispatch(setLoading());
        return axios
            .post(API("/login"), credentials)
            .then(({ data }) => {
                localStorage.setItem("jwt", data.token);
                // Set headers for axios
                axios.defaults.headers.common["Authorization"] = "Bearer " + data.token;
                dispatch(setUnauthorization(false));
                dispatch(setUser(data.user));
                dispatch(authUser());
                dispatch({
                    type: LOG_IN_SUCCESS,
                });

                dispatch(unsetLoading());
            })
            .catch((err) => {
                dispatch(unsetLoading());
                dispatch({
                    type: ADD_ERRORS,
                    payload: get(err, "response.data", []),
                });
            });
    };
}

export function logOutUser() {
    return function (dispatch) {
        dispatch(setLoadingPermissions(true));
    };
}
