import React from "react";

import Icon from "./Icon";

const UrlIcon = (props) => (
    <Icon viewBox="0 0 8.53 8.536" className={props.className} width={props.width} height={props.height}>
        <g id="url_icon" data-name="url icon" transform="rotate(90 4.265 4.265)">
            <path
                id="Path_86"
                d="M3.983.151a.509.509 0 0 0-.72.72l.677.677a1.691 1.691 0 0 1-2.393 2.391l-.676-.676a.509.509 0 1 0-.72.72l.685.685A2.713 2.713 0 0 0 4.668.827z"
                transform="translate(3.081 3.076)"
            />
            <path
                id="Path_87"
                d="M3.9 1.511l.681.677a.509.509 0 1 0 .72-.72L4.624.787A2.714 2.714 0 0 0 .787 4.628l.677.677a.508.508 0 0 0 .362.148.527.527 0 0 0 .362-.153.506.506 0 0 0 0-.72l-.676-.68A1.691 1.691 0 0 1 3.9 1.511z"
            />
            <path
                id="Path_88"
                d="M2.263 2.774a.527.527 0 0 0 .362-.148.506.506 0 0 0 0-.72L.871.151a.509.509 0 0 0-.72.72l1.754 1.754a.517.517 0 0 0 .358.149z"
                transform="translate(2.88 2.88)"
            />
        </g>
    </Icon>
);

export default UrlIcon;
