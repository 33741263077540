import React from "react";

import Icon from "./Icon";

const DateIcon = (props) => (
    <Icon viewBox="0 0 16 17" className={props.className} width={props.width} height={props.height} fill={props.fill}>
        <path
            d="M12.9378 15.4982C9.79022 15.4982 6.63306 15.4982 3.47589 15.4982C2.6986 15.4982 1.95009 15.4118 1.4127 14.7689C0.913696 14.1643 0.952081 13.4734 0.952081 12.7441C0.952081 10.8344 0.952081 8.92479 0.952081 7.01513C0.952081 5.65247 0.529847 3.33977 1.90211 2.48571C2.45869 2.14024 3.04406 2.17863 3.66782 2.17863C4.51229 2.17863 5.35676 2.17863 6.20123 2.17863C8.13007 2.17863 10.0589 2.17863 11.9974 2.17863C12.4868 2.17863 13.0338 2.12105 13.5136 2.26499C14.5596 2.57207 14.9626 3.50291 14.9722 4.51051C14.9818 6.51613 14.9722 8.52174 14.9722 10.5274C14.9626 12.2643 15.5096 15.3927 12.9378 15.4982C12.3332 15.5174 12.3332 16.4674 12.9378 16.4386C14.406 16.3811 15.6727 15.3639 15.8742 13.8764C15.951 13.3103 15.903 12.7249 15.903 12.1587C15.903 9.98037 15.903 7.80203 15.903 5.62368C15.903 5.08629 15.9318 4.5393 15.8934 4.00191C15.7879 2.54329 14.6075 1.36295 13.1393 1.24779C11.6039 1.13264 10.0205 1.2382 8.47553 1.2382C6.68104 1.2382 4.87694 1.20941 3.08245 1.2382C1.95009 1.25739 0.894504 1.79478 0.357114 2.82158C0.00205341 3.49331 0.0116496 4.20344 0.0116496 4.93275C0.0116496 7.06312 0.0116496 9.19348 0.0116496 11.3238C0.0116496 13.1087 -0.247449 15.1815 1.6814 16.1316C2.39152 16.477 3.14962 16.429 3.91732 16.429C5.06887 16.429 6.21082 16.429 7.35277 16.429C9.21444 16.429 11.0665 16.429 12.9282 16.429C13.5424 16.4386 13.5424 15.4982 12.9378 15.4982Z"
            fill={props.fill}
            fillOpacity="0.75"
        />
        <path
            d="M3.47589 0.470506C3.47589 1.36296 3.47589 2.25541 3.47589 3.14786C3.47589 3.27261 3.47589 3.39736 3.47589 3.53171C3.47589 3.78121 3.68701 4.01152 3.94611 4.00192C4.20521 3.99233 4.41632 3.79081 4.41632 3.53171C4.41632 2.63926 4.41632 1.74681 4.41632 0.854356C4.41632 0.729604 4.41632 0.604853 4.41632 0.470506C4.41632 0.221003 4.20521 -0.00930657 3.94611 0.000289678C3.6966 0.00988592 3.47589 0.211407 3.47589 0.470506Z"
            fill={props.fill}
            fillOpacity="0.75"
        />
        <path
            d="M11.6134 0.55687C11.6134 1.44932 11.6134 2.34177 11.6134 3.23422C11.6134 3.35897 11.6134 3.48373 11.6134 3.61807C11.6134 3.86758 11.8245 4.09788 12.0836 4.08829C12.3331 4.07869 12.5538 3.87717 12.5538 3.61807C12.5538 2.72562 12.5538 1.83317 12.5538 0.94072C12.5538 0.815969 12.5538 0.691218 12.5538 0.55687C12.5538 0.307368 12.3427 0.0770582 12.0836 0.0866544C11.8341 0.0962507 11.6134 0.297772 11.6134 0.55687Z"
            fill={props.fill}
            fillOpacity="0.75"
        />
        <path
            d="M15.404 5.53732C11.0569 5.53732 6.70983 5.53732 2.36274 5.53732C1.74858 5.53732 1.13442 5.53732 0.520256 5.53732C-0.0843073 5.53732 -0.0843073 6.47776 0.520256 6.47776C4.86735 6.47776 9.21445 6.47776 13.5616 6.47776C14.1757 6.47776 14.7899 6.47776 15.404 6.47776C16.0086 6.47776 16.0086 5.53732 15.404 5.53732Z"
            fill={props.fill}
            fillOpacity="0.75"
        />
        <path
            d="M4.93458 8.40659C4.66588 8.40659 4.39719 8.40659 4.12849 8.40659C4.09011 8.40659 4.05172 8.40659 4.01334 8.40659C3.76383 8.40659 3.54312 8.6177 3.54312 8.8768C3.54312 9.1455 3.54312 9.41419 3.54312 9.68289C3.54312 9.72127 3.54312 9.75966 3.54312 9.79804C3.54312 10.0571 3.75424 10.2683 4.01334 10.2683C4.28203 10.2683 4.55073 10.2683 4.81942 10.2683C4.85781 10.2683 4.89619 10.2683 4.93458 10.2683C4.99215 10.2683 5.04973 10.2587 5.10731 10.2299C5.16489 10.2107 5.22246 10.1723 5.27045 10.1339C5.34722 10.0571 5.40479 9.93239 5.40479 9.81724V9.80764C5.40479 9.53894 5.40479 9.27025 5.40479 9.00155C5.40479 8.96317 5.40479 8.92478 5.40479 8.8864C5.40479 8.6273 5.18408 8.40659 4.93458 8.40659Z"
            fill={props.fill}
            fillOpacity="0.75"
        />
        <path
            d="M8.41801 11.6405C8.14931 11.6405 7.88062 11.6405 7.61192 11.6405C7.57354 11.6405 7.53515 11.6405 7.49677 11.6405C7.23767 11.6405 7.02655 11.8516 7.02655 12.1107C7.02655 12.3794 7.02655 12.6481 7.02655 12.9168C7.02655 12.9552 7.02655 12.9936 7.02655 13.032C7.02655 13.2815 7.23767 13.5022 7.49677 13.5022C7.76546 13.5022 8.03416 13.5022 8.30285 13.5022C8.34124 13.5022 8.37962 13.5022 8.41801 13.5022C8.47559 13.5022 8.53316 13.4926 8.59074 13.4638C8.64832 13.4446 8.7059 13.4062 8.75388 13.3679C8.83065 13.2911 8.88823 13.1663 8.88823 13.0512V13.0416C8.88823 12.7729 8.88823 12.5042 8.88823 12.2355C8.88823 12.1971 8.88823 12.1587 8.88823 12.1203C8.88823 11.8612 8.67711 11.6405 8.41801 11.6405Z"
            fill={props.fill}
            fillOpacity="0.75"
        />
        <path
            d="M4.93461 11.6405C4.66591 11.6405 4.39722 11.6405 4.12852 11.6405C4.09014 11.6405 4.05175 11.6405 4.01337 11.6405C3.76387 11.6405 3.54315 11.8516 3.54315 12.1107C3.54315 12.3794 3.54315 12.6481 3.54315 12.9168C3.54315 12.9552 3.54315 12.9936 3.54315 13.032C3.54315 13.2815 3.75427 13.5022 4.01337 13.5022C4.28206 13.5022 4.55076 13.5022 4.81945 13.5022C4.85784 13.5022 4.89622 13.5022 4.93461 13.5022C4.99218 13.5022 5.04976 13.4926 5.10734 13.4638C5.16492 13.4446 5.22249 13.4062 5.27048 13.3679C5.34725 13.2911 5.40482 13.1663 5.40482 13.0512V13.0416C5.40482 12.7729 5.40482 12.5042 5.40482 12.2355C5.40482 12.1971 5.40482 12.1587 5.40482 12.1203C5.40482 11.8612 5.18411 11.6405 4.93461 11.6405Z"
            fill={props.fill}
            fillOpacity="0.75"
        />
        <path
            d="M8.41801 8.40659C8.14931 8.40659 7.88062 8.40659 7.61192 8.40659C7.57354 8.40659 7.53515 8.40659 7.49677 8.40659C7.24726 8.40659 7.02655 8.6177 7.02655 8.8768C7.02655 9.1455 7.02655 9.41419 7.02655 9.68289C7.02655 9.72127 7.02655 9.75966 7.02655 9.79804C7.02655 10.0571 7.23767 10.2683 7.49677 10.2683C7.76546 10.2683 8.03416 10.2683 8.30285 10.2683C8.34124 10.2683 8.37962 10.2683 8.41801 10.2683C8.47559 10.2683 8.53316 10.2587 8.59074 10.2299C8.64832 10.2107 8.7059 10.1723 8.75388 10.1339C8.83065 10.0571 8.88823 9.93239 8.88823 9.81724V9.80764C8.88823 9.53894 8.88823 9.27025 8.88823 9.00155C8.88823 8.96317 8.88823 8.92478 8.88823 8.8864C8.88823 8.6273 8.67711 8.40659 8.41801 8.40659Z"
            fill={props.fill}
            fillOpacity="0.75"
        />
        <path
            d="M11.9111 8.40659C11.6424 8.40659 11.3737 8.40659 11.105 8.40659C11.0666 8.40659 11.0282 8.40659 10.9898 8.40659C10.7403 8.40659 10.5196 8.6177 10.5196 8.8768C10.5196 9.1455 10.5196 9.41419 10.5196 9.68289C10.5196 9.72127 10.5196 9.75966 10.5196 9.79804C10.5196 10.0571 10.7307 10.2683 10.9898 10.2683C11.2585 10.2683 11.5272 10.2683 11.7959 10.2683C11.8343 10.2683 11.8727 10.2683 11.9111 10.2683C11.9686 10.2683 12.0262 10.2587 12.0838 10.2299C12.1414 10.2107 12.1989 10.1723 12.2469 10.1339C12.3237 10.0571 12.3813 9.93239 12.3813 9.81724V9.80764C12.3813 9.53894 12.3813 9.27025 12.3813 9.00155C12.3813 8.96317 12.3813 8.92478 12.3813 8.8864C12.3813 8.6273 12.1701 8.40659 11.9111 8.40659Z"
            fill={props.fill}
            fillOpacity="0.75"
        />
    </Icon>
);
export default DateIcon;
