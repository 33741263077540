import max from "lodash/max";
import { DELETE, ADD_OPTION, ADD_OPTIONS, UPDATE_OPTION, UPDATE_OPTIONS, DELETE_OPTIONS, SET_UNSAVED_CHANGES } from "../constants";

export const addOptions = (options) => ({
    type: ADD_OPTIONS,
    payload: options,
});

export const deleteOptions = (options) => ({
    type: DELETE_OPTIONS,
    payload: options,
});

export function updateOptions(options) {
    return function (dispatch) {
        dispatch({
            type: UPDATE_OPTIONS,
            payload: options,
        });

        dispatch({
            type: SET_UNSAVED_CHANGES,
        });
    };
}

export function addOption(option) {
    return function (dispatch, getState) {
        const { options } = getState();
        // Get the order of bubble options?
        const bubbleOptions = options.filter((o) => o.optionable === option.optionable && o.optionableId === option.optionableId).map((o) => o.order);
        const order = bubbleOptions.length === 0 ? 0 : max(bubbleOptions) + 1;
        dispatch({
            type: ADD_OPTION,
            payload: {
                ...option,
                order,
            },
        });

        dispatch({
            type: SET_UNSAVED_CHANGES,
        });
    };
}

export function updateOption(option) {
    return function (dispatch) {
        dispatch({
            type: UPDATE_OPTION,
            payload: option,
        });
        dispatch({
            type: SET_UNSAVED_CHANGES,
        });
    };
}

export function deleteOption(option) {
    return function (dispatch) {
        dispatch({
            type: UPDATE_OPTION,
            payload: { ...option, action: DELETE },
        });
        dispatch({
            type: SET_UNSAVED_CHANGES,
        });
    };
}
