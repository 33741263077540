import get from "lodash/get";
import toLower from "lodash/toLower";

import uniqid from "uniqid";
import { List } from "immutable";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { PulseLoader } from "react-spinners";
import { useTranslation } from "react-i18next";

import Http from "../../../../services/Http";
import { UPDATE, NEW } from "../../../../constants";
import { updateAttachment } from "../../../../actions/attachments";

/* Components */
import Buttons from "./components/Buttons";
import AddButton from "./components/AddButton";
import BubbleContainer from "../BubbleContainer";
import ImageIcon from "../../../icons/ImageIcon";
import ImageEmptyIcon from "../../../icons/ImageEmptyIcon";

function getAttachment(attachments, bubbleId) {
    return List(attachments).find((a) => a.bubbleId === bubbleId);
}

const ImageBubble = (props) => {
    const [uploading, setUploading] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleLoadLocalFile = (event, attachment) => {
        event.preventDefault();
        const { files } = event.target;
        const image = files[0];

        setUploading(true);

        const formData = new FormData();
        formData.append("image", image);
        let intent = props.flow.intent;
        const botId = props.bot.id;
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        let fileName = image.name.replace(/ /g, "_");
        fileName = uniqid() + `-${fileName}`;
        const path = toLower(`${botId}/flows/${intent}/images/${fileName}`);
        formData.append("path", path);
        Http.post(`/images/upload`, formData, config)
            .then(({ data }) => {
                dispatch(
                    updateAttachment({
                        ...attachment,
                        url: data,
                        action: attachment.action === NEW ? NEW : UPDATE,
                    })
                );
                setUploading(false);
            })
            .catch(console.error);
    };

    let attachment = getAttachment(props.attachments, props.bubble.id);

    const url = attachment && get(attachment, "url");
    const options = attachment && get(props.bubble, "text");

    return (
        <BubbleContainer
            {...props}
            bubbleStyles={props.bubbleStyles}
            icon={<ImageIcon className="mx-2 fill-current text-gray-400" width="25" height="27" />}
            title={t("bubbles.Imagen")}>
            <div className="relative my-2 flex-1 rounded-lg">
                <div className="p-2">
                    {uploading ? (
                        <div className="flex h-32 min-h-[202px] items-center justify-center overflow-hidden rounded-lg bg-[#E2E2E2]">
                            <PulseLoader color="#00b3c7" />
                        </div>
                    ) : (
                        <label
                            id={attachment.id}
                            className={`${
                                !url && "min-h-[202px] bg-[#E2E2E2]"
                            } flex w-full cursor-pointer items-center justify-center overflow-hidden rounded-lg`}>
                            {url ? (
                                <img src={url} alt={url} className="image-bubble h-full rounded-md object-cover" />
                            ) : (
                                <ImageEmptyIcon width="116" height="112" />
                            )}
                            <input
                                type="file"
                                name="imageUrl"
                                className="hidden"
                                accept="image/*"
                                onChange={(event) => handleLoadLocalFile(event, attachment)}
                                id={attachment.id}
                            />
                        </label>
                    )}
                </div>
                <Buttons
                    bot={props.bot}
                    intents={props.intents}
                    setFlow={props.setFlow}
                    buttons={options}
                    flows={props.flows}
                    bubble={props.bubble}
                />
            </div>
            <AddButton
                attachment={attachment}
                flows={props.flows}
                bubble={props.bubble}
                botType={props.bot.type}
                buttonCounter={props.buttonCounter}
                TotalButtons={props.buttons}
            />
        </BubbleContainer>
    );
};

export default ImageBubble;
