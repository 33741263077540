import isEmpty from "lodash/isEmpty";

export function isValidToolBubbles(bubbles) {
    const toolkitBubbles = bubbles.filter((bubble) => bubble.type === "tool");

    if (toolkitBubbles.length === 0) return true;

    return toolkitBubbles.every(({ BubbleTool }) => {
        const { toolId, toolkitId, variable, version, input, hasInputs = false } = BubbleTool || {};

        if (!toolId || !toolkitId || !variable || !version) return false;

        if (hasInputs && isEmpty(input)) return false;

        return true;
    });
}
