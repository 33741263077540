import initialState from "./initialState";
import {
    ADD_METRIC_COMPANY_CHANNELS,
    ADD_METRIC_BOT_ACTIVITY,
    ADD_METRIC_TOP_FLOWS,
    ADD_METRIC_UNIQUE_USERS,
    ADD_METRIC_TOP_INTENTS,
    ADD_DAILY_SESSIONS,
    ADD_TODAY_SESSIONS,
    ADD_SESSIONS,
} from "../constants";

export const metrics = function metrics(state = initialState.metrics, action) {
    switch (action.type) {
        case ADD_METRIC_COMPANY_CHANNELS:
            return { ...state, company_channels: action.payload };
        case ADD_METRIC_BOT_ACTIVITY:
            return { ...state, bot_activity: action.payload };
        case ADD_METRIC_TOP_FLOWS:
            return { ...state, top_flows: action.payload };
        case ADD_METRIC_UNIQUE_USERS:
            return { ...state, unique_users: action.payload };
        case ADD_METRIC_TOP_INTENTS:
            return { ...state, top_intents: action.payload };
        case ADD_DAILY_SESSIONS:
            return { ...state, daily_sessions: action.payload };
        case ADD_TODAY_SESSIONS:
            return { ...state, today_sessions: action.payload };
        case ADD_SESSIONS:
            return { ...state, sessions: action.payload };
        default:
            return state;
    }
};

export default metrics;
