import React from "react";

import Icon from "./Icon";

const CloseIcon = (props) => (
    <Icon viewBox="0 0 32 32" className={props.className} width={props.width} height={props.height}>
        <path d="M8 24L24 8M8 8L24 24" stroke={props.stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </Icon>
);

export default CloseIcon;
