import { SET_LOADING, UNSET_LOADING, SET_LOADING_FLOW, UNSET_LOADING_FLOW } from "../constants";
import initialState from "./initialState";

export const isLoading = (state = initialState.isLoading, action) => {
    switch (action.type) {
        case SET_LOADING:
            return true;
        case UNSET_LOADING:
            return false;
        default:
            return state;
    }
};

export const isLoadingFlow = (state = initialState.isLoading, action) => {
    switch (action.type) {
        case SET_LOADING_FLOW:
            return true;
        case UNSET_LOADING_FLOW:
            return false;
        default:
            return state;
    }
};
