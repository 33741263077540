import initialState from "./initialState";
import { ADD_EXPRESSION, ADD_EXPRESSIONS, UPDATE_EXPRESSION, DELETE_EXPRESSION, SET_EXPRESSION_STATE, RESET_EXPRESSION_STATE } from "../constants";
import { updateById, deleteById } from "../helpers";

export const expressions = function expressions(state = initialState.expressions, action) {
    switch (action.type) {
        case ADD_EXPRESSIONS:
            return [...action.payload];
        case ADD_EXPRESSION:
            return [{ ...action.payload }, ...state];
        case UPDATE_EXPRESSION:
            return updateById(state, action.payload);
        case DELETE_EXPRESSION:
            return deleteById(state, action.payload.expressionId);
        default:
            return state;
    }
};

export const expressionState = function expressionState(state = initialState.expressionState, action) {
    switch (action.type) {
        case SET_EXPRESSION_STATE:
            return action.payload;
        case RESET_EXPRESSION_STATE:
            return null;
        default:
            return state;
    }
};
