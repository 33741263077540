import Transactions from "../resources/Transactions";
import { ADD_RESOURCE_REPORT } from "../constants";

const { transaction, reportFile } = Transactions();

export const addResourceReport = (resourceReport) => {
    return {
        type: ADD_RESOURCE_REPORT,
        payload: resourceReport,
    };
};

export function getReportResources(
    pageLimit,
    rows,
    startDate,
    endDate,
    resourceName = null,
    botId = null,
    companyId = null,
    state = null,
    query = null,
    controller
) {
    return function (dispatch) {
        const body = {
            startDate: startDate,
            endDate: endDate,
            companyId: companyId ? companyId.toString() : null,
            botId: botId,
            resourceName: resourceName,
            state: state,
            pag: pageLimit,
            limit: rows,
            query: query,
        };
        return transaction(body, { signal: controller.signal })
            .then(({ data }) => {
                dispatch(addResourceReport(data));

                return data;
            })
            .catch((err) => {
                if (err.message) {
                    if (err.message === "Operation canceled by the user.") {
                        return;
                    }
                } else {
                    return console.error;
                }
            });
    };
}

export function getReportResourcesFile(startDate, endDate, companyId, botId, resourceName, state, query) {
    return function (dispatch, getState) {
        const { companies } = getState();
        let companiesIds = companies.reduce((acc, value) => {
            acc.push(value.id);
            return acc;
        }, []);
        if (companyId) {
            companiesIds = [];
            companiesIds.push(companyId);
        }
        let companiesQuery = companiesIds.join(",");
        const body = {
            startDate,
            endDate,
            companyId: companiesQuery,
            botId,
            resourceName,
            state,
            columns:
                "name:Recurso,referenceId:Id,legalId:Identificación,flowName:Flujo,state:Estado,createdAt:Fecha,companyName:Compañía,botName:Bot,source:Canal,errorResponse:Error",
            query: query,
        };
        return reportFile(body)
            .then(({ data }) => {
                if (data.type === "text/csv") {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const today = new Date().toISOString().slice(0, 10);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `reporte-recursos-${today}.csv`);
                    document.body.appendChild(link);
                    link.click();
                }
            })
            .catch(console.error);
    };
}
