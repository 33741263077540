import React from "react";

import Icon from "./Icon";

const DotsMenu = (props) => (
    <Icon viewBox="0 0 3 17" className={props.className} width={props.width} height={props.height} fill="none">
        <path
            d="M1.474 0.825928C1.92867 0.825928 2.288 0.950594 2.552 1.19993C2.816 1.46393 2.948 1.83059 2.948 2.29993C2.948 2.68126 2.80133 3.01859 2.508 3.31193C2.21467 3.61993 1.87 3.77393 1.474 3.77393C1.034 3.77393 0.674667 3.63459 0.396001 3.35593C0.132001 3.07726 5.19557e-07 2.72526 5.38149e-07 2.29993C5.55458e-07 1.90393 0.146667 1.55926 0.440001 1.26593C0.733334 0.972594 1.078 0.825928 1.474 0.825928Z"
            fill="#727C94"
            fillOpacity="0.5"
        />
        <path
            d="M1.474 6.98163C1.92867 6.98163 2.288 7.1063 2.552 7.35563C2.816 7.61963 2.948 7.9863 2.948 8.45563C2.948 8.83696 2.80133 9.1743 2.508 9.46763C2.21467 9.77563 1.87 9.92963 1.474 9.92963C1.034 9.92963 0.674667 9.7903 0.396 9.51163C0.132 9.23296 2.50482e-07 8.88096 2.69074e-07 8.45563C2.86384e-07 8.05963 0.146667 7.71496 0.44 7.42163C0.733334 7.1283 1.078 6.98163 1.474 6.98163Z"
            fill="#727C94"
            fillOpacity="0.5"
        />
        <path
            d="M1.474 13.1373C1.92867 13.1373 2.288 13.262 2.552 13.5113C2.816 13.7753 2.948 14.142 2.948 14.6113C2.948 14.9927 2.80133 15.33 2.508 15.6233C2.21467 15.9313 1.87 16.0853 1.474 16.0853C1.034 16.0853 0.674667 15.946 0.396 15.6673C0.132 15.3887 -1.85919e-08 15.0367 0 14.6113C1.73097e-08 14.2153 0.146667 13.8707 0.44 13.5773C0.733333 13.284 1.078 13.1373 1.474 13.1373Z"
            fill="#727C94"
            fillOpacity="0.5"
        />
    </Icon>
);
export default DotsMenu;
