import { DELETE, ADD_RANDOM, UPDATE_RANDOM, DELETE_RANDOMS, SET_UNSAVED_CHANGES } from "../constants";

export function addRandom(random) {
    return function (dispatch) {
        dispatch({
            type: ADD_RANDOM,
            payload: random,
        });
        dispatch({
            type: SET_UNSAVED_CHANGES,
        });
    };
}

export function updateRandom(random) {
    return function (dispatch) {
        dispatch({
            type: UPDATE_RANDOM,
            payload: random,
        });

        dispatch({
            type: SET_UNSAVED_CHANGES,
        });
    };
}

export const deleteRandoms = (randoms) => ({
    type: DELETE_RANDOMS,
    payload: randoms,
});

export function deleteRandom(random) {
    return function (dispatch) {
        dispatch({
            type: UPDATE_RANDOM,
            payload: { ...random, action: DELETE },
        });

        dispatch({
            type: SET_UNSAVED_CHANGES,
        });
    };
}
