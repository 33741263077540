import { useState, useEffect } from "react";
import Validator from "validatorjs";

export default function (data, rules) {
    const [isValid, setIsValid] = useState(false);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        let validation = new Validator(data, rules);

        setIsValid(validation.passes());
        setErrors(validation.errors);
    }, [data]);

    return [isValid, errors];
}
