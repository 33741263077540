import initialState from "./initialState";
import { mergeById } from "../helpers";
import { ADD_BUBBLE_RESOURCE, ADD_BUBBLE_RESOURCES, UPDATE_BUBBLE_RESOURCE, UPDATE_BUBBLE_RESOURCES } from "../constants";

function bubbleResources(state = initialState.bubbleResources, action) {
    switch (action.type) {
        case ADD_BUBBLE_RESOURCES:
            return [...action.payload];
        case ADD_BUBBLE_RESOURCE:
            return [...state, action.payload];
        case UPDATE_BUBBLE_RESOURCE:
            return mergeById(state, action.payload, "bubbleId");
        case UPDATE_BUBBLE_RESOURCES:
            return mergeById(state, action.payload, "bubbleId");
        default:
            return state;
    }
}

export default bubbleResources;
