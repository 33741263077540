import initialState from "./initialState";
import { SET_BOT, UNSET_BOT } from "../constants";

export const bot = function bot(state = initialState.bot, action) {
    switch (action.type) {
        case SET_BOT:
            return { ...action.payload };
        case UNSET_BOT:
            return {};
        default:
            return state;
    }
};

export default bot;
