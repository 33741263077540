import Slider from "react-slick";
import shortid from "shortid";
import toUpper from "lodash/toUpper";
import get from "lodash/get";

import Element from "./components/Element";
import { NEW, MAX_ELEMENTS } from "../../../../constants";

import PlusIcon from "../../../icons/PlusIcon";
import LeftIcon from "../../../icons/LeftIcon";
import RightIcon from "../../../icons/RightIcon";
import { useTranslation } from "react-i18next";
import BubbleRoot from "../BubbleRoot";

const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: true,
    swipe: true,
    adaptiveHeight: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    //initialSlide: initialSlide > 3 ? initialSlide - 1 : initialSlide
};

const arrowStyle = "absolute top-0 right-0 h-8 w-6 flex items-center justify-center cursor-pointer text-blue-light hover:text-primary-200 -mt-9 z-10";
const arrowIconStyles = "fill-current";
const arrowSize = "18px";
let extraSliderHeight = "";

function NextArrow(props) {
    const { onClick } = props;
    return (
        <div className={arrowStyle} onClick={onClick}>
            <RightIcon className={arrowIconStyles} width={arrowSize} height={arrowSize} />
        </div>
    );
}

function PrevArrow(props) {
    const { onClick } = props;
    return (
        <div className={`${arrowStyle} mr-8`} onClick={onClick}>
            <LeftIcon className={arrowIconStyles} width={arrowSize} height={arrowSize} />
        </div>
    );
}

function addElement(props) {
    const mockElement = { id: shortid.generate(), imageUrl: null, subtitle: "", title: "", bubbleId: props.bubble.id, action: NEW };
    props.addElement(mockElement);
}

function customAdaptativeHeight(evt, add) {
    const addatativeVale = 20;
    add ? (extraSliderHeight = addatativeVale * evt) : (extraSliderHeight = addatativeVale * evt * -1);
}
const GenericBubble = (props) => {
    const { bot, elements } = props;

    const type = toUpper(get(bot, "type", ""));
    const canAdd = elements.size < MAX_ELEMENTS[type];
    const { t } = useTranslation();

    function renderSlides(element) {
        return (
            <Element
                element={element}
                flows={props.flows}
                intents={props.intents}
                setFlow={props.setFlow}
                options={props.options}
                addOption={props.addOption}
                updateOption={props.updateOption}
                deleteOption={props.deleteOption}
                deleteElement={props.deleteElement}
                updateElement={props.updateElement}
                bot={props.bot}
                flow={props.flow}
                uploadImage={props.uploadImage}
                customAdaptativeHeight={customAdaptativeHeight}
                buttonCounter={props.buttonCounter}
                TotalButtons={props.buttons}
                setButtonCreated={props.setButtonCreated}
                bubbleStyles={props.bubbleStyles}
            />
        );
    }

    return (
        <BubbleRoot {...props} bubbleStyles={props.bubbleStyles}>
            <div className="flex flex-col">
                <button
                    className="mb-2 flex h-9 w-fit items-center justify-center rounded-8 bg-white px-2 text-center text-sm font-medium text-primary-200 shadow-element"
                    disabled={!canAdd}
                    onClick={() => addElement(props)}>
                    <PlusIcon className="fill-current text-primary-200" width="18px" height="18px" />
                    {t("common.Agregar")}
                </button>

                <div className="relative">
                    <Slider className="slider-size" {...settings}>
                        {elements.map((element) => {
                            return <div className="relative mr-6">{renderSlides(element)}</div>;
                        })}
                    </Slider>
                </div>
            </div>
        </BubbleRoot>
    );
};

export default GenericBubble;
