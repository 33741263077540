import Http from "../services/Http";
import { deleteFlow } from "./flow";
import { unsetLoading, setLoading } from "./isLoading";
import { getIntents, loadingIntents } from "./intents";
import {
    SET_BOT,
    UNSET_BOT,
    DELETE_FLOWS,
    DELETE_INTENTS,
    DELETE_ALL_INPUTS,
    DELETE_ALL_BUBBLES,
    DELETE_ALL_OPTIONS,
    DELETE_ALL_RANDOMS,
    DELETE_ALL_CONTACTS,
    DELETE_ALL_RESOURCES,
    DELETE_ALL_SANDBOXES,
    UNSET_UNSAVED_CHANGES,
    DELETE_ALL_ATTACHMENTS,
    DELETE_ALL_PMA_BUBBLES,
    DELETE_ALL_CONDITIONALS,
    DELETE_ALL_QUICK_REPLIES,
} from "../constants";

import get from "lodash/get";
import toUpper from "lodash/toUpper";
import { getJustResources } from "./resources";

export function setBot(bot) {
    return function (dispatch) {
        dispatch({
            type: SET_BOT,
            payload: bot,
        });
        dispatch({ type: UNSET_UNSAVED_CHANGES });
    };
}

export function getBot(botId) {
    return function (dispatch) {
        dispatch(loadingIntents(true));
        dispatch(setLoading());
        return Http.get(`/bots/${botId}`)
            .then(({ data }) => {
                const providerName = get(data, "properties.provider.name");

                if (providerName !== undefined && toUpper(get(data, "type", "")) === "WHATSAPP") {
                    data.type = providerName;
                }

                if (toUpper(get(data, "type", "")) === "TWITTER") {
                    data.type = get(data, "channelCredentials.name", "TWITTER");
                }

                data.slug = get(data, "Company.slug", "");
                data.companySlug = get(data, "Company.slug", "");
                dispatch(getIntents(data));
                dispatch(setBot(data));
                dispatch(getJustResources(get(data, "companyId", "")));
            })
            .catch((error) => {
                dispatch(loadingIntents(false));
                dispatch(unsetLoading());

                console.log(`error on getBot with id: ${botId}`, error);
            });
    };
}

export function unsetBot() {
    return function (dispatch) {
        dispatch({
            type: UNSET_BOT,
        });
        dispatch(deleteFlow());
        dispatch({ type: DELETE_INTENTS });
        dispatch({ type: DELETE_FLOWS });
        dispatch({ type: DELETE_ALL_BUBBLES });
        dispatch({ type: DELETE_ALL_OPTIONS });
        dispatch({ type: DELETE_ALL_RANDOMS });
        dispatch({ type: DELETE_ALL_ATTACHMENTS });
        dispatch({ type: DELETE_ALL_INPUTS });
        dispatch({ type: DELETE_ALL_CONDITIONALS });
        dispatch({ type: DELETE_ALL_RESOURCES });
        dispatch({ type: DELETE_ALL_QUICK_REPLIES });
        dispatch({ type: DELETE_ALL_SANDBOXES });
        dispatch({ type: DELETE_ALL_CONTACTS });
        dispatch({ type: DELETE_ALL_PMA_BUBBLES });
    };
}
