import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import toUpper from "lodash/toUpper";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import DropdownMenu from "./DropdownMenu";
import { Modal } from "./GenericModal";
// import JelouIcon from "../icons/JelouIcon";
import ReconnectModal from "./ReconnectModal";
import BackArrowIcon from "../icons/BackArrowIcon";
import PlayIcon from "../icons/PlayIcon";
import { useState } from "react";
import CloseIcon from "../icons/CloseIcon";
import { setIsOpenBotTester } from "../../actions/testBot";
import { hasPermission } from "../../utils/utils";

// import BotOptions from "./BotOptions";

// import { hasPermission } from "../../utils/utils";

let NO_AUTH_ROUTES = ["/login", "/login/", "/recover-password/", "/recover-password"];

const Header = () => {
    const unSavedChanges = useSelector((state) => state.unSavedChanges);
    const unauthorized = useSelector((state) => state.unauthorized);
    const permissions = useSelector((state) => state.permissions);
    const bot = useSelector((state) => state.bot);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // const botId = !isEmpty(bot) ? bot.id : localStorage.getItem("botId");

    const renderName = !isEmpty(bot) && (window.location.pathname.includes("/flows") || window.location.pathname.includes("/resources"));

    const botName = get(bot, "name", "");
    const botCompanyName = get(bot, "Company.name", "");
    const savingChanges = useSelector((state) => state.savingChanges);

    const [showWarningModal, setShowWarningModal] = useState(false);
    const canCreateExpression = toUpper(get(bot, "properties.nlpDriver", "")) !== "DATABASE";

    const handleGoToRoot = () => {
        if (unSavedChanges || savingChanges) {
            setShowWarningModal(true);
        } else {
            navigate("/");
        }
    };

    const showTab = (permission) => hasPermission(permissions, permission);

    // const optionStyle = "text-gray-400 text-sm flex font-medium items-center";
    // const segmentation =
    //     "flex group h-full text-center border-b-4 border-transparent hover:border-b-4 hover:border-primary-200 h-full group flex items-center min-w-28 justify-center cursor-pointer";

    // const activeClassName = "flex group h-full border-b-4 border-primary-200 min-w-28 justify-center cursor-pointer";

    return (
        <>
            {!NO_AUTH_ROUTES.includes(window.location.pathname) && !window.location.pathname.includes("new-password") && unauthorized && (
                <ReconnectModal />
            )}
            {savingChanges && <div className="fixed left-0 top-0 z-200 h-screen w-screen"></div>}
            <div className="fixed left-0 top-0 z-20 flex h-[3.25rem] w-full items-center justify-between border-b border-[#DCDEE4] bg-white">
                <div className="flex h-full items-center space-x-6">
                    {renderName ? (
                        <div className="flex h-full w-[30rem] min-w-[30rem] items-center">
                            {/* <div className="pl-6">
                                <svg width="26" height="35" viewBox="0 0 26 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="13" cy="4.75" r="4.5" fill="#00B3C7" />
                                    <path
                                        d="M25 9.75H18.5V21.75C18.5 25.25 15.4591 26.75 12.5 26.75C8.9 26.75 7.33333 23.0833 7 21.25H0C0 27.75 6 33.75 12.5 33.75C19 33.75 24.5 28.75 25 23.25C25.3621 19.2664 25 12.75 25 9.75Z"
                                        fill="#00B3C7"
                                    />
                                </svg>
                            </div> */}
                            <button className="ml-0 flex h-full items-center bg-white pl-4 text-base text-gray-500" onClick={handleGoToRoot}>
                                <div className="flex items-center text-left">
                                    <BackArrowIcon width="0.375rem" height="0.5625rem" className="mr-3" />
                                    <span className="mr-2 text-gray-400">{botCompanyName}</span> {" / "}
                                    <span className="ml-2 font-bold text-primary-200">{botName}</span>
                                </div>
                            </button>
                        </div>
                    ) : (
                        <NavLink to="/" className="min-w-[30rem] pl-6">
                            <svg width="26" height="35" viewBox="0 0 26 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="13" cy="4.75" r="4.5" fill="#00B3C7" />
                                <path
                                    d="M25 9.75H18.5V21.75C18.5 25.25 15.4591 26.75 12.5 26.75C8.9 26.75 7.33333 23.0833 7 21.25H0C0 27.75 6 33.75 12.5 33.75C19 33.75 24.5 28.75 25 23.25C25.3621 19.2664 25 12.75 25 9.75Z"
                                    fill="#00B3C7"
                                />
                            </svg>
                        </NavLink>
                    )}
                </div>
                <div className="flex items-center pr-3">
                    {renderName && showTab("view flows") && canCreateExpression && (
                        <button
                            className="mr-4 flex h-[2.125rem] items-center rounded-full border border-primary-200 px-4 py-1 hover:bg-[#E6F7F9]"
                            onClick={() => dispatch(setIsOpenBotTester(true))}>
                            <PlayIcon width="20" height="20" className="mr-px" />
                            <span className="text-sm font-semibold text-primary-200">{t("common.Probar vocablos")}</span>
                        </button>
                    )}
                    <DropdownMenu />
                </div>
            </div>
            <Modal
                openModal={showWarningModal}
                closeModal={() => null}
                title={t("Tienes cambios sin guardar")}
                className="h-min w-96 rounded-20 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)]">
                <div className="h-full flex-row">
                    <header className="relative flex items-center justify-between bg-primary-350 px-10 py-5">
                        <div className="flex items-center gap-x-4">
                            <div className="text-base font-semibold text-primary-200">{"Tienes cambios sin guardar"}</div>
                        </div>
                        <button className="absolute inset-y-0 right-0 mr-3" onClick={() => setShowWarningModal(false)}>
                            <CloseIcon width="32px" height="32px" className="stroke-current text-primary-200" />
                        </button>
                    </header>
                    <div className="px-10 py-6">
                        <p className="text-sm font-medium text-gray-400">{t("¿Estás seguro que deseas continuar?")}</p>

                        <footer className="mb-0 mt-6 flex items-center justify-end gap-x-3">
                            <button
                                type="reset"
                                onClick={() => setShowWarningModal(false)}
                                className="h-9 w-28 rounded-3xl bg-gray-10 font-bold text-gray-400">
                                Omitir
                            </button>
                            <a
                                href="/"
                                className="flex h-9 w-28 min-w-fit items-center justify-center rounded-3xl bg-primary-200 px-5 font-bold text-white">
                                {t("common.Sí")}
                            </a>
                        </footer>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Header;
