import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { updateElement } from "../../../../../actions/elements";

import uniqid from "uniqid";
import get from "lodash/get";
import toLower from "lodash/toLower";
import { PulseLoader } from "react-spinners";
import TextareaAutosize from "react-autosize-textarea";

import Modal from "../../../../common/Modal";
import BubbleSettings from "../../BubbleSettings";

import Http from "../../../../../services/Http";
import TrashIcon from "../../../../icons/TrashIcon";
import BlocksIcon from "../../../../icons/BlocksIcon";
// import SettingsIcon from "../../../../icons/SettingsIcon";
import { DELETE, UPDATE, NEW } from "../../../../../constants";
// import CanRenderOption from "../../../../common/CanRenderOption";
import Buttons from "../../TextBubble/components/Buttons";
import AddButton from "../../TextBubble/components/AddButton";
import ImageEmptyIcon from "../../../../icons/ImageEmptyIcon";

const getElementOptions = (options, elementId) => {
    return options.filter((option) => option.optionableId === elementId && option.optionable === "Element" && option.action !== DELETE);
};

const inputStyle = "form-input";

const Element = (props) => {
    const { options, element } = props;
    const { t } = useTranslation();
    const [buttonsCreated, setButtonsCreated] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const dispatch = useDispatch();

    const elementId = get(element, "id", null);
    const totalOptions = getElementOptions(options, elementId);

    useEffect(() => {
        setButtonsCreated(totalOptions.length);
    }, [options, element]);

    const buttonCounter = (number) => {
        setButtonsCreated((prevState) => {
            return prevState + number;
        });
    };

    // Update image
    const handleLoadLocalFile = async (event) => {
        event.preventDefault();
        const { files } = event.target;
        const image = files[0];

        setUploading(true);

        const formData = new FormData();
        formData.append("image", image);
        const botId = props.bot.id;
        let intent = props.flow.intent;
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        let fileName = image.name.replace(/ /g, "_");
        fileName = uniqid() + `-${fileName}`;
        const path = toLower(`${botId}/flows/${intent}/images/${fileName}`);
        formData.append("path", path);
        Http.post(`/images/upload`, formData, config)
            .then(({ data }) => {
                dispatch(
                    updateElement({
                        ...element,
                        imageUrl: data,
                        action: element.action === NEW ? NEW : UPDATE,
                    })
                );
                setUploading(false);
            })
            .catch((error) => {
                console.log(error);
                setUploading(false);
            });
    };

    // Delete image

    const handleChange = ({ target }) => {
        const value = target.value;
        const name = target.name;

        dispatch(
            updateElement({
                ...element,
                [name]: value,
                action: element.action === NEW ? NEW : UPDATE,
            })
        );
    };

    function toggleIsOpen() {
        setIsOpen(!isOpen);
    }

    let url = get(element, "imageUrl", "");

    return (
        <div className={`${props.bubbleStyles} relative`}>
            <button
                className="absolute -right-[0.75rem] top-0 flex items-center justify-center rounded-md bg-white p-1 text-gray-400 shadow-element hover:bg-red hover:text-white"
                onClick={() => props.deleteElement(element)}>
                <TrashIcon className="fill-current" width="16px" height="16px" />
            </button>
            <div className="flex items-center rounded-t-xl bg-white p-3">
                <BlocksIcon className="-my-2 mx-2 fill-current text-gray-400" width="30" />
                <span className="font-medium text-gray-400">{t("bubbles.Carrusel")}</span>
            </div>
            <div className="bg-gray-13">
                <div className="my-3 flex flex-col items-start">
                    <div className="flex w-full flex-col">
                        <div className="relative flex-1 rounded-lg">
                            <div className="w-full space-y-3 px-3">
                                {uploading ? (
                                    <div className="flex h-32 min-h-[202px] items-center justify-center overflow-hidden rounded-lg bg-[#E2E2E2]">
                                        <PulseLoader color="#00b3c7" />
                                    </div>
                                ) : (
                                    <label
                                        id={element.id}
                                        className={`${
                                            !url && "min-h-[202px] bg-[#E2E2E2]"
                                        } flex w-full cursor-pointer items-center justify-center overflow-hidden rounded-lg`}>
                                        {url ? (
                                            <img src={url} alt={url} className="image-bubble h-full rounded-md object-cover" />
                                        ) : (
                                            <ImageEmptyIcon width="116" height="112" />
                                        )}
                                        <input
                                            type="file"
                                            name="imageUrl"
                                            className="hidden"
                                            accept="image/*"
                                            onChange={(event) => handleLoadLocalFile(event)}
                                            id={element.id}
                                        />
                                    </label>
                                )}
                                <div className="grid gap-2 rounded-lg bg-white px-3 py-2 text-sm font-semibold text-gray-400 text-opacity-75">
                                    <label>{t("bubbles.Título")}</label>
                                    <input
                                        type="text"
                                        name="title"
                                        placeholder={t("bubbles.Título")}
                                        onChange={handleChange}
                                        defaultValue={element.title}
                                        className={inputStyle}
                                    />

                                    <label>
                                        {t("bubbles.Subtítulo")}
                                        <TextareaAutosize
                                            className={inputStyle + " pt-2"}
                                            style={{ minHeight: 60 }}
                                            placeholder={t("bubbles.Subtítulo")}
                                            name="subtitle"
                                            onChange={handleChange}
                                            defaultValue={element.subtitle}
                                            async={true}
                                        />
                                    </label>
                                </div>
                            </div>
                            <Buttons
                                buttons={totalOptions}
                                intents={props.intents}
                                setFlow={props.setFlow}
                                updateOption={props.updateOption}
                                deleteOption={props.deleteOption}
                                flows={props.flows}
                                bubble={props.element}
                                bot={props.bot}
                                customAdaptativeHeight={props.customAdaptativeHeight}
                                buttonCounter={buttonCounter}
                            />
                        </div>

                        <AddButton
                            addOption={props.addOption}
                            optionable="Element"
                            bubble={props.element}
                            flows={props.flows}
                            botType={props.bot.type}
                            buttonCounter={buttonCounter}
                            TotalButtons={buttonsCreated}
                        />

                        {/* <CanRenderOption option="view generic elements settings">
                            <div className="flex w-full justify-end px-3">
                                <div
                                    className="mt-1 flex w-7 cursor-pointer items-center justify-center rounded-lg bg-white py-2 text-gray-450 shadow-element hover:bg-primary-200 hover:text-white"
                                    onClick={toggleIsOpen}>
                                    <SettingsIcon className="fill-current" width="15px" height="15px" />
                                </div>
                            </div>
                        </CanRenderOption> */}
                    </div>
                </div>
            </div>
            <Modal
                titleModal={t("bubbles.Ajustes")}
                textButtonSecondary={t("common.Cancelar")}
                textButtonPrimary={t("common.Guardar")}
                closeModal={toggleIsOpen}
                isShowModal={isOpen}
                handleClickPrimaryButton={toggleIsOpen}
                minHeight="min-h-[11rem]"
                showButtons>
                <BubbleSettings updateBubble={updateElement} bubble={element} />
            </Modal>
        </div>
        //         <Modal
        //             title={t("Ajustes")}
        //             cancelButton={true}
        //             loading={false}
        //             acceptText={t("Guardar")}
        //             showModal={this.state.settingsOpen}
        //             onLoadingText={t("Agregando...")}
        //             onClose={this.setSettings}
        //             onConfirm={this.setSettings}>
        //             <BubbleSettings updateBubble={this.props.updateElement} bubble={this.props.element} />
        //         </Modal>
    );
};

export default Element;
