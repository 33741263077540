import get from "lodash/get";
import {
    ADD_ERRORS,
    ADD_RESOURCE,
    ADD_RESOURCES,
    UPDATE_RESOURCE,
    SAVING_RESOURCE,
    DELETE_RESOURCE,
    ResourceTemplate,
    RESET_RESOURCE_STATE,
    TOGGLE_ADDING_RESOURCE,
    ADD_RESOURCES_LIGTH,
} from "../constants";
import Http, { HttpV2 } from "../services/Http";

export const updateResource = (resource) => ({
    type: UPDATE_RESOURCE,
    payload: resource,
});

export const toggleAddingResource = () => ({
    type: TOGGLE_ADDING_RESOURCE,
});

/**
 * Get Flow from server
 * If response is null creates a flow to be saved later
 * @param {Object} intent
 */
export function getResources(companyId) {
    return function (dispatch, getState) {
        let id = -1;
        if (companyId) {
            id = companyId;
        } else {
            const { user } = getState();
            id = user.companyId;
        }
        return Http.get(`/resources/${id}`)
            .then(({ data }) => {
                // Parse JSON for spaces
                const resources = data.map((resource) => {
                    return {
                        ...resource,
                        response: JSON.stringify(resource.response, null, "\t"),
                    };
                });
                dispatch({
                    type: ADD_RESOURCES,
                    payload: resources,
                });
                return resources;
            })
            .catch((err) => {
                console.log("====== err", err);
                dispatch({
                    type: ADD_ERRORS,
                    payload: err.response.data.errors,
                });
            });
    };
}

export function getJustResources(companyId) {
    return function (dispatch) {
        return HttpV2.get(`/resources/${companyId}`)
            .then(({ data }) => {
                dispatch({
                    type: ADD_RESOURCES_LIGTH,
                    payload: get(data, "data", []),
                });
                return get(data, "data", []);
            })
            .catch((err) => {
                console.log("====== err", err);
                dispatch({
                    type: ADD_ERRORS,
                    payload: err.response.data.errors,
                });
            });
    };
}
export function getResourceResponseById(resourceId) {
    return function (dispatch) {
        return HttpV2.get(`/resources/response/${resourceId}`)
            .then(({ data }) => {
                return get(data, "data", []);
            })
            .catch((err) => {
                console.log("====== err", err);
                dispatch({
                    type: ADD_ERRORS,
                    payload: err.response.data.errors,
                });
            });
    };
}

export function createResource(name, companyId) {
    return function (dispatch) {
        const response = ResourceTemplate;
        return Http.post(`/resources/${companyId}`, { name, response })
            .then(({ data }) => {
                dispatch({
                    type: ADD_RESOURCE,
                    payload: {
                        ...data,
                        companyId: parseInt(data.companyId, 10),
                        response: JSON.stringify(data.response, null, "\t"),
                    },
                });
            })
            .catch((err) => {
                console.log("====== err", err);
                dispatch({
                    type: ADD_ERRORS,
                    payload: err.response.data.errors,
                });
            });
    };
}

export function saveResource(resource) {
    return function (dispatch) {
        dispatch({
            type: SAVING_RESOURCE,
        });
        return Http.put(`/resources/${resource.id}`, resource)
            .then(() => {
                dispatch({
                    type: RESET_RESOURCE_STATE,
                });
                dispatch(updateResource(resource));
            })
            .catch((err) => {
                console.log("====== err", err);
                dispatch({
                    type: ADD_ERRORS,
                    payload: err.response.data.errors,
                });
            });
    };
}

export function deleteResource(resource) {
    return function (dispatch) {
        return Http.delete(`/resources/${resource.id}`)
            .then(() => {
                dispatch({
                    type: DELETE_RESOURCE,
                    payload: resource.id,
                });
            })
            .catch((err) => {
                console.log("====== err", err);
                dispatch({
                    type: ADD_ERRORS,
                    payload: err.response.data.errors,
                });
            });
    };
}
