import get from "lodash/get";
import BubbleOptions from "./BubbleOptions";

const BubbleContainer = (props) => {
    const {
        bubble,
        deleteBubble,
        dragHandleProps,
        updateBubble,
        buttonCounter,
        bot,
        bubbleStyles,
        children,
        icon,
        title,
        openModal = undefined,
        className,
        canOverflow = false,
    } = props;
    return (
        <div className={className ? className : "relative mb-8 flex items-start"}>
            <BubbleOptions
                {...props}
                bubble={bubble}
                deleteBubble={deleteBubble}
                dragHandleProps={dragHandleProps}
                updateBubble={updateBubble}
                buttonCounter={buttonCounter}
                channel={get(bot, "type", "")}
                input={props.input}
            />
            <div className={`${bubbleStyles} ${canOverflow && "overflow-hidden"}`}>
                <div className={`flex items-center rounded-t-xl bg-white p-3 ${openModal && "cursor-pointer"}`} onClick={openModal}>
                    {icon}
                    <span className="font-medium text-gray-400">{title}</span>
                </div>
                <div className="bg-gray-13">{children}</div>
            </div>
        </div>
    );
};
export default BubbleContainer;
