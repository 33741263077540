import includes from "lodash/includes";
import { useSelector } from "react-redux";
import ReconnectModal from "./ReconnectModal";

const CanRenderOption = (props) => {
    const { option, children } = props;
    const permissions = useSelector((state) => state.permissions);
    const unauthorized = useSelector((state) => state.unauthorized);

    const hasPermission = (option) => {
        return permissions && includes(permissions, option);
    };

    return (
        <>
            {unauthorized && <ReconnectModal />}
            {hasPermission(option) ? children : null}
        </>
    );
};

export default CanRenderOption;
