import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Menu, Transition } from "@headlessui/react";

import CanRenderOption from "../../common/CanRenderOption";
import DotsMenu from "../../icons/DotsMenu";
import Tippy from "@tippyjs/react";

const IntentActions = (props) => {
    const { t } = useTranslation();
    const { openMenu, setOpen, intent, editMode, handleDelete, isDefault, setToDefault, canEditIntent } = props;

    return (
        <Menu as="div" className="relative">
            <div>
                <Menu.Button onClick={() => setOpen(!openMenu)}>
                    <DotsMenu
                        width="0.1875rem"
                        height="1.0625rem"
                        className="mr-3 fill-current text-gray-400 hover:text-gray-500 group-hover:font-medium group-hover:text-gray-500"
                    />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                show={openMenu}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="divide-y-1 absolute right-0 z-40 mt-2 w-40 scale-100 transform divide-gray-400/10 overflow-hidden rounded-lg bg-white text-gray-400 opacity-100 shadow-lg focus:outline-none">
                    <CanRenderOption option="update flows">
                        <Tippy
                            content={!canEditIntent ? t("flows.Esta base de conocimiento no se puede editar") : ""}
                            theme="jelou"
                            arrow={false}
                            placement={"bottom"}
                            touch={false}>
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        onClick={() => editMode(intent)}
                                        disabled={!canEditIntent}
                                        className={`group relative flex w-full cursor-pointer items-center space-x-3 rounded-t-lg px-3 py-2 text-sm text-gray-400 disabled:cursor-not-allowed
                                            disabled:text-gray-350 ${active ? " hover:bg-[#E5F7F9]" : ""}`}>
                                        <span className="flex w-full items-center text-left text-sm">{t("common.Editar")}</span>
                                    </button>
                                )}
                            </Menu.Item>
                        </Tippy>
                    </CanRenderOption>

                    <CanRenderOption option="delete flows">
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    onClick={() => handleDelete(intent)}
                                    className={`group relative flex w-full cursor-pointer items-center space-x-3 rounded-t-lg px-3 py-2 text-sm text-gray-400 ${
                                        active ? " hover:bg-[#E5F7F9]" : ""
                                    }`}>
                                    <span className="flex w-full items-center text-left text-sm">{t("common.Borrar")}</span>
                                </button>
                            )}
                        </Menu.Item>
                    </CanRenderOption>
                    {!isDefault && (
                        <CanRenderOption option="delete flows">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        onClick={() => setToDefault(intent)}
                                        className={`group relative flex w-full cursor-pointer items-center space-x-3 rounded-t-lg px-3 py-2 text-sm text-gray-400 ${
                                            active ? " hover:bg-[#E5F7F9]" : ""
                                        }`}>
                                        <span className="flex w-full items-center text-left text-sm">{t("common.Por defecto")}</span>
                                    </button>
                                )}
                            </Menu.Item>
                        </CanRenderOption>
                    )}
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

export default IntentActions;
