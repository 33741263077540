import { useState, useRef } from "react";
// import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import get from "lodash/get";
import orderBy from "lodash/orderBy";
import isEmpty from "lodash/isEmpty";
import toUpper from "lodash/toUpper";

import useOnClickOutside from "../../../../../hooks/useOnClickOutside";
import useValidateObject from "../../../../../hooks/useValidateObject";
import { NEW, UPDATE } from "../../../../../constants";

/* Components */
import FlowIcon from "../../../../icons/FlowIcon";
import SlideOver from "../../../../common/SlideOver";
import SettingsIcon from "../../../../icons/SettingsIcon";
import FormCombobox from "../../../../common/FormCombobox";
import DeleteOption from "../../TextBubble/components/DeleteButton";
import FlowButtonSettings from "../../TextBubble/components/FlowButtonSettings";
import CanRenderOption from "../../../../common/CanRenderOption";

/**
 * Get validations base on bot types: Facebook, Twitter
 * @param {String} type
 */
function getRulesByType(type) {
    switch (toUpper(type)) {
        case "TWITTER":
        case "TWITTER_WS":
            return "required|min:1|max:36";
        case "FACEBOOK":
            return "required|min:1|max:20";
        case "INSTAGRAM":
            return "required|min:1|max:36";
        case "WEB":
            return "required|min:1|max:20";
        case "GUPSHUP":
            return "required|min:1|max:20";
        case "ALDEAMO":
        case "WHATSAPP_CLOUD":
            return "required|min:1|max:20";
        case "SMOOCH":
            return "required|min:1|max:20";
        default:
            break;
    }
}

function buildClasses(isValid) {
    const borderStyle = `${!isValid ? "border-error" : "border-primary-200"}`;
    const titleStyle = `${!isValid ? "text-error" : "text-primary-200"}`;
    const formInputStyle = `${!isValid ? "!border-solid !border-2 border-error" : "border-transparent"}`;

    return {
        titleStyle,
        borderStyle,
        formInputStyle,
    };
}

const QuickReply = (props) => {
    const ref = useRef();
    const { quickReply, updateQuickReply, deleteQuickReply, flows, type, setFlow } = props;
    const currentFlow = flows.find((flow) => flow.id === get(quickReply, "flowId", get(quickReply, "payload.flowId", "")));
    const [isOpen, setIsOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [toFlow, setToFlow] = useState(currentFlow || "");
    const { t } = useTranslation();

    // const dispatch = useDispatch();

    useOnClickOutside(ref, () => setIsOpen(false));

    const [isValid] = useValidateObject(quickReply, { title: getRulesByType(type) });

    const { titleStyle, borderStyle, formInputStyle } = buildClasses(isValid);

    function closeModal() {
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true);
    }

    const handleSelectChange = ({ id }) => {
        const payload = {
            ...quickReply.payload,
            type: "flow",
            flowId: id,
        };

        updateQuickReply({
            ...quickReply,
            flowId: id,
            payload,
            action: quickReply.action === NEW ? NEW : UPDATE,
        });
    };

    const handleFlowChange = (flow) => {
        setToFlow(flow);
    };

    const handleGoToFlow = () => {
        const intent = props.intents.find((intent) => intent.slug === toFlow.intent);
        setFlow(intent);
    };

    const handleChange = ({ target }) => {
        let value = target.value;
        let name = target.name;

        // Construct postback
        updateQuickReply({
            ...quickReply,
            [name]: value,
            action: quickReply.action === NEW ? NEW : UPDATE,
        });
    };

    let flows_intents = [];
    flows_intents = flows;

    const flowOptions = flows_intents.map((flow) => {
        return {
            ...flow,
            id: flow.id,
            name: flow.title,
        };
    });

    return (
        <div>
            <SlideOver
                isOpen={isOpen}
                title={t(`common.botonName`, { botonName: !isEmpty(quickReply.title) ? quickReply.title : "" })}
                closeModal={closeModal}>
                <div className="w-full px-5 py-4">
                    <div className="grid gap-4 text-sm font-semibold text-gray-400 text-opacity-75">
                        <label htmlFor="title" className="flex flex-col">
                            {t("common.Nombre")}
                            <input
                                autoFocus
                                type="text"
                                name="title"
                                value={quickReply.title}
                                onChange={handleChange}
                                className={`form-input ${formInputStyle}`}
                            />
                        </label>
                        <label htmlFor="flujo" className="flex flex-col">
                            {t("flows.Flujo")}
                            <FormCombobox
                                options={orderBy(flowOptions, ["name"], ["asc"])}
                                value={flowOptions.find((flow) => flow.id === get(quickReply, "flowId", get(quickReply, "payload.flowId", "")))}
                                handleChange={(evt) => {
                                    handleSelectChange(evt);
                                    handleFlowChange(evt);
                                }}
                                name={t("flows.Flujo")}
                                background={"#fff"}
                                hasCleanFilter={false}
                            />
                        </label>
                    </div>

                    <div className="mt-3 flex justify-end">
                        <div>
                            <button
                                className="mr-1 flex items-center justify-center rounded-lg border border-input bg-white px-2 py-1 text-primary-200 disabled:cursor-not-allowed disabled:text-grey-100"
                                onClick={handleGoToFlow}
                                disabled={isEmpty(toFlow)}>
                                <div className="flex items-center justify-center">
                                    <FlowIcon className="fill-current" width="16px" height="16px" />
                                </div>
                            </button>
                        </div>
                        <CanRenderOption option="view qr settings">
                            <button
                                className="flex cursor-pointer items-center justify-center rounded-lg border border-input bg-white px-2 py-1 text-primary-200"
                                onClick={() => setShow(!show)}>
                                <SettingsIcon className="fill-current" width="16px" height="16px" />
                            </button>
                        </CanRenderOption>
                    </div>
                </div>
                <FlowButtonSettings show={show} updateOption={updateQuickReply} option={quickReply} />
            </SlideOver>

            <div
                onClick={openModal}
                onMouseEnter={() => setShowDelete(true)}
                onMouseLeave={() => setShowDelete(false)}
                className={`relative flex h-9 cursor-pointer flex-col items-center justify-center rounded-full border-2 bg-white px-6 text-center shadow-input ${titleStyle} ${
                    isOpen ? borderStyle : "border-transparent"
                }`}>
                <span className="text-center font-sans text-sm font-bold leading-normal">{quickReply.title || t("common.Nombre del botón")}</span>
                {showDelete && <DeleteOption option={quickReply} deleteOption={deleteQuickReply} buttonCounter={props.buttonCounter} />}
            </div>
        </div>
    );
};

export default QuickReply;
