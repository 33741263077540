import initialState from "./initialState";
import { ADD_OPERATORS } from "../constants";

function operators(state = initialState.operators, action) {
    switch (action.type) {
        case ADD_OPERATORS:
            return [...action.payload];
        default:
            return state;
    }
}

export default operators;
