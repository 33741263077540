import Select from "react-select";
import orderBy from "lodash/orderBy";
import { useTranslation } from "react-i18next";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import AddResource from "./AddResource";
import SearchIcon from "../../icons/SearchIcon";
import ResourceItem from "./../components/ResourceItem";
import CanRenderOption from "../../common/CanRenderOption";

const customStyles = {
    control: (base) => ({
        ...base,
        border: "1px solid #bacad6",
        background: "white",
        minHeight: "32px",
        height: "32px",
        borderRadius: "0.8125rem",
        fontFamily: "Nunito",
    }),
    placeholder: (base) => ({
        ...base,
        fontWeight: 400,
        fontSize: "14px",
    }),
    dropdownIndicator: (base) => ({
        ...base,
        padding: "6px",
    }),
    multiValue: (base) => ({
        ...base,
        borderRadius: ".5rem",
    }),
};

const ResourcesList = (props) => {
    const {
        addingResource,
        createResource,
        toggleAddingResource,
        handleResourceName,
        companies,
        handleCompany,
        handleSearch,
        company,
        filteredResources,
        selectResource,
        resourceId,
        handleDelete,
        loadinResources,
    } = props;

    const resources = filteredResources.filter((resoruce) => resoruce.companyId === company.id);
    const resources_by_name = orderBy(resources, ["name"], ["asc"]);

    const { t } = useTranslation();

    let loadingSkeleton = [];

    for (let i = 0; i < 10; i++) {
        loadingSkeleton.push(
            <div className="w-52 py-2">
                <SkeletonTheme>
                    <Skeleton />
                </SkeletonTheme>
            </div>
        );
    }

    return (
        <div className="flex w-64 flex-col bg-white py-6 shadow-sidebar">
            <CanRenderOption option="create resource">
                <AddResource
                    addingResource={addingResource}
                    createResource={createResource}
                    toggleAddingResource={toggleAddingResource}
                    handleResourceName={handleResourceName}
                    companies={companies}
                />
            </CanRenderOption>
            <CanRenderOption option="view resource">
                <div className="flex h-full flex-1 flex-col pb-10 pl-4 pr-4 pt-4">
                    <div className="relative mb-4">
                        <div className="pointer-events-none absolute left-0 top-0 flex h-full w-10 items-center justify-center">
                            <SearchIcon width="18px" height="18px" className="fill-current text-gray-400" />
                        </div>
                        <input
                            type="search"
                            className="input-search w-full text-gray-400"
                            placeholder={t("resources.Buscar recurso")}
                            onChange={handleSearch}
                        />
                        <div />
                    </div>
                    <div className="relative z-50 mb-4 flex-col items-center">
                        <h5 className="font-base mb-1 pl-1 text-sm text-gray-500">{t("common.Compañía")}</h5>
                        <Select
                            className="relative inline-block w-full flex-1"
                            styles={customStyles}
                            getOptionValue={(o) => o.id}
                            getOptionLabel={(o) => o.name}
                            onChange={handleCompany}
                            options={orderBy(companies, ["name"], ["asc"])}
                            placeholder={t("common.Escoja una empresa")}
                            value={company}
                            name="companyId"
                        />
                    </div>
                    <h4 className="mb-2 pl-2 font-sans text-base font-extrabold text-gray-450">{t("resources.Recursos")}</h4>
                    <ul className="flex-1 overflow-y-auto">
                        {loadinResources
                            ? loadingSkeleton
                            : resources_by_name.map((resource) => (
                                  <ResourceItem
                                      key={resource.id}
                                      resource={resource}
                                      resourceId={resourceId}
                                      selectResource={selectResource}
                                      handleDelete={handleDelete}
                                  />
                              ))}
                    </ul>
                </div>
            </CanRenderOption>
        </div>
    );
};

export default ResourcesList;
