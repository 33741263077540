import initialState from "./initialState";
import { ADD_SAVED_ERRORS } from "../constants";

function savedErrors(state = initialState.savedErrors, action) {
    switch (action.type) {
        case ADD_SAVED_ERRORS:
            return [...state, action.payload];
        default:
            return state;
    }
}

export default savedErrors;
