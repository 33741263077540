import dayjs from "dayjs";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { createColumnHelper } from "@tanstack/react-table";

import get from "lodash/get";
import first from "lodash/first";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";

import { useGetBots } from "../../actions/bots";
import { getCompanies } from "../../actions/companies";
import { getResources } from "../../actions/resources";
import TransactionsFilters from "./components/TransactionsFilters";
import { getReportResources, getReportResourcesFile } from "../../actions/transactions";

import Table from "../common/Table";
import Layout from "../common/Layout";
import { notifyError } from "../common/Toasts";

const BdPResourcesReport = () => {
    const companies = useSelector((state) => state.companies);
    const resources = useSelector((state) => state.resources);
    const [initialDate, setInitialDate] = useState(dayjs().subtract(1, "week").startOf("day").format());
    const [finalDate, setFinalDate] = useState(dayjs().endOf("day").format());
    const [botSelected, setBotSelected] = useState([]);
    const [filteredBots, setFilteredBots] = useState("");
    const [companySelected, setCompanySelected] = useState([]);
    const [pageLimit, setPageLimit] = useState(1);
    const [rows, setRows] = useState(30);

    const [resourceSelected, setResourceSelected] = useState([]);
    const [state, setState] = useState(null);
    const [downloadingFile, setDownloadingFile] = useState(false);
    const [query, setQuery] = useState("");

    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const maxPage = 1000;
    const [sorting, setSorting] = useState([]);
    const { data: bots = [] } = useGetBots();

    const dispatch = useDispatch();
    const controller = new AbortController();

    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getCompanies());
    }, []);

    useEffect(() => {
        if (!isEmpty(companies) && !isEmpty(bots)) {
            setCompanySelected(first(orderBy(companies, ["name"], ["asc"])));
            handleChangeCompany(first(orderBy(companies, ["name"], ["asc"])));
        }
    }, [companies, bots]);

    useEffect(() => {
        if (!isEmpty(companySelected)) {
            dispatch(getResources(companySelected.id));
        }
    }, [companySelected]);

    useEffect(() => {
        if (!isEmpty(companySelected)) {
            getBdPReport();
        }
        return () => controller.abort();
    }, [pageLimit, rows, initialDate, finalDate, botSelected, companySelected, query, resourceSelected, state]);

    /**
     * This function updates the bot selected and bots which appears on the selector based on the selected company and filters the bots by the company selected.
     * @param company - The "company" parameter is an object that represents a company.
     */
    const handleChangeCompany = (company) => {
        setCompanySelected(company);
        setFilteredBots(bots.filter((bot) => bot.companyId === company.id));
        setBotSelected(null);
        setResourceSelected(null);
    };

    const handleChangeBot = (bot) => {
        setBotSelected(bot);
    };

    const handleState = (state) => {
        setState(state);
    };

    const handleChangeResource = (resource) => {
        setResourceSelected(resource);
    };

    const cleanBots = () => {
        setBotSelected(null);
    };

    const cleanStates = () => {
        setState(null);
    };

    const cleanResources = () => {
        setResourceSelected(null);
    };

    const getBdPReport = async (initialDateSent, finalDateSent) => {
        setLoadingData(true);
        const initDate = !isEmpty(initialDateSent) ? initialDateSent : initialDate;
        const finlDate = !isEmpty(finalDateSent) ? finalDateSent : finalDate;

        const botId = get(botSelected, "id", null);
        const companyId = get(companySelected, "id", null);

        return await dispatch(
            getReportResources(
                pageLimit,
                rows,
                initDate,
                finlDate,
                resourceSelected ? resourceSelected.name : null,
                botId,
                companyId,
                state ? state?.id : null,
                query,
                controller
            )
        ).then((res) => {
            setLoadingData(false);
            setData(res);
        });
    };

    const columnHelper = createColumnHelper();

    const columns = [
        columnHelper.accessor("flowName", {
            cell: (info) => (
                <div className="w-72">
                    <p className="truncate">{info.getValue()}</p>
                </div>
            ),
            header: () => <span>{t("reports.Flujo")}</span>,
        }),

        columnHelper.accessor("name", {
            cell: (info) => (
                <div className="w-72">
                    <p className="truncate">{info.getValue()}</p>
                </div>
            ),
            header: () => <span>{t("reports.Recurso")}</span>,
        }),

        columnHelper.accessor("state", {
            header: () => <span className="flex w-full justify-center">{t("reports.Estado")}</span>,
            cell: ({ row: { original } }) => (
                <div className="w-full">
                    {original.state ? (
                        <div className="mx-auto h-3 w-3 rounded-full bg-green opacity-75" />
                    ) : (
                        <div className="mx-auto h-3 w-3 rounded-full bg-red opacity-75" />
                    )}
                </div>
            ),
        }),

        columnHelper.accessor("botName", {
            cell: (info) => info.getValue(),
            header: () => <span>{t("reports.Bot")}</span>,
        }),

        columnHelper.accessor("createdAt", {
            header: t("reports.Fecha"),
            cell: (info) => {
                return <div>{dayjs(info.getValue()).add(5, "hour").format("DD/MM/YYYY / HH:mm:ss")}</div>;
            },
        }),
    ];

    const execute = (initialDateSent, finalDateSent) => {
        getBdPReport(initialDateSent, finalDateSent);
    };

    const handleInput = (evt) => {
        const { target } = evt;
        const { value } = target;
        setQuery(value);
        if (isEmpty(value)) {
            setQuery(null);
        }
    };

    const downLoadExcel = async () => {
        setDownloadingFile(true);
        const botId = get(botSelected, "id", null);
        const companyId = get(companySelected, "id", null);
        await dispatch(
            getReportResourcesFile(
                initialDate,
                finalDate,
                companyId,
                botId,
                resourceSelected ? resourceSelected.name : null,
                state ? state?.id : null,
                query
            )
        )
            .then(() => {
                setDownloadingFile(false);
            })
            .catch(() => {
                setDownloadingFile(false);
                notifyError(t("common.error"));
            });
    };

    return (
        <Layout showSidebar={false} screen={false}>
            <div className="my-24 flex w-full flex-col px-10">
                <div className="rounded-2xl bg-white p-5 shadow-lg">
                    <h1 className="pb-5 text-lg font-medium text-primary-200">{t("reports.Reporte de Recursos")}</h1>

                    <TransactionsFilters
                        companies={companies}
                        companySelected={companySelected}
                        filteredBots={filteredBots}
                        botSelected={botSelected}
                        initialDate={initialDate}
                        setInitialDate={setInitialDate}
                        finalDate={finalDate}
                        resources={resources}
                        resourceSelected={resourceSelected}
                        setFinalDate={setFinalDate}
                        handleState={handleState}
                        handleChangeBot={handleChangeBot}
                        handleChangeCompany={handleChangeCompany}
                        handleChangeResource={handleChangeResource}
                        cleanBots={cleanBots}
                        cleanStates={cleanStates}
                        cleanResources={cleanResources}
                        state={state}
                        execute={execute}
                        handleInput={handleInput}
                        downloadingFile={downloadingFile}
                        loadingRefresh={loadingData}
                        downLoadExcel={downLoadExcel}
                        getBdPReport={getBdPReport}
                    />

                    <Table
                        columns={columns}
                        data={data}
                        pageLimit={pageLimit}
                        setPageLimit={setPageLimit}
                        rows={rows}
                        setRows={setRows}
                        maxPage={maxPage}
                        sorting={sorting}
                        setSorting={setSorting}
                        loading={loadingData}
                    />
                </div>
            </div>
        </Layout>
    );
};

export default BdPResourcesReport;
