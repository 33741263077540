import isEmpty from "lodash/isEmpty";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Transition } from "@headlessui/react";
import { useState, useEffect, Fragment } from "react";

import PlusIcon from "../../../../icons/PlusIcon";
import TrashIcon from "../../../../icons/TrashIcon";
import CheckmarkIcon from "../../../../icons/CheckmarkIcon";

import { UPDATE, NEW } from "../../../../../constants";

const FlowButtonSettings = (props) => {
    let { payload = {} } = props.option;
    const [key, setKey] = useState(null);
    const [value, setValue] = useState("");
    const [isAdding, setisAdding] = useState(false);
    const [store, setStore] = useState({});
    const { updateOption } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    let properties = isEmpty(props.option.properties) ? {} : props.option.properties;

    useEffect(() => {
        setStore(payload.store || {});
    }, [props.option]);

    function handleKeyChange({ target }) {
        const { value } = target;
        setKey(value);
    }

    function handleValueChange({ target }) {
        const { value } = target;
        setValue(value);
    }

    function handleChange({ target }) {
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        dispatch(
            updateOption({
                ...props.option,
                properties: {
                    ...properties,
                    [name]: `${value}`,
                },
                action: props.option.action === NEW ? NEW : UPDATE,
            })
        );
    }

    // Rename key if exists or add a key with empty value
    function deleteKey(key) {
        const newStore = { ...store };
        delete newStore[key];

        dispatch(
            updateOption({
                ...props.option,
                payload: {
                    ...payload,
                    store: newStore,
                },
                action: props.option.action === NEW ? NEW : UPDATE,
            })
        );

        setStore({});
    }

    function saveKey() {
        if (isEmpty(key)) {
            return;
        }
        const newStore = {
            ...store,
            [key]: value,
        };

        setKey(null);
        setValue(null);
        setisAdding(false);

        dispatch(
            updateOption({
                ...props.option,
                payload: {
                    ...payload,
                    store: newStore,
                },
                action: props.option.action === NEW ? NEW : UPDATE,
            })
        );
    }

    function addKey() {
        setKey("");
        setisAdding(true);
    }

    return (
        <Transition appear show={props.show} as={Fragment}>
            <Transition.Child
                as={Fragment}
                enter="transition-opacity duration-300 ease-in"
                enterFrom="opacity-0 -right-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-300 ease-in"
                leaveFrom="opacity-100"
                leaveTo="opacity-0 -right-28">
                <div className="w-full px-5 py-4">
                    <div className="mb-2 transform transition-all">
                        <div className="grid gap-4 text-sm font-semibold text-gray-400 text-opacity-75">
                            <label htmlFor="ruta" className="flex flex-col">
                                {t("Ruta")}
                                <input
                                    autoFocus
                                    placeholder={`${t("ej")} ${t("results.data")}`}
                                    type="text"
                                    name="path"
                                    defaultValue={properties.path}
                                    onChange={handleChange}
                                    className="form-input"
                                />
                            </label>

                            <label htmlFor="ruta" className="flex flex-col">
                                {t("Validar Ruta")}
                                <input
                                    placeholder={`${t("ej")} ${t("results.data.description")}`}
                                    type="text"
                                    name="path"
                                    defaultValue={properties.validatePath}
                                    onChange={handleChange}
                                    className="form-input"
                                />
                            </label>

                            <label htmlFor="ruta" className="flex flex-col">
                                {t("Validar valor")}
                                <input
                                    placeholder={`${t("ej")} ${t("Interagua")}`}
                                    type="text"
                                    name="validateValue"
                                    defaultValue={properties.validateValue}
                                    onChange={handleChange}
                                    className="form-input"
                                />
                            </label>

                            <span>{t("Parámetros")}</span>
                            {Object.keys(store).map((key, i) => (
                                <div className="mb-2 flex w-full items-center" key={i}>
                                    <div className="grid-2 column-gap-2 row-gap-2 grid flex-1 items-center">
                                        <input
                                            type="text"
                                            className="form-input"
                                            defaultValue={key}
                                            disabled={true}
                                            placeholder={t("Key")}
                                            name="key"
                                        />
                                        <input
                                            type="text"
                                            className="form-input"
                                            defaultValue={store[key]}
                                            disabled={true}
                                            placeholder={t("Value")}
                                            name="value"
                                        />
                                    </div>
                                    <div className="ml-2 flex justify-around">
                                        <button
                                            className="ml-2 flex items-center justify-center rounded-full bg-white py-1 px-2 text-red shadow-input hover:bg-red hover:text-white lg:h-8 lg:w-8"
                                            onClick={() => {
                                                deleteKey(key);
                                            }}>
                                            <TrashIcon className="fill-current" width="18px" height="18px" />
                                        </button>
                                    </div>
                                </div>
                            ))}
                            {key !== null && (
                                <div className="mb-2 flex w-full items-center">
                                    <div className="grid-2 column-gap-2 row-gap-2 grid flex-1 items-center">
                                        <input
                                            placeholder="Key"
                                            type="text"
                                            name="key"
                                            value={key}
                                            onChange={handleKeyChange}
                                            className="form-input"
                                        />
                                        <input
                                            placeholder={t("Value")}
                                            type="text"
                                            name="value"
                                            value={value}
                                            onChange={handleValueChange}
                                            className="form-input"
                                        />
                                    </div>
                                    <div className="ml-2 flex justify-around">
                                        <button
                                            className="ml-2 flex items-center justify-center rounded-full bg-primary-200 text-white hover:bg-primary-100 lg:h-8 lg:w-8"
                                            onClick={() => {
                                                saveKey(key);
                                            }}>
                                            <CheckmarkIcon className="fill-current" width="1.125rem" height="1.125rem" />
                                        </button>
                                    </div>
                                </div>
                            )}
                            {!isAdding && (
                                <div className="flex justify-end">
                                    <button
                                        className="flex items-center justify-center rounded-full bg-primary-200 text-white hover:bg-primary-100 lg:h-8 lg:w-8"
                                        onClick={addKey}>
                                        <PlusIcon className="fill-current" width="18px" height="18px" />
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Transition.Child>
        </Transition>
    );
};

export default FlowButtonSettings;
