import { SET_UNATHORIZATION } from "../constants";

export function setUnauthorization(boolean) {
    return function (dispatch) {
        dispatch({
            type: SET_UNATHORIZATION,
            payload: boolean,
        });
    };
}
