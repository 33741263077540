import get from "lodash/get";

/* Components */
import CardTitle from "../../CardTitle";
import { BeatLoader } from "react-spinners";
import { useTranslation } from "react-i18next";

const TodaySessions = (props) => {
    const { loadingCards, metrics, formatBigNumber } = props;
    const { t } = useTranslation();
    const dailySessions = get(metrics, "daily_sessions", []);

    return (
        <div className="mx-1 h-38 w-1/3 rounded-lg bg-white p-4 shadow-input">
            <CardTitle>
                <div className="flex w-full items-center justify-between">
                    <span className="block w-1/2 text-base font-bold text-gray-500">{t("metrics.Promedio Diario")}</span>
                </div>
            </CardTitle>
            <div className="m-auto mt-4 flex h-full items-center justify-center">
                {loadingCards ? (
                    <div className="pb-6 pt-2 text-center">
                        <BeatLoader color="#00B3C7" size={10} />
                    </div>
                ) : (
                    <p className="pb-6 text-4xl text-gray-400">{formatBigNumber(get(dailySessions, "amount", 0))}</p>
                )}
            </div>
        </div>
    );
};

export default TodaySessions;
