import React from "react";
import Icon from "./Icon";

const RefreshIcon = (props) => {
    return (
        <Icon viewBox="0 0 20 21" className={props.className} width={props.width} height={props.height} fill="none" stroke={props.stroke}>
            <path
                d="M16.8351 14.8592C15.5071 16.6681 13.8141 17.8066 11.6436 18.0965C7.5045 18.6497 3.90467 16.1142 2.98764 12.0668C2.84907 11.4548 2.43222 11.1232 1.93092 11.2266C1.36291 11.3436 1.0934 11.8217 1.26095 12.509C2.05871 15.78 3.98463 18.125 7.12875 19.3538C11.0983 20.9053 15.4972 19.6009 18.0585 16.207C18.1248 16.1192 18.1975 16.0358 18.2889 15.9238C18.6074 16.1515 18.9037 16.3846 19.2224 16.5807C19.3481 16.658 19.5615 16.7227 19.6677 16.6661C19.7764 16.6081 19.8508 16.3977 19.8578 16.2499C19.9164 15.0288 19.9586 13.8067 19.9992 12.5847C20.0136 12.155 19.8225 12.0082 19.4241 12.1582C18.2809 12.5885 17.141 13.0276 16.0037 13.4732C15.5835 13.6378 15.5513 13.9047 15.9105 14.1838C16.1977 14.4068 16.4976 14.6137 16.8351 14.8592Z"
                fill={props.fill ? props.fill : "#A6B4D0"}
            />
            <path
                d="M2.55632 4.92308C2.23515 4.69197 1.93464 4.46625 1.62447 4.25445C1.2485 3.99751 1.00459 4.11139 0.986169 4.55609C0.934737 5.79181 0.887121 7.02754 0.847368 8.2635C0.833443 8.69652 1.02098 8.83532 1.42795 8.68259C2.57159 8.25317 3.71209 7.81565 4.84878 7.36803C5.2681 7.20295 5.2928 6.93613 4.93031 6.65629C4.64237 6.43416 4.34456 6.22529 4.02114 5.98856C4.81733 4.84716 5.81229 3.98404 7.04263 3.40278C11.5081 1.29293 16.6859 3.81672 17.8372 8.65744C17.8664 8.78029 17.8879 8.90607 17.9295 9.02443C18.0811 9.45521 18.5029 9.70294 18.922 9.61512C19.3586 9.52348 19.6865 9.15178 19.6292 8.70393C19.5711 8.248 19.4673 7.78758 19.3103 7.3559C18.0458 3.87938 15.6213 1.65318 11.9712 1.01241C8.24782 0.35883 5.18118 1.66688 2.81169 4.60797C2.73375 4.70454 2.65537 4.80067 2.55632 4.92308Z"
                fill={props.fill ? props.fill : "#A6B4D0"}
            />
        </Icon>
    );
};
export default RefreshIcon;
