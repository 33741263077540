import get from "lodash/get";
import trim from "lodash/trim";
import first from "lodash/first";
import toUpper from "lodash/toUpper";
import isEmpty from "lodash/isEmpty";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Transition } from "@headlessui/react";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef, Fragment, useLayoutEffect } from "react";

import Http from "../../../services/Http";
import { addFlow, getFlow } from "../../../actions/flow";
import { getFlows } from "../../../actions/flows";
import { getBot, unsetBot } from "../../../actions/bot";
import { getExpressions } from "../../../actions/expressions";
import { getCompanies } from "../../../actions/companies";
import { setLoading, unsetLoading, setLoadingFlow, unsetLoadingFlow } from "../../../actions/isLoading";
import { UNSET_UNSAVED_CHANGES } from "../../../constants";
import { addIntents, createIntent, deleteIntent, toggleAddingIntent } from "../../../actions/intents";

import Layout from "./../../common/Layout";
import CloseIcon from "../../icons/CloseIcon";
import Flow from "./../../flow/containers/Flow";
import { Modal } from "../../common/GenericModal";
import { Renderize } from "../../common/Renderize";
import IntentsList from "./../components/IntentsList";
import DeleteIntent from "../components/DeleteIntent";
import { escapedRegEXp } from "../../../helpers";
import { addCurrentIntent } from "../../../actions/intent";
import { notify } from "../../common/Toasts";

const Intents = () => {
    const [query, setQuery] = useState("");
    const [deleting, setDeleting] = useState(false);
    const [createIntentLoading, setCreateIntentLoading] = useState(false);
    const [error, setError] = useState(null);
    const intents = useSelector((state) => state.intents);
    const intent = useSelector((state) => state.intent);
    const unSavedChanges = useSelector((state) => state.unSavedChanges);
    const [newFlow, setNewFlow] = useState({});
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const flow = useSelector((state) => state.flow);
    const flows = useSelector((state) => state.flows);
    const addingIntent = useSelector((state) => state.addingIntent);
    const savingChanges = useSelector((state) => state.savingChanges);
    const isLoading = useSelector((state) => state.isLoading);
    const [showIntents, setShowIntents] = useState(true);

    const bot = useSelector((state) => state.bot);
    const companies = useSelector((state) => state.companies);
    const [isDialogFlow, setIsDialogFlow] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [selectedIntent, setSelectedIntent] = useState(false);
    const [loadingExistingFlow, setLoadingExistinFlow] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deteleSubtitle, setDeleteSubtitle] = useState("");
    const [intentToDelete, setIntentToDelete] = useState("");

    const controller = new AbortController();

    const inputRef = useRef(null);

    const { botId } = useParams();

    useLayoutEffect(() => {
        if (!isEmpty(botId)) {
            dispatch(getBot(botId));
        }
    }, [botId]);

    useEffect(() => {
        if (!isEmpty(intent)) {
            dispatch(getExpressions(intent));
            dispatch(getFlows(botId));
        }
    }, [intent]);

    useLayoutEffect(() => {
        dispatch(getCompanies());
    }, []);

    useEffect(() => {
        if (!isEmpty(bot) && !isEmpty(companies)) {
            const company = companies.find((company) => {
                return company.id === get(bot, "Company.id", get(bot, "companyId", ""));
            });

            setIsDialogFlow(toUpper(get(bot, "properties.nlpDriver", get(company, "properties.nlpDriver", ""))) === "DIALOGFLOW");
        }
    }, [bot, companies]);

    useEffect(() => {
        return () => {
            dispatch(unsetBot());
        };
    }, []);

    const handleChange = ({ target }) => {
        const value = target.value;
        const name = target.name;

        setNewFlow({ ...newFlow, [name]: value });
        setError("");
    };

    const handleSearch = ({ target }) => {
        const value = target.value;
        setQuery(value);
    };

    const searchIntents = () => {
        if (!query) {
            return intents;
        }
        return intents.filter(({ name }) => {
            var regExp = escapedRegEXp(query);
            return regExp.test(toUpper(name));
        });
    };

    const alreadyExist = (name) => {
        return intents.find((intent) => {
            return toUpper(trim(intent.name)) === toUpper(trim(name));
        });
    };

    const addIntent = async () => {
        setCreateIntentLoading(true);

        const name = get(newFlow, "name");

        if (isEmpty(name) || !/\S/.test(name)) {
            setError(t("flows.Nombre del flujo no puede estar vacío"));
            setCreateIntentLoading(false);
            return;
        }

        if (!isDialogFlow && (/[A-Z]/.test(name) || /[À-ÿ]/.test(name))) {
            setError(t("flows.Nombre del flujo no puede contener mayúsculas o caracteres acentuados"));
            setCreateIntentLoading(false);
            return;
        }

        if (alreadyExist(name)) {
            setCreateIntentLoading(false);
            setError(t("flows.Nombre del flujo ya existe"));
            return;
        }

        await dispatch(createIntent({ name })).then((res) => {
            console.log(res);
            inputRef.current.value = "";
        });
        setCreateIntentLoading(false);
        dispatch(toggleAddingIntent());
        notify(t("flows.Flujo creado exitosamente"));
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            if (isEmpty(get(newFlow, "name", "")) || !/\S/.test(get(newFlow, "name", ""))) {
                setError(t("flows.Nombre del flujo no puede estar vacío"));
                return;
            }
            if (!isEmpty(get(newFlow, "name", ""))) {
                addIntent();
            }
        }
    };

    const handleGetFlow = async (intentSelected) => {
        if (intent?.id === intentSelected?.id) {
            return;
        }
        if (unSavedChanges || savingChanges) {
            setSelectedIntent(intentSelected);
            setShowWarningModal(true);
        } else {
            try {
                dispatch(addCurrentIntent(intentSelected));
                dispatch(setLoadingFlow());
                const params = {
                    botId: bot.id,
                    intentId: intentSelected.id,
                    nlpDriver: intentSelected.nlpDriver,
                };

                const data = await dispatch(getFlow(params, controller.signal));

                console.log(data, "data");

                dispatch(unsetLoadingFlow());
            } catch (error) {
                dispatch(unsetLoadingFlow());

                console.log(error, "error");
            }
        }
    };

    const setFlow = async (intent) => {
        setShowWarningModal(false);
        dispatch(setLoading());
        dispatch({ type: UNSET_UNSAVED_CHANGES });

        try {
            const params = {
                botId: bot.id,
                intentId: intent.id,
                nlpDriver: intent.nlpDriver,
            };
            dispatch(addCurrentIntent(intent));
            await dispatch(getFlow(params));
            dispatch(unsetLoading());
        } catch (error) {
            dispatch(unsetLoading());
            console.log(error, "error");
        }
    };

    const renderSubtitle = (data) => {
        return (
            <div className="flex items-center">
                {data.length > 0
                    ? t("Este flujo está siendo utilizado en los siguientes bots:")
                    : t("Este flujo no está siendo utilizado en ningún bot.")}
                {data.map((bot) => (
                    <span className="upppercase ml-2 rounded-full bg-primary-200 px-2 py-px text-sm text-white" key={bot.id}>
                        {bot.name}
                    </span>
                ))}
            </div>
        );
    };

    const handleDelete = (intent) => {
        setIntentToDelete(intent);
        setShowDeleteModal(true);
        setLoadingExistinFlow(true);

        Http.get(`/flows/check/${intent.id}/`)
            .then(({ data }) => {
                setLoadingExistinFlow(false);
                setDeleteSubtitle(renderSubtitle(data));
            })
            .catch(console.error);
    };

    const deleteCurrentIntent = () => {
        setDeleting(true);
        try {
            dispatch(deleteIntent(intentToDelete))
                .then(() => {
                    setShowDeleteModal(false);
                    setDeleting(false);
                    if (flow.intentId === intentToDelete.id) {
                        dispatch(addCurrentIntent(first(intents)));
                        dispatch(addFlow(first(intents)));
                        setFlow(first(intents));
                    }
                    notify(t("flows.Flujo eliminado exitosamente"));
                })
                .catch((error) => {
                    console.log(error, "this is an error1");
                });
        } catch (error) {
            setDeleting(false);
            console.log(error, "this is an error");
        }
    };

    //create a function that every two seconds show a random message from my message array list and if its the last one, start again
    const [message, setMessage] = useState("Estamos cargando tus flujos...");
    const [counter, setCounter] = useState(0);
    const messages = [
        "Estamos preparando todo para ti!",
        "Cargando...",
        "Solo un momento...",
        "Casi listo...",
        "Ya casi terminamos...",
        "Estamos terminando...",
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setMessage(messages[counter]);
            setCounter((counter) => counter + 1);
        }, 5000);
        return () => clearInterval(interval);
    }, [counter]);

    return (
        <>
            <Transition
                as={Fragment}
                show={true}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <div>
                    <Layout botId={botId} unSavedChanges={unSavedChanges}>
                        {isLoading ? (
                            <div className="relative flex h-screen w-screen items-center justify-center">
                                <span className="mt-[10rem] text-2xl font-semibold text-gray-500">{message}</span>
                                <svg className="absolute h-20 w-20 animate-spin text-primary-200" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                <div className="absolute">
                                    <svg width="26" height="34" viewBox="0 0 26 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="13" cy="4.75" r="4.5" fill="#00B3C7" />
                                        <path
                                            d="M25 9.75H18.5V21.75C18.5 25.25 15.4591 26.75 12.5 26.75C8.9 26.75 7.33333 23.0833 7 21.25H0C0 27.75 6 33.75 12.5 33.75C19 33.75 24.5 28.75 25 23.25C25.3621 19.2664 25 12.75 25 9.75Z"
                                            fill="#00B3C7"
                                        />
                                    </svg>
                                </div>
                            </div>
                        ) : (
                            <Renderize permission="view flows">
                                <div className="flex w-full flex-col">
                                    <div className="fixHeight flex flex-1">
                                        <IntentsList
                                            getBot={getBot}
                                            addIntent={addIntent}
                                            addIntents={addIntents}
                                            deleteIntent={deleteIntent}
                                            createIntent={createIntent}
                                            toggleAddingIntent={toggleAddingIntent}
                                            getFlow={getFlow}
                                            setFlow={setFlow}
                                            handleChange={handleChange}
                                            handleKeyPress={handleKeyPress}
                                            loading={createIntentLoading}
                                            error={error}
                                            intents={searchIntents()}
                                            handleSearch={handleSearch}
                                            botId={botId}
                                            deleting={deleting}
                                            handleDelete={handleDelete}
                                            handleGetFlow={handleGetFlow}
                                            addingIntent={addingIntent}
                                            flow={flow}
                                            flows={flows}
                                            inputRef={inputRef}
                                            showIntents={showIntents}
                                            setShowIntents={setShowIntents}
                                            isDialogFlow={isDialogFlow}
                                        />
                                        <Flow intents={intents} setFlow={handleGetFlow} showIntents={showIntents} setShowIntents={setShowIntents} />
                                    </div>
                                </div>
                            </Renderize>
                        )}
                    </Layout>
                </div>
            </Transition>

            <Modal
                openModal={showWarningModal}
                closeModal={() => null}
                title={t("Tienes cambios sin guardar")}
                className="h-min w-96 rounded-20 shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)]">
                <div className="h-full flex-row">
                    <header className="relative flex items-center justify-between bg-primary-350 px-10 py-5">
                        <div className="flex items-center gap-x-4">
                            <div className="text-base font-semibold text-primary-200">{"Tienes cambios sin guardar"}</div>
                        </div>
                        <button className="absolute inset-y-0 right-0 mr-3" onClick={() => setShowWarningModal(false)}>
                            <CloseIcon width="32px" height="32px" className="stroke-current text-primary-200" />
                        </button>
                    </header>
                    <div className="px-10 py-6">
                        <p className="text-sm font-medium text-gray-400">{t("¿Estás seguro que deseas continuar?")}</p>

                        <footer className="mb-0 mt-6 flex items-center justify-end gap-x-3">
                            <button
                                type="reset"
                                onClick={() => setShowWarningModal(false)}
                                disabled={createIntentLoading}
                                className="h-9 w-28 rounded-3xl bg-gray-10 font-bold text-gray-400">
                                Omitir
                            </button>
                            <button
                                onClick={() => {
                                    setFlow(selectedIntent);
                                }}
                                disabled={createIntentLoading}
                                className="flex h-9 w-28 min-w-fit items-center justify-center rounded-3xl bg-primary-200 px-5 font-bold text-white">
                                {createIntentLoading ? (
                                    <svg className={`mr-1 mt-px h-5 w-5 animate-spin text-white`} fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path
                                            // className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                ) : (
                                    "Si"
                                )}
                            </button>
                        </footer>
                    </div>
                </div>
            </Modal>
            <DeleteIntent
                openModal={showDeleteModal}
                intent={intentToDelete}
                closeModal={() => {
                    setShowDeleteModal(false);
                    setDeleteSubtitle("");
                }}
                loadingExistingFlow={loadingExistingFlow}
                subtitle={deteleSubtitle}
                deleteIntent={deleteCurrentIntent}
                loading={deleting}
            />
        </>
    );
};

export default Intents;
