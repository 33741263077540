import CanRenderOption from "../../common/CanRenderOption";
import TrashIcon from "../../icons/TrashIcon";

const ResourceItem = (props) => {
    const { resource, resourceId, selectResource, handleDelete } = props;
    const isSelected = resource.id === resourceId;

    const deleteButtonStyles = `cursor-pointer h-full w-10 px-2 items-center justify-center group-hover:flex ${isSelected ? "flex" : "hidden"}`;

    return (
        <li className={`hovereable flex items-center border border-transparent pl-2 ${isSelected ? "font-bold" : "font-base"}`}>
            <div
                onClick={() => selectResource(resource)}
                className="input-sidebar break-word flex h-full cursor-pointer select-none flex-wrap items-center py-2 font-sans text-sm leading-normal text-gray-400 hover:font-medium hover:text-gray-500">
                {resource.name}
            </div>
            <div className={deleteButtonStyles}>
                <CanRenderOption option="delete resource">
                    <div onClick={() => handleDelete(resource)}>
                        <TrashIcon className="fill-current text-gray-400" width="16px" height="16px" />
                    </div>
                </CanRenderOption>
            </div>
        </li>
    );
};

export default ResourceItem;
