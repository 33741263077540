import Http, { HttpV2 } from "../services/Http";

export default function Flow() {
    // function store(data) {
    //     return Http.post("/flows", data);
    // }
    function store(data) {
        return HttpV2.put("/flows/update", data);
    }

    function update(flowId, botId, data) {
        return Http.patch(`/flows/${botId}/${flowId}`, data);
    }

    return { store, update };
}
