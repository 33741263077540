import initialState from "./initialState";
import { keyById, mergeById, deleteByIds } from "../helpers";
import { ADD_OPTION, ADD_OPTIONS, UPDATE_OPTION, DELETE_OPTIONS, UPDATE_OPTIONS, DELETE_ALL_OPTIONS } from "../constants";

function options(state = initialState.options, action) {
    switch (action.type) {
        case ADD_OPTIONS:
            return action.payload;
        case UPDATE_OPTIONS:
            return mergeById(state, action.payload);
        case ADD_OPTION:
            if (Array.isArray(action.payload)) {
                return [...state, ...action.payload];
            }
            return mergeById(state, action.payload);
        case UPDATE_OPTION:
            return keyById(state).merge(keyById(action.payload)).toList().toJS();
        case DELETE_OPTIONS:
            return deleteByIds(state, action.payload);
        case DELETE_ALL_OPTIONS:
            return [];
        default:
            return state;
    }
}

export default options;
