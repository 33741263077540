import { SET_IMPERSONATE } from "../constants";
import initialState from "./initialState";

function impersonate(state = initialState.impersonate, action) {
    switch (action.type) {
        case SET_IMPERSONATE:
            return action.payload;
        default:
            return state;
    }
}

export default impersonate;
