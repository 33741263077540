import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";

import Select from "react-select";
import { BeatLoader } from "react-spinners";
import { withTranslation } from "react-i18next";

const customStyles = {
    control: (base) => ({
        ...base,
        border: "1px solid rgba(166, 180, 208, 0.5)",
        background: "white",
        minHeight: "36px",
        height: "36px",
        borderRadius: "0.8125rem",
        fontFamily: "Nunito",
    }),
    placeholder: (base) => ({
        ...base,
        fontWeight: 400,
        fontSize: "15px",
        color: "#7e819f",
    }),
    dropdownIndicator: (base) => ({
        ...base,
        padding: "6px",
    }),
    multiValue: (base) => ({
        ...base,
        borderRadius: ".5rem",
    }),
};

const TrainFlow = (props) => {
    const { flows, selected, setFlow, flowTrain, trainWord, loading, setLoading } = props;
    const { t } = props;

    const disabled = isEmpty(selected);
    const disabledButton = isEmpty(flowTrain);

    const trainWords = async () => {
        setLoading(true);
        const selected = props.selected.map((select) => {
            return select.message;
        });

        await trainWord(selected);
    };

    return (
        <div className="flex w-full flex-row justify-end">
            <div className="flex w-full flex-row items-center justify-end">
                {props.error !== "" ? <small className="text-bold mr-3 text-sm text-red">{props.error}</small> : null}
                <Select
                    className="relative mr-3 inline-block w-1/4"
                    placeholder={t("filters.Seleccionar flujo")}
                    styles={customStyles}
                    options={orderBy(flows, ["name"], ["asc"])}
                    getOptionValue={(o) => o.id}
                    getOptionLabel={(o) => o.title}
                    onChange={setFlow}
                    name="flowIsDefault"
                    value={flowTrain}
                    isDisabled={disabled}
                />
            </div>
            <button className={"button button-primary"} onClick={() => trainWords()} disabled={disabledButton || loading}>
                {loading ? (
                    <div className="w-20">
                        <BeatLoader size={8} color={"#fff"} />
                    </div>
                ) : (
                    t("reports.Entrenar")
                )}
            </button>
        </div>
    );
};

export default withTranslation()(TrainFlow);
