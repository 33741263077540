import orderBy from "lodash/orderBy";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import {
    ADD_ERRORS,
    ADD_INTENT,
    ADD_INTENTS,
    DELETE_INTENT,
    UPDATE_INTENT,
    TOGGLE_ADDING_INTENT,
    LOADING_INTENTS,
    ADD_CURRENT_INTENT,
} from "../constants";
import { setFlow, updateSelectedFlowByIntent, getFlow } from "./flow";

import { deleteFromFlows, UpdateIntentFromFlows } from "./flows";
import first from "lodash/first";
// import orderBy from "lodash/orderBy";

import Http from "../services/Http";
import { unsetLoading } from "./isLoading";
import { addBubbles } from "./bubbles";

export function addIntent(intent) {
    return function (dispatch) {
        dispatch({
            type: ADD_INTENT,
            payload: intent,
        });
        dispatch({
            type: ADD_CURRENT_INTENT,
            payload: intent,
        });

        // Set flow for rencently created intent
        dispatch(setFlow(intent));
    };
}

export const addIntents = (intents) => ({
    type: ADD_INTENTS,
    payload: intents,
});

export const toggleAddingIntent = () => ({
    type: TOGGLE_ADDING_INTENT,
});

export const editIntent = (intent) => ({
    type: UPDATE_INTENT,
    payload: intent,
});

export const loadingIntents = (isLoading) => ({
    type: LOADING_INTENTS,
    payload: isLoading,
});

export function getIntents(bot) {
    return function (dispatch) {
        // Get intents
        return Http.get(`/intents/group/${bot.Company.id}/${bot.id}`, {
            params: {
                botId: bot.id,
            },
        })
            .then(({ data: resp }) => {
                const data = get(resp, "data", []);
                dispatch(addIntents(orderBy(data, ["trained", "slug"], ["desc", "asc"])));
                // dispatch(addIntents(resp));

                const firstTrainedIntent = first(orderBy(data, ["trained", "slug"], ["desc", "asc"]));

                // const firstTrainedIntent = first(resp);

                if (isEmpty(firstTrainedIntent)) {
                    dispatch(loadingIntents(false));
                    dispatch(unsetLoading());
                    return;
                }

                const params = {
                    botId: bot.id,
                    intentId: firstTrainedIntent.id,
                    nlpDriver: firstTrainedIntent.nlpDriver,
                };

                dispatch({
                    type: ADD_CURRENT_INTENT,
                    payload: firstTrainedIntent,
                });

                dispatch(getFlow(params));
                dispatch(loadingIntents(false));
            })
            .catch((error) => {
                console.log(`error on get intents with id: ${bot.name}`, error);
                dispatch(loadingIntents(false));
            });
    };
}

export function deleteIntent(intent) {
    return function (dispatch, getState) {
        const { flow, bot } = getState();
        const slug = intent.slug;
        const name = intent.name;
        const { companyId } = bot;
        return Http.delete(`/intents`, {
            data: { intentId: intent.id, slug: slug, companyId: companyId, name: name },
            params: {
                botId: bot.id,
            },
        })
            .then((data) => {
                dispatch({
                    type: DELETE_INTENT,
                    payload: intent,
                });

                // Delete old flow from flows
                dispatch(deleteFromFlows(flow));

                return get(data, "data", []);
            })
            .catch(console.error);
    };
}

export function createIntent(data) {
    return function (dispatch, getState) {
        const bot = getState().bot;
        return Http.post(`/intents/${bot.slug}`, data, {
            params: {
                botId: bot.id,
            },
        })
            .then(({ data }) => {
                dispatch(addIntent(data));
                dispatch(addBubbles([]));
                return data;
            })
            .catch((err) => {
                dispatch({
                    type: ADD_ERRORS,
                    payload: err.response.data.errors,
                });
                return err.response;
            });
    };
}

export function updateIntent(companySlug, intent) {
    return function (dispatch, getState) {
        const { intents, bot } = getState();
        const intentBefore = intents.find((inten) => inten.slug === intent.slug);
        return Http.put(
            `/intents/${companySlug}/${intent.slug}/${intentBefore.id}`,
            {
                name: intent.name,
                description: intent.description,
            },
            {
                params: {
                    botId: bot.id,
                },
            }
        )
            .then((response) => {
                dispatch(editIntent(response.data));
                // Sending intent data that should be edited on the flows
                dispatch(updateSelectedFlowByIntent(response.data));
                dispatch(UpdateIntentFromFlows(response.data));
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
}
