import shortid from "shortid";

import flatten from "lodash/flatten";
import get from "lodash/get";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import isNumber from "lodash/isNumber";
import max from "lodash/max";
import omit from "lodash/omit";
import toLower from "lodash/toLower";

import { List } from "immutable";
import beautify from "js-beautify";
import {
    ADD_ATTACHMENT,
    ADD_BUBBLE,
    ADD_BUBBLE_RESOURCE,
    ADD_BUBBLE_RESOURCES,
    ADD_CONDITIONAL,
    ADD_CONDITIONALS,
    ADD_CONTACTS,
    ADD_ELEMENT,
    ADD_ELEMENTS,
    ADD_FLOW,
    ADD_INPUT,
    ADD_INPUTS,
    ADD_OPTIONS,
    ADD_PMA_BUBBLE,
    ADD_PMA_BUBBLES,
    ADD_QUICK_REPLIES,
    ADD_RANDOM,
    ADD_RANDOMS,
    ADD_RUN_ACTION_BUBBLE,
    ADD_RUN_ACTION_BUBBLES,
    ADD_SANDBOX,
    ADD_SANDBOXES,
    ADD_TO_FLOWS,
    AttachmentTypes,
    BubbleTypes,
    DELETE,
    DELETE_BUBBLES,
    MERGE_BUBBLES,
    NEW,
    SANDOX_ACTIONS,
    SET_UNSAVED_CHANGES,
    UNSET_UNSAVED_CHANGES,
    UPDATE_ATTACHMENTS,
    UPDATE_BUBBLE,
    UPDATE_BUBBLES,
    UPDATE_BUBBLE_RESOURCES,
    UPDATE_BUBBLE_TEXT,
    UPDATE_CONDITIONALS,
    UPDATE_CONTACTS,
    UPDATE_CURRENT_INTENT,
    UPDATE_ELEMENTS,
    UPDATE_INPUT,
    UPDATE_INPUTS,
    UPDATE_INTENTS,
    UPDATE_OPTIONS,
    UPDATE_PMA_BUBBLES,
    UPDATE_QUICK_REPLIES,
    UPDATE_RANDOMS,
    UPDATE_RUN_ACTION_BUBBLES,
    UPDATE_SANDBOXES,
} from "../constants";
import { arrayByKey, deleteByIds, objectByKey, omitKeys } from "../helpers";
import Flow from "../resources/Flow";
import { HttpV2 } from "../services/Http";
import { addFlow, getFlow } from "./flow";
import { unsetLoading } from "./isLoading";

const { store } = Flow();

export function addBubbles(bubbles) {
    return function (dispatch) {
        return new Promise((resolve) => {
            let inputs = objectByKey(bubbles, "Input");
            let sandboxes = objectByKey(bubbles, "Sandbox");
            let options = arrayByKey(bubbles, "Options");
            let randoms = arrayByKey(bubbles, "Randoms");
            let elements = arrayByKey(bubbles, "Elements");
            let resources = arrayByKey(bubbles, "Resources");
            let attachments = objectByKey(bubbles, "Attachment");
            let conditionals = objectByKey(bubbles, "Conditional");
            let quickReplies = arrayByKey(bubbles, "QuickReplies");
            let contacts = objectByKey(bubbles, "Contact");
            let pmaBubbles = objectByKey(bubbles, "BubblePma");
            let runActionsBubbles = objectByKey(bubbles, "BubbleRunAction");

            resources = resources.map(({ BubbleResource }) => {
                return { ...BubbleResource };
            });
            // Extract options from elements
            options.push(...arrayByKey(elements, "Options"));

            // Dispatch Bubble options
            dispatch({
                type: ADD_OPTIONS,
                payload: options,
            });

            // Dispatch Bubble Randoms
            dispatch({
                type: ADD_RANDOMS,
                payload: randoms,
            });

            // Dispatch Bubble Attachments
            dispatch({
                type: UPDATE_ATTACHMENTS,
                payload: attachments,
            });

            // Dispatch Bubble Inputs
            dispatch({
                type: UPDATE_INPUTS,
                payload: inputs,
            });

            // Dispatch Bubble Conditionals
            dispatch({
                type: UPDATE_CONDITIONALS,
                payload: conditionals,
            });

            // Dispatch Bubble Resources
            dispatch({
                type: UPDATE_BUBBLE_RESOURCES,
                payload: resources,
            });

            // Dispatch Bubble QuickReplies
            dispatch({
                type: UPDATE_QUICK_REPLIES,
                payload: flatten(quickReplies),
            });

            // Dispatch Bubble Sandboxes
            dispatch({
                type: UPDATE_SANDBOXES,
                payload: sandboxes,
            });

            // Dispatch Bubble Contact cards
            dispatch({
                type: UPDATE_CONTACTS,
                payload: contacts,
            });

            // Dispatch Bubble Contact cards
            dispatch({ type: UPDATE_PMA_BUBBLES, payload: pmaBubbles });

            dispatch({ type: UPDATE_RUN_ACTION_BUBBLES, payload: runActionsBubbles });

            // Dispatch Bubble Elements
            return resolve(
                dispatch({
                    type: UPDATE_ELEMENTS,
                    payload: elements.map((element) => {
                        return { ...omitKeys(element, ["Options"]) };
                    }),
                })
            );
        }).then(() => {
            dispatch({
                type: UPDATE_BUBBLES,
                payload: bubbles.map(({ id, type, text, order, flowId, properties, BubbleTool, BubbleSkill }) => {
                    return { id, type, text, order, flowId, properties, BubbleTool, BubbleSkill };
                }),
            });
            dispatch(unsetLoading());
        });
    };
}

export function addBubble(bubble) {
    return function (dispatch, getState) {
        const { bubbles, flow } = getState();

        // Get the latest order not by length
        const flowBubbles = bubbles.filter((b) => b.flowId === flow.id).map((b) => b.order);
        const order = flowBubbles.length === 0 ? 0 : max(flowBubbles) + 1;

        dispatch({
            type: ADD_BUBBLE,
            payload: {
                ...bubble,
                order,
            },
        });
    };
}

export const deleteBubbles = (bubbles) => ({
    type: DELETE_BUBBLES,
    payload: bubbles,
});

export function updateTextBubble(text) {
    return function (dispatch) {
        dispatch({
            type: SET_UNSAVED_CHANGES,
        });
        dispatch({
            type: UPDATE_BUBBLE_TEXT,
            payload: text,
        });
    };
}

export function updateBubble(bubble) {
    return function (dispatch) {
        dispatch({
            type: SET_UNSAVED_CHANGES,
        });
        dispatch({
            type: UPDATE_BUBBLE,
            payload: bubble,
        });
    };
}
export function mergeBubbles(bubble) {
    return function (dispatch) {
        dispatch({
            type: SET_UNSAVED_CHANGES,
        });
        dispatch({
            type: MERGE_BUBBLES,
            payload: bubble,
        });
    };
}

export function updateBubbles(bubbles) {
    return function (dispatch) {
        dispatch({
            type: SET_UNSAVED_CHANGES,
        });
        dispatch({
            type: UPDATE_BUBBLES,
            payload: bubbles,
        });
    };
}

export function deleteBubble(bubble) {
    return function (dispatch) {
        dispatch({
            type: SET_UNSAVED_CHANGES,
        });

        dispatch({
            type: UPDATE_BUBBLE,
            payload: { ...bubble, action: DELETE },
        });
    };
}

/**
 * Add bubble type text
 */
export function addTextBubble(id) {
    return function (dispatch, getState) {
        let flow = getState().flow;
        let bubble = {
            id,
            flowId: flow.id,
            text: "",
            type: BubbleTypes.TEXT,
            Options: [],
            action: NEW,
        };

        dispatch({
            type: SET_UNSAVED_CHANGES,
        });
        dispatch(addBubble(bubble));
    };
}

/**
 * Add Generic bubble with an empty attachment
 */
export function addGenericBubble(id) {
    return function (dispatch, getState) {
        let flow = getState().flow;
        // Create bubble
        let bubble = {
            id,
            flowId: flow.id,
            type: BubbleTypes.GENERIC,
            action: NEW,
        };

        // Dispatch inicial element
        dispatch({
            type: ADD_ELEMENT,
            payload: {
                id: shortid.generate(),
                title: "",
                subtitle: "",
                imageUrl: "",
                bubbleId: bubble.id,
                action: NEW,
            },
        });

        // Set unsaved changes
        dispatch({
            type: SET_UNSAVED_CHANGES,
        });

        dispatch(addBubble(bubble));
    };
}

/**
 * Add Bubble type image ( attachment )
 */
export function addImageBubble(id) {
    return function (dispatch, getState) {
        let flow = getState().flow;
        // Create bubble
        let bubble = {
            id,
            flowId: flow.id,
            type: BubbleTypes.IMAGE,
            action: NEW,
        };

        // Dispatch attachment
        dispatch({
            type: ADD_ATTACHMENT,
            payload: {
                id: shortid.generate(),
                type: AttachmentTypes.IMAGE,
                url: "",
                bubbleId: bubble.id,
                action: NEW,
            },
        });

        // Set unsaved changes
        dispatch({
            type: SET_UNSAVED_CHANGES,
        });

        dispatch(addBubble(bubble));
    };
}

/**
 * Add Bubble type image ( attachment )
 */
export function addVideoBubble(id) {
    return function (dispatch, getState) {
        let flow = getState().flow;
        // Create bubble
        let bubble = {
            id,
            flowId: flow.id,
            type: BubbleTypes.VIDEO,
            action: NEW,
        };

        // Dispatch attachment
        dispatch({
            type: ADD_ATTACHMENT,
            payload: {
                id: shortid.generate(),
                type: AttachmentTypes.VIDEO,
                url: "",
                bubbleId: bubble.id,
                action: NEW,
            },
        });

        // Set unsaved changes
        dispatch({
            type: SET_UNSAVED_CHANGES,
        });

        dispatch(addBubble(bubble));
    };
}

/**
 * Add Bubble type file ( attachment )
 */
export function addFileBubble(id) {
    return function (dispatch, getState) {
        let flow = getState().flow;
        // Create bubble
        let bubble = {
            id,
            flowId: flow.id,
            type: BubbleTypes.FILE,
            action: NEW,
        };

        // Dispatch attachment
        dispatch({
            type: ADD_ATTACHMENT,
            payload: {
                id: shortid.generate(),
                type: AttachmentTypes.FILE,
                url: "",
                bubbleId: bubble.id,
                action: NEW,
            },
        });

        // Set unsaved changes
        dispatch({
            type: SET_UNSAVED_CHANGES,
        });

        dispatch(addBubble(bubble));
    };
}

/**
 * Add Bubble type audio ( attachment )
 */
export function addAudioBubble(id) {
    return function (dispatch, getState) {
        let flow = getState().flow;
        // Create bubble
        let bubble = {
            id,
            flowId: flow.id,
            type: BubbleTypes.AUDIO,
            action: NEW,
        };

        // Dispatch attachment
        dispatch({
            type: ADD_ATTACHMENT,
            payload: {
                id: shortid.generate(),
                type: AttachmentTypes.AUDIO,
                url: "",
                bubbleId: bubble.id,
                action: NEW,
            },
        });

        // Set unsaved changes
        dispatch({
            type: SET_UNSAVED_CHANGES,
        });

        dispatch(addBubble(bubble));
    };
}

/**
 * Add Bubble type sticker ( attachment )
 */
export function addStickerBubble(id) {
    return function (dispatch, getState) {
        let flow = getState().flow;
        // Create bubble
        let bubble = {
            id,
            flowId: flow.id,
            type: BubbleTypes.STICKER,
            action: NEW,
        };

        // Dispatch attachment
        dispatch({
            type: ADD_ATTACHMENT,
            payload: {
                id: shortid.generate(),
                type: AttachmentTypes.STICKER,
                url: "",
                bubbleId: bubble.id,
                action: NEW,
            },
        });

        // Set unsaved changes
        dispatch({
            type: SET_UNSAVED_CHANGES,
        });

        dispatch(addBubble(bubble));
    };
}

/**
 * Add Bubble type random
 */
export function addRandomBubble(id) {
    return function (dispatch, getState) {
        let flow = getState().flow;

        // Create bubble
        let bubble = {
            id,
            flowId: flow.id,
            type: BubbleTypes.RANDOM,
            action: NEW,
        };

        // Dispatch random
        dispatch({
            type: ADD_RANDOM,
            payload: {
                id: shortid.generate(),
                text: "",
                bubbleId: bubble.id,
                action: NEW,
            },
        });

        // Set unsaved changes
        dispatch({
            type: SET_UNSAVED_CHANGES,
        });

        dispatch(addBubble(bubble));
    };
}

/**
 * Add Bubble type input
 */
export function addInputBubble(id) {
    return function (dispatch, getState) {
        let flow = getState().flow;
        // Create bubble
        let bubble = {
            id,
            flowId: flow.id,
            type: BubbleTypes.INPUT,
            action: NEW,
        };

        // Dispatch Input
        dispatch({
            type: ADD_INPUT,
            payload: {
                id: shortid.generate(),
                text: "",
                name: "",
                validations: "",
                messages: "",
                cache: false,
                bubbleId: bubble.id,
                action: NEW,
            },
        });

        // Set unsaved changes
        dispatch({
            type: SET_UNSAVED_CHANGES,
        });

        dispatch(addBubble(bubble));
    };
}

/**
 * Add Bubble type conditional
 */
export function addConditionalBubble(id) {
    return function (dispatch, getState) {
        let flow = getState().flow;
        // Create bubble
        let bubble = {
            id,
            flowId: flow.id,
            type: BubbleTypes.CONDITIONAL,
            action: NEW,
        };

        // Dispatch Conditional
        dispatch({
            type: ADD_CONDITIONAL,
            payload: {
                id: shortid.generate(),
                value: "",
                operator: "",
                against: "",
                flowId: "",
                bubbleId: bubble.id,
                action: NEW,
            },
        });

        // Set unsaved changes
        dispatch({
            type: SET_UNSAVED_CHANGES,
        });

        dispatch(addBubble(bubble));
    };
}

/**
 * Add Bubble type resource
 */
export function addResourceBubble(id) {
    return function (dispatch, getState) {
        let flow = getState().flow;
        // Create bubble
        let bubble = {
            id,
            flowId: flow.id,
            type: BubbleTypes.RESOURCE,
            action: NEW,
        };

        // Dispatch Conditional
        dispatch({
            type: ADD_BUBBLE_RESOURCE,
            payload: {
                id: shortid.generate(),
                resourceId: "",
                bubbleId: bubble.id,
                action: NEW,
            },
        });

        // Set unsaved changes
        dispatch({
            type: SET_UNSAVED_CHANGES,
        });

        dispatch(addBubble(bubble));
    };
}

/**
 * Add Bubble type quick_reply
 */
export function addQuickReplyBubble(id) {
    return function (dispatch, getState) {
        let flow = getState().flow;

        // Create bubble
        let bubble = {
            id,
            flowId: flow.id,
            type: BubbleTypes.QUICK_REPLY,
            action: NEW,
        };

        // Set unsaved changes
        dispatch({
            type: SET_UNSAVED_CHANGES,
        });

        dispatch(addBubble(bubble));
    };
}

/**
 * Add Bubble type contact_bubble
 */
export function addContactCardBubble(id) {
    return function (dispatch, getState) {
        let flow = getState().flow;

        // Create bubble
        let bubble = {
            id,
            flowId: flow.id,
            type: BubbleTypes.CONTACT_BUBBLE,
            action: NEW,
        };

        // Set unsaved changes
        dispatch({
            type: SET_UNSAVED_CHANGES,
        });

        dispatch(addBubble(bubble));
    };
}

/**
 * Add Sandbox bubble
 */
export function addSandboxBubble(id) {
    return function (dispatch, getState) {
        let flow = getState().flow;

        // Create bubble
        let bubble = {
            id,
            flowId: flow.id,
            type: BubbleTypes.SANDBOX,
            action: NEW,
        };

        const code = beautify(`/**
        * Your sandbox code goes here
        * It must be and IIFE
        * Moment is available as moment
        * Lodash is available as _
        * @param {Object} storeParams
        * @param {Object} userCache
        * @param {Object} user
        * @returns {{storeParams, userCache}} 
        */
        (() => {
            // Code
            return {
                storeParams,
                userCache
            }
        })();`);

        dispatch({
            type: ADD_SANDBOX,
            payload: {
                id: shortid.generate(),
                code,
                storeAction: SANDOX_ACTIONS.MERGE,
                cacheAction: SANDOX_ACTIONS.MERGE,
                bubbleId: bubble.id,
                action: NEW,
            },
        });

        // Set unsaved changes
        dispatch({
            type: SET_UNSAVED_CHANGES,
        });

        dispatch(addBubble(bubble));
    };
}

/**
 * Add bubble type text
 */
export function addCheckpointBubble(id) {
    return function (dispatch, getState) {
        let flow = getState().flow;
        let bubble = {
            id,
            flowId: flow.id,
            text: "",
            type: BubbleTypes.CHECKPOINT,
            Options: [],
            action: NEW,
        };

        dispatch({
            type: SET_UNSAVED_CHANGES,
        });
        dispatch(addBubble(bubble));
    };
}

/**
 * Add bubble type text
 */
export function addPMABubble(id) {
    return function (dispatch, getState) {
        let flow = getState().flow;
        let bubble = { id, flowId: flow.id, text: "", type: BubbleTypes.PMA, Options: [], action: NEW };

        // Dispatch Input
        dispatch({
            type: ADD_PMA_BUBBLE,
            payload: {
                id: shortid.generate(),
                assignmentType: "",
                assignmentBy: "",
                teamId: null,
                operatorId: null,
                bubbleId: bubble.id,
                errorHandlers: {},
                action: NEW,
            },
        });

        // Set unsaved changes
        dispatch({ type: SET_UNSAVED_CHANGES });
        dispatch(addBubble(bubble));
    };
}

export function addToolkitBubble(id) {
    return function (dispatch, getState) {
        const flow = getState().flow;
        const bubble = {
            id,
            flowId: flow.id,
            type: BubbleTypes.TOOLKIT,
            action: NEW,
            BubbleTool: {
                id: shortid.generate(),
                action: NEW,
                toolkitId: null,
                toolId: null,
                version: null,
                input: {},
                output: {},
                variable: null,
                defaultFlowId: null,
            },
        };

        dispatch({ type: SET_UNSAVED_CHANGES });
        dispatch(addBubble(bubble));
    };
}

export function addSkillBubble(id) {
    return function (dispatch, getState) {
        const flow = getState().flow;
        const bubble = {
            id,
            flowId: flow.id,
            type: BubbleTypes.SKILL,
            action: NEW,
            BubbleSkill: {
                id: shortid.generate(),
                action: NEW,
                skillId: null,
                skillName: null,
                version: null,
            },
        };

        dispatch({ type: SET_UNSAVED_CHANGES });
        dispatch(addBubble(bubble));
    };
}
export function addRunActionBubble(id) {
    return function (dispatch, getState) {
        const flow = getState().flow;
        const bubble = {
            id,
            flowId: flow.id,
            type: BubbleTypes.RUN_ACTION,
            action: NEW,
        };
        dispatch({
            type: ADD_RUN_ACTION_BUBBLE,
            payload: {
                id: shortid.generate(),
                payload: {},
                bubbleId: bubble.id,
                action: "REDIRECT",
            },
        });

        dispatch({ type: SET_UNSAVED_CHANGES });
        dispatch(addBubble(bubble));
    };
}

export const createFlow = () => {
    return function (dispatch, getState) {
        // Send flow and bubbles to the server
        let state = getState();

        // Build request args
        const flow = omit(state.flow, ["unSavedChanges"]);

        flow.Bubbles = state.bubbles.map((bubble) => buildBubble(bubble, state));
        // Set unsave changes before the call to make it faster
        // FIXME: Check if this works when promise fails

        // Getting the conditional array that got empty values.
        const errorsArray = flow.Bubbles.filter((bubble) => {
            //errors here will also happen if you have created a bubble wrong and you receive undefined
            const { type } = bubble;

            if (toLower(type) === "conditional") {
                const { Conditional } = bubble;
                const { value, operator, flowId } = Conditional;
                if (isEmpty(value.trim()) || isEmpty(operator) || isEmpty(flowId.toString())) {
                    return true;
                }
                return false;
            }

            if (toLower(type) === "pma") {
                const { BubblePma = [] } = bubble;

                const { assignmentBy, assignmentType } = BubblePma;

                const errorHandlers = get(BubblePma, "errorHandlers", {});
                const teamId = !isNumber(get(BubblePma, "teamId")) ? "" : get(BubblePma, "teamId");
                const operatorId = !isNumber(get(BubblePma, "operatorId")) ? "" : get(BubblePma, "operatorId");

                if (isEmpty(assignmentType) || isEmpty(assignmentBy)) {
                    return true;
                }

                if (!isEmpty(assignmentBy) && toLower(assignmentBy) === "teams" && isEmpty(teamId.toString())) {
                    return true;
                }

                if (!isEmpty(assignmentBy) && toLower(assignmentBy) === "operators" && isEmpty(operatorId.toString())) {
                    return true;
                }

                let save = false;

                for (let key in errorHandlers) {
                    if (!includes(["OPERATOR_NOT_FOUND", "OPERATORS_NOT_IN_SCHEDULER", "GENERAL_ERROR"], key) || isEmpty(errorHandlers[key])) {
                        save = true;
                        return save;
                    }
                    return save;
                }

                //map object errorHandler and check if is exists error and flowId on each object

                // errorHandlers.map((err) => {
                //     const error = get(err, "error", "");
                //     const flowId = get(err, "flowId", "");
                //     if (isEmpty(error) || isEmpty(flowId.toString())) {
                //         save = true;
                //         return true;
                //     }
                //     return save;
                // });
                return save;
            }

            return false;
        });
        // Ignoring those conditional bubbles
        flow.Bubbles = deleteByIds(flow.Bubbles, errorsArray);
        dispatch({ type: UNSET_UNSAVED_CHANGES });

        return HttpV2.post(`/flows`, {
            flow,
        }).then(({ data }) => {
            const results = get(data, "data", []);
            const providerId = Object.keys(results.providerId)[0];

            const flowResult = { ...results, intentId: results.providerId[providerId], nlpDriver: providerId };

            dispatch(addBubbles(get(flowResult, "Bubbles", [])));

            // Add new flow to flows and set flow as current one
            dispatch({
                type: ADD_FLOW,
                payload: omit(flowResult, ["Bubbles"]),
            });
            dispatch({
                type: ADD_TO_FLOWS,
                payload: omit(flowResult, ["Bubbles"]),
            });

            return flowResult;
        });
    };
};

export const saveBubbles = (flowSelected) => {
    return function (dispatch, getState) {
        // Send flow and bubbles to the server
        let state = getState();

        // let respFlow;

        // Build request args
        const flow = omit(state.flow, ["unSavedChanges"]);

        flow.Bubbles = state.bubbles.map((bubble) => buildBubble(bubble, state));
        // Set unsave changes before the call to make it faster
        // FIXME: Check if this works when promise fails

        // Getting the conditional array that got empty values.
        const errorsArray = flow.Bubbles.filter((bubble) => {
            //errors here will also happen if you have created a bubble wrong and you receive undefined
            const { type } = bubble;

            if (toLower(type) === "conditional") {
                const { Conditional } = bubble;
                const { value, operator, flowId } = Conditional;
                if (isEmpty(value.trim()) || isEmpty(operator) || isEmpty(flowId.toString())) {
                    return true;
                }
                return false;
            }

            if (toLower(type) === "pma") {
                const { BubblePma = [] } = bubble;

                const { assignmentBy, assignmentType } = BubblePma;

                const errorHandlers = get(BubblePma, "errorHandlers", {});
                const teamId = !isNumber(get(BubblePma, "teamId")) ? "" : get(BubblePma, "teamId");
                const operatorId = !isNumber(get(BubblePma, "operatorId")) ? "" : get(BubblePma, "operatorId");

                if (isEmpty(assignmentType) || isEmpty(assignmentBy)) {
                    return true;
                }

                if (!isEmpty(assignmentBy) && toLower(assignmentBy) === "teams" && isEmpty(teamId.toString())) {
                    return true;
                }

                if (!isEmpty(assignmentBy) && toLower(assignmentBy) === "operators" && isEmpty(operatorId.toString())) {
                    return true;
                }

                let save = false;

                for (let key in errorHandlers) {
                    if (!includes(["OPERATOR_NOT_FOUND", "OPERATORS_NOT_IN_SCHEDULER", "GENERAL_ERROR"], key) || isEmpty(errorHandlers[key])) {
                        save = true;
                        return save;
                    }
                    return save;
                }

                //map object errorHandler and check if is exists error and flowId on each object

                // errorHandlers.map((err) => {
                //     const error = get(err, "error", "");
                //     const flowId = get(err, "flowId", "");
                //     if (isEmpty(error) || isEmpty(flowId.toString())) {
                //         save = true;
                //         return true;
                //     }
                //     return save;
                // });
                return save;
            }

            return false;
        });
        // Ignoring those conditional bubbles
        flow.Bubbles = deleteByIds(flow.Bubbles, errorsArray);
        dispatch({ type: UNSET_UNSAVED_CHANGES });

        flow.providerId = { [flow.nlpDriver]: flow.intentId };

        return store({ flow }).then(({ data }) => {
            const results = get(data, "data", []);
            const providerId = Object.keys(results.providerId)[0];

            const flowResult = { ...results, intentId: results.providerId[providerId], nlpDriver: providerId };
           
            
            //dispatch(addBubbles(get(flowResult, "Bubbles", [])));
            const params = {
                botId: flowResult.botId,
                intentId: flowResult.intentId,
                nlpDriver: flowResult.nlpDriver,
            };
            dispatch(getFlow(params));

            dispatch({
                type: ADD_FLOW,
                payload: omit(flowResult, ["Bubbles"]),
            });
            dispatch({
                type: ADD_TO_FLOWS,
                payload: omit(flowResult, ["Bubbles"]),
            });
            return flowResult;
        });
    };
};

export function buildBubble(bubble, state) {
    const {
        inputs,
        randoms,
        options,
        elements,
        attachments,
        conditionals,
        bubbleResources,
        quickReplies,
        contactsCards,
        sandboxes,
        pmaBubbles,
        runActionBubbles,
    } = state;
    switch (bubble.type) {
        case BubbleTypes.TEXT:
            return { ...bubble, Options: getOptions(options, { optionableId: bubble.id, optionable: "Bubble" }) };
        case BubbleTypes.INPUT:
            return { ...bubble, Input: findByBubbleId(inputs, bubble.id) };
        case BubbleTypes.IMAGE:
            return { ...bubble, Attachment: findByBubbleId(attachments, bubble.id) };
        case BubbleTypes.VIDEO:
            return { ...bubble, Attachment: findByBubbleId(attachments, bubble.id) };
        case BubbleTypes.FILE:
            return { ...bubble, Attachment: findByBubbleId(attachments, bubble.id) };
        case BubbleTypes.STICKER:
            return { ...bubble, Attachment: findByBubbleId(attachments, bubble.id) };
        case BubbleTypes.AUDIO:
            return { ...bubble, Attachment: findByBubbleId(attachments, bubble.id) };

        case BubbleTypes.GENERIC:
            let Elements = getByBubbleId(elements, bubble.id);
            Elements = Elements.map((element) => {
                return { ...element, Options: getOptions(options, { optionableId: element.id, optionable: "Element" }) };
            });
            return { ...bubble, Elements };
        case BubbleTypes.CONDITIONAL:
            return { ...bubble, Conditional: findByBubbleId(conditionals, bubble.id) };
        case BubbleTypes.RESOURCE:
            return { ...bubble, BubbleResource: findByBubbleId(bubbleResources, bubble.id) };
        case BubbleTypes.RANDOM:
            let Randoms = getByBubbleId(randoms, bubble.id);
            return { ...bubble, Randoms };
        case BubbleTypes.QUICK_REPLY:
            let QuickReplies = getQuickReplies(quickReplies, { repliableId: bubble.id, repliable: "Bubble" });
            return { ...bubble, QuickReplies };
        case BubbleTypes.CONTACT_BUBBLE:
            return { ...bubble, contactsCard: findByBubbleId(contactsCards, bubble.id) };
        case BubbleTypes.SANDBOX:
            return { ...bubble, Sandbox: findByBubbleId(sandboxes, bubble.id) };
        case BubbleTypes.CHECKPOINT:
            return { ...bubble };
        case BubbleTypes.PMA:
            return { ...bubble, BubblePma: findByBubbleId(pmaBubbles, bubble.id) };
        case BubbleTypes.TOOLKIT:
            return { ...bubble };
        case BubbleTypes.RUN_ACTION:
            return { ...bubble, BubbleRunAction: findByBubbleId(runActionBubbles, bubble.id) };
        default:
            return { ...bubble };
    }
}

function getByBubbleId(arr, bubbleId) {
    return List(arr)
        .filter((i) => i.bubbleId === bubbleId)
        .toJS();
}

function findByBubbleId(arr, bubbleId) {
    return List(arr).find((i) => i.bubbleId === bubbleId);
}

function getOptions(options, source) {
    let { optionable, optionableId } = source;
    return List(options)
        .filter((option) => {
            return option.optionable === optionable && option.optionableId === optionableId;
        })
        .toJS();
}

function getQuickReplies(quickReplies, source) {
    let { repliable, repliableId } = source;
    return List(quickReplies)
        .filter((quickReply) => {
            return quickReply.repliable === repliable && quickReply.repliableId === repliableId;
        })
        .toJS();
}

// function getByActions(arr = [], actions = []) {
//     return arr.filter((item) => {
//         return includes(actions, item.action);
//     });
// }
