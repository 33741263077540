import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";

import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import FormCombobox from "../../../../common/FormCombobox";
import SlideOver from "../../../../common/SlideOver";
import FlowIcon from "../../../../icons/FlowIcon";
import SettingsIcon from "../../../../icons/SettingsIcon";
import DeleteOption from "./DeleteButton";
import FlowButtonSettings from "./FlowButtonSettings";

import { updateOption } from "../../../../../actions/options";
import { NEW, UPDATE } from "../../../../../constants";
import CanRenderOption from "../../../../common/CanRenderOption";

const INPUTS_NAMES = {
    TITLE: "title",
    DESCRIPTION: "description",
};

const LIMIT_CHARACTERS_DESCRIPTION = 72;

const FlowButton = (props) => {
    const { intents, flows, option, index, setFlow, deleteOption } = props;
    const currentFlow = flows.find((flow) => flow.id === option.payload.flowId);
    const [toFlow, setToFlow] = useState(currentFlow || "");
    const [showJson, setShowJson] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [hasError, setError] = useState(null);

    const dispatch = useDispatch();

    let flows_intents = [];
    flows_intents = flows;

    const { t } = useTranslation();

    const handleSelectChange = ({ id }) => {
        const payload = {
            ...option.payload,
            type: "flow",
            flowId: id,
        };
        dispatch(
            updateOption({
                ...option,
                payload,
                action: option.action === NEW ? NEW : UPDATE,
            })
        );
    };

    const handleChange = ({ target }) => {
        const { value, name } = target;

        if (name === INPUTS_NAMES.DESCRIPTION && value.length >= LIMIT_CHARACTERS_DESCRIPTION) {
            setError(t("common.errorLimitDescription"));
            return;
        }

        setError(null);

        const newOption = {
            ...option,
            [name]: value,
            action: option.action === NEW ? NEW : UPDATE,
        };

        dispatch(updateOption(newOption));
    };

    const handleGoToFlow = (evt) => {
        const intent = intents.find((intent) => intent.slug === toFlow.intent);
        setFlow(intent);
    };

    const handleFlowChange = (flow) => {
        setToFlow(flow);
    };

    const handleClick = () => {
        setShowJson(!showJson);
    };

    function closeModal() {
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true);
    }

    const flowOptions = flows_intents.map((flow) => {
        return {
            ...flow,
            id: flow.id,
            name: flow.title,
        };
    });

    return (
        <Draggable draggableId={option.id.toString()} index={index}>
            {(provided) => (
                <div>
                    <SlideOver
                        isOpen={isOpen}
                        title={t(`common.optionName`, { optionName: !isEmpty(option.title) ? option.title : "" })}
                        closeModal={closeModal}>
                        <div className="w-full px-5 py-4">
                            <div className="grid gap-4 text-sm font-semibold text-gray-400 text-opacity-75">
                                <label htmlFor={INPUTS_NAMES.TITLE} className="flex flex-col">
                                    <span className="flex gap-1">
                                        {t("common.Nombre")}
                                        <span className="text-red-200">*</span>
                                    </span>
                                    <input
                                        autoFocus
                                        type="text"
                                        placeholder="Escribe un nombre"
                                        name={INPUTS_NAMES.TITLE}
                                        value={option.title}
                                        onChange={handleChange}
                                        className="form-input"
                                    />
                                </label>
                                <label htmlFor={INPUTS_NAMES.DESCRIPTION} className="flex flex-col">
                                    <span className="flex gap-1">
                                        {t("common.description")}
                                        <small>(opcional)</small>
                                    </span>
                                    <textarea
                                        name={INPUTS_NAMES.DESCRIPTION}
                                        value={option?.description}
                                        placeholder="Escribe una descripción"
                                        onChange={handleChange}
                                        className={Boolean(hasError) ? "form-input--error resize-none" : "form-input resize-none"}
                                    />
                                    {Boolean(hasError) && <span className="mt-2 text-xs text-error">{hasError}</span>}
                                </label>
                                <label htmlFor="flujo" className="flex flex-col">
                                    <span className="flex gap-1">
                                        {t("flows.Flujo")}
                                        <span className="text-red-200">*</span>
                                    </span>
                                    <FormCombobox
                                        placeholder="Selecciona un flujo"
                                        options={orderBy(flowOptions, ["name"], ["asc"])}
                                        value={flowOptions.find((flow) => flow.id === option.payload.flowId)}
                                        handleChange={(evt) => {
                                            handleSelectChange(evt);
                                            handleFlowChange(evt);
                                        }}
                                        name={t("flows.Flujo")}
                                        background={"#fff"}
                                        hasCleanFilter={false}
                                    />
                                </label>
                            </div>

                            <div className="mt-3 flex justify-end">
                                <div>
                                    <button
                                        className="mr-1 flex items-center justify-center rounded-lg border border-input bg-white px-2 py-1 text-primary-200 disabled:cursor-not-allowed disabled:text-grey-100"
                                        onClick={handleGoToFlow}
                                        disabled={isEmpty(toFlow)}>
                                        <div className="flex items-center justify-center">
                                            <FlowIcon className="fill-current" width="16px" height="16px" />
                                        </div>
                                    </button>
                                </div>
                                <CanRenderOption option="view flow bubble settings">
                                    <button
                                        className="flex cursor-pointer items-center justify-center rounded-lg border border-input bg-white px-2 py-1 text-primary-200"
                                        onClick={handleClick}>
                                        <SettingsIcon className="fill-current" width="16px" height="16px" />
                                    </button>
                                </CanRenderOption>
                            </div>
                        </div>
                        <FlowButtonSettings show={showJson} updateOption={updateOption} option={option} />
                    </SlideOver>

                    <div
                        onClick={openModal}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`relative flex cursor-pointer flex-col items-center justify-center rounded-10 border-2 bg-white px-4 py-2 text-center ${
                            isOpen ? "border-primary-200" : "border-transparent"
                        }`}>
                        <span className="text-center font-sans text-sm font-bold leading-normal text-primary-200">
                            {option.title || t("common.Nombre de la opción")}
                        </span>
                        {currentFlow && (
                            <span className="mb-px rounded-md bg-grey-badge px-1 py-px text-center text-xs text-gray-400">{currentFlow.title}</span>
                        )}
                        <DeleteOption option={option} deleteOption={deleteOption} buttonCounter={props.buttonCounter} />
                    </div>
                </div>
            )}
        </Draggable>
    );
};

export default FlowButton;
