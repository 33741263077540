import { DELETE, ADD_ELEMENT, ADD_ELEMENTS, UPDATE_ELEMENT, SET_UNSAVED_CHANGES, DELETE_ELEMENTS } from "../constants";
import { deleteBubble } from "./bubbles";

export const addElements = (elements) => ({
    type: ADD_ELEMENTS,
    payload: elements,
});

export function addElement(element) {
    return function(dispatch) {
        dispatch({
            type: ADD_ELEMENT,
            payload: element,
        });
        dispatch({
            type: SET_UNSAVED_CHANGES,
        });
    };
}

export function updateElement(element) {
    return function(dispatch) {
        dispatch({
            type: UPDATE_ELEMENT,
            payload: element,
        });

        dispatch({
            type: SET_UNSAVED_CHANGES,
        });
    };
}
export const deleteElements = (elements) => ({
    type: DELETE_ELEMENTS,
    payload: elements,
});
export function deleteElement(element) {
    return function(dispatch, getState) {
        dispatch({
            type: UPDATE_ELEMENT,
            payload: { ...element, action: DELETE },
        });

        // Check if there is no more elements for bubble
        let { elements } = getState();
        let remainingElements = elements.filter((e) => e.bubbleId === element.bubbleId && e.action !== DELETE);

        if (remainingElements.length === 0) {
            dispatch(deleteBubble({ id: element.bubbleId }));
        }

        dispatch({
            type: SET_UNSAVED_CHANGES,
        });
    };
}
