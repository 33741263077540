import first from "lodash/first";
import orderBy from "lodash/orderBy";
import isEmpty from "lodash/isEmpty";
// import includes from "lodash/includes";
import isNil from "lodash/isNil";

import Fuse from "fuse.js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { confirmAlert } from "react-confirm-alert";
import { useSelector, useDispatch } from "react-redux";

import { saveResource, getResources, deleteResource, updateResource, createResource, toggleAddingResource } from "../../actions/resources";

import Layout from "../common/Layout";
import Resource from "./components/Resource";
import ResourceList from "./components/ResourcesList";
import ConfirmModal from "../common/ConfirmModal";
import { ResourceMethods, ResourceTypes } from "../../constants";
import { Renderize } from "../common/Renderize";
import { notifyError } from "../common/Toasts";
import { getCompanies } from "../../actions/companies";

const Resources = (props) => {
    const resources = useSelector((state) => state.resources);
    const companies = useSelector((state) => state.companies);
    // const permissions = useSelector((state) => state.permissions);
    const resourceState = useSelector((state) => state.resourceState);
    const addingResource = useSelector((state) => state.addingResource);

    const [resourceName, setResourceName] = useState("");
    const [query, setQuery] = useState("");
    const [company, setCompany] = useState(first(companies));
    const [resourceId, setResourceId] = useState("");
    const [loadinResources, setLoadingResources] = useState(false);
    const [newResource, setNewResource] = useState({
        type: -1,
        method: -1,
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const fuseOptions = {
        threshold: 0.1,
        keys: ["name"],
    };

    useEffect(() => {
        dispatch(getCompanies());
    }, []);

    useEffect(() => {
        if (!isEmpty(companies)) {
            setCompany(first(orderBy(companies, ["name"], ["asc"])));
        }
    }, [companies]);

    // useEffect(() => {
    //     if (!isEmpty(resources)) {
    //         setNewResource(first(orderBy(resources, ["name"], ["asc"])));
    //         setResourceId(first(orderBy(resources, ["name"], ["asc"])).id);
    //     }
    // }, [resources]);

    useEffect(() => {
        if (!isEmpty(company)) {
            setNewResource({
                type: -1,
                method: -1,
            });
            setLoadingResources(true);
            dispatch(getResources(company.id)).then(() => {
                setLoadingResources(false);
            });
        }
    }, [company]);

    const createRes = (companyId) => {
        if (!isEmpty(resourceName)) {
            dispatch(createResource(resourceName, companyId));
            dispatch(toggleAddingResource());
            setResourceName("");
        } else {
            notifyError(t("resources.Por favor ingrese el nombre del recurso para poder guardar"));
        }
    };

    const handleResourceName = (e) => {
        setResourceName(e.target.value);
    };

    const handleCompany = (company) => {
        setCompany(company);
    };

    const handleSearch = (e) => {
        setQuery(e.target.value);
    };

    const searchResources = () => {
        if (isEmpty(query)) {
            return resources;
        }

        const fuse = new Fuse(resources, fuseOptions);
        const result = fuse.search(query);

        let resourcesSearch = [];
        result.map((resource) => {
            return resourcesSearch.push(resource.item);
        });
        return resourcesSearch;
    };

    const filteredResources = searchResources();

    const selectResource = (resource) => {
        const { id } = resource;
        setResourceId(id);

        const rscType = ResourceTypes.find((type) => type.value === resource.type)?.value;
        const rscMethods = ResourceMethods.find((type) => type.value === resource.method)?.value;

        if (isNil(rscType)) {
            setNewResource({ ...resource, type: null, method: null });
            return;
        }
        if (isNil(rscMethods)) {
            setNewResource({ ...resource, type: rscType, method: null });
            return;
        }
        setNewResource({ ...resource, type: rscType, method: rscMethods });
    };

    const handleChange = (e) => {
        const value = e.name === "companyId" ? e.id : e.value;
        const name = e.name;
        dispatch(updateResource({ id: resourceId, [name]: value }));
        setNewResource({ ...newResource, [name]: value });
    };

    const handleInputs = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        dispatch(updateResource({ id: resourceId, [name]: value }));
        setNewResource({ ...newResource, [name]: value });
    };

    const handleJSON = (json) => {
        dispatch(
            updateResource({
                id: resourceId,
                response: json,
            })
        );
        setNewResource({ ...newResource, response: json });
    };

    const handleDelete = (resource) => {
        confirmAlert({
            customUI: ({ onClose }) => (
                <ConfirmModal
                    title={`Estas seguro que deseas eliminar ${resource.name}?`}
                    onClose={onClose}
                    onConfirm={() => {
                        dispatch(deleteResource(resource));
                        setResourceId("");
                        onClose();
                    }}
                />
            ),
        });
    };

    return (
        <Layout>
            <Renderize permission="view resource view">
                <div className="flex w-full">
                    <ResourceList
                        addingResource={addingResource}
                        resources={resources}
                        createResource={createRes}
                        toggleAddingResource={toggleAddingResource}
                        handleResourceName={handleResourceName}
                        companies={companies}
                        handleCompany={handleCompany}
                        handleSearch={handleSearch}
                        company={company}
                        filteredResources={filteredResources}
                        selectResource={selectResource}
                        resourceId={resourceId}
                        handleDelete={handleDelete}
                        loadinResources={loadinResources}
                    />
                    <Resource
                        resource={newResource}
                        resourceState={resourceState}
                        handleChange={handleChange}
                        handleInputs={handleInputs}
                        companies={companies}
                        handleJSON={handleJSON}
                        saveResource={saveResource}
                    />
                </div>
            </Renderize>
        </Layout>
    );
};

export default Resources;
