import TextArea from "../../common/FormComponent/TextArea";

import toUpper from "lodash/toUpper";
import { useTranslation } from "react-i18next";

const BubbleInputSettings = (props) => {
    const { t } = useTranslation();
    const bellowsReprompt = toUpper(props.channel) === "GOOGLE" || toUpper(props.channel) === "ALEXA";

    return (
        <div className="grid gap-4 pb-4 text-sm font-semibold text-gray-400 text-opacity-75">
            <label className="flex flex-col">
                {t("bubbles.Intentos máximos")}
                <input
                    className="form-input"
                    type="tel"
                    defaultValue={props.properties.maxAttempts}
                    onChange={props.handleChange}
                    placeholder={`${t("common.ej")}: ${t("3")}`}
                    name="maxAttempts"
                    min="0"
                />
            </label>

            <label className="flex flex-col">
                {t("bubbles.Mensaje intento máximos")}
                <input
                    className="form-input"
                    type="text"
                    defaultValue={props.properties.maxAttemptsMessage}
                    onChange={props.handleChange}
                    placeholder={`${t("common.ej")}: ${t("bubbles.Has excedido el máximo de intentos")}`}
                    name="maxAttemptsMessage"
                />
            </label>

            <label className="flex flex-col">
                {t("bubbles.Vocablos para cancelar")}
                <TextArea
                    defaultValue={props.properties.cancelExpressions}
                    onChange={props.handleChange}
                    placeholder={`${t("common.ej")}: ${t("bubbles.Cancelar, salir")}`}
                    name="cancelExpressions"
                    minHeight={75}
                />
            </label>

            <label className="flex flex-col">
                {t("bubbles.Mensaje al cancelar")}
                <input
                    className="form-input"
                    type="text"
                    defaultValue={props.properties.cancelExpressionsMessage}
                    onChange={props.handleChange}
                    placeholder={`${t("common.ej")}: ${t("bubbles.Has cancelado tu solicitud")}`}
                    name="cancelExpressionsMessage"
                />
            </label>

            {bellowsReprompt && (
                <label className="flex flex-col">
                    {t("bubbles.Al volver a preguntar")}
                    <TextArea
                        defaultValue={props.properties.reprompt}
                        onChange={props.handleChange}
                        placeholder={t("bubbles.Mensaje de volver a preguntar por defecto")}
                        name="reprompt"
                        minHeight={75}
                    />
                </label>
            )}
        </div>
    );
};

export default BubbleInputSettings;
