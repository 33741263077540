import React from "react";

import Icon from "./Icon";

const BlocksIcon = (props) => (
    <Icon viewBox="0 0 21 13" className={props.className} width={props.width} height={props.height}>
        <path
            d="M4.56134 0.97388C3.12452 2.57035 1.7105 4.14402 0.273681 5.74049C-0.0912269 6.15101 -0.0912269 6.69837 0.273681 7.08609C1.7105 8.68256 3.12452 10.2562 4.56134 11.8527C4.90344 12.2404 5.56484 12.1948 5.90694 11.8527C6.29466 11.465 6.24904 10.8948 5.90694 10.5071C4.47012 8.91063 3.05611 7.33696 1.61928 5.74049C1.61928 6.19662 1.61928 6.65276 1.61928 7.08609C3.05611 5.48962 4.47012 3.91595 5.90694 2.31948C6.24904 1.93177 6.29466 1.33879 5.90694 0.97388C5.56484 0.608972 4.90344 0.586165 4.56134 0.97388Z"
            fill={props.fill}
        />
        <path
            d="M15.9192 11.8525C17.356 10.256 18.77 8.68236 20.2068 7.08589C20.5717 6.67537 20.5717 6.12801 20.2068 5.74029C18.77 4.14382 17.356 2.57015 15.9192 0.973685C15.5771 0.585971 14.9157 0.631584 14.5736 0.973685C14.1859 1.3614 14.2315 1.93157 14.5736 2.31929C16.0104 3.91576 17.4244 5.48942 18.8612 7.08589C18.8612 6.62976 18.8612 6.17362 18.8612 5.74029C17.4244 7.33676 16.0104 8.91043 14.5736 10.5069C14.2315 10.8946 14.1859 11.4876 14.5736 11.8525C14.9157 12.1946 15.5771 12.2174 15.9192 11.8525Z"
            fill={props.fill}
        />
        <path
            d="M10.742 0.67726C9.89811 3.8702 9.07706 7.08595 8.23322 10.2789C8.11918 10.735 8.00515 11.1912 7.89112 11.6245C7.57182 12.8104 9.41916 13.335 9.73845 12.1262C10.5823 8.93329 11.4033 5.71755 12.2472 2.52461C12.3612 2.06848 12.4753 1.61234 12.5893 1.17901C12.9086 -0.00693888 11.0612 -0.508689 10.742 0.67726Z"
            fill={props.fill}
        />
    </Icon>
);

export default BlocksIcon;
