import get from "lodash/get";
import { useDispatch } from "react-redux";
import TrashIcon from "../../icons/TrashIcon";

import CanRenderOption from "../../common/CanRenderOption";

const Expression = (props) => {
    const { deleteExpression, flow, expression } = props;
    const ButtonStyles = "group-hover:flex h-full px-2 items-center justify-center hidden";
    const liStyle = "group pl-2 min-h-10 h-10 border-transparent flex flex-wrap break-all relative";

    const dispatch = useDispatch();

    return (
        <li className={liStyle}>
            <div className="select flex h-full flex-1 cursor-pointer flex-wrap items-center text-gray-400 group-hover:text-primary-200">
                <div>{get(expression, "source", "")}</div>
            </div>
            <div className="flex">
                <CanRenderOption option="delete expressions">
                    <button className={ButtonStyles} onClick={() => dispatch(deleteExpression(flow.intent, expression.id))}>
                        <TrashIcon className="fill-current text-gray-400 hover:text-primary-200" width="18px" height="18px" />
                    </button>
                </CanRenderOption>
            </div>
        </li>
    );
};

export default Expression;
