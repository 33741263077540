import { combineReducers } from "redux";
import bot from "./bot";
import user from "./user";
import flows from "./flows";
import errors from "./errors";
import inputs from "./inputs";
import { flow } from "./flow";
import session from "./session";
import bubbles from "./bubbles";
import reports from "./reports";
import metrics from "./metrics";
import options from "./options";
import randoms from "./randoms";
import elements from "./elements";
import sandboxes from "./sandboxes";
import companies from "./companies";
import operators from "./operators";
import pmaBubbles from "./pmaBubbles";
import permissions from "./permissions";
import attachments from "./attachments";
import impersonate from "./impersonate";
import unauthorized from "./unathorized";
import conditionals from "./conditionals";
import quickReplies from "./quickReplies";
import transactions from "./transactions";
import contactsCards from "./contactsCards";
import unSavedChanges from "./unSavedChanges";
import bubbleResources from "./bubbleResources";
import { intents, addingIntent, loadingIntents } from "./intents";
import { contexts, contextDetail } from "./contexts";
import loadingPermissions from "./loadingPermissions";
import { expressions, expressionState } from "./expressions";
import { resources, addingResource, resourceState, resourcesLight } from "./resources";
import runActionBubbles from "./runAction";
import savingChanges from "./savingChanges";
import intent from "./intent";
import testBot from "./testBot";
import { isLoading, isLoadingFlow } from "./isLoading";
import savedErrors from "./savedErrors";

const allReducers = combineReducers({
    user,
    permissions,
    bot,
    flow,
    expressions,
    intent,
    flows,
    companies,
    errors,
    inputs,
    reports,
    randoms,
    metrics,
    bubbles,
    options,
    intents,
    sandboxes,
    runActionBubbles,
    pmaBubbles,
    elements,
    resources,
    resourcesLight,
    attachments,
    quickReplies,
    conditionals,
    resourceState,
    expressionState,
    bubbleResources,
    contactsCards,
    operators,
    transactions,
    contexts,
    contextDetail,
    session,
    unSavedChanges,
    isLoading,
    isLoadingFlow,
    loadingPermissions,
    addingResource,
    addingIntent,
    loadingIntents,
    unauthorized,
    impersonate,
    savingChanges,
    isOpenBotTester: testBot,
    savedErrors,
});

const rootReducer = (state, action) => {
    if (action.type === "LOGOUT") {
        state = undefined;
    }
    return allReducers(state, action);
};

export default rootReducer;
