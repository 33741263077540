import React from "react";
import Icon from "./Icon";

const GroupIcon = (props) => (
    <Icon viewBox="0 0 20 19" className={props.className} width={props.width} height={props.height}>
        <path
            d="M0.616297 1.26234H19.2768C19.682 1.26234 19.8931 0.944436 19.8931 0.630924C19.8931 0.317413 19.682 5.60149e-07 19.2768 5.60149e-07H0.616297C0.211121 -0.000487016 0 0.317413 0 0.630924C0 0.944436 0.211121 1.26234 0.616297 1.26234Z"
            fill={props.fill}
            fillOpacity={props.fillOpacity}
        />
        <path
            d="M19.2768 10.0762H0.616297C0.211121 10.0762 0 10.3941 0 10.7076C0 11.0211 0.211121 11.3385 0.616297 11.3385H19.2768C19.682 11.3385 19.8931 11.0206 19.8931 10.7071C19.8931 10.3936 19.682 10.0762 19.2768 10.0762Z"
            fill={props.fill}
            fillOpacity={props.fillOpacity}
        />
        <path
            d="M5.85042 3.9897C5.83092 3.34659 5.41648 2.81074 4.8192 2.65521C4.6305 2.60596 4.44181 2.60986 4.2541 2.61132L4.14146 2.6123L2.62608 2.61035C2.25503 2.6084 1.88594 2.60645 1.51245 2.6123C0.95369 2.6201 0.468551 2.91996 0.246217 3.39388C0.109695 3.68497 0.109695 3.97946 0.109695 4.23885L0.107745 6.40272C0.0984809 7.00195 0.0882418 7.68114 0.714778 8.11606C1.02975 8.33547 1.37934 8.35351 1.71041 8.35351H4.42865C4.43694 8.354 4.44522 8.354 4.45303 8.354C4.49203 8.354 4.53299 8.35254 4.57004 8.34717C5.27118 8.2794 5.80264 7.74697 5.84847 7.05071C5.85578 6.93369 5.85383 6.8157 5.85237 6.69868L5.85042 4.51482L5.85237 4.34417C5.85335 4.22569 5.85432 4.10721 5.85042 3.9897ZM1.37886 7.01365C1.36228 6.95856 1.36569 6.86445 1.36862 6.78205L1.37154 4.10672C1.37057 3.95752 1.37886 3.92193 1.41786 3.89658C1.44858 3.87707 1.49978 3.87268 1.55731 3.87268C1.58071 3.87268 1.6046 3.87366 1.62898 3.87415L4.24971 3.87561L4.32967 3.87415C4.40573 3.87268 4.5052 3.86976 4.54079 3.89316C4.58175 3.91998 4.58906 3.95508 4.58809 4.14475V6.86202C4.58809 7.08191 4.52665 7.08923 4.47107 7.09166H1.58413C1.40177 7.09069 1.39153 7.05558 1.37886 7.01365Z"
            fill={props.fill}
            fillOpacity={props.fillOpacity}
        />
        <path
            d="M12.8172 3.98921C12.7967 3.34708 12.3823 2.81074 11.786 2.65521C11.5973 2.60596 11.4096 2.60986 11.2209 2.61132L11.1083 2.6123L9.59287 2.61035C9.22183 2.6084 8.85078 2.60645 8.47925 2.6123C7.92048 2.6201 7.43535 2.91996 7.21301 3.39388C7.07551 3.68692 7.07551 3.98044 7.07551 4.23885L7.07356 6.40272C7.0643 7.00195 7.05406 7.68114 7.68157 8.11606C7.99655 8.33547 8.34614 8.35351 8.6772 8.35351H11.3945C11.4028 8.354 11.411 8.354 11.4188 8.354C11.4588 8.354 11.4978 8.35254 11.5368 8.34717C12.237 8.2794 12.7685 7.74697 12.8153 7.05071C12.8226 6.93369 12.8206 6.8157 12.8192 6.69868L12.8172 6.55143L12.8182 4.3432C12.8192 4.22569 12.8211 4.1077 12.8172 3.98921ZM8.34467 7.01511C8.32907 6.95953 8.33249 6.86592 8.33541 6.78352L8.33834 4.10428C8.33736 3.95703 8.34565 3.92193 8.38563 3.8956C8.41537 3.87658 8.46657 3.8722 8.52313 3.8722C8.54653 3.8722 8.5714 3.87317 8.59578 3.87366L11.2155 3.87512L11.2965 3.87366C11.3725 3.8722 11.473 3.86927 11.5066 3.89219C11.5466 3.919 11.5549 3.95606 11.5539 4.14426V6.86153C11.5539 7.08191 11.4954 7.08874 11.4369 7.09118H8.54946C8.36759 7.09069 8.35686 7.05558 8.34467 7.01511Z"
            fill={props.fill}
            fillOpacity={props.fillOpacity}
        />
        <path
            d="M19.7845 3.98921C19.764 3.34708 19.3486 2.81074 18.7518 2.65521C18.5631 2.60596 18.3754 2.60986 18.1867 2.61132L18.0741 2.6123L16.5587 2.61035C16.1876 2.6084 15.8186 2.60645 15.4451 2.6123C14.8873 2.6201 14.4021 2.91996 14.1798 3.3934C14.0423 3.68448 14.0423 3.97898 14.0423 4.23885L14.0404 6.40272C14.0311 7.00195 14.0209 7.68114 14.6474 8.11655C14.9643 8.33547 15.3129 8.35351 15.644 8.35351H18.3613C18.3696 8.354 18.3778 8.354 18.3856 8.354C18.4256 8.354 18.4656 8.35254 18.5027 8.34717C19.2038 8.2794 19.7353 7.74697 19.7811 7.05071C19.7884 6.93125 19.7874 6.81131 19.785 6.69088L19.784 6.55143L19.785 4.3432C19.7865 4.22569 19.7889 4.1077 19.7845 3.98921ZM15.312 7.01365C15.2954 6.95953 15.2998 6.8664 15.3027 6.78498C15.3046 6.74597 15.3056 6.70794 15.3056 6.67284V4.10428C15.3046 3.95703 15.3129 3.92193 15.3529 3.8956C15.3827 3.87658 15.4339 3.8722 15.4904 3.8722C15.5138 3.8722 15.5387 3.87317 15.5631 3.87366L18.1828 3.87512L18.2628 3.87366C18.3379 3.8722 18.4383 3.86927 18.4739 3.89267C18.5149 3.91949 18.5222 3.9546 18.5212 4.14426V6.86153C18.5212 7.08191 18.4627 7.08874 18.4042 7.09118H15.5167C15.3349 7.09069 15.3246 7.05558 15.312 7.01365Z"
            fill={props.fill}
            fillOpacity={props.fillOpacity}
        />
        <path
            d="M5.85042 14.1906C5.83092 13.5475 5.41648 13.0117 4.82017 12.8561C4.62855 12.8064 4.44181 12.8093 4.25312 12.8118L4.14146 12.8127L2.62608 12.8108C2.25503 12.8098 1.88594 12.8079 1.51245 12.8127C0.95369 12.8205 0.468551 13.1204 0.246217 13.5943C0.109695 13.8854 0.109695 14.1799 0.109695 14.4393L0.107745 16.6032C0.0984809 17.2024 0.0882418 17.8816 0.714778 18.3165C1.02975 18.5359 1.37934 18.554 1.71041 18.554H4.42962C4.43791 18.5544 4.4462 18.5544 4.454 18.5544C4.49496 18.5554 4.53299 18.5535 4.56907 18.5481C5.2702 18.4813 5.80264 17.9484 5.84847 17.2511C5.85578 17.1341 5.85383 17.0161 5.85237 16.8991L5.85042 14.7153L5.85237 14.5446C5.85335 14.4256 5.85432 14.3072 5.85042 14.1906ZM1.37886 17.2141C1.36228 17.1585 1.36569 17.0649 1.36862 16.9825L1.37154 14.3072C1.37057 14.158 1.37886 14.1224 1.41786 14.097C1.44858 14.077 1.50173 14.0731 1.55926 14.0731C1.58266 14.0731 1.60655 14.0736 1.62996 14.0741L4.24971 14.0756L4.32967 14.0741C4.40134 14.0722 4.50276 14.0687 4.54079 14.0936C4.58175 14.1204 4.58906 14.1555 4.58809 14.3452V17.0625C4.58809 17.2819 4.52665 17.2892 4.47107 17.2916H1.58413C1.40177 17.2911 1.39153 17.2555 1.37886 17.2141Z"
            fill={props.fill}
            fillOpacity={props.fillOpacity}
        />
        <path
            d="M12.8182 14.5436C12.8192 14.4256 12.8211 14.3076 12.8172 14.1901C12.7967 13.548 12.3823 13.0117 11.787 12.8561C11.5953 12.8064 11.4076 12.8093 11.2199 12.8118L11.1083 12.8127L9.59287 12.8108C9.21987 12.8098 8.84834 12.8079 8.47925 12.8127C7.92048 12.8205 7.43535 13.1204 7.21301 13.5943C7.07551 13.8874 7.07551 14.1809 7.07551 14.4393L7.07356 16.6032C7.0643 17.2024 7.05406 17.8816 7.68157 18.3165C7.99655 18.5359 8.34614 18.554 8.6772 18.554H11.3954C11.4037 18.5544 11.412 18.5544 11.4198 18.5544C11.4598 18.5554 11.4988 18.5535 11.5349 18.5481C12.236 18.4813 12.7685 17.9484 12.8153 17.2511C12.8226 17.1341 12.8206 17.0161 12.8192 16.8991L12.8172 16.7524L12.8182 14.5436ZM8.34467 17.2156C8.32907 17.1595 8.33249 17.0664 8.33541 16.984L8.33834 14.3042C8.33736 14.1555 8.34468 14.1229 8.38563 14.096C8.41537 14.0765 8.46754 14.0726 8.52508 14.0726C8.54848 14.0726 8.57335 14.0731 8.59675 14.0736L11.2155 14.0751L11.2965 14.0736C11.3691 14.0717 11.4705 14.0692 11.5066 14.0926C11.5476 14.1194 11.5549 14.1545 11.5539 14.3447V17.062C11.5539 17.2819 11.4954 17.2887 11.4369 17.2911H8.54946C8.36759 17.2911 8.35686 17.2555 8.34467 17.2156Z"
            fill={props.fill}
            fillOpacity={props.fillOpacity}
        />
        <path
            d="M19.7845 14.1901C19.764 13.548 19.3486 13.0117 18.7533 12.8561C18.5617 12.8064 18.373 12.8093 18.1862 12.8118L18.0746 12.8127L16.5592 12.8108C16.1881 12.8098 15.819 12.8079 15.4456 12.8127C14.8878 12.8205 14.4026 13.1204 14.1803 13.5938C14.0428 13.8849 14.0428 14.1794 14.0428 14.4393L14.0409 16.6032C14.0316 17.2024 14.0213 17.8816 14.6479 18.317C14.9648 18.5359 15.3134 18.554 15.6445 18.554H18.3627C18.371 18.5544 18.3793 18.5544 18.3871 18.5544C18.4271 18.5554 18.4661 18.5535 18.5022 18.5481C19.2033 18.4813 19.7357 17.9484 19.7816 17.2511C19.7889 17.1317 19.7879 17.0113 19.7855 16.8918L19.7845 16.7524L19.7855 14.5436C19.7865 14.4256 19.7889 14.3081 19.7845 14.1901ZM15.312 17.2141C15.2954 17.16 15.2998 17.0668 15.3027 16.9854C15.3046 16.9464 15.3056 16.9084 15.3056 16.8733V14.3042C15.3046 14.1555 15.312 14.1229 15.3529 14.096C15.3827 14.0765 15.4348 14.0726 15.4924 14.0726C15.5158 14.0726 15.5397 14.0731 15.564 14.0736L18.1828 14.0751L18.2628 14.0736C18.3344 14.0717 18.4368 14.0682 18.4739 14.0931C18.5149 14.1199 18.5222 14.155 18.5212 14.3447V17.062C18.5212 17.2819 18.4627 17.2887 18.4042 17.2911H15.5167C15.3349 17.2911 15.3246 17.2555 15.312 17.2141Z"
            fill={props.fill}
            fillOpacity={props.fillOpacity}
        />
    </Icon>
);
export default GroupIcon;
