import { Dialog, Transition } from "@headlessui/react";
import CloseIcon from "../icons/CloseIcon";
import { BeatLoader } from "react-spinners";

const ModalHeadless = ({
    children,
    isShowModal = false,
    closeModal = () => null,
    handleClickPrimaryButton = () => null,
    className = "",
    titleModal = null,
    textButtonSecondary = "",
    textButtonPrimary = "",
    loading = false,
    disablePrimaryBtn = false,
    showButtons = false,
    minHeight = false,
} = {}) => {
    return (
        <Transition appear show={isShowModal} as={"div"}>
            <Dialog as="div" className="relative z-50" open={isShowModal} onClose={closeModal}>
                <Transition.Child
                    as={"div"}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTomb-5
                    text-base
                    font-bold
                    text-gray-400="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-smoke-light bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={"div"}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel>
                                <section
                                    className={`relative inline-block min-w-96 max-w-md transform overflow-hidden rounded-20 bg-white text-left align-middle shadow-xl transition-all ${className}`}>
                                    <div className="flex items-start justify-end pr-4 pt-4">
                                        <button
                                            aria-label="Close"
                                            onClick={(evt) => {
                                                evt.preventDefault();
                                                closeModal();
                                            }}>
                                            <CloseIcon className="stroke-current text-gray-400" width="32" height="32" />
                                        </button>
                                    </div>
                                    <div className={`px-4 ${minHeight}`}>
                                        {titleModal && <h3 className="mb-5 text-base font-bold text-gray-400">{titleModal}</h3>}
                                        {children}
                                    </div>
                                    {showButtons && (
                                        <footer className="flex h-20 items-center justify-end gap-4 bg-modal-footer px-4">
                                            <button
                                                type="button"
                                                onClick={(evt) => {
                                                    evt.preventDefault();
                                                    closeModal();
                                                }}
                                                className="button-cancel-xl rounded-3xl border-transparent px-5 py-2 text-base font-bold outline-none">
                                                {textButtonSecondary}
                                            </button>
                                            <button
                                                onClick={handleClickPrimaryButton}
                                                disabled={disablePrimaryBtn || loading}
                                                type="submit"
                                                className="button-gradient-xl disabled:cursor-not-allowed disabled:bg-opacity-60">
                                                {loading ? (
                                                    <div className="flex justify-center">
                                                        <BeatLoader color="#fff" />
                                                    </div>
                                                ) : (
                                                    textButtonPrimary
                                                )}
                                            </button>
                                        </footer>
                                    )}
                                </section>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default ModalHeadless;
