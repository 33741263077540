import shortid from "shortid";
import get from "lodash/get";
import first from "lodash/first";
import { omitKeys } from "../helpers";
import Http from "../services/Http";
import isEmpty from "lodash/isEmpty";
import clone from "lodash/clone";
import { addBubbles } from "./bubbles";
import { ADD_FLOW, DELETE_FLOW, UPDATE_FLOW, UNSET_UNSAVED_CHANGES } from "../constants";

/**
 * Set the current flow, is there is no flow creates a mock
 * @param {Object} flow
 */
export function addFlow(flow) {
    return function (dispatch, getState) {
        // Mock a flow
        if (isEmpty(flow)) {
            const { intent, bot } = getState();
            flow = mockFlow(intent, bot.id);
        }
        dispatch({
            type: ADD_FLOW,
            payload: flow,
        });
    };
}

export const updateFlow = (flow) => ({
    type: UPDATE_FLOW,
    payload: flow,
});

export const deleteFlow = () => ({
    type: DELETE_FLOW,
});

export function setFlow(data) {
    return function (dispatch, getState) {
        //Find flow by providerId
        const { flows, bot, intents } = getState();

        let intent = isEmpty(data) ? first(intents) : data;

        let flow = !isEmpty(flows) ? flows.find((flow) => flow.intent === intent.slug) : [];

        if (isEmpty(flow)) {
            flow = mockFlow(intent, bot.id);
        }

        dispatch({ type: UNSET_UNSAVED_CHANGES });

        dispatch(addFlow(flow));
    };
}

/**
 * Get Flow from server
 * If response is null creates a flow to be saved later
 * @param {Object} intent
 */
export function getFlow(params) {
    const { botId, intentId, nlpDriver } = params;
    return function (dispatch) {
        return Http.get(`/flows/${botId}/${intentId}/${nlpDriver}`)
            .then(({ data: resp }) => {
                const { data } = resp;

                const Flow = omitKeys(data, ["Bubbles"]);

                dispatch(addFlow({ ...Flow, nlpDriver: nlpDriver }));
                dispatch(addBubbles(get(data, "Bubbles", [])));
                return data;
            })
            .catch((err) => {
                dispatch(addFlow([]));
                dispatch(addBubbles([]));
                console.log(`error getting flow of botId: ${botId}`, err);
                return err;
            });
    };
}

/**
 * Create a temporal flow
 * @param {Object} intent
 */
function mockFlow(intent, botId) {
    if (!intent) {
        return;
    }
    // const providerId = {};
    // providerId[intent.nlpDriver] = intent.id;

    return {
        botId: botId,
        type: "default",
        title: intent.name,
        intentId: intent.id,
        intent: intent.slug,
        unSavedChanges: true,
        default: 0,
        providerId: intent.id,
        nlpDriver: intent.nlpDriver,
        id: shortid.generate(),
        Bubbles: [],
    };
}

// Updates the intent's info of the flow if it is selected.
export function updateSelectedFlowByIntent(intent) {
    return function (dispatch, getState) {
        const { flow } = getState();
        if (intent.id === flow.intentId) {
            const newflow = clone(flow, true);
            newflow.intent = intent.slug;
            newflow.title = intent.name;
            dispatch(updateFlow(newflow));
        }
        return;
    };
}
