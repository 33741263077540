import first from "lodash/first";
import sortBy from "lodash/sortBy";
import isEmpty from "lodash/isEmpty";
import React, { Component } from "react";
import { Droppable } from "react-beautiful-dnd";

import UrlButton from "./UrlButton";
import FlowButton from "./FlowButton";
import PhoneButton from "./PhoneButton";

import { OptionTypes } from "../../../../../constants";

class Buttons extends Component {
    render() {
        const buttons = sortBy(this.props.buttons, ["order"]);
        const { bot, flows, updateOption, deleteOption, bubble, intents } = this.props;

        if (!isEmpty(buttons)) {
            const type = first(buttons).optionable;
            const droppableId = bubble.id.toString();

            return (
                <Droppable droppableId={droppableId} type={type}>
                    {(provided) => (
                        <div ref={provided.innerRef} className="relative mt-3 space-y-3 px-3" {...provided.droppableProps}>
                            {buttons.map((option, index) => {
                                switch (option.type) {
                                    case OptionTypes.URL:
                                        return (
                                            <UrlButton
                                                bot={bot}
                                                index={index}
                                                option={option}
                                                updateOption={updateOption}
                                                deleteOption={deleteOption}
                                                key={option.id}
                                                buttonCounter={this.props.buttonCounter}
                                            />
                                        );
                                    case OptionTypes.PHONE:
                                        return (
                                            <PhoneButton
                                                bot={bot}
                                                index={index}
                                                option={option}
                                                key={option.id}
                                                updateOption={updateOption}
                                                deleteOption={deleteOption}
                                                buttonCounter={this.props.buttonCounter}
                                            />
                                        );
                                    case OptionTypes.FLOW:
                                        return (
                                            <FlowButton
                                                bot={bot}
                                                intents={intents}
                                                setFlow={this.props.setFlow}
                                                index={index}
                                                option={option}
                                                key={option.id}
                                                flows={flows}
                                                updateOption={updateOption}
                                                deleteOption={deleteOption}
                                                customAdaptativeHeight={this.props.customAdaptativeHeight}
                                                buttonCounter={this.props.buttonCounter}
                                            />
                                        );
                                    default:
                                        return <div />;
                                }
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            );
        }
        return <div />;
    }
}

export default Buttons;
