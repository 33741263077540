import Tippy from "@tippyjs/react";
import { useTranslation } from "react-i18next";

const itemStyle =
    "px-4 bg-red-100 shadow-checkpoint text-red-400 text-center py-2 flex items-center justify-center bg-white rounded-10 border-2 border-transparent";

const AddButton = (props) => {
    const { errorList, optionsErrorArr, addValidationError } = props;
    const { t } = useTranslation();

    return (
        <Tippy
            content={
                errorList.length === optionsErrorArr.length
                    ? "Máximo número de errores alcanzado"
                    : errorList.length >= 1 && !errorList.every((error) => error.error && error.flowId)
                    ? "Debe finalizar la configuración antes de agregar otro error"
                    : "Agregar error"
            }
            theme="jelou"
            arrow={false}
            placement={"top"}
            touch={false}>
            <div className="relative">
                <div className="relative my-2 flex flex-col justify-center space-y-3 px-3">
                    <button
                        className={`${itemStyle} disabled:cursor-not-allowed disabled:bg-white disabled:text-input`}
                        onClick={() => addValidationError()}
                        disabled={
                            errorList.length === optionsErrorArr.length ||
                            (errorList.length >= 1 && !errorList.every((error) => error.error && error.flowId))
                        }>
                        <span className={`font-sans text-sm font-bold`}>{`+ ${t("Agregar validación de error")}`}</span>
                    </button>
                </div>
            </div>
        </Tippy>
    );
};

export default AddButton;
