import React from "react";

import Icon from "./Icon";

const PinIcon = (props) => (
    <Icon viewBox="0 0 11 12" className={props.className} width={props.width} height={props.height}>
        <path
            d="M7.14437 8.17453C7.23147 8.01134 7.30256 7.8389 7.35899 7.66227C7.46968 7.31772 7.51915 6.95349 7.51878 6.59178C7.51886 6.19476 7.45764 5.79792 7.34575 5.41737C7.82142 4.85232 8.29648 4.28833 8.77215 3.72327C8.8911 3.58132 9.01265 3.44228 9.12947 3.2991C9.2403 3.16384 9.29722 3.01596 9.26192 2.84045C9.22691 2.66938 9.11102 2.56689 8.96693 2.4837C8.80468 2.39002 8.64244 2.29635 8.48019 2.20268C7.81628 1.81937 7.15236 1.43605 6.4895 1.05336C6.32619 0.959067 6.16288 0.86478 5.99957 0.770492C5.92165 0.725505 5.84266 0.679901 5.76474 0.634915C5.69216 0.593009 5.62003 0.552787 5.53631 0.540031C5.37544 0.515466 5.20091 0.571253 5.09181 0.696126C4.97795 0.826788 4.93568 1.01586 4.8781 1.17475C4.59687 1.95027 4.31609 2.72746 4.03486 3.50298C3.6824 3.5884 3.33736 3.72506 3.01892 3.89701C2.68392 4.0779 2.36706 4.30626 2.1073 4.58609C1.96798 4.73621 1.84264 4.90152 1.73501 5.07563C1.642 5.22687 1.60396 5.39134 1.67109 5.56103C1.74502 5.74604 1.90932 5.82382 2.07263 5.91811C2.26156 6.02718 2.44942 6.13565 2.63835 6.24472C3.01728 6.4635 3.3962 6.68227 3.77513 6.90104C3.63708 7.14014 3.49966 7.37817 3.36161 7.61726C3.149 7.98551 2.93701 8.3527 2.7244 8.72095C2.67325 8.80954 2.62103 8.89752 2.57034 8.9878C2.41253 9.27099 2.55592 9.65122 2.88599 9.71797C3.04148 9.74939 3.20109 9.71203 3.32217 9.60831C3.39615 9.54428 3.44134 9.45367 3.48941 9.37041C3.60034 9.17828 3.71126 8.98615 3.82219 8.79402C4.08225 8.34358 4.34231 7.89314 4.60176 7.44377C4.611 7.42775 4.62025 7.41174 4.63011 7.39467C4.97274 7.59249 5.31644 7.79092 5.65908 7.98874C5.85227 8.10028 6.04486 8.21289 6.23867 8.32337C6.32727 8.37452 6.41643 8.43454 6.5132 8.46906C6.59318 8.49816 6.67969 8.50114 6.76338 8.48684C6.94344 8.45844 7.06096 8.33131 7.14437 8.17453Z"
            fill="#00B3C7"
        />
        <path
            d="M5.22713 6.6022C4.43619 6.14556 3.64755 5.68739 2.85661 5.23074C3.05763 5.028 3.28615 4.86391 3.54311 4.7319C3.83716 4.58003 4.15837 4.4723 4.48364 4.41673C4.63097 4.39078 4.73907 4.31941 4.817 4.18936C4.89863 4.05289 4.93958 3.88583 4.99375 3.73778C5.11417 3.40596 5.23413 3.07246 5.35455 2.74064C5.47496 2.40882 5.59476 2.07807 5.71411 1.74564C6.48584 2.19119 7.2565 2.63613 8.02822 3.08169C7.60373 3.58521 7.17863 4.0898 6.75521 4.59394C6.64348 4.72583 6.53282 4.85834 6.41957 4.98794C6.32522 5.09713 6.27141 5.26673 6.30488 5.40845C6.32152 5.48068 6.35653 5.5507 6.37822 5.62158C6.40263 5.70256 6.42474 5.78506 6.44409 5.86739C6.48071 6.02089 6.50891 6.17665 6.52044 6.33416C6.53565 6.52227 6.53481 6.71107 6.51853 6.89949C6.50707 7.03519 6.47742 7.17036 6.43862 7.30166C6.03515 7.06871 5.6306 6.83515 5.22713 6.6022Z"
            fill="#0EC2D7"
        />
    </Icon>
);

export default PinIcon;
