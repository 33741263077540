import { GET_COMPANY, ADD_COMPANIES } from "../constants";
import initialState from "./initialState";
import { mergeById } from "../helpers";

export const companies = function companies(state = initialState.companies, action) {
    switch (action.type) {
        case ADD_COMPANIES:
            return mergeById(state, action.payload);
        case GET_COMPANY:
            return [{ ...action.payload }, ...state];
        default:
            return state;
    }
};

export default companies;
