import React from "react";

import Icon from "./Icon";

const CheckmarkIcon = (props) => (
    <Icon viewBox="0 0 24 24" className={props.className} width={props.width} height={props.height}>
        <path style={props.style} d="M9 16.172l10.594-10.594 1.406 1.406-12 12-5.578-5.578 1.406-1.406z" />
    </Icon>
);

export default CheckmarkIcon;
