import uniqid from "uniqid";
import { ADD_METRIC_REPORT, FILTER_METRIC } from "../constants";
import Reports from "../resources/Reports";

const { report, reportFile } = Reports();

export const addMetricReport = (metrics) => {
    return {
        type: ADD_METRIC_REPORT,
        payload: metrics,
    };
};

export const filterMetricReport = (metrics) => {
    return {
        type: FILTER_METRIC,
        payload: metrics,
    };
};

export function getReport(
    pageLimit,
    rows,
    startDate,
    endDate,
    flowName = null,
    message = null,
    botId = null,
    companyId = null,
    flowIsDefault = null,
    source = null,
    isFilter,
    flowType,
    controller
) {
    return function (dispatch) {
        const body = {
            flowName,
            message,
            startDate,
            endDate,
            botId,
            companyId: companyId ? companyId.toString() : null,
            flowIsDefault,
            source,
            pag: pageLimit,
            limit: rows,
            flowType,
        };
        return report(body, { signal: controller.signal })
            .then(({ data }) => {
                if (isFilter) {
                    dispatch(filterMetricReport(data));
                } else {
                    dispatch(addMetricReport(data));
                }
                return data;
            })
            .catch((err) => {
                if (err.message) {
                    if (err.message === "Operation canceled by the user.") {
                        return;
                    }
                } else {
                    return console.error;
                }
            });
    };
}

export function getReportFile(initialDate, finalDate, flowName = null, message, referenceId = null, botId, companyId, flowIsDefault, source) {
    return function (dispatch) {
        const body = {
            flowName,
            message,
            referenceId,
            startDate: initialDate,
            endDate: finalDate,
            botId,
            companyId: companyId.toString(),
            flowIsDefault,
            source,
            cols: "message:Vocablo,flowName:Flujo,createdAt:Fecha,count:Cantidad,source:Canal",
            columns:
                "createdAt:Fecha,legalId:Identificacion,flowIsDefault:Frase entendida,botId:Bot,companyId:Compañía,source:Canal,status:Estado,flowName:Flujo,flowType:Tipo,message:Mensaje,referenceId:Id,trainedTo:Entrenado a",
        };
        return reportFile(body)
            .then(({ data }) => {
                if (data.type === "text/xml") {
                    const today = new Date().toISOString().slice(0, 10);
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `reporte-${uniqid()}-${today}.xls`);
                    document.body.appendChild(link);
                    link.click();
                    return { toast: false, message: "reports.Reporte generado. Le llegará al correo en unos minutos" };
                }
                if (data.type === "application/json") {
                    return { toast: true, message: "reports.Reporte generado. Le llegará al correo en unos minutos" };
                }
            })
            .catch((error) => {
                const lang = localStorage.getItem("lang") || "es";
                // Verifica si el error es un error HTTP 413
                if (error.response && error.response.status === 413) {
                    throw new Error(
                        "reports.La solicitud no puede ser procesada debido a que el número de registros excede el número permitido por Excel"
                    );
                } else if (error && error.error && error.error.clientMessages && error.error.clientMessages[lang]) {
                    throw new Error(error.error.clientMessages[lang]);
                } else {
                    throw new Error("common.error");
                }
            });
    };
}
