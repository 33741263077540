import get from "lodash/get";
import BubbleOptions from "./BubbleOptions";

const BubbleRoot = (props) => {
    const { bubble, deleteBubble, dragHandleProps, updateBubble, buttonCounter, bot, className } = props;
    return (
        <div className={className ? className : "relative mb-8 flex w-[49rem] items-start"}>
            <BubbleOptions
                bubble={bubble}
                deleteBubble={deleteBubble}
                dragHandleProps={dragHandleProps}
                updateBubble={updateBubble}
                buttonCounter={buttonCounter}
                channel={get(bot, "type", "")}
                input={props.input}
            />
            {props.children}
        </div>
    );
};
export default BubbleRoot;
