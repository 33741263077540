import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

import CloseIcon from "../icons/CloseIcon";

export function SidebarRigth({ children, open, setOpen, title }) {
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 z-20 overflow-hidden" onClose={setOpen}>
                <div className="absolute inset-0 overflow-hidden">
                    <Dialog.Overlay className="absolute inset-0" />

                    <div className="z-130 pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-500"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-500"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full">
                            <div className="pointer-events-auto w-screen max-w-[21.25rem]">
                                <div className="flex h-full flex-col bg-white py-3 shadow-xl">
                                    <div className="px-4 sm:px-6">
                                        <div className="flex items-start justify-between">
                                            <Dialog.Title className="text-lg font-bold text-primary-200">{title}</Dialog.Title>
                                            <div className="ml-3 flex h-7 items-center">
                                                <button type="button" onClick={() => setOpen(false)}>
                                                    <CloseIcon className="stroke-current text-gray-300" width="2em" height="2em" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="relative mt-3 flex-1 text-gray-400">
                                        {/* Replace with your content */}
                                        {children}
                                        {/* /End replace */}
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
