import { ADD_CURRENT_INTENT, UPDATE_CURRENT_INTENT } from "../constants";
import initialState from "./initialState";

function intent(state = initialState.intent, action) {
    switch (action.type) {
        case ADD_CURRENT_INTENT:
            return action.payload;
        case UPDATE_CURRENT_INTENT:
            return action.payload;
        default:
            return state;
    }
}

export default intent;
