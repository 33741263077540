import { useQuery } from "@tanstack/react-query";
import orderBy from "lodash/orderBy";
import { useState } from "react";
import { useSelector } from "react-redux";

import { useToolkitCredentials } from "./credentials.hook";
import { ToolkitAdpter, ToolkitRepository } from "./toolkitRepository";

export function useToolkit() {
    const { app_token } = useToolkitCredentials();
    const toolkitRespository = new ToolkitRepository(app_token);

    const { data: toolkits = [], isLoading } = useQuery(["toolkits"], async () => {
        const toolkits = await toolkitRespository.getToolkits();
        return ToolkitAdpter.toolkitToOptions(toolkits);
    });

    return { toolkits, isLoading };
}

export function useToolVersions() {
    const [loading, setLoading] = useState(false);
    const [toolVersions, setToolVersions] = useState([]);

    const { app_token } = useToolkitCredentials();
    const toolkitRespository = new ToolkitRepository(app_token);

    const getVersions = async (toolkitId, toolId) => {
        setLoading(true);

        try {
            const versions = await toolkitRespository.getVersions(toolkitId, toolId);
            const optionsVersions = ToolkitAdpter.versionToOptions(versions);
            setToolVersions(optionsVersions);
            return optionsVersions;
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return { toolVersions, getVersions, loading };
}

export function useFlowsIntents() {
    const flows_intents = useSelector((state) => state.flows);

    const flowsMaps = flows_intents.map((flow) => ({
        ...flow,
        id: flow.id,
        name: flow.title,
    }));

    const flowOptions = orderBy(flowsMaps, ["name"], ["asc"]);
    return { flowOptions };
}
