import Icon from "./Icon";

const BackArrowIcon = (props) => (
    <Icon viewBox="0 0 6 9" className={props.className} width={props.width} height={props.height}>
        <path
            d="M4.67912 0.227069C3.1909 1.4676 1.71301 2.69752 0.224784 3.93805C-0.074928 4.19252 -0.074928 4.80748 0.224784 5.06195C1.71301 6.30248 3.1909 7.5324 4.67912 8.77293C4.9995 9.0486 5.45424 9.10162 5.77462 8.77293C6.04332 8.49726 6.10533 7.92471 5.77462 7.64903C4.28639 6.40851 2.80851 5.17858 1.32028 3.93805C1.32028 4.30915 1.32028 4.69085 1.32028 5.06195C2.80851 3.82142 4.28639 2.58089 5.77462 1.35097C6.095 1.07529 6.05366 0.502742 5.77462 0.227069C5.45424 -0.101618 4.9995 -0.0486037 4.67912 0.227069Z"
            fill={props.fill ?? "#727C94"}
        />
    </Icon>
);

export default BackArrowIcon;
