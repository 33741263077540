import initialState from "./initialState";
import { LOADING_PERMISSIONS } from "../constants";

export const loadingPermissions = function permissions(state = initialState.loadingPermissions, action) {
    switch (action.type) {
        case LOADING_PERMISSIONS:
            return action.payload;
        default:
            return state;
    }
};

export default loadingPermissions;
