import initialState from "./initialState";
import { updateById, mergeById, deleteByIds } from "../helpers";
import { ADD_CONTACT, ADD_CONTACTS, UPDATE_CONTACT, UPDATE_CONTACTS, DELETE_CONTACTS } from "../constants";

function contactsCards(state = initialState.contactsCards, action) {
    switch (action.type) {
        case ADD_CONTACTS:
            return [...action.payload];
        case ADD_CONTACT:
            return [...state, action.payload];
        case UPDATE_CONTACT:
            return updateById(state, action.payload);
        case UPDATE_CONTACTS:
            return mergeById(state, action.payload);
        case DELETE_CONTACTS:
            return deleteByIds(state, action.payload);
        default:
            return state;
    }
}

export default contactsCards;
