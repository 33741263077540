import includes from "lodash/includes";
import linkifyHtml from "linkify-html";

export const API = (endpoint) => `${process.env.REACT_APP_API}${endpoint}`;

export const hasPermission = (permissions, permission) => {
    return permissions && includes(permissions, permission);
};

export const disableButtons = (idsArray) => {
    idsArray.forEach((id) => {
        const button = document.getElementById(id);
        button.disabled = true;
    });
};

export function createMarkup(html) {
    return { __html: linkifyHtml(html, { target: "_blank" }) };
}

export function formatMessage(message = "", style) {
    return (
        <div
            className={style || "max-w-full whitespace-pre-wrap break-words text-13"}
            dangerouslySetInnerHTML={createMarkup(
                message
                    .replace(/\*(.+?)\*/g, "<b>$1</b>")
                    .replace(/ _(.+?)_ /g, "<i>$1</i>")
                    .replace(/ ~(.+?)~ /g, "<strike>$1</strike>")
                    .replace(/ ```(.+?)``` /g, "<tt>$1</tt>")
                    .trim()
            )}
        />
    );
}

export const copyToClipboard = (elementId) => {
    document.getElementById(`custom-tooltip-${elementId}`).style.display = "inline";
    navigator.clipboard.writeText(elementId);
    setTimeout(function () {
        document.getElementById(`custom-tooltip-${elementId}`).style.display = "none";
    }, 600);
};
