import { Renderize } from "../common/Renderize";
import includes from "lodash/includes";
import Layout from "../common/Layout";
import { getCompanies } from "../../actions/companies";
import { useGetBots } from "../../actions/bots";

import {
    getCompanyChannels,
    getBotActivity,
    getTopFlows,
    getTopIntents,
    getUniqueUsers,
    getDailySessions,
    getTodaySessions,
    getSessions,
    getSessionsFile,
    getUniqUsersFile,
} from "../../actions/metrics";

/* Components */
import ChannelsActivity from "./components/ChannelMetric/ChannelsActivity";
import BotMetrics from "./components/BotMetrics/BotMetrics";
import BotInteractionsMetrics from "./components/BotInteractionsMetric/BotInteractionsMetric";
import SessionsActivityMetrics from "./components/SessionsActivityMetrics/SessionsActivityMetrics";
import SectionTitle from "./components/SectionTitle";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

const Metrics = () => {
    const companies = useSelector((state) => state.companies);
    const metrics = useSelector((state) => state.metrics);
    const permissions = useSelector((state) => state.permissions);
    const { data: bots = [] } = useGetBots();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCompanies());
    }, []);

    const { t } = useTranslation();

    const formatBigNumber = (amount, decimalCount = 2, decimals = false, thousands = ",", millions = "'") => {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

            const negativeSign = amount < 0 ? "-" : "";

            let i = decimals
                ? (amount = Math.abs(Number(amount) || 0)
                      .toFixed(decimalCount)
                      .toString())
                : parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)), 10).toString();
            let j = decimals ? (i.length > decimalCount + 3 ? i.length % (decimalCount + 3) : 0) : i.length > 3 ? i.length % 3 : 0;

            return negativeSign + (j ? i.substr(0, j) + thousands : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands);
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div className={includes(permissions, "view metrics") ? "container mx-auto my-10" : ""}>
            <Layout showSidebar={false} screen={false}>
                <Renderize permission="view metrics">
                    <div className="flex w-full flex-1 flex-col px-10">
                        <div className="mb-16">
                            <SectionTitle title={t("metrics.Métricas por Compañía")} />
                            <ChannelsActivity
                                metrics={metrics}
                                companies={companies}
                                getCompanyChannels={getCompanyChannels}
                                formatBigNumber={formatBigNumber}
                            />
                        </div>
                        <div className="mb-16">
                            <SectionTitle title={t("metrics.Métricas de Sesión")} />
                            <SessionsActivityMetrics
                                metrics={metrics}
                                bots={bots}
                                companies={companies}
                                getDailySessions={getDailySessions}
                                getTodaySessions={getTodaySessions}
                                getSessions={getSessions}
                                getSessionsFile={getSessionsFile}
                                formatBigNumber={formatBigNumber}
                            />
                        </div>
                        <div className="mb-16">
                            <SectionTitle title={t("metrics.Métricas por Bot")} />
                            <BotMetrics
                                metrics={metrics}
                                bots={bots}
                                companies={companies}
                                getTopFlows={getTopFlows}
                                getTopIntents={getTopIntents}
                                getUniqueUsers={getUniqueUsers}
                                getUniqUsersFile={getUniqUsersFile}
                            />
                        </div>
                        <div className="mb-16">
                            <SectionTitle title={t("metrics.Interacciones con el Bot")} />
                            <BotInteractionsMetrics bots={bots} metrics={metrics} companies={companies} getBotActivity={getBotActivity} />
                        </div>
                    </div>
                </Renderize>
            </Layout>
        </div>
    );
};

export default Metrics;
