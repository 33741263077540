import initialState from "./initialState";
import { SET_UNATHORIZATION } from "../constants";

function unauthorized(state = initialState.unauthorized, action) {
    switch (action.type) {
        case SET_UNATHORIZATION:
            return action.payload;
        default:
            return state;
    }
}

export default unauthorized;
