import React from "react";

import Icon from "./Icon";

const WebIcon = (props) => (
    <Icon viewBox="0 0 100 100" className={props.className} width={props.width} height={props.height}>
        <g>
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0" y1="50" x2="100" y2="50" gradientTransform="matrix(1 0 0 -1 0 100)">
                <stop offset="0" stopColor="#4FAFC3" />
                <stop offset="1" stopColor="#00A2CF" />
            </linearGradient>
            <rect fill="url(#SVGID_1_)" width="100" height="100" />
        </g>
        <path
            d="M85.04,48.28l-0.07-0.71c-0.05-0.5-0.09-1-0.15-1.5c-0.92-8.25-4.39-15.34-10.31-21.05
	c-4.87-4.7-10.73-7.79-17.41-9.2c-1.15-0.24-2.32-0.37-3.46-0.5c-0.53-0.06-1.06-0.12-1.58-0.19c-0.08-0.01-0.15-0.02-0.23-0.04
	l-3.51-0.03l-2.07,0.21c-5.1,0.54-9.84,2.11-14.09,4.69c-8.3,5.03-13.74,12.33-16.17,21.71c-0.5,1.94-0.81,3.99-0.95,6.26
	c0,0.06-0.02,0.12-0.03,0.18l-0.03,0.22l0,3.43l0.07,0.7c0.05,0.47,0.09,0.93,0.15,1.4c0.62,5.69,2.57,10.98,5.79,15.72
	c3.64,5.35,8.47,9.45,14.36,12.19c3.73,1.74,7.64,2.77,11.6,3.07c1.03,0.08,2.04,0.12,3.05,0.12c8.99,0,17.04-3.16,23.95-9.41
	c5.95-5.38,9.56-12.24,10.74-20.38c0.1-0.67,0.16-1.35,0.22-2.03c0.03-0.32,0.06-0.64,0.09-0.96c0.01-0.08,0.02-0.17,0.04-0.25
	l0.03-0.21L85.04,48.28z M62.84,63.67c-1.25,4.67-2.86,8.24-5.06,11.21c-1.4,1.89-2.82,3.16-4.48,4c-0.22,0.11-0.45,0.21-0.69,0.3
	V63.26C56.02,63.29,59.46,63.43,62.84,63.67z M69.51,42.99c2.79-0.38,5.89-0.82,8.92-1.69c0.96,3.05,1.39,6.35,1.26,9.79
	c-0.12,3.23-0.75,6.41-1.89,9.43c-2.8-0.75-5.62-1.17-8.01-1.48l-0.53-0.07C69.96,53.97,70.04,48.74,69.51,42.99z M68.7,37.73
	c-1.16-5.68-2.85-10.24-5.27-14.18c5.43,2.72,10.26,7.48,12.96,12.81C74.94,36.81,70.33,37.6,68.7,37.73z M67.67,66.58
	c0.19-0.67,0.36-1.34,0.52-2.01l0.09-0.38c2.32,0.29,4.78,0.63,7.15,1.19c-3.01,4.86-6.95,8.5-12,11.1
	C65.19,73.65,66.58,70.4,67.67,66.58z M52.58,47.43l0-3.36l11.61-0.49l0.03,0.28c0.06,0.53,0.11,1.07,0.15,1.62
	c0.21,2.98,0.24,5.63,0.07,8.09c-0.08,1.13-0.21,2.27-0.35,3.38c-0.06,0.49-0.12,0.98-0.18,1.5c-3.78-0.17-7.56-0.33-11.35-0.48
	C52.58,54.46,52.58,50.94,52.58,47.43z M52.61,38.76V20.85c0.1,0.04,0.21,0.08,0.3,0.12c1.77,0.79,3.29,2.1,4.8,4.1
	c1.93,2.56,3.44,5.68,4.61,9.52c0.28,0.91,0.51,1.85,0.74,2.77c0.08,0.31,0.15,0.61,0.23,0.96L52.61,38.76z M30.66,58.85l-8.59,1.36
	c-2.21-6.24-2.42-12.49-0.62-18.59l8.93,1.48C29.7,48.74,29.79,53.9,30.66,58.85z M25.36,65.04c1.21-0.19,2.38-0.38,3.56-0.56
	c0.52-0.08,1.05-0.14,1.58-0.2c0.43-0.05,0.85-0.1,1.35-0.16c0,0,0,0,0,0.01c1.1,3.96,2.54,7.35,4.41,10.36
	c0.57,0.92,1.22,1.8,1.85,2.66c0.02,0.02,0.03,0.04,0.05,0.06c-5.79-2.52-10.29-6.47-13.71-12.01L25.36,65.04z M47.27,38.68
	c-1.74,0.06-8.52-0.18-10.72-0.41c0.09-0.38,0.17-0.75,0.27-1.12c1.1-4.3,2.59-7.84,4.56-10.82c1.45-2.19,2.92-3.7,4.63-4.74
	c0.39-0.24,0.81-0.44,1.26-0.65V38.68z M47.29,57.91l-11.4,0.47c-0.66-2.79-0.81-12.04-0.25-14.84l0.16,0.01
	c0.44,0.03,0.88,0.06,1.33,0.08c3.36,0.13,6.72,0.26,10.08,0.38l0.09,0l0,0.09L47.29,57.91z M37.96,63.6
	c1.16-0.07,2.31-0.14,3.45-0.18c1.16-0.04,2.32-0.07,3.48-0.1c0.78-0.02,1.55-0.04,2.4-0.06c-0.01,4.22-0.01,8.44-0.01,12.66v3.2
	c-1.55-0.65-2.97-1.74-4.33-3.33c-1.92-2.22-3.46-4.98-4.74-8.42c-0.32-0.87-0.6-1.78-0.87-2.67c-0.11-0.35-0.21-0.69-0.32-1.04
	L37.96,63.6z M23.5,36.56c1.43-2.78,3.22-5.25,5.47-7.51c2.95-2.98,6.4-5.05,9.12-6.22c-3.07,4.11-5.28,9.03-6.74,14.97
	C28.83,37.5,26.18,37.15,23.5,36.56z"
            fill={"#FFFFFF"}
        />
    </Icon>
);

export default WebIcon;
