import initialState from "./initialState";
import { ADD_CONTEXTS, ADD_CONTEXT_DETAIL } from "../constants";

export const contexts = function reports(state = initialState.contexts, action) {
    switch (action.type) {
        case ADD_CONTEXTS:
            return [...state, ...action.payload];
        default:
            return state;
    }
};

export const contextDetail = function reports(state = initialState.contextDetail, action) {
    switch (action.type) {
        case ADD_CONTEXT_DETAIL:
            return [...state, ...action.payload];
        default:
            return state;
    }
};

export default contexts;
