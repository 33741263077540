import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNumber from "lodash/isNumber";

import { useSelector, useDispatch } from "react-redux";
import { copyToClipboard } from "../../../utils/utils";
import { setIsOpenBotTester } from "../../../actions/testBot";

const FlowBubble = (props) => {
    const { message, setFlow, setMessages } = props;

    const bot = useSelector((state) => state.bot);
    const intents = useSelector((state) => state.intents);

    const intentSlug = get(message, "intent", "");
    const flowId = get(message, "Flow.id", "N/D");
    const flow = get(message, "Flow.title", "--");
    const nlpDriver = get(message, "nlpDriver", "");

    const dispatch = useDispatch();

    const handleGoToFlow = () => {
        let slug = !isEmpty(intentSlug) ? intentSlug : flow;

        const intentSelected = intents.find((intent) => intent.slug === slug);

        const params = {
            id: intentSelected.id,
            botId: bot.id,
            nlpDriver: nlpDriver,
        };
        setFlow(params);
        dispatch(setIsOpenBotTester(false));
        setMessages([]);
    };

    return (
        <div className="text-15">
            <div className="w-full">
                <div className="grid grid-cols-4 space-y-2 text-center">
                    <span className="mt-2 font-semibold">Id</span>
                    <span className="font-semibold">Flujo</span>
                    <span className="font-semibold">Entendido</span>
                    <span className="font-semibold">Acción</span>
                    <button className="relative" onClick={() => copyToClipboard(flowId)}>
                        {flowId}
                        <span
                            className="absolute bottom-0 right-0 mb-5 mr-7 hidden rounded-xl bg-primary-10 px-2 py-1 text-primary-200"
                            id={`custom-tooltip-${flowId}`}>
                            {"Copiado"}
                        </span>
                    </button>
                    <span>{flow}</span>
                    <span>
                        <div className="w-full">
                            {message?.understood ? (
                                <div className="mx-auto h-3 w-3 rounded-full bg-green opacity-75" />
                            ) : (
                                <div className="mx-auto h-3 w-3 rounded-full bg-red opacity-75" />
                            )}
                        </div>
                    </span>
                    <span>
                        <button
                            className="text-primary-200 underline hover:font-medium disabled:cursor-not-allowed disabled:text-grey-100"
                            onClick={handleGoToFlow}
                            disabled={!isNumber(flowId)}>
                            <div className="flex items-center justify-center">Ir a flujo</div>
                        </button>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default FlowBubble;
