import React from "react";

import Icon from "./Icon";

const VideoIcon = (props) => (
    <Icon viewBox="0 0 22 16" stroke={props.stroke} className={props.className} width={props.width} height={props.height}>
        <path
            d="M12.6733 14.411C9.92536 14.411 7.17738 14.4345 4.42941 14.411C3.51342 14.411 2.66789 13.9883 2.19815 13.1897C1.84584 12.6025 1.84584 11.9919 1.84584 11.3577C1.84584 9.6197 1.84584 7.88166 1.84584 6.14363C1.84584 5.6504 1.84584 5.15718 1.84584 4.66395C1.86933 3.60704 2.47999 2.64408 3.46644 2.29177C3.98316 2.10388 4.52335 2.12736 5.06355 2.12736C5.86211 2.12736 6.66067 2.12736 7.45923 2.12736C9.10331 2.12736 10.7239 2.12736 12.368 2.12736C13.0491 2.12736 13.7068 2.2448 14.2704 2.69105C14.9516 3.23125 15.2099 4.00631 15.2099 4.85184C15.2099 6.49593 15.2099 8.11654 15.2099 9.76062C15.2099 11.1464 15.4918 12.8844 14.2704 13.8708C13.8007 14.2231 13.237 14.411 12.6733 14.411C11.7339 14.4345 11.7104 15.9142 12.6733 15.8907C14.2 15.8672 15.6327 14.9982 16.2903 13.6125C16.6426 12.8609 16.6896 12.1093 16.6896 11.2873C16.6896 9.43181 16.6896 7.59983 16.6896 5.74436C16.6896 5.15718 16.7131 4.57001 16.6426 3.98284C16.4078 2.50316 15.3039 1.25835 13.8712 0.812096C13.237 0.600713 12.6029 0.624189 11.9452 0.624189C11.0762 0.624189 10.1837 0.624189 9.3147 0.624189C7.69409 0.624189 6.07349 0.624189 4.47638 0.624189C3.30203 0.624189 2.15117 1.09394 1.3761 1.98644C0.671495 2.80849 0.389648 3.77144 0.389648 4.85184C0.389648 6.58988 0.389648 8.35141 0.389648 10.0894C0.389648 10.6766 0.389648 11.2638 0.389648 11.851C0.413135 13.2132 1.07077 14.458 2.19815 15.2331C3.56039 16.1491 5.46284 15.8907 7.01298 15.8907C8.79799 15.8907 10.5595 15.8907 12.3445 15.8907C12.4619 15.8907 12.5794 15.8907 12.6968 15.8907C13.6128 15.8907 13.6128 14.411 12.6733 14.411Z"
            fill={props.fill}
        />
        <path
            d="M20.4946 11.9213C19.0853 11.3341 17.6761 10.7235 16.2669 10.1363C16.3843 10.3477 16.5018 10.5591 16.6427 10.7704C16.6427 9.07938 16.6427 7.4118 16.6427 5.72074C16.5253 5.93212 16.4078 6.14351 16.2669 6.35489C17.042 6.02608 17.817 5.69725 18.5921 5.36844C19.0853 5.15705 19.5551 4.96916 20.0483 4.75778C20.1657 4.71081 20.2832 4.64034 20.4006 4.59337C20.518 4.56988 20.3771 4.56988 20.4006 4.61685C20.4006 4.61685 20.4476 4.61686 20.4476 4.64035C20.4945 5.2745 20.4476 5.90864 20.4476 6.54279C20.4476 7.69365 20.4476 8.86799 20.4476 10.0189C20.4476 10.4886 20.4476 10.9818 20.4476 11.4516C20.4476 11.6395 20.5415 11.9213 20.2832 11.8274C19.3907 11.522 18.9914 12.9312 19.8839 13.2601C20.8938 13.6124 21.8803 12.8373 21.9273 11.8039C21.9273 11.522 21.9273 11.2402 21.9273 10.9583C21.9273 9.66655 21.9273 8.39826 21.9273 7.10648C21.9273 6.28443 21.9742 5.46238 21.9273 4.66383C21.8803 3.51297 20.7529 2.85533 19.7195 3.30159C19.3907 3.44251 19.0618 3.58343 18.733 3.72435C17.6526 4.1706 16.5957 4.64034 15.5153 5.08659C15.2804 5.18054 15.1395 5.48587 15.1395 5.72074C15.1395 7.4118 15.1395 9.07938 15.1395 10.7704C15.1395 11.0053 15.2804 11.3106 15.5153 11.4046C16.9245 11.9918 18.3337 12.6024 19.743 13.1896C20.1188 13.354 20.5415 13.307 20.7529 12.9312C20.9408 12.6259 20.8703 12.0857 20.4946 11.9213Z"
            fill={props.fill}
        />
    </Icon>
);

export default VideoIcon;
