import { Component } from "react";
import { BeatLoader } from "react-spinners";
import { withTranslation } from "react-i18next";

class ConfirmModal extends Component {
    state = {
        deleting: false,
        cancel: true,
        goTo: false,
    };

    componentDidMount() {
        if (this.props.acceptText) {
            this.setState({
                cancel: false,
            });
        }
        if (this.props.goTo) {
            this.setState({
                goTo: true,
            });
        }
    }

    componentWillUnmount = () => {
        this.setState({ deleting: false });
    };
    render() {
        const { t } = this.props;
        const yesCancelStyle = "w-20 rounded-20 bg-red text-white hover:bg-red-300 focus:outline-none";
        return (
            <>
                <div className="max-w-md rounded-lg bg-white shadow-modal">
                    <div>
                        <h1 className="mb-6 flex items-center px-8 pt-8 font-sans text-xl font-normal text-gray-500">{this.props.title}</h1>
                        <div className="px-8 pb-8 font-sans leading-normal text-gray-400">
                            {this.props.subtitle || t("common.¿Estás seguro que deseas continuar?")}
                        </div>
                        <div className="flex w-full justify-end rounded-b-lg bg-modal-footer px-6 py-3">
                            {this.state.cancel && (
                                <button onClick={this.props.onClose} className="mr-3 h-9 py-2 px-3 text-gray-400 focus:outline-none">
                                    {t(this.props.cancelText) || t("No")}
                                </button>
                            )}
                            {this.state.goTo ? (
                                <a
                                    onClick={(evt) => {
                                        this.setState({ deleting: true });
                                        this.props.onConfirm(evt);
                                    }}
                                    href="/"
                                    className={`${yesCancelStyle} flex items-center justify-center`}>
                                    {t(this.props.acceptText) || t("common.Sí")}
                                </a>
                            ) : (
                                <button
                                    onClick={(evt) => {
                                        this.setState({ deleting: true });
                                        this.props.onConfirm(evt);
                                    }}
                                    className={yesCancelStyle}>
                                    {this.state.deleting ? <BeatLoader color={"#ffffff"} size={8} /> : this.props.acceptText || t("common.Sí")}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(ConfirmModal);
