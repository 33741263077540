import React from "react";

const CharacterCounter = (props) => {
    const length = props.length || 0;
    const max = props.max;
    const className = `${
        length > max ? "bg-red-light" : max - length <= 5 ? "bg-yellow-dark" : "bg-green-light"
    } text-white rounded-md block mb-1 mr-1 text-xxs py-px px-1`;

    return <span className={className}>{max - length}</span>;
};

export default CharacterCounter;
