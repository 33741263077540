import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import Expression from "../resources/Expression";
import {
    ADD_EXPRESSION,
    ADD_EXPRESSIONS,
    SAVING_EXPRESSION,
    DELETE_EXPRESSION,
    DELETING_EXPRESSION,
    LOADING_EXPRESSIONS,
    SET_EXPRESSION_STATE,
    RESET_EXPRESSION_STATE,
} from "../constants";
import Http from "../services/Http";

const { all } = Expression();

export const addExpressions = (expressions) => ({
    type: ADD_EXPRESSIONS,
    payload: expressions,
});

/**
 * Create a new expression for the current flow intentId
 * @param {Object} expression { source: SOURCE, language: {isocode: ISOCODE} }
 * @returns {Promise}
 */
export function addExpression(expression) {
    return function (dispatch, getState) {
        dispatch(setExpressionState(SAVING_EXPRESSION));
        const { flow, bot } = getState();
        return Http.post(`/expressions/${bot.slug}/${flow.intent}/${flow.intentId}`, expression, {
            params: {
                botId: bot.id,
            },
        })
            .then(({ data }) => {
                dispatch({
                    type: ADD_EXPRESSION,
                    payload: data,
                });
                dispatch(resetExpressionState());
            })
            .catch((err) => {
                console.log("======= err:", err);
                dispatch(resetExpressionState());
            });
    };
}

export function getExpressions(intent) {
    return function (dispatch, getState) {
        dispatch(setExpressionState(LOADING_EXPRESSIONS));

        const { bot } = getState();

        // If the bot has no Company or something. Then can't find any expression
        // This validation handle an error on adding flows without setting a bot.
        if (!isEmpty(get(bot, "Company", []))) {
            const companySlug = get(bot, "Company.slug", "");
            return all(companySlug, intent, bot)
                .then(({ data }) => {
                    dispatch(addExpressions(data));
                    dispatch(resetExpressionState());
                })
                .catch((err) => dispatch(resetExpressionState()));
        } else {
            dispatch(resetExpressionState());
        }
    };
}

// Decided to delete the expression instantly. If error, then add it again to the state.
// (would be nice if it gives the error message.)
// Now the data is sent to the body to avoid the spaces "%20" error in the url.
export function deleteExpression(intentSlug, expressionId) {
    return function (dispatch, getState) {
        dispatch(setExpressionState(DELETING_EXPRESSION));

        let { expressions, bot } = getState();
        const expression = expressions.find((expression) => expression.id === expressionId);
        dispatch({
            type: DELETE_EXPRESSION,
            payload: { expressions, expressionId },
        });

        return Http.delete(`/expressions`, {
            data: { companySlug: bot.slug, intentSlug, expressionId, source: expression.source, intentId: expression.intentId },
            params: {
                botId: bot.id,
            },
        })
            .then((data) => {
                dispatch(resetExpressionState(data));
            })
            .catch((err) => {
                console.log("====== err:", err);
                dispatch({
                    type: ADD_EXPRESSION,
                    payload: expression,
                });
            });
    };
}

export const setExpressionState = (state) => ({
    type: SET_EXPRESSION_STATE,
    payload: state,
});

export const resetExpressionState = () => ({
    type: RESET_EXPRESSION_STATE,
});
