import Http from "../services/Http";
import isEmpty from "lodash/isEmpty";

export default function transactions() {
    function transaction(body, controller = null) {
        return Http.post(`/metrics/resources`, body, {
            ...(!isEmpty(controller) ? { signal: controller.signal } : {}),
        });
    }

    function reportFile(body) {
        return Http({
            url: "/metrics/resources/file",
            method: "POST",
            responseType: "blob",
            data: body,
            timeout: 0,
        });
    }
    return { transaction, reportFile };
}
