import initialState from "./initialState";
import { mergeById } from "../helpers";
import { ADD_RUN_ACTION_BUBBLE, ADD_RUN_ACTION_BUBBLES, UPDATE_RUN_ACTION_BUBBLES } from "../constants";

function runActionBubbles(state = initialState.runActionBubbles, action) {
    switch (action.type) {
        case ADD_RUN_ACTION_BUBBLES:
            return [...action.payload];
        case ADD_RUN_ACTION_BUBBLE:
            return [...state, action.payload];
        case UPDATE_RUN_ACTION_BUBBLES:
            return mergeById(state, action.payload);
        // case DELETE_ALL_PMA_BUBBLES:
        //     return [];
        default:
            return state;
    }
}

export default runActionBubbles;
