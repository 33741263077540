/* eslint-disable array-callback-return */
import { useTranslation } from "react-i18next";
import "react-loading-skeleton/dist/skeleton.css";
import { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Disclosure, Transition } from "@headlessui/react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import AddIntent from "./AddIntent";
import IntentItem from "./IntentItem";
import { GridLoader } from "react-spinners";
import DownIcon from "../../icons/DownIcon";
import GroupIcon from "../../icons/GroupIcon";
import RightIcon from "../../icons/RightIcon";
import SearchIcon from "../../icons/SearchIcon";
import ContainerIcon from "../../icons/ContainerIcon";
import PlusIcon from "../../icons/PlusIcon";
import { toggleAddingIntent } from "../../../actions/intents";

const IntentsList = (props) => {
    const { flow, handleGetFlow, inputRef, showIntents, setShowIntents, isDialogFlow, handleDelete, intents } = props;
    const defaultFlow = props.flows.find((flow) => flow.isDefault);
    const loadingIntents = useSelector((state) => state.loadingIntents);
    const [groupPost, setGroupPost] = useState(false);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    let loadingSkeleton = [];

    for (let i = 0; i < 10; i++) {
        loadingSkeleton.push(
            <div className="w-56 py-2 pl-3" key={i}>
                <SkeletonTheme>
                    <Skeleton />
                </SkeletonTheme>
            </div>
        );
    }
    const [deleting, setDeleting] = useState(false);

    const showFilter = () => {
        setGroupPost(!groupPost);
    };

    useEffect(() => {
        setGroupPost(true);
    }, []);

    const countTrained = intents.filter(function (item) {
        return item.trained;
    }).length;

    const countNotTrained = intents.filter(function (item) {
        return !item.trained;
    }).length;

    return (
        <>
            {deleting && (
                <div className="fixed bottom-[4rem] right-[4rem] z-[1000] rounded-8 bg-white p-4 ">
                    <GridLoader size={10} color="#00B3C7" />
                </div>
            )}
            <Transition.Root show={showIntents} as={Fragment}>
                <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-700 sm:duration-700"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-500"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full">
                    <div className="relative flex w-64 flex-col bg-white pb-6 pt-[0.7rem] shadow-sidebar" onClose={() => setShowIntents(false)}>
                        <AddIntent
                            addIntent={props.addIntent}
                            handleChange={props.handleChange}
                            addingIntent={props.addingIntent}
                            toggleAddingIntent={props.toggleAddingIntent}
                            handleKeyPress={props.handleKeyPress}
                            loading={props.loading}
                            error={props.error}
                            inputRef={inputRef}
                            t={t}
                        />
                        <div className="mb-4 flex items-center justify-between px-4">
                            <h4 className="font-sans text-base font-extrabold text-gray-450">{t("flows.Flujos")}</h4>
                            <div className="flex space-x-2">
                                <button
                                    onClick={() => dispatch(toggleAddingIntent())}
                                    className="h-[1.875rem] w-[1.875rem] rounded-full bg-primary-200 hover:bg-[#009EAF]">
                                    <PlusIcon width="20" height="20" className="m-auto fill-current text-white" />
                                </button>
                                {/* <button
                                    className={`h-[1.875rem] w-[1.875rem] rounded-full ${
                                        showSearch ? "bg-[#004137]" : "bg-[#209F8B] hover:bg-[#006757]"
                                    }`}
                                    onClick={() => setShowSearch(!showSearch)}>
                                    <SearchIcon width="20" height="20" className="m-auto fill-current text-white" />
                                </button> */}
                            </div>
                        </div>
                        <div className="flex h-full flex-1 flex-col overflow-auto">
                            <div className="mb-4 flex items-center justify-between pr-4">
                                {/* <div className="mb-4 flex items-center justify-between"> */}
                                <div className="relative px-4">
                                    {/* <div className="relative w-full px-4"> */}
                                    <div className="pointer-events-none absolute left-[1.25rem] top-0 flex h-full w-10 items-center justify-center">
                                        <SearchIcon width="1.125rem" height="1.125rem" className="fill-current text-gray-450" />
                                    </div>
                                    <input
                                        type="search"
                                        className="input-search w-full"
                                        placeholder={t("flows.Buscar flujo")}
                                        onChange={props.handleSearch}
                                        autoFocus
                                    />
                                </div>
                                <div className="flex">
                                    <button className="border-transparent focus:outline-none" onClick={showFilter}>
                                        <GroupIcon
                                            width="20"
                                            height="19"
                                            className={`fill-current ${groupPost ? "text-primary-200" : "text-gray-100"}`}
                                            fillOpacity={`${groupPost ? "" : "0.5"}`}
                                        />
                                    </button>
                                </div>
                            </div>

                            <ul className="flex-1 overflow-y-auto">
                                {loadingIntents ? (
                                    loadingSkeleton
                                ) : groupPost ? (
                                    <>
                                        <Disclosure>
                                            {({ open }) => (
                                                <div className="flex flex-col">
                                                    <Disclosure.Button className="sticky top-0 z-40 flex items-center space-x-3 border-x-0 border-b border-t-0 border-[#a6b4d03f] bg-white py-3">
                                                        <span className="ml-[1.9375rem] text-13 font-bold text-gray-400">
                                                            {t("bubbles.Con Burbujas")}
                                                        </span>
                                                        <span className="text-13 font-bold text-gray-400">{countTrained}</span>
                                                        {open ? (
                                                            <DownIcon
                                                                className="select-none fill-current text-gray-400 outline-none"
                                                                width="1.25rem"
                                                                height="1.25rem"
                                                            />
                                                        ) : (
                                                            <RightIcon
                                                                className="select-none fill-current text-gray-400 outline-none"
                                                                width="1.25rem"
                                                                height="1.25rem"
                                                            />
                                                        )}
                                                    </Disclosure.Button>

                                                    <Disclosure.Panel>
                                                        {intents.map((intent, index) => {
                                                            if (intent.trained) {
                                                                return (
                                                                    <Transition
                                                                        key={index}
                                                                        show={open}
                                                                        enter="transition duration-100 ease-out"
                                                                        enterFrom="transform scale-95 opacity-0"
                                                                        enterTo="transform scale-100 opacity-100"
                                                                        leave="transition duration-75 ease-out"
                                                                        leaveFrom="transform scale-100 opacity-100"
                                                                        leaveTo="transform scale-95 opacity-0">
                                                                        <IntentItem
                                                                            setDeleting={setDeleting}
                                                                            {...props}
                                                                            intent={intent}
                                                                            key={intent.id}
                                                                            defaultFlow={defaultFlow}
                                                                            loading={props.loading}
                                                                            flow={flow}
                                                                            handleGetFlow={handleGetFlow}
                                                                            isDialogFlow={isDialogFlow}
                                                                            handleDelete={handleDelete}
                                                                        />
                                                                    </Transition>
                                                                );
                                                            }
                                                        })}
                                                    </Disclosure.Panel>
                                                </div>
                                            )}
                                        </Disclosure>
                                        <Disclosure>
                                            {({ open }) => (
                                                <div className="flex flex-col">
                                                    <Disclosure.Button className="sticky top-0 z-40 flex items-center space-x-3 border-x-0 border-b border-t-0 border-[#a6b4d03f] bg-white py-3">
                                                        <span className="ml-[1.9375rem] text-13 font-bold text-gray-400">
                                                            {t("bubbles.Sin Burbujas")}
                                                        </span>
                                                        <span className="text-13 font-bold text-gray-400">{countNotTrained}</span>
                                                        {open ? (
                                                            <DownIcon
                                                                className="select-none fill-current text-gray-400 outline-none"
                                                                width="1.25rem"
                                                                height="1.25rem"
                                                            />
                                                        ) : (
                                                            <RightIcon
                                                                className="select-none fill-current text-gray-400 outline-none"
                                                                width="1.25rem"
                                                                height="1.25rem"
                                                            />
                                                        )}
                                                    </Disclosure.Button>

                                                    <Disclosure.Panel>
                                                        {intents.map((intent, index) => {
                                                            if (!intent.trained) {
                                                                return (
                                                                    <Transition
                                                                        key={index}
                                                                        show={open}
                                                                        enter="transition duration-100 ease-out"
                                                                        enterFrom="transform scale-95 opacity-0"
                                                                        enterTo="transform scale-100 opacity-100"
                                                                        leave="transition duration-75 ease-out"
                                                                        leaveFrom="transform scale-100 opacity-100"
                                                                        leaveTo="transform scale-95 opacity-0">
                                                                        <IntentItem
                                                                            setDeleting={setDeleting}
                                                                            {...props}
                                                                            intent={intent}
                                                                            key={intent.id}
                                                                            defaultFlow={defaultFlow}
                                                                            loading={props.loading}
                                                                            flow={flow}
                                                                            handleGetFlow={handleGetFlow}
                                                                            isDialogFlow={isDialogFlow}
                                                                            handleDelete={handleDelete}
                                                                        />
                                                                    </Transition>
                                                                );
                                                            }
                                                        })}
                                                    </Disclosure.Panel>
                                                </div>
                                            )}
                                        </Disclosure>
                                    </>
                                ) : (
                                    intents.map((intent, index) => {
                                        return (
                                            <Transition
                                                key={index}
                                                enter="transition duration-100 ease-out"
                                                enterFrom="transform scale-95 opacity-0"
                                                enterTo="transform scale-100 opacity-100"
                                                leave="transition duration-75 ease-out"
                                                leaveFrom="transform scale-100 opacity-100"
                                                leaveTo="transform scale-95 opacity-0">
                                                <IntentItem
                                                    setDeleting={setDeleting}
                                                    {...props}
                                                    intent={intent}
                                                    key={intent.id}
                                                    defaultFlow={defaultFlow}
                                                    loading={props.loading}
                                                    flow={flow}
                                                    handleGetFlow={handleGetFlow}
                                                    isDialogFlow={isDialogFlow}
                                                    handleDelete={handleDelete}
                                                />
                                            </Transition>
                                        );
                                    })
                                )}

                                {/* {loadingIntents
                                    ? loadingSkeleton
                                    : intents.map((intent, index) => {
                                          return (
                                              <Transition
                                                  key={index}
                                                  enter="transition duration-100 ease-out"
                                                  enterFrom="transform scale-95 opacity-0"
                                                  enterTo="transform scale-100 opacity-100"
                                                  leave="transition duration-75 ease-out"
                                                  leaveFrom="transform scale-100 opacity-100"
                                                  leaveTo="transform scale-95 opacity-0">
                                                  <IntentItem
                                                      setDeleting={setDeleting}
                                                      {...props}
                                                      intent={intent}
                                                      key={intent.id}
                                                      defaultFlow={defaultFlow}
                                                      loading={props.loading}
                                                      flow={flow}
                                                      handleGetFlow={handleGetFlow}
                                                      isDialogFlow={isDialogFlow}
                                                      handleDelete={handleDelete}
                                                  />
                                              </Transition>
                                          );
                                      })} */}
                            </ul>
                            <button className="absolute bottom-0 right-0 flex justify-center" onClick={() => setShowIntents(false)}>
                                <ContainerIcon width="39" height="32" />
                                <div className="absolute bottom-0 top-[0.5rem]">
                                    <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M4.70885 1.08198C3.3254 2.1353 1.95163 3.17952 0.568181 4.23284C0.289386 4.44912 0.265035 5.00049 0.523677 5.24051C1.80889 6.41169 3.08525 7.57295 4.37045 8.74413C4.64679 9.00398 5.0524 9.06952 5.35267 8.78751C5.6045 8.55098 5.68277 8.0401 5.39717 7.77983C4.11196 6.60866 2.8356 5.44739 1.5504 4.27622C1.5357 4.60894 1.52059 4.95117 1.50589 5.28389C2.88934 4.23057 4.26353 3.17685 5.64656 2.13304C5.94473 1.89856 5.93033 1.38357 5.69106 1.12536C5.41683 0.817974 5.00702 0.8475 4.70885 1.08198Z"
                                            fill="#00B3C7"
                                        />
                                        <path
                                            d="M9.80672 1.08198C8.42327 2.1353 7.0495 3.17952 5.66605 4.23284C5.38726 4.44912 5.3629 5.00049 5.62155 5.24051C6.90675 6.41169 8.18312 7.57295 9.46832 8.74413C9.74466 9.00398 10.1503 9.06952 10.4505 8.78751C10.7024 8.55098 10.7806 8.0401 10.495 7.77983C9.20983 6.60866 7.93347 5.44739 6.64827 4.27622C6.63357 4.60894 6.61846 4.95117 6.60376 5.28389C7.98721 4.23057 9.3614 3.17685 10.7444 2.13304C11.0426 1.89856 11.0282 1.38357 10.7889 1.12536C10.5147 0.817974 10.1049 0.8475 9.80672 1.08198Z"
                                            fill="#00B3C7"
                                        />
                                    </svg>
                                </div>
                            </button>
                        </div>
                    </div>
                </Transition.Child>
            </Transition.Root>
        </>
    );
};

export default IntentsList;
