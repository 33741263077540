const DocumentIcon = ({ width = 20, height = 17, color = "currentColor", className } = {}) => (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 17" className={className}>
        <path
            d="M17.182 14.387H3.129c-.512-.026-.794-.384-.794-.87V2.868c0-.281-.025-.563.128-.819.282-.41.743-.333 1.178-.333h4.326c-.23-.128-.46-.256-.691-.41.41.615.819 1.204 1.203 1.818.358.512.793 1.51 1.484 1.562 1.536.076 3.098 0 4.634 0H16.9c.538 0 1.024.179 1.05.819.051 1.51 0 3.02 0 4.53v2.918c.026.59.077 1.408-.768 1.434-1.024.051-1.05 1.638 0 1.613a2.472 2.472 0 0 0 2.406-2.407V7.296c0-1.433.23-3.174-1.28-3.942-.563-.281-1.152-.281-1.74-.281H9.989c.23.128.46.256.691.41-.665-.999-1.305-2.023-1.996-2.97-.41-.563-1.28-.41-1.895-.41H4.204c-.563 0-1.229-.076-1.766.103-1.05.333-1.69 1.28-1.69 2.355-.025 1.126 0 2.253 0 3.404v7.065c0 .41-.025.82.077 1.229.282 1.075 1.28 1.74 2.355 1.766H8.48c2.841 0 5.708.051 8.55 0h.153c1.05-.025 1.05-1.638 0-1.638Z"
            fill={color}
        />
    </svg>
);

export default DocumentIcon;
