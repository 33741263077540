import dayjs from "dayjs";
import "dayjs/locale/es";
import orderBy from "lodash/orderBy";
import DateRangePicker from "../../../../common/DateRangePicker";
import ComboboxSelect from "../../../../common/Combobox";
import DateIcon from "../../../../icons/DateIcon";
import { useTranslation } from "react-i18next";

const ChannelsFilters = (props) => {
    const { initialDate, setInitialDate, finalDate, setFinalDate, handleChange, companySelected, companies, execute } = props;
    const { t } = useTranslation();

    const dateChange = (range) => {
        let [startDate, endDate] = range;
        setInitialDate(dayjs(startDate).format());
        setFinalDate(dayjs(endDate).endOf("day").format());
        execute(null, dayjs(startDate).format(), dayjs(endDate).endOf("day").format());
    };

    return (
        <div className="mb-3">
            <div className="flex w-full flex-col space-x-4 sm:flex-row sm:items-end">
                <div className="flex w-64">
                    <ComboboxSelect
                        options={orderBy(companies, ["name"], ["asc"])}
                        value={companySelected}
                        placeholder={t("Seleccione o escriba Compañía")}
                        label={"Compañía"}
                        handleChange={handleChange}
                        name={"Compañía"}
                        background={"#eef1f4"}
                    />
                </div>
                <div className="flex">
                    <DateRangePicker
                        icon={<DateIcon width="1rem" height="1.0625rem" fill="#A6B4D0" />}
                        dateValue={[initialDate, finalDate]}
                        dateChange={dateChange}
                        background={"#eef1f4"}
                    />
                </div>
            </div>
        </div>
    );
};

export default ChannelsFilters;
