import React from "react";

import TrashIcon from "../../../../icons/TrashIcon";

const DeleteButton = (props) => {
    return (
        <div
            className="z-550 absolute right-0 top-0 -mt-2 -mr-1 flex h-6 w-6 items-center justify-center rounded-lg bg-white text-gray-400 shadow-element hover:bg-red hover:text-white"
            onClick={() => {
                if (props.buttonCounter) {
                    props.buttonCounter(-1);
                }
                props.deleteOption(props.option);
            }}>
            <TrashIcon className="fill-current" width="16px" height="16px" />
        </div>
    );
};

export default DeleteButton;
