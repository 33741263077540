import { SET_BOT_TESTER } from "../constants";
import initialState from "./initialState";

function botTester(state = initialState.isOpenBotTester, action) {
    switch (action.type) {
        case SET_BOT_TESTER:
            return action.payload;
        default:
            return state;
    }
}

export default botTester;
