import { UPDATE_INPUT, ADD_INPUTS, UPDATE_INPUTS, SET_UNSAVED_CHANGES } from "../constants";

export const addInputs = (inputs) => ({
    type: ADD_INPUTS,
    payload: inputs,
});

export function updateInput(input) {
    return function (dispatch) {
        dispatch({
            type: UPDATE_INPUT,
            payload: input,
        });

        dispatch({
            type: SET_UNSAVED_CHANGES,
        });
    };
}

export function mergeInputs(inputs) {
    return function (dispatch) {
        dispatch({
            type: UPDATE_INPUTS,
            payload: inputs,
        });
    };
}
