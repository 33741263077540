import { List } from "immutable";
import get from "lodash/get";
import omit from "lodash/omit";
import forIn from "lodash/forIn";
import isEmpty from "lodash/isEmpty";
import toUpper from "lodash/toUpper";
import isEqual from "lodash/isEqual";
import orderBy from "lodash/orderBy";
import includes from "lodash/includes";

import Tippy from "@tippyjs/react";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";
import TextareaAutosize from "react-autosize-textarea";

import BubbleContainer from "../BubbleContainer";
import SlideOver from "../../../common/SlideOver";
import SettingsIcon from "../../../icons/SettingsIcon";
import QuestionIcon from "../../../icons/QuestionIcon";
import FormCombobox from "../../../common/FormCombobox";
import { updateInput, mergeInputs } from "../../../../actions/inputs";
import Checkbox from "../../../common/FormComponent/Checkbox";
import { NEW, UPDATE, VALIDATIONS, VALIDATION_MESSAGES, MASK_TYPES } from "../../../../constants";
import CanRenderOption from "../../../common/CanRenderOption";

const formTextArea =
    "form-input rounded-10 font-normal shadow-textArea w-full px-3 py-2 text-gray-400 text-15 focus:outline-none border-transparent focus:ring-transparent focus:border-transparent placeholder:text-gray-400 placeholder:text-opacity-70";

const InputBubble = (props) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [copied, setCopied] = useState(false);

    const dispatch = useDispatch();

    const getInput = () => {
        return List(props.inputs).find((a) => a.bubbleId === props.bubble.id);
    };
    const input = getInput();
    const [maskedSelected, setMaskedSelected] = useState(!isEmpty(input?.properties?.inputMaskType));
    const inputId = get(input, "id", null);

    useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setCopied(false);
            }, 1000);
        }
    }, [copied]);

    useEffect(() => {
        if (!maskedSelected) {
            cleanMask(input);
        }
    }, [maskedSelected]);

    const handleSelectChange = (validation, input) => {
        let rules = {
            [input.name]: validation.value,
        };

        let messages = {};
        forIn(rules, (value, key) => {
            if (includes(value, ":{botId},{referenceId}")) {
                const validationValues = value.replace(":{botId},{referenceId}", "");

                const rules = validationValues.split("|");

                for (let rule of rules) {
                    messages[`${key}.${rule}`] = VALIDATION_MESSAGES[rule];
                }
            } else {
                messages[`${key}.${value}`] = VALIDATION_MESSAGES[value];
            }
        });

        const isImageOrLocation = validation.rules;
        if (isImageOrLocation) {
            rules = validation.rules;
        }

        dispatch(
            updateInput({
                ...input,
                messages,
                validations: rules,
                action: input.action === NEW ? NEW : UPDATE,
            })
        );
    };

    const getValue = (input, validations) => {
        return validations.find((validation) => {
            let rules = {};
            if (validation.rules) {
                rules = validation.rules;
            } else {
                rules = {
                    [input.name]: validation.value,
                };
            }

            return isEqual(input.validations, rules);
        });
    };

    const handleChange = ({ target }, input) => {
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const { validations, messages } = getInput();
        const isEditingInputTag = validations && target.type !== "textarea" && target.type !== "checkbox";
        if (isEditingInputTag) {
            const inputName = target.value;
            const validationValue = Object.values(validations)[0];

            const messageValue = Object.values(messages)[0];
            const messageKey = Object.keys(messages)[0];
            let messageType;
            try {
                messageType = messageKey.split(".")[1];
            } catch (error) {
                messageType = "required";
            }

            let rules = {};
            const newMessageKey = `${inputName}.${messageType}`;

            const isNumericOrEmail = Object.keys(validations).length < 2;
            if (isNumericOrEmail) {
                rules[inputName] = validationValue;
            } else {
                rules = validations;
            }

            dispatch(
                updateInput({
                    ...input,
                    [name]: value,
                    messages: { [newMessageKey]: messageValue },
                    validations: rules,
                    action: input.action === NEW ? NEW : UPDATE,
                })
            );
        } else {
            dispatch(
                updateInput({
                    ...input,
                    [name]: value,
                    action: input.action === NEW ? NEW : UPDATE,
                })
            );
        }
    };

    const handleMessageChange = ({ target }, input) => {
        const message = target.value;
        const rules = input.validations;

        let messages = {};
        forIn(rules, (value, key) => {
            messages[`${key}.${value}`] = message;
        });
        dispatch(
            updateInput({
                ...input,
                messages,
                action: input.action === NEW ? NEW : UPDATE,
            })
        );
    };

    const getMessage = (input) => {
        let message = "";
        const messages = get(input, "messages", {});
        if (!isEmpty(messages)) {
            for (let key in messages) {
                if (!isEmpty(messages[key])) {
                    message = messages[key];
                } else {
                    message = "";
                }
                break;
            }
        }
        return message;
    };

    const botType = toUpper(props.bot.type);
    const validations = VALIDATIONS[botType];
    const message = getMessage(input);

    const typesAllowed = ["WHATSAPP", "OFFICIAL_WHATSAPP", "VENOM", "GUPSHUP", "SMOOCH"];
    const isGupshupBot = includes(typesAllowed, toUpper(botType));

    const closeModal = () => {
        setIsOpen(false);
    };

    const openModal = () => {
        setIsOpen(!isOpen);
    };

    const handlePropertiesSelect = (mask, input) => {
        dispatch(
            updateInput({
                ...input,
                properties: {
                    ...input.properties,
                    inputMaskType: mask.id,
                },
                action: input.action === NEW ? NEW : UPDATE,
            })
        );
    };

    const handleProperties = ({ target }, input) => {
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        dispatch(
            updateInput({
                ...input,
                properties: {
                    ...input.properties,
                    [name]: value,
                },
                action: input.action === NEW ? NEW : UPDATE,
            })
        );
    };

    const cleanMask = (input) => {
        const cleanInput = omit(input, ["properties"]);
        dispatch(
            mergeInputs({
                ...cleanInput,
                action: input.action === NEW ? NEW : UPDATE,
            })
        );
    };

    return (
        <BubbleContainer
            {...props}
            bubbleStyles={props.bubbleStyles}
            input={input}
            icon={<QuestionIcon className="mx-2 fill-current text-gray-400" width="16" height="25" />}
            title={t("bubbles.Pregunta")}
            openModal={openModal}>
            <div className="relative flex-1 rounded-lg" id={input?.id}>
                <div className="my-2 w-full px-2">
                    <div className="grid gap-3 rounded-lg bg-white px-3 py-2 text-sm font-semibold text-gray-400 text-opacity-75">
                        <label htmlFor="title">
                            {t("bubbles.Pregunta")}
                            <TextareaAutosize
                                name="text"
                                value={input.text}
                                className="form-input"
                                placeholder={t("Pregunta")}
                                style={{ minHeight: 80 }}
                                onChange={(event) => handleChange(event, input)}
                            />
                        </label>
                        <label htmlFor="title">
                            {t("bubbles.Etiqueta")}
                            <input
                                type="text"
                                name="name"
                                value={input.name}
                                placeholder={t("bubbles.Nombre de la etiqueta")}
                                onChange={(event) => handleChange(event, input)}
                                className="form-input"
                                required
                            />
                        </label>
                        <label htmlFor="title">
                            {t("common.Validar")}
                            <FormCombobox
                                options={orderBy(validations, ["name"], ["asc"])}
                                value={getValue(input, validations)}
                                handleChange={(evt) => {
                                    handleSelectChange(evt, input);
                                }}
                                name={t("common.Validación")}
                                background={"#fff"}
                                hasCleanFilter={false}
                                placeholder={t("common.Seleccionar validación")}
                                disabled={input.name.length === 0}
                            />
                        </label>
                    </div>

                    <div className="flex justify-between">
                        <CopyToClipboard text={inputId} title={t("Copiar Id")} onCopy={() => setCopied(true)}>
                            <button className="mt-2 flex w-7 cursor-pointer items-center justify-center rounded-lg bg-white py-2 text-primary-200 shadow-input hover:bg-primary-200 hover:text-white">
                                {copied ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        width="0.9375rem"
                                        height="0.9375rem">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        width="0.9375rem"
                                        height="0.9375rem"
                                        stroke="currentColor">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z"
                                        />
                                    </svg>
                                )}
                            </button>
                        </CopyToClipboard>
                        <Tippy content={t("bubbles.Configurar")} theme="jelou" arrow={false} placement={"bottom"} touch={false}>
                            <button
                                className="mt-2 flex w-7 cursor-pointer items-center justify-center rounded-lg bg-white py-2 text-primary-200 shadow-input hover:bg-primary-200 hover:text-white"
                                onClick={openModal}>
                                <SettingsIcon className="fill-current" width="0.9375rem" height="0.9375rem" />
                            </button>
                        </Tippy>
                    </div>
                </div>
            </div>
            <SlideOver isOpen={isOpen} title={t("bubbles.Pregunta")} closeModal={closeModal}>
                <div className="w-full px-5 py-4">
                    <div className="divide-y divide-gray-200">
                        <div className="grid gap-4 text-sm font-semibold text-gray-400 text-opacity-75">
                            <label htmlFor="title">
                                {t("bubbles.Etiqueta")}
                                <input
                                    type="text"
                                    name="name"
                                    value={input.name}
                                    placeholder={t("bubbles.Nombre de la etiqueta")}
                                    onChange={(event) => handleChange(event, input)}
                                    className="form-input"
                                />
                            </label>
                            <label htmlFor="title">{t("common.Validar")}</label>
                            <FormCombobox
                                options={orderBy(validations, ["name"], ["asc"])}
                                value={getValue(input, validations)}
                                handleChange={(evt) => {
                                    handleSelectChange(evt, input);
                                }}
                                name={t("common.Validación")}
                                background={"#fff"}
                                hasCleanFilter={false}
                                placeholder={t("common.Seleccionar validación")}
                                disabled={input.name.length === 0}
                            />
                            <label>
                                {t("Mensaje")}
                                <TextareaAutosize
                                    name="message"
                                    value={message}
                                    style={{ minHeight: 80 }}
                                    placeholder={t("Mensaje de error")}
                                    onChange={(event) => handleMessageChange(event, input)}
                                    className={formTextArea}
                                />
                            </label>

                            <CanRenderOption option="view cache">
                                <div className="mb-3 flex h-9 justify-end">
                                    <label
                                        htmlFor="cache"
                                        className="flex select-none items-center space-x-2 text-left font-sans text-sm font-semibold leading-tight text-gray-400">
                                        <span>{t("No volver a preguntar")}</span>
                                        <Checkbox
                                            type="checkbox"
                                            onChange={(event) => handleChange(event, input)}
                                            checked={input.cache}
                                            name="cache"
                                        />
                                    </label>
                                </div>
                            </CanRenderOption>
                        </div>

                        {isGupshupBot && (
                            <div className="grid gap-4 pt-3 text-sm font-semibold text-gray-400 text-opacity-75">
                                <div className="flex h-9">
                                    <label
                                        htmlFor="mask"
                                        className="flex select-none items-center space-x-2 text-left font-sans text-sm font-semibold leading-tight text-gray-400">
                                        <span>{t("Enmascarar")}</span>
                                        <Checkbox
                                            type="checkbox"
                                            onChange={() => setMaskedSelected(!maskedSelected)}
                                            checked={maskedSelected}
                                            name="mask"
                                        />
                                    </label>
                                </div>

                                {maskedSelected && (
                                    <>
                                        <label htmlFor="title">{t("bubbles.Tipo de máscara")}</label>
                                        <FormCombobox
                                            options={orderBy(MASK_TYPES, ["name"], ["asc"])}
                                            value={MASK_TYPES.find((type) => type.id === input?.properties?.inputMaskType)}
                                            handleChange={(evt) => {
                                                handlePropertiesSelect(evt, input);
                                            }}
                                            name={t("common.Enmascarar")}
                                            background={"#fff"}
                                            hasCleanFilter={false}
                                            placeholder={t("common.Seleccionar tipo de máscara")}
                                            disabled={!maskedSelected}
                                        />
                                        <label htmlFor="inputMask">
                                            {t("bubbles.Máscara")}
                                            <input
                                                type="text"
                                                name="inputMask"
                                                value={input?.properties?.inputMask}
                                                placeholder={t("bubbles.Máscara")}
                                                onChange={(event) => handleProperties(event, input)}
                                                className="form-input"
                                                disabled={!input?.properties?.inputMaskType}
                                            />
                                        </label>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </SlideOver>
        </BubbleContainer>
    );
};

export default InputBubble;
