import uniqid from "uniqid";
import get from "lodash/get";
import { List } from "immutable";
import { useState } from "react";
import toLower from "lodash/toLower";
import { PulseLoader } from "react-spinners";
import { useTranslation } from "react-i18next";

import Http from "../../../../services/Http";
import { UPDATE, NEW } from "../../../../constants";

/* Components */
import StickerIcon from "../../../icons/StickerIcon";
import BubbleContainer from "../BubbleContainer";
import { notifyError } from "../../../common/Toasts";

function getAttachment(attachments, bubbleId) {
    return List(attachments).find((a) => a.bubbleId === bubbleId);
}

const StickerBubble = (props) => {
    const { attachments, bubble, flow, bot, updateAttachment } = props;
    const { t } = useTranslation();
    const [uploading, setUploading] = useState(false);

    let attachment = getAttachment(attachments, bubble.id);
    const url = attachment && get(attachment, "url");

    const handleLoadLocalFile = (event, attachment) => {
        event.preventDefault();
        const { files } = event.target;
        const image = files[0];

        setUploading(true);

        const formData = new FormData();
        formData.append("image", image);
        let intent = flow.intent;
        const botId = bot.id;
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };

        const src = URL.createObjectURL(image);
        const img = new Image();
        img.src = src;

        let err;

        img.onload = function () {
            const height = this.height;
            const width = this.width;

            //check height and width of image and throw error and break upload if is greater than 512
            if (height > 512 || width > 512) {
                notifyError(t("bubbles.Ocurrió un error al intentar subir este archivo"));
                err = true;
            }
        };

        if (err) {
            setUploading(false);
            return;
        }

        let fileName = image.name.replace(/ /g, "_");
        fileName = uniqid() + `-${fileName}`;
        const path = toLower(`${botId}/flows/${intent}/images/${fileName}`);
        formData.append("path", path);
        try {
            Http.post(`/images/upload`, formData, config)
                .then(({ data }) => {
                    updateAttachment({
                        ...attachment,
                        url: data,
                        action: attachment.action === NEW ? NEW : UPDATE,
                    });
                    setUploading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setUploading(false);
                });
        } catch (error) {
            console.log(error);
            setUploading(false);
        }
    };

    const stickerAcceptance = () => {
        return "image/webp";
    };

    console.log(uploading, "uploading");

    return (
        <BubbleContainer
            {...props}
            bubbleStyles={props.bubbleStyles}
            icon={<StickerIcon className="mx-2 fill-current text-gray-400" width="18px" height="18px" />}
            title={t("bubbles.Pegatina")}>
            <div className="relative my-2 flex-1 rounded-lg">
                <div className="p-2">
                    {uploading ? (
                        <div className="flex h-32 min-h-[202px] items-center justify-center overflow-hidden rounded-lg bg-[#E2E2E2]">
                            <PulseLoader color="#00b3c7" />
                        </div>
                    ) : (
                        <label
                            id={attachment.id}
                            className={`${
                                !url && "min-h-[202px] bg-[#E2E2E2]"
                            } flex w-full cursor-pointer items-center justify-center overflow-hidden rounded-lg text-gray-450 hover:text-blue`}>
                            {url ? (
                                <img src={url} alt={url} className="image-bubble h-full rounded-md object-cover" />
                            ) : (
                                <div className="flex h-32 w-full flex-col items-center justify-center">
                                    <StickerIcon className="mb-2 fill-current text-gray-400" width="18px" height="18px" />
                                    <span className="font-sans text-sm font-extrabold">{t("bubbles.Subir pegatina")}</span>
                                </div>
                            )}
                            <input
                                type="file"
                                name="imageUrl"
                                className="hidden"
                                accept={stickerAcceptance()}
                                onChange={(e) => handleLoadLocalFile(e, attachment)}
                                id={attachment.id}
                            />
                        </label>
                    )}
                </div>
            </div>
        </BubbleContainer>
    );
};

export default StickerBubble;
