import { ADD_BUBBLE_RESOURCE, SET_UNSAVED_CHANGES, ADD_BUBBLE_RESOURCES, UPDATE_BUBBLE_RESOURCE, UPDATE_BUBBLE_RESOURCES } from "../constants";

export const addBubbleResources = (bubbleResources) => ({
    type: ADD_BUBBLE_RESOURCES,
    payload: bubbleResources,
});

export const addBubbleResource = (bubbleResource) => ({
    type: ADD_BUBBLE_RESOURCE,
    payload: bubbleResource,
});

export function updateBubbleResource(BubbleResource) {
    return function (dispatch) {
        dispatch({
            type: UPDATE_BUBBLE_RESOURCE,
            payload: BubbleResource,
        });

        dispatch({
            type: SET_UNSAVED_CHANGES,
        });
    };
}

export const updateBubbleResources = (BubbleResources) => ({
    type: UPDATE_BUBBLE_RESOURCES,
    payload: BubbleResources,
});
