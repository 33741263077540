import { useState } from "react";
import { useTranslation } from "react-i18next";

/* Components */
import CanRenderOption from "../../common/CanRenderOption";
import Modal from "../../common/Modal";
import DragIcon from "../../icons/DragIcon";
import SettingsIcon from "../../icons/SettingsIcon";
import TrashIcon from "../../icons/TrashIcon";
import BubbleSettings from "./BubbleSettings";

function hasSettings(type) {
    return type === "text" || type === "quick_reply" || type === "generic" || type === "input" || type === "sandbox";
}

const BubbleOptions = ({ dragHandleProps, deleteBubble, bubble, updateBubble, input, updateInput, sandbox, updateSandbox, channel }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    function toggleIsOpen() {
        setIsOpen(!isOpen);
    }

    return (
        <div className="relative mr-4">
            <div
                className="mb-2 flex w-7 items-center justify-center rounded-lg bg-white py-2 text-90 shadow-input hover:bg-blue hover:text-white"
                {...dragHandleProps}>
                <DragIcon className="fill-current" width="16px" height="16px" />
            </div>
            <CanRenderOption option="delete bubble">
                <div
                    className="flex w-7 cursor-pointer items-center justify-center rounded-lg bg-white py-2 text-red shadow-input hover:bg-red hover:text-white"
                    onClick={() => deleteBubble(bubble)}>
                    <TrashIcon className="fill-current" width="15px" height="15px" />
                </div>
            </CanRenderOption>
            {hasSettings(bubble.type) && (
                <CanRenderOption option="view bubble settings">
                    <div
                        className="mt-2 flex w-7 cursor-pointer items-center justify-center rounded-lg bg-white py-2 text-primary-200 shadow-input hover:bg-primary-200 hover:text-white"
                        onClick={() => toggleIsOpen()}>
                        <SettingsIcon className="fill-current" width="15px" height="15px" />
                    </div>
                </CanRenderOption>
            )}
            <Modal
                titleModal={t("bubbles.Ajustes")}
                textButtonSecondary={t("common.Cancelar")}
                textButtonPrimary={t("common.Guardar")}
                closeModal={toggleIsOpen}
                isShowModal={isOpen}
                handleClickPrimaryButton={toggleIsOpen}
                minHeight="min-h-[21rem]"
                showButtons>
                <BubbleSettings
                    channel={channel}
                    updateBubble={updateBubble}
                    updateInput={updateInput}
                    input={input}
                    updateSandbox={updateSandbox}
                    sandbox={sandbox}
                    bubble={bubble}
                />
            </Modal>
        </div>
    );
};

export default BubbleOptions;
