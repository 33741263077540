import Icon from "./Icon";

const SandboxIcon = (props) => (
    <Icon viewBox="0 0 14 17" className={props.className} width={props.width} height={props.height}>
        <path
            d="M14 4.31697C14 2.86993 12.7897 1.69297 11.3016 1.69297C9.81355 1.69297 8.60322 2.86993 8.60322 4.31697C8.60322 5.43684 9.33032 6.39202 10.3487 6.7675C10.2042 7.04418 9.96935 7.39331 9.60355 7.71829C8.92387 8.32214 8.06129 8.60101 7.04065 8.5505C5.47806 8.46706 4.30387 8.99186 3.47064 9.69233V5.13601C4.58161 4.81103 5.39226 3.80974 5.39226 2.624C5.39226 1.17696 4.18194 0 2.69387 0C1.21032 0.00219582 0 1.17915 0 2.624C0 3.80754 0.812903 4.81103 1.92161 5.13601V11.864C0.812903 12.189 0 13.1903 0 14.376C0 15.823 1.21032 17 2.69839 17C4.18645 17 5.39677 15.823 5.39677 14.376C5.39677 13.243 4.65387 12.279 3.61742 11.9123C3.99452 11.2074 4.94968 9.95143 6.95935 10.0546C9.81581 10.2105 11.1865 8.39899 11.6313 7.64144C11.7532 7.43503 11.9 7.14957 11.9948 6.85094C13.1465 6.55012 14 5.52906 14 4.31697ZM12.4487 4.31697C12.4487 4.9318 11.9339 5.43245 11.3016 5.43245C10.6694 5.43245 10.1545 4.9318 10.1545 4.31697C10.1545 3.70214 10.6694 3.2015 11.3016 3.2015C11.9339 3.2015 12.4487 3.69995 12.4487 4.31697ZM1.54903 14.376C1.54903 13.7612 2.06387 13.2605 2.69613 13.2605C3.32839 13.2605 3.84323 13.7612 3.84323 14.376C3.84323 14.9908 3.32839 15.4915 2.69613 15.4915C2.06387 15.4915 1.54903 14.9908 1.54903 14.376ZM2.69839 3.73947C2.06613 3.73947 1.55129 3.23883 1.55129 2.624C1.55129 2.00917 2.06613 1.50853 2.69839 1.50853C3.33065 1.50853 3.84548 2.00917 3.84548 2.624C3.84548 3.23883 3.33065 3.73947 2.69839 3.73947Z"
            fill={props.fill}
        />
    </Icon>
);

export default SandboxIcon;
