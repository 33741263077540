import Icon from "./Icon";

const ContainerIcon = (props) => (
    <Icon viewBox="0 0 39 32" className={props.className} width={props.width} height={props.height}>
        <g filter="url(#filter0_d_4_861)">
            <path d="M35 24L16 24C9.37258 24 4 18.6274 4 12C4 5.37258 9.37258 -3.33072e-07 16 2.46316e-07L35 1.90735e-06L35 24Z" fill="#F2FBFC" />
        </g>
        <defs>
            <filter id="filter0_d_4_861" x="0" y="0" width="39" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.8625 0 0 0 0 0.8625 0 0 0 0 0.8625 0 0 0 0.5 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_861" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_861" result="shape" />
            </filter>
        </defs>
    </Icon>
);

export default ContainerIcon;
