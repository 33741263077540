import React from "react";

import Icon from "./Icon";

const DragIcon = (props) => (
    <Icon viewBox="0 0 13.4 35.7" className={props.className} width={props.width} height={props.height}>
        <path
            d="M1.4,18.6v-1.5c0-2.1,1.3-3.7,2.9-3.7H9c1.6,0,2.9,1.7,2.9,3.7v1.5c0,2.1-1.3,3.7-2.9,3.7H4.4
		C2.7,22.3,1.4,20.6,1.4,18.6z"
        />
        <path
            d="M6.1,0.3L0.2,7.7C-0.1,8,0,8.5,0.3,8.8c0.1,0.1,0.3,0.2,0.5,0.2h11.9c0.4,0,0.7-0.3,0.7-0.7
			c0-0.2-0.1-0.3-0.2-0.5L7.3,0.3C7,0,6.6-0.1,6.2,0.2C6.2,0.2,6.1,0.2,6.1,0.3L6.1,0.3L6.1,0.3z"
        />
        <path
            d="M0.1,27.2C0,27.5,0,27.8,0.2,28l5.9,7.4c0.3,0.3,0.7,0.4,1,0.1c0,0,0.1-0.1,0.1-0.1l5.9-7.4
			c0.3-0.3,0.2-0.8-0.1-1c-0.1-0.1-0.3-0.2-0.5-0.2H0.7C0.5,26.8,0.2,26.9,0.1,27.2z"
        />
    </Icon>
);

export default DragIcon;
