import { JelouAPI } from "../services/Http";
import { ADD_OPERATORS } from "../constants";

import isEmpty from "lodash/isEmpty";

const addOperators = (operators) => ({
    type: ADD_OPERATORS,
    payload: operators,
});

/**
 * It gets the operators for a company
 * @param companyId - The id of the company you want to get the operators for.
 * @returns A function that takes dispatch and getState as arguments.
 */
export function getOperators(companyId) {
    return function (dispatch, getState) {
        const { companies } = getState();
        const credentials = getCompanyCredentials(companyId, companies);
        const { username, password } = credentials;
        return JelouAPI.get(`/operators`, {
            auth: {
                username: username,
                password: password,
            },
        })
            .then((res) => {
                dispatch(addOperators(res.data));
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
}

/**
 * "Given a companyId and a list of companies, return the clientId and clientSecret for the company
 * with the given companyId."
 *
 * The function is a pure function. It doesn't mutate the companies array. It doesn't mutate the
 * company object. It doesn't mutate the clientId or clientSecret. It doesn't mutate anything. It just
 * returns a new object
 * @param companyId - The id of the company you want to get the credentials for.
 * @param companies - The list of companies that the user has access to.
 * @returns an object with the clientId and clientSecret of the company that matches the companyId.
 */
const getCompanyCredentials = (companyId, companies) => {
    if (!isEmpty(companies)) {
        const { clientId, clientSecret } = companies.find((company) => company.id === companyId);
        return { username: clientId, password: clientSecret };
    }
    return null;
};
