import React from "react";

const Icon = (props) => (
    <svg
        xmlns="https://www.w3.org/2000/svg"
        fill="none"
        height={props.height}
        width={props.width}
        viewBox={props.viewBox}
        stroke={props.stroke}
        strokeWidth={props.strokeWidth}
        strokeLinecap={props.strokeLinecap}
        strokeLinejoin={props.strokeLinejoin}
        className={props.className}>
        {props.children}
    </svg>
);

export default Icon;
