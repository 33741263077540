import initialState from "./initialState";
import { mergeById } from "../helpers";
import { ADD_ATTACHMENT, DELETE_ALL_ATTACHMENTS, UPDATE_ATTACHMENT, UPDATE_ATTACHMENTS } from "../constants";

function attachments(state = initialState.attachments, action) {
    switch (action.type) {
        case ADD_ATTACHMENT:
            return [...state, action.payload];
        case UPDATE_ATTACHMENT:
            return mergeById(state, action.payload);
        case UPDATE_ATTACHMENTS:
            return mergeById(state, action.payload);
        case DELETE_ALL_ATTACHMENTS:
            return [];
        default:
            return state;
    }
}

export default attachments;
