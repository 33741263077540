import { Transition } from "@headlessui/react";
import { Fragment, useEffect } from "react";
import has from "lodash/has";
import omit from "lodash/omit";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-autosize-textarea/lib";

import { NEW, UPDATE } from "../../../../../constants";
import DeleteButton from "./DeleteButton";
import { updateBubble, mergeBubbles } from "../../../../../actions/bubbles";

const textArea =
    "rounded-10 shadow-textArea w-full px-3 py-2 text-gray-400 text-15 focus:outline-none border-transparent focus:ring-primary-200 focus:border-primary-200 placeholder:text-gray-400 placeholder:text-opacity-70";

const Buttons = (props) => {
    const { bubble } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const hasTextOptions = has(bubble, "text");
    const textAreaId = document.getElementById("text-area");

    const deleteOption = () => {
        dispatch(
            mergeBubbles({
                ...omit(bubble, "text"),
                action: bubble.action === NEW ? NEW : UPDATE,
            })
        );
    };

    useEffect(() => {
        if (hasTextOptions && textAreaId) {
            textAreaId.scrollIntoView({ behavior: "smooth" });
        }
    }, [hasTextOptions, textAreaId]);

    return (
        <Transition as={Fragment} show={hasTextOptions} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100">
            <div className="relative space-y-3 px-3">
                <DeleteButton {...props} deleteOption={deleteOption} />
                <TextareaAutosize
                    id="text-area"
                    className={textArea}
                    style={{ minHeight: 80 }}
                    placeholder={t("bubbles.Ingresa el texto que deseas que la persona vea cuando invoca el flujo seleccionado.")}
                    onChange={({ target }) => {
                        dispatch(
                            updateBubble({
                                ...bubble,
                                text: target.value,
                                action: bubble.action === NEW ? NEW : UPDATE,
                            })
                        );
                    }}
                    defaultValue={bubble.text}
                />
            </div>
        </Transition>
    );
};
export default Buttons;
