import Icon from "./Icon";

const HamburgerIcon = (props) => (
    <Icon viewBox="0 0 18 12" fill="none" className={props.className} width={props.width} height={props.height}>
        <rect width="18" height="2" rx="1" fill="#727C94" fillOpacity="0.3" />
        <rect y="5" width="18" height="2" rx="1" fill="#727C94" fillOpacity="0.3" />
        <rect y="10" width="18" height="2" rx="1" fill="#727C94" fillOpacity="0.3" />
    </Icon>
);

export default HamburgerIcon;
