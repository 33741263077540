import Http from "../services/Http";

export default function metrics() {
    function companyChannels(companyIds, initDate, endDate, controller) {
        return Http.post(
            `/metrics/companyChannels`,
            { companyIds, initDate, endDate },
            {
                signal: controller.signal,
            }
        );
    }

    function topFlows(botId, initDate, endDate, controller) {
        return Http.post(
            `/metrics/topFlows`,
            { botId, initDate, endDate },
            {
                signal: controller.signal,
            }
        );
    }

    function topIntents(botId, initDate, endDate, controller) {
        return Http.post(
            `/metrics/topIntents`,
            { botId, initDate, endDate },
            {
                signal: controller.signal,
            }
        );
    }

    function uniqueUsers(botId, initDate, endDate, controller) {
        return Http.post(
            `/metrics/uniqueUsers`,
            { botId, initDate, endDate },
            {
                signal: controller.signal,
            }
        );
    }

    function botActivity(botId, initDate, endDate, controller) {
        return Http.post(
            `/metrics/botActivity`,
            { botId, initDate, endDate },
            {
                signal: controller.signal,
            }
        );
    }

    function todaySessions(companiesIds, botId, initDate, endDate, controller) {
        return Http.post(
            `/metrics/todaySessions`,
            { companiesIds, botId, initDate, endDate },
            {
                signal: controller.signal,
            }
        );
    }

    function dailySessions(companiesIds, botId, initDate, endDate, controller) {
        return Http.post(
            `/metrics/dailySessions`,
            { companiesIds, botId, initDate, endDate },
            {
                signal: controller.signal,
            }
        );
    }

    function sessions(companiesIds, botId, initDate, endDate, controller) {
        return Http.post(
            `/metrics/sessions`,
            { companiesIds, botId, initDate, endDate },
            {
                signal: controller.signal,
            }
        );
    }

    function uniqUsersFile(body) {
        let { startDate, endDate } = body;
        body = { ...body, startDate: startDate, endDate: endDate };
        return Http({
            url: "/metrics/uniqueUsers/file",
            method: "POST",
            responseType: "blob",
            data: body,
            timeout: 0,
        });
    }

    function sessionsFile(body) {
        let { startDate, endDate } = body;
        body = { ...body, startDate: startDate, endDate: endDate };
        return Http({
            url: "/metrics/sessions/file",
            method: "POST",
            responseType: "blob",
            data: body,
            timeout: 0,
        });
    }

    return {
        companyChannels,
        topFlows,
        topIntents,
        uniqueUsers,
        botActivity,
        todaySessions,
        dailySessions,
        sessions,
        uniqUsersFile,
        sessionsFile,
    };
}
