import { useQuery } from "@tanstack/react-query";
import Http from "../services/Http";

const getBots = async (signal) => {
    const { data } = await Http.get("/bots", {
        signal,
    });
    return data;
};

// Retrieve Operator with pagination
export function useGetBots() {
    return useQuery(["getBotsData"], ({ signal }) => getBots(signal), {
        refetchOnWindowFocus: false,
        select: (data) => {
            return data;
        },
    });
}
