import get from "lodash/get";
import first from "lodash/first";
import toUpper from "lodash/toUpper";
import isEmpty from "lodash/isEmpty";
import uniqueId from "lodash/uniqueId";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useRef, useEffect } from "react";

import { UPDATE, NEW } from "../../../../constants";
import { JelouAPI } from "../../../../services/Http";
import { getOperators } from "../../../../actions/operators";
import { getCompanies } from "../../../../actions/companies";
import { updatePMABubble } from "../../../../actions/pmaBubbles";

import Buttons from "./components/Buttons";
import JCXIcon from "../../../icons/JCXIcon";
import AddButton from "./components/AddButton";
import BubbleContainer from "../BubbleContainer";
import FormCombobox from "../../../common/FormCombobox";

const PMABubble = (props) => {
    const { bubble, updateBubble, bot } = props;
    const { t } = useTranslation();

    let pma = get(props, "pma", {});

    const pmaRef = useRef(null);
    pmaRef.current = pma;

    const { assignmentType = "", assignmentBy = "", errorHandlers = [], operatorId = "", teamId = "" } = pma;

    const [teamOptions, setTeamOptions] = useState([]);
    const [assignedTo, setAssignedTo] = useState([]);
    const [errorList, setErrorList] = useState([]);

    const operators = useSelector((state) => state.operators);
    const companies = useSelector((state) => state.companies);

    const optionsErrorArr = [
        { value: "OPERATOR_NOT_FOUND", name: "Operador no encontrado" },
        { value: "OPERATORS_NOT_IN_SCHEDULER", name: "Fuera de horario" },
        { value: "GENERAL_ERROR", name: "Error General" },
    ];
    const [optionsError, setOptionsError] = useState([...optionsErrorArr]);

    const dispatch = useDispatch();

    const assignationOptions = [
        { value: 1, id: "DIRECT_ASSIGNMENT", name: "Directa" },
        { value: 2, id: "QUEUE", name: "Colas" },
    ];

    const assginateOptions = [
        { value: 1, id: "SHUFFLE", name: "Aleatorio" },
        { value: 2, id: "TEAMS", name: "Equipo" },
        { value: 3, id: "OPERATORS", name: "Operador" },
    ];

    const assginateQueueOptions = [
        { value: 1, id: "GENERAL", name: "General" },
        { value: 2, id: "TEAMS", name: "Equipo" },
    ];

    const [assignmentTypeValue, setAssignmentTypeValue] = useState(assignationOptions.find((option) => option.id === assignmentType));
    const [assignmentByValue, setAssignmentByValue] = useState(
        toUpper(assignmentType) === "QUEUE"
            ? assginateQueueOptions.find((option) => option.id === assignmentBy)
            : assginateOptions.find((option) => option.id === assignmentBy)
    );

    const parseErrors = (errorsObject) => {
        //parse given object to array of objects, beign key error the object.key and the flowId the object.value
        let errors = [];
        for (let key in errorsObject) {
            errors.push({ id: uniqueId(), error: key, flowId: get(errorsObject[key], "flowId", "") });
        }

        setErrorList(errors);
    };

    const formatToOptionsOnSelect = (options) => {
        return options.map((option) => ({ id: option.id, value: option.id, name: option.names || option.name }));
    };

    useEffect(() => {
        if (!isEmpty(pma)) {
            if (!isEmpty(errorHandlers)) {
                parseErrors(errorHandlers);
            }
            if (!isEmpty(teamOptions) && !isEmpty(operators)) {
                if (assignmentBy === "OPERATORS") {
                    let assignedTo = operators.filter((operator) => operator.id === operatorId);
                    setAssignedTo(!isEmpty(assignedTo) ? first(formatToOptionsOnSelect(assignedTo)) : []);
                } else if (assignmentBy === "TEAMS") {
                    let assignedTo = teamOptions.filter((team) => team.value === teamId);
                    setAssignedTo(!isEmpty(assignedTo) ? first(assignedTo) : []);
                }
            }
        }
    }, [pma, teamOptions, operators]);

    useEffect(() => {
        if (!isEmpty(bot) && !isEmpty(companies)) {
            getTeamOptions();
            dispatch(getOperators(bot.companyId));
        }
    }, [bot, companies]);

    useEffect(() => {
        dispatch(getCompanies());
    }, []);

    const onChange = (option) => {
        setAssignmentTypeValue(option);

        if (option !== assignmentTypeValue && !isEmpty(assignmentTypeValue)) {
            setAssignmentByValue([]);
        }

        updateBubble({ ...bubble, assignmentType: option.id, action: props.bubble.action === NEW ? NEW : UPDATE });
        updatePMABubbles({ assignmentType: option.id });
    };

    const onChangeAssignedTo = (option) => {
        setAssignmentByValue(option);
        if (option !== assignmentByValue && !isEmpty(assignmentByValue)) {
            setAssignedTo([]);
        }

        updateBubble({
            ...bubble,
            assignmentType: assignmentTypeValue.id,
            assignmentBy: option.id,
            action: props.bubble.action === NEW ? NEW : UPDATE,
            teamId: null,
            operatorId: null,
        });
        updatePMABubbles({ assignmentBy: option.id, teamId: null, operatorId: null });
    };

    const selectOptionToAssign = (option) => {
        setAssignedTo(option);

        updateBubble({
            ...bubble,
            assignmentType: assignmentTypeValue.id,
            assignmentBy: assignmentByValue.id,
            ...(assignmentByValue.id === "TEAMS" ? { teamId: option.value, operatorId: null } : { operatorId: option.value, teamId: null }),

            action: props.bubble.action === NEW ? NEW : UPDATE,
        });

        updatePMABubbles({
            ...(get(assignmentByValue, "id", "") === "TEAMS" ? { teamId: option.value, operatorId: null } : { operatorId: option.id, teamId: null }),
        });
    };

    const getTeamOptions = async () => {
        try {
            const companyId = get(bot, "companyId", "");
            const { data } = await JelouAPI.get(`/company/${companyId}/teams`, { params: { limit: 200 } });
            const { results } = data;
            let teamArray = [];
            results.forEach((team) => {
                const teamOption = {};
                teamOption.id = team.id;
                teamOption.value = team.id;
                teamOption.name = team.name;
                teamArray.push(teamOption);
            });
            setTeamOptions(teamArray);
        } catch (error) {
            console.log(error);
        }
    };

    function addValidationError() {
        setErrorList([...errorList, { id: uniqueId(), action: props.bubble.action === NEW ? NEW : UPDATE }]);
    }

    const updatePMABubbles = (obj) => {
        dispatch(updatePMABubble({ ...pmaRef.current, ...obj, action: pma.action === NEW ? NEW : UPDATE }));
    };

    return (
        <div className="flex">
            <BubbleContainer
                {...props}
                bubbleStyles={props.bubbleStyles}
                icon={<JCXIcon className="mx-2 fill-current text-gray-400" width="18" height="18" />}
                title={t("bubbles.Operador")}>
                <div className="w-full p-2">
                    <div className="grid gap-4 text-sm font-semibold text-gray-400 text-opacity-75">
                        <label htmlFor="title">
                            {t("Tipo de asignación")}{" "}
                            <FormCombobox
                                handleChange={onChange}
                                options={assignationOptions}
                                placeholder={t("Asignación")}
                                value={assignmentTypeValue}
                                hasCleanFilter={false}
                                className={"bubble-form-input"}
                            />
                        </label>

                        {!isEmpty(assignmentTypeValue) && (
                            <label htmlFor="title">
                                {toUpper(get(assignmentTypeValue, "id", "")) === "QUEUE" ? t("Asignar cola a") : t("Asignar a")}
                                <FormCombobox
                                    className={"bubble-form-input"}
                                    handleChange={onChangeAssignedTo}
                                    options={toUpper(get(assignmentTypeValue, "id", "")) === "QUEUE" ? assginateQueueOptions : assginateOptions}
                                    placeholder={t("Asignación")}
                                    value={assignmentByValue}
                                    hasCleanFilter={false}
                                />
                            </label>
                        )}

                        {(toUpper(get(assignmentByValue, "id", "")) === "TEAMS" || toUpper(get(assignmentByValue, "id", "")) === "OPERATORS") && (
                            <label htmlFor="title">
                                {t("Asignar a")}
                                <FormCombobox
                                    className={"bubble-form-input"}
                                    handleChange={selectOptionToAssign}
                                    options={
                                        toUpper(get(assignmentByValue, "id", "")) === "TEAMS"
                                            ? teamOptions
                                            : operators.map((operator) => {
                                                  return {
                                                      id: operator.id,
                                                      name: operator.names,
                                                  };
                                              })
                                    }
                                    placeholder={t("Asignación")}
                                    value={assignedTo}
                                    hasCleanFilter={false}
                                />
                            </label>
                        )}
                    </div>
                </div>

                <Buttons
                    bot={bot}
                    intents={props.intents}
                    setFlow={props.setFlow}
                    flows={props.flows}
                    bubble={props.bubble}
                    deleteOption={props.deleteOption}
                    updateOption={props.updateOption}
                    buttonCounter={props.buttonCounter}
                    options={optionsError}
                    setOptions={setOptionsError}
                    errorList={errorList}
                    setErrorList={setErrorList}
                    updateBubble={updateBubble}
                    allOptions={optionsErrorArr}
                    pma={pma}
                    updatePMABubbles={updatePMABubbles}
                />
                <AddButton errorList={errorList} optionsErrorArr={optionsErrorArr} addValidationError={addValidationError} />
            </BubbleContainer>
        </div>
    );
};

export default PMABubble;
