import {
    ADD_INTENT,
    ADD_INTENTS,
    DELETE_INTENT,
    UPDATE_INTENT,
    TOGGLE_ADDING_INTENT,
    DELETE_INTENTS,
    LOADING_INTENTS,
    UPDATE_INTENTS,
} from "../constants";
import { mergeById, updateById } from "../helpers";
export const intents = function flows(state = [], action) {
    switch (action.type) {
        case ADD_INTENTS:
            return [...action.payload];
        case ADD_INTENT:
            return [action.payload, ...state];
        case UPDATE_INTENT:
            return updateById(state, action.payload);
        case UPDATE_INTENTS:
            return mergeById(state, action.payload);
        case DELETE_INTENT:
            return state.filter((intent) => intent.id !== action.payload.id);
        case DELETE_INTENTS:
            return [];
        default:
            return state;
    }
};

export const addingIntent = function addingIntent(state = false, action) {
    if (action.type === TOGGLE_ADDING_INTENT) {
        return !state;
    } else {
        return state;
    }
};

export const loadingIntents = function loadingIntents(state = false, action) {
    if (action.type === LOADING_INTENTS) {
        return action.payload;
    } else {
        return state;
    }
};
