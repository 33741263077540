import { DELETE, ADD_QUICK_REPLY, UPDATE_QUICK_REPLY, DELETE_QUICK_REPLIES, SET_UNSAVED_CHANGES } from "../constants";

export function addQuickReply(quickReply) {
    return function (dispatch) {
        dispatch({
            type: ADD_QUICK_REPLY,
            payload: quickReply,
        });
        dispatch({
            type: SET_UNSAVED_CHANGES,
        });
    };
}

export function updateQuickReply(quickReply) {
    return function (dispatch) {
        dispatch({
            type: UPDATE_QUICK_REPLY,
            payload: quickReply,
        });

        dispatch({
            type: SET_UNSAVED_CHANGES,
        });
    };
}

export const deleteQuickReplies = (quickReplies) => ({
    type: DELETE_QUICK_REPLIES,
    payload: quickReplies,
});

export function deleteQuickReply(quickReply) {
    return function (dispatch) {
        dispatch({
            type: UPDATE_QUICK_REPLY,
            payload: { ...quickReply, action: DELETE },
        });

        dispatch({
            type: SET_UNSAVED_CHANGES,
        });
    };
}
