import React from "react";

import Icon from "./Icon";

const SickerIcon = (props) => (
    <Icon viewBox="0 0 21 21" className={props.className} width={props.width} height={props.height}>
        <path
            d="M10.288 18.6761C7.70234 18.6486 5.22669 17.4657 3.65878 15.4027C2.06336 13.3396 1.54072 10.5889 2.2284 8.05827C2.91608 5.61013 4.73156 3.54708 7.06967 2.58433C9.49031 1.56656 12.3235 1.78662 14.5791 3.13447C17.0823 4.64737 18.5952 7.37059 18.6227 10.2864C18.6227 11.3866 20.3557 11.4141 20.3557 10.2864C20.3282 7.12302 18.8428 4.15224 16.3396 2.22674C13.864 0.32874 10.5081 -0.276419 7.50979 0.576305C4.53901 1.42903 2.11838 3.62961 0.935565 6.49036C-0.247246 9.37862 0.000319168 12.7895 1.62325 15.4577C3.43872 18.4835 6.7671 20.3265 10.288 20.354C11.3883 20.409 11.4158 18.6761 10.288 18.6761Z"
            fill={props.fill}
        />
        <path
            d="M18.8978 9.70862C16.2021 12.4043 13.5339 15.0725 10.8382 17.7682C10.4531 18.1533 10.068 18.5384 9.68287 18.9235C10.178 19.1161 10.6731 19.3361 11.1683 19.5287C11.1958 18.8685 11.2233 18.1808 11.2508 17.5207C11.2783 16.778 11.2783 16.0628 11.4983 15.3476C12.186 13.092 14.4966 11.0565 16.8898 10.9189C17.0823 10.9189 17.2749 10.9189 17.4674 10.9189C17.5499 10.9189 17.9075 10.9464 17.6875 10.9189C17.8525 10.9464 18.0176 10.9739 18.1826 11.0014C18.5677 11.0565 18.9253 11.1115 19.3104 11.1665C19.778 11.2215 20.2456 11.0565 20.3832 10.5613C20.4932 10.1487 20.2456 9.57107 19.778 9.48854C18.9803 9.37852 18.1551 9.18597 17.3574 9.18597C15.8995 9.15846 14.4141 9.6811 13.2038 10.5063C11.966 11.359 10.8932 12.4868 10.2605 13.8622C9.7929 14.8525 9.62785 15.8702 9.57284 16.9705C9.54533 17.8232 9.49032 18.7035 9.46281 19.5562C9.4353 20.3264 10.4256 20.684 10.9482 20.1614C13.6439 17.4657 16.3121 14.7974 19.0078 12.1017C19.3929 11.7166 19.778 11.3315 20.1631 10.9464C20.9058 10.1487 19.6955 8.91091 18.8978 9.70862Z"
            fill={props.fill}
        />
        <path
            d="M7.01464 9.15851C7.6527 9.15851 8.16995 8.64126 8.16995 8.00321C8.16995 7.36515 7.6527 6.8479 7.01464 6.8479C6.37659 6.8479 5.85934 7.36515 5.85934 8.00321C5.85934 8.64126 6.37659 9.15851 7.01464 9.15851Z"
            fill={props.fill}
        />
        <path
            d="M13.5614 9.15851C14.1995 9.15851 14.7167 8.64126 14.7167 8.00321C14.7167 7.36515 14.1995 6.8479 13.5614 6.8479C12.9233 6.8479 12.4061 7.36515 12.4061 8.00321C12.4061 8.64126 12.9233 9.15851 13.5614 9.15851Z"
            fill={props.fill}
        />
        <path
            d="M5.44672 12.0743C5.88684 12.4869 6.32695 12.927 6.79458 13.2846C7.86736 14.1098 9.18771 14.5499 10.5631 14.4949C11.0032 14.4674 11.4433 14.1098 11.4158 13.6422C11.3883 13.2021 11.0307 12.762 10.5631 12.7895C10.4255 12.7895 10.288 12.7895 10.123 12.7895C10.0404 12.7895 9.98541 12.7895 9.90289 12.7895C9.87538 12.7895 9.62782 12.762 9.84788 12.7895C9.57281 12.7345 9.27023 12.6795 9.02266 12.5969C8.96765 12.5694 8.88512 12.5419 8.83011 12.5144C8.66507 12.4594 8.94014 12.5694 8.7751 12.4869C8.66507 12.4319 8.52753 12.3769 8.4175 12.2943C8.27996 12.2118 8.16994 12.1568 8.0324 12.0743C7.97739 12.0193 7.92237 11.9918 7.83985 11.9367C7.78483 11.9092 7.81234 11.9092 7.86736 11.9642C7.83985 11.9367 7.81235 11.9092 7.78484 11.8817C7.39974 11.5516 7.04214 11.194 6.65704 10.864C6.32695 10.5614 5.7493 10.5064 5.44672 10.864C5.14414 11.194 5.08913 11.7442 5.44672 12.0743Z"
            fill={props.fill}
        />
    </Icon>
);

export default SickerIcon;
