import get from "lodash/get";
import toUpper from "lodash/toUpper";

import TextBubble from "./TextBubble";
import FlowBubble from "./FlowBubble";

const Bubble = (props) => {
    const { styles, message, key, type, setFlow, setMessages } = props;

    // const by = get(message, "type", "");

    if (!message) {
        return <div></div>;
    }

    const renderBubble = () => {
        switch (toUpper(type)) {
            case "USER":
                return <TextBubble text={get(message, "message", "")} messages={message} styles={styles} />;
            case "BOT":
                return (
                    <FlowBubble text={get(message, "message", "")} message={message} styles={styles} setFlow={setFlow} setMessages={setMessages} />
                );
            default:
                return <TextBubble />;
        }
    };

    return (
        <div id={key}>
            <div>
                <div>{renderBubble()}</div>
            </div>
        </div>
    );
};

export default Bubble;
