import { List } from "immutable";
import toString from "lodash/toString";
import React, { Component } from "react";
import { Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";

/* actions */
import { updateAttachment } from "../../actions/attachments";
import { updateBubbleResource } from "../../actions/bubbleResources";
import { updateBubble, updateTextBubble } from "../../actions/bubbles";
import { updateConditional } from "../../actions/conditionals";
import { addElement, deleteElement, updateElement } from "../../actions/elements";
import { updateInput } from "../../actions/inputs";
import { addOption, deleteOption, updateOption } from "../../actions/options";
import { addQuickReply, deleteQuickReply, updateQuickReply } from "../../actions/quickReplies";
import { addRandom, deleteRandom, updateRandom } from "../../actions/randoms";
import { updateSandbox } from "../../actions/sandboxes";
import { BubbleTypes, DELETE } from "../../constants";

/* Components */
import AudioBubble from "./components/AudioBubble";
import GenericBubble from "./components/CarruselBubble/GenericBubble";
import CheckpointBubble from "./components/CheckpointBubble/CheckpointBubble";
import ContactCardBubble from "./components/ContactCardBubble/ContactCardBubble";
import FileBubble from "./components/FileBubble";
import GoToFlowBubble from "./components/GoToFlowBubble/GoToFlowBubble";
import ImageBubble from "./components/ImageBubble/ImageBubble";
import InputBubble from "./components/InputBubble/InputBubble";
import PMABubble from "./components/MutiagentPanelBubble/MutiagentPanelBubble";
import QuickReplyBubble from "./components/QuickReplyBubble/QuickReplyBubble";
import RandomBubble from "./components/RandomBubble/RandomBubble";
import ResourceBubble from "./components/ResourceBubble/ResourceBubble";
import RunActionBubble from "./components/RunActionBubble/RunActionBubble";
import SandboxBubble from "./components/SandboxBubble";
import { SkillBubble } from "./components/Skill/Skill.bubble";
import StickerBubble from "./components/StickerBubble/StickerBubble";
import TextBubble from "./components/TextBubble/TextBubble";
import ToolkitBubble from "./components/Toolkit/Toolkit";
import UnsupportedBubble from "./components/UnsupportedBubble/UnsupportedBubble";
import VideoBubble from "./components/VideoBubble/VideoBubble";

const mapStateToProps = (state) => {
    return {
        flows: state.flows,
        inputs: state.inputs,
        options: state.options,
        intents: state.intents,
        randoms: state.randoms,
        elements: state.elements,
        resources: state.resources,
        attachments: state.attachments,
        quickReplies: state.quickReplies,
        conditionals: state.conditionals,
        pmaBubbles: state.pmaBubbles,
        bubbleResources: state.bubbleResources,
        contactsCards: state.contactsCards,
        sandboxes: state.sandboxes,
        runActionBubbles: state.runActionBubbles,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addOption: (option) => dispatch(addOption(option)),
        addRandom: (random) => dispatch(addRandom(random)),
        updateInput: (input) => dispatch(updateInput(input)),
        addElement: (element) => dispatch(addElement(element)),
        updateRandom: (random) => dispatch(updateRandom(random)),
        updateOption: (option) => dispatch(updateOption(option)),
        deleteOption: (option) => dispatch(deleteOption(option)),
        deleteRandom: (random) => dispatch(deleteRandom(random)),
        deleteElement: (element) => dispatch(deleteElement(element)),
        updateElement: (element) => dispatch(updateElement(element)),
        updateTextBubble: (bubble) => dispatch(updateTextBubble(bubble)),
        addQuickReply: (quickReply) => dispatch(addQuickReply(quickReply)),
        updateQuickReply: (quickReply) => dispatch(updateQuickReply(quickReply)),
        updateSandbox: (sandbox) => dispatch(updateSandbox(sandbox)),
        deleteQuickReply: (quickReply) => dispatch(deleteQuickReply(quickReply)),
        updateAttachment: (attachment) => dispatch(updateAttachment(attachment)),
        updateConditional: (conditional) => dispatch(updateConditional(conditional)),
        updateBubbleResource: (bubbleResource) => dispatch(updateBubbleResource(bubbleResource)),
        updateBubble: (bubble) => dispatch(updateBubble(bubble)),
    };
};

class ConnectedBubble extends Component {
    state = {
        buttonsCreated: 0,
    };
    componentDidMount() {
        if (this.props.bubble.type === BubbleTypes.TEXT) {
            const bubbleId = this.props.bubble.id;
            const options = this.props.options.filter(
                (option) => option.optionableId === bubbleId && option.optionable === "Bubble" && option.action !== DELETE
            );
            this.setState({
                buttonsCreated: options.length,
            });
        }
    }

    getConditional = (bubbleId) => {
        return List(this.props.conditionals).find((a) => a.bubbleId === bubbleId);
    };

    getBubbleResource = (bubbleId) => {
        return List(this.props.bubbleResources).find((a) => a.bubbleId === bubbleId);
    };

    getElements = (bubbleId) => {
        return List(this.props.elements).filter((e) => e.bubbleId === bubbleId && e.action !== DELETE);
    };

    getRandoms = (bubbleId) => {
        return List(this.props.randoms).filter((e) => e.bubbleId === bubbleId && e.action !== DELETE);
    };

    getQuickReplies = (bubbleId) => {
        return List(this.props.quickReplies).filter((e) => {
            return e.repliable === "Bubble" && e.repliableId === bubbleId && e.action !== DELETE;
        });
    };

    getContactCard = (bubbleId) => {
        const contact = List(this.props.contactsCards).find((a) => a.bubbleId === bubbleId);
        if (!contact) {
            return { name: "", phone: "", email: "", address: "" };
        }
        return contact;
    };

    getSandbox = (bubbleId) => {
        const sandbox = List(this.props.sandboxes).find((a) => a.bubbleId === bubbleId);
        return sandbox;
    };

    getPMA = (bubbleId) => {
        const pmaBubbe = this.props.pmaBubbles.find((a) => a.bubbleId === bubbleId);
        return pmaBubbe;
    };

    getRunActions = (bubbleId) => {
        const runActionsBubble = this.props.runActionBubbles.find((a) => a.bubbleId === bubbleId);
        return runActionsBubble;
    };

    // This function should add or substract 1 button at time.
    buttonCounter = (number) => {
        this.setState({ buttonsCreated: this.state.buttonsCreated + number }, () => {
            if (this.state.buttonsCreated < 0) {
                this.setState({
                    buttonsCreated: 0,
                });
            }
        });
    };

    renderBubble(dragHandleProps, bubbleStyles) {
        const bubbleId = this.props.bubble.id;
        switch (this.props.bubble.type) {
            case BubbleTypes.TEXT:
                return (
                    <TextBubble
                        {...this.props}
                        bubbleId={bubbleId}
                        intents={this.props.intents}
                        setFlow={this.props.setFlow}
                        dragHandleProps={dragHandleProps}
                        handleButtonChange={this.handleButtonChange}
                        buttonCounter={this.buttonCounter}
                        buttons={this.state.buttonsCreated}
                        setButtonCreated={this.setButtonCreated}
                        bubbleStyles={bubbleStyles}
                    />
                );
            case BubbleTypes.GENERIC:
                return (
                    <GenericBubble
                        {...this.props}
                        dragHandleProps={dragHandleProps}
                        elements={this.getElements(bubbleId)}
                        handleButtonChange={this.handleButtonChange}
                        addElement={this.props.addElement}
                        buttonCounter={this.buttonCounter}
                        buttons={this.state.buttonsCreated}
                        setButtonCreated={this.setButtonCreated}
                        bubbleStyles={bubbleStyles}
                    />
                );
            case BubbleTypes.IMAGE:
                return <ImageBubble {...this.props} dragHandleProps={dragHandleProps} bubbleStyles={bubbleStyles} />;
            case BubbleTypes.INPUT:
                return (
                    <InputBubble
                        {...this.props}
                        bot={this.props.bot}
                        bubble={this.props.bubble}
                        deleteBubble={this.props.deleteBubble}
                        inputs={this.props.inputs}
                        dragHandleProps={dragHandleProps}
                        updateBubble={this.props.updateBubble}
                        bubbleStyles={bubbleStyles}
                    />
                );
            case BubbleTypes.CONDITIONAL:
                return (
                    <GoToFlowBubble
                        {...this.props}
                        intents={this.props.intents}
                        setFlow={this.props.setFlow}
                        dragHandleProps={dragHandleProps}
                        conditional={this.getConditional(bubbleId)}
                        bubbleStyles={bubbleStyles}
                    />
                );
            case BubbleTypes.RESOURCE:
                return (
                    <ResourceBubble
                        {...this.props}
                        bubbleResource={this.getBubbleResource(bubbleId)}
                        dragHandleProps={dragHandleProps}
                        bubbleStyles={bubbleStyles}
                    />
                );
            case BubbleTypes.RANDOM:
                return (
                    <RandomBubble {...this.props} randoms={this.getRandoms(bubbleId)} dragHandleProps={dragHandleProps} bubbleStyles={bubbleStyles} />
                );
            case BubbleTypes.QUICK_REPLY:
                return (
                    <QuickReplyBubble
                        {...this.props}
                        buttonCounter={this.buttonCounter}
                        quickReplies={this.getQuickReplies(bubbleId)}
                        dragHandleProps={dragHandleProps}
                        bubbleStyles={bubbleStyles}
                    />
                );
            case BubbleTypes.CONTACT_BUBBLE:
                return (
                    <ContactCardBubble
                        {...this.props}
                        dragHandleProps={dragHandleProps}
                        getContactCard={() => this.getContactCard(bubbleId)}
                        updateBubble={this.props.updateBubble}
                        bubbleStyles={bubbleStyles}
                    />
                );
            case BubbleTypes.SANDBOX:
                return (
                    <SandboxBubble
                        {...this.props}
                        updateSandbox={this.props.updateSandbox}
                        sandbox={this.getSandbox(bubbleId)}
                        dragHandleProps={dragHandleProps}
                        bubbleStyles={bubbleStyles}
                    />
                );
            case BubbleTypes.VIDEO:
                return <VideoBubble {...this.props} dragHandleProps={dragHandleProps} bubbleStyles={bubbleStyles} />;
            case BubbleTypes.FILE:
                return <FileBubble {...this.props} dragHandleProps={dragHandleProps} bubbleStyles={bubbleStyles} />;
            case BubbleTypes.CHECKPOINT:
                return (
                    <CheckpointBubble
                        {...this.props}
                        updateBubble={this.props.updateBubble}
                        dragHandleProps={dragHandleProps}
                        bubbleStyles={bubbleStyles}
                    />
                );
            case BubbleTypes.PMA:
                return <PMABubble pma={this.getPMA(bubbleId)} {...this.props} dragHandleProps={dragHandleProps} bubbleStyles={bubbleStyles} />;
            case BubbleTypes.AUDIO:
                return <AudioBubble {...this.props} dragHandleProps={dragHandleProps} bubbleStyles={bubbleStyles} />;
            case BubbleTypes.STICKER:
                return <StickerBubble {...this.props} dragHandleProps={dragHandleProps} bubbleStyles={bubbleStyles} />;
            case BubbleTypes.TOOLKIT:
                return <ToolkitBubble {...this.props} dragHandleProps={dragHandleProps} bubbleStyles={bubbleStyles} />;
            case BubbleTypes.SKILL:
                return <SkillBubble {...this.props} dragHandleProps={dragHandleProps} bubbleStyles={bubbleStyles} />;
            case BubbleTypes.RUN_ACTION:
                return (
                    <RunActionBubble
                        {...this.props}
                        runActionBubble={this.getRunActions(bubbleId)}
                        dragHandleProps={dragHandleProps}
                        bubbleStyles={bubbleStyles}
                    />
                );
            default:
                return <UnsupportedBubble {...this.props} dragHandleProps={dragHandleProps} bubbleStyles={bubbleStyles} />;
        }
    }

    render() {
        const bubbleStyles = `border-2 border-[#DCDEE4] drop-shadow-[4px_4px_5px_rgba(184, 189, 201, 0.25)] relative ${
            this.props.bubble.type === BubbleTypes.SANDBOX ? "w-[64%]" : "w-72"
        } rounded-xl bg-gray-13`;

        return (
            <Draggable draggableId={toString(this.props.bubble.id)} index={this.props.index}>
                {(provided) => (
                    <div ref={provided.innerRef} id={this.props.bubble.id} className="inline-block" {...provided.draggableProps}>
                        {this.renderBubble(provided.dragHandleProps, bubbleStyles)}
                    </div>
                )}
            </Draggable>
        );
    }
}

const Bubble = connect(mapStateToProps, mapDispatchToProps)(ConnectedBubble);

export default Bubble;
