import initialState from "./initialState";
import { SET_PERMISSIONS } from "../constants";

export const permissions = function permissions(state = initialState.permissions, action) {
    switch (action.type) {
        case SET_PERMISSIONS:
            return [...action.payload];
        default:
            return state;
    }
};

export default permissions;
