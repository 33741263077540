import { Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";

import get from "lodash/get";
// import first from "lodash/first";
import isEmpty from "lodash/isEmpty";
import isNumber from "lodash/isNumber";
import toUpper from "lodash/toUpper";

import BotTester from "../../bot-tester/bot-tester";
import Bubble from "../../bubbles/Bubble";
import LockFlow from "./LockFlow";
import ToolBox from "./ToolBox";

import {
    createFlow,
    // buildBubble,
    saveBubbles,
} from "../../../actions/bubbles";
import { notSavingChanges, setSavingChanges } from "../../../actions/savingChanges";
import { setIsOpenBotTester } from "../../../actions/testBot";
import { SET_UNSAVED_CHANGES } from "../../../constants";
import { isValidToolBubbles } from "../../bubbles/components/Toolkit/validated";
import CanRenderOption from "../../common/CanRenderOption";
import { Renderize } from "../../common/Renderize";
import { notify, notifyError } from "../../common/Toasts";
import TopNav from "../../common/TopNav";
import ContainerRigthIcon from "../../icons/ContainerRigthIcon";
import TrainingWord from "./ShowTraining";
// import { createFlow } from "../../../actions/flows";
// import ErrorsModal from "./ErrorsModal";

const FlowBubbles = (props) => {
    const { bubbles, intents, flow, showIntents, setShowIntents } = props;
    const [copied, setCopied] = useState(false);
    // const [showErrorsModal, setShowErrorsModal] = useState(false);
    const savingChanges = useSelector((state) => state.savingChanges);
    const unSavedChanges = useSelector((state) => state.unSavedChanges);
    const savedErrors = useSelector((state) => state.savedErrors);
    const isOpenBotTester = useSelector((state) => state.isOpenBotTester);
    const isLoadingFlow = useSelector((state) => state.isLoadingFlow);
    const lang = useSelector((state) => state.user?.lang) ?? "es";
    const bot = useSelector((state) => state.bot);

    const canCreateExpression = toUpper(get(bot, "properties.nlpDriver", "")) !== "DATABASE";

    const inputs = useSelector((state) => state.inputs);
    // const options = useSelector((state) => state.options);
    // const randoms = useSelector((state) => state.randoms);
    // const elements = useSelector((state) => state.elements);
    // const attachments = useSelector((state) => state.attachments);
    // const conditionals = useSelector((state) => state.conditionals);
    const bubbleResources = useSelector((state) => state.bubbleResources);
    // const quickReplies = useSelector((state) => state.quickReplies);
    // const contactsCards = useSelector((state) => state.contactsCards);
    // const sandboxes = useSelector((state) => state.sandboxes);
    // const pmaBubbles = useSelector((state) => state.pmaBubbles);
    // const runActionBubbles = useSelector((state) => state.runActionBubbles);

    const savedErrorsRef = useRef(savedErrors);
    savedErrorsRef.current = savedErrors;

    const [showTraining, setShowTraining] = useState(false);
    // const [isOpenBotTester, setIsOpenBotTester] = useState(false);
    const dispatch = useDispatch();
    const title = get(flow, "title") ?? get(flow, "intent", "");
    const id = get(flow, "id");

    const handleCloseTester = () => {
        dispatch(setIsOpenBotTester(false));
    };

    const { t } = useTranslation();

    useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setCopied(false);
            }, 1000);
        }
    }, [copied]);

    // useEffect(() => {
    //     if (!isEmpty(savedErrors)) {
    //         console.log(savedErrors, "savedErrors");
    //         setShowErrorsModal(true);
    //     }
    // }, [savedErrors]);

    const mapErrorMessages = (error) => {
        switch (toUpper(error)) {
            case "TOOLKIT":
                return t("flows.No se puede guardar el flujo, por favor validar la configuracion en las burbujas toolkit");
            case "RESOURCES":
                return t("flows.No se puede guardar el flujo, seleccionar recurso");
            case "INTENTS":
                return t("flows.No se puede guardar el flujo, falta Nombre de la etiqueta o está incompleta la configuración");
            default:
                return t("flows.No se puede guardar el flujo");
        }
    };

    const getResourcesBubbles = () => {
        let resourcesBubbles = [];
        bubbleResources.map((resourceBubble) => {
            const bubble = bubbles.find((bubble) => bubble.id === resourceBubble.bubbleId);
            if (bubble) {
                if (bubble.flowId === flow.id) {
                    resourcesBubbles.push(resourceBubble);
                }
            }
            return resourcesBubbles;
        });
        return resourcesBubbles;
    };

    const getInputsBubbles = () => {
        let inputBubbles = [];
        inputs.map((inputBubble) => {
            const bubble = bubbles.find((bubble) => bubble.id === inputBubble.bubbleId);
            if (bubble) {
                if (bubble.flowId === flow.id) {
                    inputBubbles.push(inputBubble);
                }
            }
            return inputBubbles;
        });
        return inputBubbles;
    };

    const inputsComplete = (inputsBubbles) => {
        let isComplete;
        inputsBubbles.map((bubble) => {
            if (!isEmpty(bubble.name)) {
                if (get(bubble, "properties.inputMask", false)) {
                    isComplete = true;
                } else {
                    isComplete = true;
                }
            } else {
                isComplete = false;
            }
            return isComplete;
        });
        return isComplete;
    };

    const resourcesComplete = (resourcesBubbles) => {
        let isComplete;
        resourcesBubbles.map((bubble) => {
            if (isNumber(bubble.resourceId)) {
                isComplete = true;
            } else {
                isComplete = false;
            }
            return isComplete;
        });
        return isComplete;
    };

    const saveAll = async () => {
        const resourcesBubbles = getResourcesBubbles();

        const inputsBubbles = getInputsBubbles();

        if (!isEmpty(resourcesBubbles) && !resourcesComplete(resourcesBubbles)) {
            notifyError(mapErrorMessages("resources"));
            return;
        }

        if (!isEmpty(inputsBubbles) && !inputsComplete(inputsBubbles)) {
            notifyError(mapErrorMessages("intents"));
            return;
        }

        if (!isValidToolBubbles(bubbles)) {
            notifyError(mapErrorMessages("toolkit"));
            return;
        }

        dispatch(setSavingChanges());

        if (isNumber(flow?.id)) {
            await dispatch(saveBubbles(flow))
                .then((resp) => {
                    notify(t("flows.Flujo actualizado exitosamente"));
                    dispatch(notSavingChanges());
                })
                .catch((error) => {
                    if (error?.response) {
                        const { clientMessages = {} } = error.response?.data?.error || {};
                        notifyError(clientMessages[lang]);
                        dispatch(notSavingChanges());
                        dispatch({ type: SET_UNSAVED_CHANGES });
                    }
                });
        } else {
            await dispatch(createFlow(flow))
                .then((resp) => {
                    console.log(resp);
                    notify(t("flows.Flujo creado exitosamente"));
                    dispatch(notSavingChanges());
                })
                .catch((error) => {
                    if (error?.response) {
                        const { clientMessages = {} } = error.response?.data?.error || {};
                        notifyError(clientMessages[lang]);
                        dispatch(notSavingChanges());
                        dispatch({ type: SET_UNSAVED_CHANGES });
                    }
                });
        }
    };

    return (
        <div className="relative flex flex-1 flex-col bg-gray-13">
            <Transition.Root show={!showIntents} as={Fragment}>
                <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transform transition ease-in-out duration-500 sm:duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <button className="absolute -left-[4px] bottom-0 flex justify-center" onClick={() => setShowIntents(true)}>
                        <ContainerRigthIcon width="39" height="32" />
                        <div className="absolute bottom-0 top-[0.5rem]">
                            <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.29118 7.91804C7.67463 6.86473 9.0484 5.8205 10.4318 4.76719C10.7106 4.5509 10.735 3.99953 10.4764 3.75951C9.19114 2.58833 7.91478 1.42707 6.62958 0.255892C6.35324 -0.00396058 5.94763 -0.0694985 5.64736 0.212513C5.39553 0.449039 5.31726 0.959928 5.60286 1.22019C6.88807 2.39137 8.16443 3.55263 9.44963 4.72381C9.46433 4.39108 9.47944 4.04885 9.49414 3.71613C8.11069 4.76945 6.7365 5.82318 5.35347 6.86699C5.0553 7.10147 5.0697 7.61645 5.30897 7.87466C5.5832 8.18205 5.99301 8.15252 6.29118 7.91804Z"
                                    fill="#00B3C7"
                                />
                                <path
                                    d="M1.19328 7.91804C2.57673 6.86473 3.9505 5.8205 5.33395 4.76719C5.61274 4.5509 5.6371 3.99953 5.37845 3.75951C4.09324 2.58833 2.81688 1.42707 1.53168 0.255892C1.25534 -0.00396058 0.849728 -0.0694985 0.549462 0.212513C0.297625 0.449039 0.219356 0.959928 0.504958 1.22019C1.79017 2.39137 3.06653 3.55263 4.35173 4.72381C4.36643 4.39108 4.38154 4.04885 4.39624 3.71613C3.01279 4.76945 1.6386 5.82318 0.255571 6.86699C-0.0425969 7.10147 -0.0282038 7.61645 0.211067 7.87466C0.485303 8.18205 0.895114 8.15252 1.19328 7.91804Z"
                                    fill="#00B3C7"
                                />
                            </svg>
                        </div>
                    </button>
                </Transition.Child>
            </Transition.Root>
            {isLoadingFlow ? (
                <div className="relative flex h-full w-full items-center justify-center">
                    <svg className="absolute h-20 w-20 animate-spin text-primary-200" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <div className="absolute">
                        <svg width="26" height="34" viewBox="0 0 26 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="13" cy="4.75" r="4.5" fill="#00B3C7" />
                            <path
                                d="M25 9.75H18.5V21.75C18.5 25.25 15.4591 26.75 12.5 26.75C8.9 26.75 7.33333 23.0833 7 21.25H0C0 27.75 6 33.75 12.5 33.75C19 33.75 24.5 28.75 25 23.25C25.3621 19.2664 25 12.75 25 9.75Z"
                                fill="#00B3C7"
                            />
                        </svg>
                    </div>
                </div>
            ) : (
                <>
                    <div className="pb-5">
                        <TopNav>
                            <div className="flex w-full items-center">
                                <CanRenderOption option="lock flow">
                                    <LockFlow flow={flow} updateFlow={props.updateFlow} />
                                </CanRenderOption>
                                <h1 className="ml-1 justify-start whitespace-nowrap text-lg leading-9 text-gray-400">{t("flows.Flujo")} / </h1>
                                <div className="flex items-center">
                                    <h4 className="ml-2 mr-4 font-sans text-lg font-bold text-primary-200">{title}</h4>
                                    <div className="flex items-center space-x-4">
                                        <CopyToClipboard text={id} title={t("Copiar Id")} onCopy={() => setCopied(true)}>
                                            <div>
                                                <span className="flex h-full items-center justify-center text-primary-200">
                                                    <button className="flex items-center rounded-8 bg-[#E6F7F9] px-4 py-2 font-sans text-sm font-medium text-[#009EAF]">
                                                        {copied ? (
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth={1.5}
                                                                stroke="currentColor"
                                                                className="h-5 w-5">
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
                                                                />
                                                            </svg>
                                                        ) : (
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth="1.5"
                                                                stroke="currentColor"
                                                                className="h-5 w-5">
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z"
                                                                />
                                                            </svg>
                                                        )}
                                                    </button>
                                                </span>
                                            </div>
                                        </CopyToClipboard>
                                        {canCreateExpression && (
                                            <div id="btnAddVoc" className="h-full">
                                                <CanRenderOption option="view expressions">
                                                    <TrainingWord
                                                        showTraining={showTraining}
                                                        setShowTraining={setShowTraining}
                                                        canCreateExpression={canCreateExpression}
                                                    />
                                                </CanRenderOption>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-end space-x-4">
                                <CanRenderOption option="update flows">
                                    <button
                                        className={`rounded-full bg-primary-200 px-4 py-1 text-sm font-semibold text-white disabled:cursor-not-allowed disabled:bg-gray-250 disabled:text-gray-75 ${
                                            savingChanges && "w-24"
                                        }`}
                                        onClick={saveAll}
                                        disabled={!unSavedChanges}>
                                        {savingChanges ? <BeatLoader color={"#ffffff"} size={6} /> : t("common.Guardar")}
                                    </button>
                                </CanRenderOption>
                            </div>
                        </TopNav>
                    </div>
                    <Renderize permission="view bubble">
                        <div className="flex overflow-auto">
                            <Droppable droppableId={`droppable_bubbles`} type="bubbles">
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className="pr-63 flex flex-1 flex-col overflow-y-scroll pb-16 pl-3">
                                        {bubbles.map((bubble, index) => (
                                            <Bubble
                                                {...props}
                                                intents={intents}
                                                setFlow={props.setFlow}
                                                index={index}
                                                bubble={bubble}
                                                key={bubble.id}
                                                length={bubbles.length}
                                            />
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                        <ToolBox {...props} />
                    </Renderize>
                </>
            )}

            {canCreateExpression && <BotTester onClose={handleCloseTester} isOpenBotTester={isOpenBotTester} setFlow={props.setFlow} />}
            {/* <ErrorsModal onClose={() => setShowErrorsModal(false)} openModal={showErrorsModal} savedErrors={first(savedErrors)} /> */}
        </div>
    );
};
export default FlowBubbles;
