import get from "lodash/get";
import shortid from "shortid";

import Http, { JelouAPI } from "../services/Http";
import { ADD_FLOWS, DELETE_FROM_FLOWS, UPDATE_FROM_FLOWS } from "../constants";

export function addFlows(flows) {
    return function (dispatch) {
        dispatch({
            type: ADD_FLOWS,
            payload: flows,
        });
    };
}

export function getFlows(botId) {
    return function (dispatch) {
        return JelouAPI.get(`/bots/${botId}/flows`, {
            params: {
                shouldPaginate: false,
                hasBubbles: true,
                state: true,
            },
        })
            .then(({ data }) => {
                const results = get(data, "results", []);
                let mapedFlows = results.map((flow) => {
                    return {
                        ...flow,
                    };
                });

                dispatch(addFlows(mapedFlows));
                return data;
            })
            .catch((err) => {
                dispatch(addFlows([]));
                console.log(`error getting flows of botId: ${botId}`, err);
            });
    };
}

export function UpdateIntentFromFlows(intent) {
    return function (dispatch, getState) {
        let { flows } = getState();
        let filteredFlows = flows.find((x) => x.intentId === intent.id);
        filteredFlows = { ...filteredFlows, intent: intent.slug, title: intent.name };
        dispatch(updateFlow(filteredFlows));
    };
}

export const updateFlow = (flow) => ({
    type: UPDATE_FROM_FLOWS,
    payload: flow,
});
export const deleteFromFlows = (flow) => ({
    type: DELETE_FROM_FLOWS,
    payload: flow,
});

function mockFlow(intent, botId) {
    if (!intent) {
        return;
    }
    // const providerId = {};
    // providerId[intent.nlpDriver] = intent.id;
    return {
        botId: botId,
        type: "default",
        title: intent.name,
        intentId: intent.id,
        intent: intent.slug,
        unSavedChanges: true,
        nlpDriver: intent.nlpDriver,
        providerId: intent.id,
        default: true,
        id: shortid.generate(),
        Bubbles: [],
    };
}

// Set the given flow to the default flow
export function setFlowDefault(flowId, botId) {
    return function (dispatch, getState) {
        const { intent } = getState();
        let data = mockFlow(intent, botId);
        return Http.post(`/flows/default/${flowId}/${botId}`, data)
            .then(async ({ data }) => {
                return data;
            })
            .catch((err) => {
                return err;
            });
    };
}
