import React from "react";

import Icon from "./Icon";

const JCXIcon = (props) => (
    <Icon viewBox="0 0 18 18" stroke={props.stroke} className={props.className} width={props.width} height={props.height}>
        <path
            d="M3.66596 9.75452H2.92768C2.11514 9.75452 1.45549 8.94352 1.45549 7.94955C1.45549 6.95331 2.11514 6.14232 2.92768 6.14232H3.36454V7.01883H2.92768C2.58912 7.01883 2.30298 7.44352 2.30298 7.94729C2.30298 8.45105 2.58912 8.87575 2.92768 8.87575H3.66596V9.75452Z"
            fill={props.fill}
        />
        <path
            d="M14.3339 9.75452H15.0722C15.8847 9.75452 16.5444 8.94352 16.5444 7.94955C16.5444 6.95331 15.8847 6.14232 15.0722 6.14232H14.6353V7.01883H15.0722C15.4108 7.01883 15.6969 7.44352 15.6969 7.94729C15.6969 8.45105 15.4108 8.87575 15.0722 8.87575H14.3339V9.75452Z"
            fill={props.fill}
        />
        <path
            d="M11.3327 10.6694V9.79292C12.2064 9.79292 12.8989 9.53539 13.3859 9.02937C14.2203 8.16416 14.2465 6.77259 14.2465 6.75904L15.07 6.80874C15.07 6.87877 15.0744 8.51205 13.991 9.64383C13.3401 10.3238 12.4445 10.6694 11.3327 10.6694Z"
            fill={props.fill}
        />
        <path
            d="M9.97412 11.8193C8.9912 11.8193 8.19176 11.1077 8.19176 10.2312C8.19176 9.35693 8.9912 8.64307 9.97412 8.64307C10.957 8.64307 11.7565 9.35467 11.7565 10.2312C11.7565 11.1077 10.957 11.8193 9.97412 11.8193ZM9.97412 9.52184C9.46737 9.52184 9.03926 9.84714 9.03926 10.2312C9.03926 10.6175 9.46737 10.9428 9.97412 10.9428C10.4809 10.9428 10.909 10.6175 10.909 10.2312C10.909 9.84714 10.4809 9.52184 9.97412 9.52184Z"
            fill={props.fill}
        />
        <path
            d="M3.39075 6.65964L3.26843 5.79217C6.01187 5.37877 7.90999 4.45482 8.90602 3.04066C9.80375 1.76883 9.62027 0.564759 9.61372 0.51506L10.4481 0.363705C10.459 0.426958 10.708 1.95633 9.60717 3.53539C8.47572 5.15964 6.38319 6.21009 3.39075 6.65964Z"
            fill={props.fill}
        />
        <path
            d="M13.5476 6.81099C10.3476 6.81099 8.85359 3.55346 8.83831 3.51732L9.60717 3.1491C9.67269 3.29142 11.2235 6.63931 14.6048 5.79895L14.8013 6.6506C14.3579 6.76355 13.9407 6.81099 13.5476 6.81099Z"
            fill={props.fill}
        />
        <path
            d="M8.99994 13.2877C5.63836 13.2877 2.90584 10.308 2.90584 6.64383C2.90584 2.97967 5.64054 0 8.99994 0C12.3615 0 15.094 2.97967 15.094 6.64383C15.094 10.308 12.3615 13.2877 8.99994 13.2877ZM8.99994 0.876506C6.10579 0.876506 3.75334 3.4631 3.75334 6.64383C3.75334 9.82455 6.10579 12.4111 8.99994 12.4111C11.8941 12.4111 14.2465 9.82455 14.2465 6.64383C14.2465 3.4631 11.8941 0.876506 8.99994 0.876506Z"
            fill={props.fill}
        />
        <path
            d="M0.020426 18C0.0116889 17.939 -0.167421 16.5203 0.721575 15.4654C1.32006 14.7538 2.23527 14.3946 3.44535 14.3946C4.4239 14.3946 5.11195 14.1461 5.49201 13.6581C6.00313 12.9985 5.81091 12.07 5.80873 12.061L6.63438 11.8645C6.64749 11.9187 6.92489 13.2018 6.1604 14.198C5.61215 14.9119 4.69912 15.2733 3.44535 15.2733C2.49738 15.2733 1.79623 15.5309 1.36593 16.0392C0.730312 16.7869 0.872289 17.9887 0.872289 18H0.020426Z"
            fill={props.fill}
        />
        <path
            d="M9.03926 17.0354L5.5903 14.2274L6.11453 13.5384L9.02178 15.9081L11.7477 13.5474L12.2916 14.2206L9.03926 17.0354Z"
            fill={props.fill}
        />
        <path
            d="M17.9795 18L17.1931 17.9977C17.1953 17.9887 17.2718 16.7846 16.6361 16.0369C16.2058 15.5286 15.5069 15.2711 14.5567 15.2711C13.3029 15.2711 12.3899 14.9096 11.8439 14.1958C11.0794 13.1995 11.3568 11.9164 11.3699 11.8622L12.1955 12.061L11.7827 11.9616L12.1955 12.0587C12.1933 12.0678 12.0011 12.9962 12.5122 13.6559C12.8923 14.1461 13.5803 14.3923 14.5589 14.3923C15.7668 14.3923 16.6842 14.7538 17.2827 15.4631C18.1673 16.5203 17.9882 17.939 17.9795 18Z"
            fill={props.fill}
        />
    </Icon>
);

export default JCXIcon;
