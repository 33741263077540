import React from "react";

import Icon from "./Icon";

const SaveIcon = (props) => (
    <Icon viewBox="0 0 24 28" className={props.className} width={props.width} height={props.height}>
        <path d="M6 24h12v-6h-12v6zM20 24h2v-14c0-0.297-0.266-0.938-0.469-1.141l-4.391-4.391c-0.219-0.219-0.828-0.469-1.141-0.469v6.5c0 0.828-0.672 1.5-1.5 1.5h-9c-0.828 0-1.5-0.672-1.5-1.5v-6.5h-2v20h2v-6.5c0-0.828 0.672-1.5 1.5-1.5h13c0.828 0 1.5 0.672 1.5 1.5v6.5zM14 9.5v-5c0-0.266-0.234-0.5-0.5-0.5h-3c-0.266 0-0.5 0.234-0.5 0.5v5c0 0.266 0.234 0.5 0.5 0.5h3c0.266 0 0.5-0.234 0.5-0.5zM24 10v14.5c0 0.828-0.672 1.5-1.5 1.5h-21c-0.828 0-1.5-0.672-1.5-1.5v-21c0-0.828 0.672-1.5 1.5-1.5h14.5c0.828 0 1.969 0.469 2.562 1.062l4.375 4.375c0.594 0.594 1.062 1.734 1.062 2.562z" />
    </Icon>
);

export default SaveIcon;
