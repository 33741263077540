import * as Sentry from "@sentry/react";
import get from "lodash/get";

import { SET_USER } from "../constants";
import { omitKeys } from "../helpers";
import Http from "../services/Http";
import i18n from "../utils/i18n";
import { setLoadingPermissions, setPermissions } from "./permissions";

export function setUser(user) {
    return function (dispatch) {
        if (process.env.NODE_ENV !== "development") {
            // Add User Scope to Sentry
            //send user email, user names and companyId to sentry as an alert!

            Sentry.setUser({
                email: user.email,
                username: user.name,
                companyId: user.companyId,
            });
        }

        dispatch({
            type: SET_USER,
            payload: user,
        });
        return;
    };
}
export function authUser() {
    return async function (dispatch) {
        dispatch(setLoadingPermissions(true));
        try {
            const { data } = await Http.get("/users/auth");
            const user_2 = omitKeys(data, ["Permissions"]);
            dispatch(setUser(user_2));
            dispatch(setPermissions(get(data, "Permissions", [])));
            dispatch(setLoadingPermissions(false));

            const { lang = "es" } = user_2;
            i18n.changeLanguage(lang);
        } catch (error) {
            console.log("error on authUser", error);
            dispatch(setLoadingPermissions(false));
        }
    };
}
