import React from "react";
import DownIcon from "../../icons/DownIcon";
const selectStyle = "form-control form-input form-input-bordered form-select-bordered w-full";
const selectStyleSmall = "input form-select-bordered w-full";

/**
 * option props should be like { id, name }
 */
const Select = (props) => (
    <div className={props.className || "relative inline-block flex-1"}>
        <select
            className={props.small ? selectStyleSmall : selectStyle}
            value={props.value}
            defaultValue={props.defaultValue}
            onChange={props.onChange}
            name={props.name}
            disabled={props.disabled || false}>
            {props.placeHolder && (
                <option key="-1" value="-1" disabled>
                    {props.placeHolder}
                </option>
            )}
            {props.options.map((option) => (
                <option key={option[props.getId] || option.id} value={option[props.getId] || option.id}>
                    {option[props.getName] || option.name}
                </option>
            ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 mx-2 flex items-center text-grey-100">
            | <DownIcon className=" ml-1 fill-current text-grey-100" width="22px" height="22" />
        </div>
    </div>
);

export default Select;
