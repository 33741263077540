import React from "react";
import TextareaAutosize from "react-autosize-textarea";
import { withTranslation } from "react-i18next";

const TextArea = (props) => {
    const textArea = "form-input";
    const minHeight = props.minHeight ? props.minHeight : 80;
    const wrapClass = props.className ? props.className : "w-full flex-1";
    const disabled = props.disabled ? props.disabled : false;
    const { t } = props;
    const placeHolder2 = t(props.placeholder);
    return (
        <div className={wrapClass}>
            <TextareaAutosize
                className={textArea}
                style={{ minHeight: minHeight }}
                placeholder={placeHolder2}
                onChange={props.onChange}
                value={props.value}
                defaultValue={props.defaultValue}
                name={props.name}
                disabled={disabled}
            />
        </div>
    );
};

export default withTranslation()(TextArea);
