import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

/* Components */
import CardTitle from "../../CardTitle";
import { BeatLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import DownloadIcon from "../../../../icons/DownloadIcon";

const UniqueUsers = (props) => {
    const { metrics, isDownloadFileUniqUsers, downLoadUniqUsers, loadingCards } = props;
    const { t } = useTranslation();

    return (
        <div className="mr-1 max-h-32 w-1/3 rounded-lg bg-white p-4 shadow-input">
            <CardTitle>
                <div className="flex w-full items-center justify-between">
                    <span className="block w-1/2 text-base">{t("metrics.Usuarios Únicos")}</span>
                    <button
                        onClick={downLoadUniqUsers}
                        className="color-gradient flex h-8 w-8 items-center justify-center rounded-full bg-primary-600 font-semibold text-white">
                        {isDownloadFileUniqUsers ? (
                            <BeatLoader color={"#fff"} size={"0.25rem"} />
                        ) : (
                            <DownloadIcon className="fill-current text-white" width="0.8125rem" height="0.9375rem" />
                        )}
                    </button>
                </div>
            </CardTitle>
            <div className="relative mt-4 flex items-center justify-center">
                {loadingCards ? (
                    <div className="pb-6 pt-2 text-center">
                        <BeatLoader color="#00B3C7" size={10} />
                    </div>
                ) : (
                    <p className="relative pb-6 text-4xl text-gray-400">{!isEmpty(metrics) && get(metrics, "unique_users.amount", 0)}</p>
                )}
            </div>
        </div>
    );
};

export default UniqueUsers;
