import uniqid from "uniqid";
import get from "lodash/get";
import { List } from "immutable";
import { useState } from "react";
import toLower from "lodash/toLower";
import { PulseLoader } from "react-spinners";

import Http from "../../../services/Http";
import { UPDATE, NEW } from "../../../constants";

/* Components */
import { useTranslation } from "react-i18next";
import MicIcon from "../../icons/MicIcon";
import { notifyError } from "../../common/Toasts";
import BubbleContainer from "./BubbleContainer";

function getAttachment(attachments, bubbleId) {
    return List(attachments).find((a) => a.bubbleId === bubbleId);
}

const AudioBubble = (props) => {
    const { attachments, bubble, flow, bot, updateAttachment } = props;
    const { t } = useTranslation();
    const [uploading, setUploading] = useState(false);

    let attachment = getAttachment(attachments, bubble.id);
    const url = attachment && get(attachment, "url");

    const handleLoadLocalFile = (event, attachment) => {
        event.preventDefault();
        const { files } = event.target;
        const audio = files[0];

        const KB_FileSize = audio.size / 1000;

        if (KB_FileSize > 16000) {
            notifyError(t("bubbles.El tamaño del archivo es demasiado grande"));
            return;
        }

        setUploading(true);

        const formData = new FormData();
        formData.append("image", audio);
        let intent = flow.intent;
        const botId = bot.id;
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        let fileName = audio.name.replace(/ /g, "_");
        fileName = uniqid() + `-${fileName}`;
        const path = toLower(`${botId}/flows/${intent}/images/${fileName}`);
        formData.append("path", path);
        Http.post(`/images/upload`, formData, config)
            .then(({ data }) => {
                updateAttachment({
                    ...attachment,
                    url: data,
                    action: attachment.action === NEW ? NEW : UPDATE,
                });
                setUploading(false);
            })
            .catch((error) => {
                console.error(error);
                setUploading(false);
            });
    };

    const audioAcceptance = () => {
        return "audio/aac, audio/mp4, audio/amr, audio/mpeg, audio/ogg";
    };

    return (
        <BubbleContainer
            {...props}
            bubbleStyles={props.bubbleStyles}
            icon={<MicIcon className="mx-2 fill-current text-gray-400" width="18px" height="18px" />}
            title={t("bubbles.Audio")}>
            <div className="relative my-2 flex-1 rounded-lg">
                <div className="p-2">
                    {uploading ? (
                        <div className="flex h-32 min-h-[202px] items-center justify-center overflow-hidden rounded-lg bg-[#E2E2E2]">
                            <PulseLoader color="#00b3c7" />
                        </div>
                    ) : (
                        <label
                            id={attachment.id}
                            className={`${
                                !url && "min-h-[202px] bg-[#E2E2E2]"
                            } flex w-full cursor-pointer items-center justify-center overflow-hidden rounded-lg text-gray-450 hover:text-blue`}>
                            {url ? (
                                <div className="flex h-32 w-full items-end">
                                    <audio src={url} controls />
                                </div>
                            ) : (
                                <div className="flex h-32 w-full flex-col items-center justify-center">
                                    <MicIcon className="mb-2 fill-current" width="18px" height="18px" />
                                    <span className="font-sans text-sm font-extrabold">{t("bubbles.Cargar audio")}</span>
                                </div>
                            )}
                            <input
                                type="file"
                                name="audioUrl"
                                className="hidden"
                                accept={audioAcceptance()}
                                onChange={(e) => handleLoadLocalFile(e, attachment)}
                                id={attachment.id}
                            />
                        </label>
                    )}
                </div>
            </div>
        </BubbleContainer>
    );
};

export default AudioBubble;
