import { Fragment } from "react";
import isEmpty from "lodash/isEmpty";
import { useDispatch } from "react-redux";
import { BeatLoader } from "react-spinners";
import { Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";

import { Modal } from "../../common/GenericModal";
import CloseIcon from "../../icons/CloseIcon";
import FlowIcon from "../../icons/FlowIcon";

const AddIntent = ({ toggleAddingIntent, addingIntent, handleChange, handleKeyPress, addIntent, loading, error, inputRef }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    return (
        <Modal openModal={addingIntent} closeModal={() => null} title={t("flows.Agregar un nuevo flujo")} className="w-[30rem] rounded-20">
            <div className="h-full flex-row">
                <header className="relative flex items-center justify-between bg-primary-350 p-5">
                    <div className="flex items-center gap-x-2">
                        <FlowIcon width="20" className="fill-current text-primary-200" />
                        <div className="text-base font-semibold text-primary-200">{t("flows.Agregar un nuevo flujo")}</div>
                    </div>
                    <button
                        className="absolute inset-y-0 right-0 mr-3"
                        onClick={() => {
                            dispatch(toggleAddingIntent());
                        }}>
                        <CloseIcon width="28" className="stroke-current text-primary-200" />
                    </button>
                </header>
                <div className="flex h-full items-center gap-x-3 p-4">
                    <div className="w-full">
                        <p className="mb-2 text-sm text-gray-400">{t("flows.Ingresa el nombre con el que quieres identificar tu flujo")}</p>
                        <input
                            type="text"
                            onChange={handleChange}
                            onKeyDown={handleKeyPress}
                            name="name"
                            className="input mb-2 w-full"
                            placeholder={t("flows.Nombre del flujo")}
                            ref={inputRef}
                            required
                        />
                        <Transition
                            as={Fragment}
                            show={!isEmpty(error)}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <div className="text-right text-sm text-red-300">{error}</div>
                        </Transition>
                    </div>
                </div>
                <footer className="flex w-full justify-end space-x-3 px-4 pb-6">
                    <button
                        disabled={loading}
                        onClick={() => dispatch(toggleAddingIntent())}
                        id="cancelDeleteIntentButton"
                        className="min-w-[4rem] rounded-2xl bg-gray-5 px-3 py-2 text-sm font-semibold text-gray-400 disabled:cursor-not-allowed">
                        {t("common.Cancelar")}
                    </button>
                    <button disabled={loading} onClick={addIntent} className="button-gradient-xl disabled:cursor-not-allowed disabled:bg-opacity-60">
                        {loading ? (
                            <div className="m-auto flex w-full justify-center text-center">
                                <BeatLoader size={10} color={"#A2B2D2"} className="flex" />
                            </div>
                        ) : (
                            <span>{t("common.Guardar")}</span>
                        )}
                    </button>
                </footer>
            </div>
        </Modal>
    );
};

export default AddIntent;
