import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import has from "lodash/has";
import toUpper from "lodash/toUpper";
import includes from "lodash/includes";

import TextIconn from "../../../../icons/TextIcon";
import { updateBubble } from "../../../../../actions/bubbles";
import { WHATSAPP_CHANNELS } from "../../../../../constants";

const itemStyle = "px-4 cursor-pointer text-center py-2 flex items-center justify-center bg-white rounded-10 border-2 border-transparent";

const AddButton = (props) => {
    const { bubble } = props;
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const IS_SUPPORTED = (props) => {
        return includes(WHATSAPP_CHANNELS, toUpper(props.botType));
    };

    const hasTextOptions = has(bubble, "text");

    if (!IS_SUPPORTED(props)) {
        return null;
    }

    return (
        !hasTextOptions && (
            <div className="relative">
                <div className="relative my-2 flex flex-col justify-center space-y-3 px-3">
                    <div
                        className={`${itemStyle}`}
                        onClick={() => {
                            dispatch(
                                updateBubble({
                                    ...bubble,
                                    text: "",
                                })
                            );
                        }}>
                        <Fragment>
                            <TextIconn className={`mr-1 fill-current text-gray-400`} width="0.8125rem" height="0.8125rem" />
                            <span className={`font-sans text-sm font-bold text-gray-400`}>{t("bubbles.Texto")}</span>
                        </Fragment>
                    </div>
                </div>
            </div>
        )
    );
};

export default AddButton;
