import React from "react";

import Icon from "./Icon";

const TextIcon = (props) => (
    <Icon viewBox="0 0 22 19" className={props.className} width={22} height={19}>
        <path
            d="M12.9376 0.0106886C10.4124 0.0106886 7.86313 -0.0133607 5.3139 0.0106886C2.78873 0.0347379 0.528096 1.83843 0.119258 4.36361C-0.0971858 5.63822 0.0471277 7.03308 0.0471277 8.33174C0.0471277 9.79875 0.0471277 11.2658 0.0471277 12.7328C0.0471277 13.1416 0.383812 13.4783 0.79265 13.4783C4.1355 13.4783 7.4543 13.4783 10.7972 13.4783C12.4085 13.4783 14.0198 13.6226 15.4868 12.8049C16.8576 12.0594 17.8436 10.6405 18.1082 9.10132C18.2525 8.1634 18.1803 7.17737 18.1803 6.23945C18.1803 4.91674 18.1322 3.66617 17.4107 2.51181C16.4487 0.972652 14.7413 0.0347379 12.9376 0.0106886C11.9756 -0.0133607 11.9756 1.50174 12.9376 1.52579C14.2603 1.54984 15.4868 2.22322 16.1602 3.37759C16.6652 4.21932 16.6652 5.10914 16.6652 6.04706C16.6652 6.86474 16.7133 7.68242 16.6652 8.50009C16.593 9.79875 15.7754 10.9531 14.621 11.5544C13.4907 12.1315 12.0237 11.9632 10.7972 11.9632C8.82511 11.9632 6.82903 11.9632 4.85699 11.9632C3.51023 11.9632 2.16346 11.9632 0.816694 11.9632C1.05719 12.2037 1.32172 12.4682 1.56222 12.7087C1.56222 10.2316 1.53817 7.77862 1.56222 5.30154C1.58627 3.2333 3.22161 1.52579 5.3139 1.52579C6.49232 1.50174 7.67076 1.52579 8.82513 1.52579C10.1959 1.52579 11.5667 1.52579 12.9376 1.52579C13.8995 1.52579 13.8995 0.0106886 12.9376 0.0106886Z"
            fill={props.fill}
        />
        <path
            d="M6.54047 12.8531C6.54047 13.4303 6.51641 14.0315 6.58856 14.6087C6.73286 15.5706 7.21384 16.5086 7.91127 17.182C8.87324 18.0958 10.0757 18.4085 11.3744 18.4085C13.7312 18.4085 16.088 18.4085 18.4449 18.4085C19.2385 18.4085 20.0081 18.4085 20.8017 18.4085C21.2105 18.4085 21.5472 18.0718 21.5472 17.6629C21.5472 16.4124 21.5472 15.1378 21.5472 13.8872C21.5472 12.3721 21.6915 10.7848 20.946 9.41403C20.2967 8.21156 19.0701 7.56224 17.6993 7.53819C16.7374 7.51414 16.7374 9.02924 17.6993 9.05329C19.4549 9.07734 20.0321 10.6646 20.0321 12.1797C20.0321 13.5505 20.0321 14.8973 20.0321 16.2681C20.0321 16.725 20.0321 17.182 20.0321 17.6389C20.2726 17.3984 20.5372 17.1339 20.7777 16.8934C18.9499 16.8934 17.1221 16.8934 15.2944 16.8934C14.1641 16.8934 13.0578 16.8934 11.9275 16.8934C11.182 16.8934 10.4124 16.9415 9.71497 16.6048C8.92134 16.22 8.32012 15.4985 8.10368 14.6568C7.95938 14.0796 8.00748 13.4543 8.00748 12.8531C8.05557 11.8911 6.54047 11.8911 6.54047 12.8531Z"
            fill={props.fill}
        />
        <path
            d="M4.83294 7.82671C5.4572 7.82671 5.96328 7.32065 5.96328 6.69639C5.96328 6.07213 5.4572 5.56607 4.83294 5.56607C4.20869 5.56607 3.70264 6.07213 3.70264 6.69639C3.70264 7.32065 4.20869 7.82671 4.83294 7.82671Z"
            fill={props.fill}
        />
        <path
            d="M9.04156 7.82671C9.66582 7.82671 10.1719 7.32065 10.1719 6.69639C10.1719 6.07213 9.66582 5.56607 9.04156 5.56607C8.4173 5.56607 7.91125 6.07213 7.91125 6.69639C7.91125 7.32065 8.4173 7.82671 9.04156 7.82671Z"
            fill={props.fill}
        />
        <path
            d="M13.2743 7.82671C13.8985 7.82671 14.4046 7.32065 14.4046 6.69639C14.4046 6.07213 13.8985 5.56607 13.2743 5.56607C12.65 5.56607 12.1439 6.07213 12.1439 6.69639C12.1439 7.32065 12.65 7.82671 13.2743 7.82671Z"
            fill={props.fill}
        />
    </Icon>
);

export default TextIcon;
