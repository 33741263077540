import { useTranslation } from "react-i18next";
import React, { useState, useRef, useEffect } from "react";

import has from "lodash/has";
import get from "lodash/get";
import omit from "lodash/omit";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import isNumber from "lodash/isNumber";

import SlideOver from "./SlideOver";
import FlowIcon from "../icons/FlowIcon";
import FormCombobox from "./FormCombobox";
import TrashIcon from "../icons/TrashIcon";
import WarningIcon from "../icons/WarningIcon";
import { CheckIcon } from "@heroicons/react/solid";

import { mergeById } from "../../helpers";

const ErrorValidation = (props) => {
    const { errorList, error, setErrorList, options, setOptions, allOptions, setFlow, updatePMABubbles } = props;

    const { t } = useTranslation();

    const errorListRef = useRef(errorList);
    errorListRef.current = errorList;

    const flows = props.flows;
    let flows_intents = [];
    flows_intents = flows;

    flows_intents = flows_intents.map((flow) => {
        return { ...flow, name: flow.title, id: flow.id, value: flow.id };
    });

    const [toFlow, setToFlow] = useState(flows_intents.find((flow) => flow.value === error.flowId) || []);
    const [errorValue, setErrorValue] = useState(allOptions.find((option) => option.value === error.error) || []);
    const [showMissingKeys, setShowMissingKeys] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [openValidationError, setOpenValidationError] = useState(false);

    useEffect(() => {
        if (!isEmpty(errorList)) {
            //want to update the option list if errorList has a key error that is in allOptions i want to remove it from the list
            let newOptions = allOptions.filter((option) => !errorList.some((error) => error.error === option.value));
            setOptions(newOptions);
        }
    }, [errorList]);

    const handleFlow = (option) => {
        let opt = { ...error, flowId: option.value };
        setErrorList(mergeById(errorList, opt));
        setToFlow(option);
    };

    const handleChange = (option) => {
        let opt = { id: error.id, error: option.value, flowId: error.flowId };
        setErrorList(mergeById(errorList, opt));
        setErrorValue(option);
    };

    const parseArrayToObj = (array) => {
        //parse given array of objects to just one object with error as the object key and its value an object with the key flowId and its value the flowId
        let errors = {};
        array.forEach((error) => {
            errors[error.error] = { flowId: error.flowId };
        });
        return errors;
    };

    const validateComplete = () => {
        if (has(error, "error") && has(error, "flowId") && isNumber(get(error, "flowId", ""))) {
            setShowMissingKeys(false);
            updatePMABubbles({ errorHandlers: parseArrayToObj(mergeById(errorListRef.current, error)) });
            setErrorList(mergeById(errorListRef.current, error));
            setShowConfirmation(true);
            setTimeout(() => {
                setShowConfirmation(false);
                setOpenValidationError(false);
            }, 2000);
        } else {
            setShowMissingKeys(true);
            return;
        }
    };

    const handleGoToFlow = () => {
        const intent = props.intents.find((intent) => intent.slug === toFlow.intent);
        setFlow(intent);
    };

    const closeModal = () => {
        validateComplete();
    };

    const editErrorValidationBubble = () => {
        setOpenValidationError(true);
    };

    return (
        <>
            <SlideOver isOpen={openValidationError} title={t("bubbles.Validación")} closeModal={closeModal}>
                <div className="w-full px-5 py-4">
                    <div className="grid gap-4 text-sm font-semibold text-gray-400 text-opacity-75">
                        <label htmlFor="title">
                            <div className="flex">
                                <div className="w-5">
                                    <WarningIcon className="mr-2 fill-current text-yellow-darker" width="1rem" height="1rem" />
                                </div>
                                <span>{t("bubbles.Acción fallida")}</span>
                            </div>
                            <FormCombobox
                                handleChange={handleChange}
                                value={errorValue}
                                options={options}
                                placeholder={t("filters.Seleccionar opción")}
                                hasCleanFilter={false}
                            />
                        </label>
                        <label htmlFor="title">
                            {t("flows.Flujo")}
                            <FormCombobox
                                options={orderBy(flows_intents, ["name"], ["asc"])}
                                handleChange={handleFlow}
                                value={toFlow}
                                placeholder={t("filters.Seleccionar flujo")}
                                hasCleanFilter={false}
                            />
                        </label>
                        <div className="flex flex-col">
                            <div className="mt-2 flex justify-end space-x-2">
                                <button
                                    className="flex cursor-pointer items-center justify-center
                                rounded-lg border border-primary-200 bg-white px-2 py-1 text-primary-200 hover:bg-primary-200 hover:text-white disabled:cursor-not-allowed disabled:border-input disabled:text-grey-100 disabled:hover:border-input disabled:hover:bg-white disabled:hover:text-grey-100"
                                    disabled={!has(error, "error") && !has(error, "flowId")}
                                    onClick={() => validateComplete()}>
                                    <div className="flex items-center justify-center">
                                        <CheckIcon className="fill-current" width="16px" height="16px" />
                                    </div>
                                </button>

                                <button
                                    className="flex cursor-pointer items-center justify-center rounded-lg border border-input bg-white px-2 py-1 text-primary-200 disabled:cursor-not-allowed disabled:text-grey-100"
                                    disabled={isEmpty(toFlow)}
                                    onClick={() => handleGoToFlow()}>
                                    <div className="flex items-center justify-center">
                                        <FlowIcon className="fill-current" width="16px" height="16px" />
                                    </div>
                                </button>

                                <button
                                    className="flex cursor-pointer items-center justify-center
                                rounded-lg border border-red bg-white px-2 py-1 text-red hover:bg-red hover:text-white"
                                    onClick={() => {
                                        if (!isEmpty(omit(error, "id")) && errorList.length >= 1) {
                                            const newErrorList = errorList.filter((err) => err.id !== error.id);
                                            const option = allOptions.filter((option) => option.value === error.error);
                                            if (options.length < 3) setOptions([...options, option]);
                                            setOptions(allOptions);
                                            setErrorList(newErrorList);
                                            updatePMABubbles({ errorHandlers: parseArrayToObj(newErrorList) });
                                        } else {
                                            setErrorList([]);
                                            updatePMABubbles({ errorHandlers: {} });
                                        }
                                    }}>
                                    <TrashIcon className="fill-current" width="15px" height="15px" />
                                </button>
                            </div>
                            {showConfirmation && <span className="text-15 text-primary-200">{t("bubbles.Acción guardada")}</span>}
                            {showMissingKeys && (
                                <span className="pt-1 text-15 text-red">
                                    {t("bubbles.Recuerda completar todos los campos o eliminar tu validación para poder salir")}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </SlideOver>
            <div className="relative flex items-start">
                <button
                    className="relative w-full items-center rounded-xl border-2 border-red-200 bg-white px-4 py-2 shadow-checkpoint focus:outline-none"
                    onClick={() => editErrorValidationBubble()}>
                    <div className="flex w-full flex-col items-center justify-center">
                        <div className="flex justify-end" width="1.75rem">
                            <WarningIcon className="mr-1 fill-current" width="1rem" height="1rem" />
                            <label className="cursor-pointer text-left text-sm font-bold text-red-300">
                                {allOptions.find((option) => option.value === error.error)
                                    ? allOptions.find((option) => option.value === error.error).name
                                    : t("bubbles.Acción fallida")}
                            </label>
                        </div>

                        {isNumber(error.flowId) && (
                            <span className="mb-px rounded-md bg-grey-badge px-1 py-px text-center text-xs text-gray-400">{`${get(
                                error,
                                "flowId",
                                ""
                            )} ${get(toFlow, "name", "")}`}</span>
                        )}
                    </div>
                </button>
                <span
                    className="z-550 absolute right-0 mr-1 flex cursor-pointer items-center justify-center rounded-md bg-white p-1 text-gray-400 shadow-element hover:bg-red hover:text-white"
                    onClick={() => {
                        if (!isEmpty(omit(error, "id")) && errorList.length >= 1) {
                            const newErrorList = errorList.filter((err) => err.id !== error.id);
                            const option = allOptions.filter((option) => option.value === error.error);
                            if (options.length < 3) setOptions([...options, option]);
                            setOptions(allOptions);
                            setErrorList(newErrorList);
                            updatePMABubbles({ errorHandlers: parseArrayToObj(newErrorList) });
                        } else {
                            setErrorList([]);
                            updatePMABubbles({ errorHandlers: {} });
                        }
                    }}>
                    <TrashIcon className="fill-current" width="15px" height="15px" />
                </span>
            </div>
        </>
    );
};

export default ErrorValidation;
