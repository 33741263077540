import React from "react";

import Icon from "./Icon";

const WidgetIcon = (props) => (
    <Icon viewBox="0 0 100 100" className={props.className} width={props.width} height={props.height}>
        <g>
            <linearGradient
                id="SVGID_1_"
                gradientUnits="userSpaceOnUse"
                x1="-1"
                y1="49.89"
                x2="101"
                y2="49.89"
                gradientTransform="matrix(1 0 0 -1 0 99.89)">
                <stop offset="0" stopColor="#4FAFC3" />
                <stop offset="1" stopColor="#209F8B" />
            </linearGradient>
            <rect fill="url(#SVGID_1_)" x="-1" y="-1" width="102" height="102" />
        </g>
        <path
            d="M68.1,24.7c0-0.7-0.1-1.4-0.2-2.1c-0.6-3-3.5-6-7.7-6c0,0-0.1,0-0.1,0c-4.7,0-9.6,0-14.2,0c-1.9,0-3.8,0-5.7,0
		c-1,0-2-0.1-3,0.1c-3.8,0.8-6.3,3.9-6.3,7.8c0,6.3,0,12.6,0,18.9V50v5.4c0,6.6,0,13.3,0,19.9c0,0.7,0.1,1.4,0.2,2.2
		c0.6,3,3.4,6,7.7,6c0,0,0,0,0.1,0c4.7,0,9.4,0,14,0c1.9,0,3.8,0,5.6,0c1.1,0,2.2,0.1,3.3-0.1c3.8-0.8,6.2-3.9,6.2-7.9
		C68.1,58.4,68.1,41.6,68.1,24.7z M63.6,24.7v50.7c0,2.3-1.3,3.6-3.6,3.6c-7,0-14,0-21,0c-2.4,0-3.7-1.3-3.7-3.7V24.6
		c0-1.6,0.7-2.8,2-3.3c0.5-0.2,1-0.3,1.6-0.3c4.1,0,8.2,0,12.3,0c2.9,0,5.8,0,8.6,0C62.3,21,63.6,22.4,63.6,24.7z"
            fill="#FFFFFF"
        />
        <path
            d="M34.3,32.5c0.1,1.3,0.1,2.5,0,3.7h31.2c-0.1-0.6-0.1-1.2-0.2-1.7c-0.1-0.7-0.1-1.4,0.1-2
		C55.1,32.5,44.7,32.5,34.3,32.5z"
            fill="#FFFFFF"
        />
        <path
            d="M65,63.9H33.5c-0.2,1.2-0.5,2.5-0.9,3.7c3.6,0,7.2,0,10.7,0h5.5h4.5c3.9,0,7.9,0,11.8,0c0-0.3-0.1-0.5-0.1-0.8
		C65.1,65.8,65.1,64.8,65,63.9z"
            fill="#FFFFFF"
        />
        <path
            d="M54.7,25.4c-0.4-0.4-1-0.6-1.8-0.6c-2.9,0-4.1,0-6.9,0c-0.7,0-1.3,0.2-1.8,0.6c-0.4,0.4-0.6,0.8-0.6,1.4
		c0,1.1,1,1.8,2.4,1.8c0.6,0,5.9,0,6.8,0c1.5,0,2.4-0.7,2.4-1.9C55.3,26.2,55.1,25.7,54.7,25.4z"
            fill="#FFFFFF"
        />
        <path
            d="M51.7,71.1c-0.5-0.5-1.1-0.7-1.9-0.7h0c-1.4,0-2.6,1.1-2.6,2.6c0,0.7,0.3,1.4,0.8,1.9c0.5,0.5,1.1,0.8,1.8,0.8
		l0,0c0.7,0,1.3-0.3,1.8-0.8c0.5-0.5,0.8-1.2,0.8-1.9C52.5,72.3,52.2,71.6,51.7,71.1z"
            fill="none"
            stroke="#FFFFFF"
            strokeWidth="2"
            strokeMiterlimit="10"
        />
    </Icon>
);

export default WidgetIcon;
