import Http from "../services/Http";

export default function expression() {
    function all(companySlug, intent, bot) {
        const { slug, id } = intent;
        return Http.get(`/expressions/${companySlug}/${slug}/${id}`, {
            params: {
                botId: bot.id,
            },
        });
    }
    return { all };
}
