import initialState from "./initialState";
import { ADD_RESOURCE_REPORT } from "../constants";

export const transactions = function transactions(state = initialState.transactions, action) {
    switch (action.type) {
        case ADD_RESOURCE_REPORT:
            return [...state, ...action.payload];
        default:
            return state;
    }
};

export default transactions;
