import { useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Draggable } from "react-beautiful-dnd";

import SlideOver from "../../../../common/SlideOver";
import { UPDATE, NEW } from "../../../../../constants";
import { updateOption } from "../../../../../actions/options";

import DeleteOption from "./DeleteButton";

const UrlButton = (props) => {
    const { option, deleteOption } = props;
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleChange = ({ target }) => {
        let value = target.value;
        let name = target.name;

        // Construct postback
        dispatch(
            updateOption({
                ...option,
                [name]: value,
                action: option.action === NEW ? NEW : UPDATE,
            })
        );
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const openModal = () => {
        setIsOpen(true);
    };

    return (
        <Draggable draggableId={option.id.toString()} index={props.index}>
            {(provided) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <SlideOver
                        isOpen={isOpen}
                        title={t(`common.optionName`, { optionName: !isEmpty(option.title) ? option.title : "" })}
                        closeModal={closeModal}>
                        <div className="w-full px-5 py-4">
                            <div className="grid gap-4 text-sm font-semibold text-gray-400 text-opacity-75">
                                <label htmlFor="title" className="flex flex-col">
                                    {t("common.Nombre")}
                                    <input autoFocus type="text" name="title" value={option.title} onChange={handleChange} className="form-input" />
                                </label>
                                <label htmlFor="title" className="flex flex-col">
                                    {t("common.Url")}
                                    <input name="url" type="text" value={option.url} onChange={handleChange} className="form-input" />
                                </label>
                            </div>
                        </div>
                    </SlideOver>

                    <div
                        onClick={openModal}
                        className={`relative flex cursor-pointer flex-col items-center justify-center rounded-10 border-2 bg-white px-4 py-2 text-center ${
                            isOpen ? "border-primary-200" : "border-transparent"
                        }`}>
                        <span className="text-center font-sans text-sm font-bold leading-normal text-primary-200">
                            {option.title || t("common.Nombre de la opción")}
                        </span>
                        {!isEmpty(option.url) && (
                            <span className="mb-px w-full truncate rounded-md bg-grey-badge px-1 py-px text-xs text-gray-400">{option.url}</span>
                        )}
                        <DeleteOption option={option} deleteOption={deleteOption} buttonCounter={props.buttonCounter} />
                    </div>
                </div>
            )}
        </Draggable>
    );
};

export default UrlButton;
