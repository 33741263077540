import React from "react";
import isEmpty from "lodash/isEmpty";
import TextareaAutosize from "react-autosize-textarea";
import { DELETE, UPDATE, NEW } from "../../../../constants";

import Buttons from "./components/Buttons";
import AddButton from "./components/AddButton";
import TextIcon from "../../../icons/TextIcon";
import BubbleContainer from "../BubbleContainer";
import { useTranslation } from "react-i18next";

/* Styles */
const textArea =
    "rounded-10 shadow-textArea w-full px-3 py-2 text-gray-400 text-15 focus:outline-none border-transparent focus:ring-primary-200 focus:border-primary-200 placeholder:text-gray-400 placeholder:text-opacity-70";

function getBubbleOptions(options, bubbleId) {
    return options.filter((option) => option.optionableId === bubbleId && option.optionable === "Bubble" && option.action !== DELETE);
}

const TextBubble = (props) => {
    const options = getBubbleOptions(props.options, props.bubble.id);
    const hasButtons = !isEmpty(options);
    const bubbleStyle = `relative px-2 pt-3 ${hasButtons ? "pb-0" : "pb-1"} w-full min-h-20`;
    const { t } = useTranslation();

    return (
        <BubbleContainer
            {...props}
            bubbleStyles={props.bubbleStyles}
            icon={<TextIcon className="mx-2 fill-current text-gray-400" width="18" height="18" />}
            title={t("bubbles.Texto")}>
            <div className="relative flex-1 rounded-lg">
                <div className={bubbleStyle}>
                    <TextareaAutosize
                        className={textArea}
                        style={{ minHeight: 80 }}
                        placeholder={t("bubbles.Ingresa el texto que deseas que la persona vea cuando invoca el flujo seleccionado.")}
                        onChange={({ target }) => {
                            props.updateBubble({
                                ...props.bubble,
                                text: target.value,
                                action: props.bubble.action === NEW ? NEW : UPDATE,
                            });
                        }}
                        defaultValue={props.bubble.text}
                    />
                </div>
                <Buttons
                    bot={props.bot}
                    intents={props.intents}
                    setFlow={props.setFlow}
                    buttons={options}
                    flows={props.flows}
                    bubble={props.bubble}
                    deleteOption={props.deleteOption}
                    updateOption={props.updateOption}
                    buttonCounter={props.buttonCounter}
                />
            </div>
            <AddButton
                addOption={props.addOption}
                flows={props.flows}
                bubble={props.bubble}
                botType={props.bot.type}
                buttonCounter={props.buttonCounter}
                TotalButtons={props.buttons}
            />
        </BubbleContainer>
    );
};

export default TextBubble;
