import React from "react";
import shortid from "shortid";
import { NEW, MAX_BUTTONS } from "../../../../../constants";

import UrlIcon from "../../../../icons/UrlIcon";
import FlowIcon from "../../../../icons/FlowIcon";
import PhoneIcon from "../../../../icons/PhoneIcon";

import toUpper from "lodash/toUpper";

import { useTranslation } from "react-i18next";

const Buttons = {
    URL: {
        type: "url",
        title: "",
        url: "",
        payload: "",
        action: NEW,
        isOpen: true,
    },
    FLOW: {
        type: "postback",
        title: "",
        url: "",
        payload: "",
        action: NEW,
        isOpen: true,
    },
    PHONE: {
        type: "phone_number",
        title: "",
        url: "",
        action: NEW,
        isOpen: true,
    },
};

const itemStyle =
    "px-4 text-center py-2 flex items-center justify-center bg-white rounded-10 border-2 border-transparent disabled:opacity-50 disabled:cursor-not-allowed";

const MaxBtn = (props) => {
    return props.TotalButtons + 1 > MAX_BUTTONS[toUpper(props.botType)];
};

const can_url = (props) => {
    return (
        toUpper(props.botType) !== "WHATSAPP" &&
        toUpper(props.botType) !== "OFFICIAL_WHATSAPP" &&
        toUpper(props.botType) !== "VENOM" &&
        toUpper(props.botType) !== "GUPSHUP" &&
        toUpper(props.botType) !== "SMOOCH" &&
        toUpper(props.botType) !== "TWITTER" &&
        toUpper(props.botType) !== "TWITTER_WS"
    );
};

const can_phone = (props) => {
    return toUpper(props.botType) === "FACEBOOK" || toUpper(props.botType) === "VOICEASSISTANCE";
};

const AddButton = (props) => {
    const { t } = useTranslation();

    return (
        <div className="relative">
            <div className="relative my-2 flex flex-col justify-center space-y-3 px-3">
                {can_url(props) && (
                    <button
                        disabled={MaxBtn(props)}
                        className={itemStyle}
                        onClick={() => {
                            if (MaxBtn(props)) {
                                return;
                            }
                            props.buttonCounter(1);
                            props.addOption({
                                ...Buttons.URL,
                                id: shortid.generate(),
                                optionableId: props.bubble.id,
                                optionable: props.optionable || "Bubble",
                            });
                        }}>
                        <UrlIcon className="mr-1 fill-current text-gray-400" width="0.8125rem" height="0.8125rem" />
                        <span className="font-sans text-sm font-bold text-gray-400">{t("common.Url")}</span>
                    </button>
                )}
                <button
                    className={itemStyle}
                    disabled={MaxBtn(props)}
                    onClick={() => {
                        if (MaxBtn(props)) {
                            return;
                        }
                        props.buttonCounter(1);
                        props.addOption({
                            ...Buttons.FLOW,
                            id: shortid.generate(),
                            bubbleId: props.bubble.id,
                            optionableId: props.bubble.id,
                            optionable: props.optionable || "Bubble",
                        });
                    }}>
                    {props.botType !== "Twitter" && (
                        <React.Fragment>
                            <FlowIcon className="mr-1 fill-current text-gray-400 " width="0.8125rem" height="0.8125rem" />
                            <span className="font-sans text-sm font-bold text-gray-400">{t("flows.Flujo")}</span>
                        </React.Fragment>
                    )}
                </button>
                {can_phone(props) && (
                    <button
                        disabled={MaxBtn(props)}
                        className={itemStyle}
                        onClick={() => {
                            if (MaxBtn(props)) {
                                return;
                            }
                            props.buttonCounter(1);
                            props.addOption({
                                ...Buttons.PHONE,
                                id: shortid.generate(),
                                optionableId: props.bubble.id,
                                optionable: props.optionable || "Bubble",
                            });
                        }}>
                        <PhoneIcon className="mr-1 fill-current text-gray-400" width="0.8125rem" height="0.8125rem" />
                        <span className="font-sans text-sm font-bold text-gray-400">{t("common.Teléfono")}</span>
                    </button>
                )}
            </div>
        </div>
    );
};

export default AddButton;
