// import includes from "lodash/includes";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Layout from "../common/Layout";
import BotList from "./components/BotList";
import { Renderize } from "../common/Renderize";

import { useGetBots } from "../../actions/bots";
import { deleteFlow } from "../../actions/flow";
import { addIntents } from "../../actions/intents";
import { setLoading } from "../../actions/isLoading";
import { setBot, unsetBot } from "../../actions/bot";
import { getCompanies } from "../../actions/companies";
import { GridLoader } from "react-spinners";
import { getFlows } from "../../actions/flows";

const Bots = (props) => {
    const isLoading = useSelector((state) => state.isLoading);
    const companies = useSelector((state) => state.companies);
    // const permissions = useSelector((state) => state.permissions);
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [currentId, setCurrentId] = useState("");

    const { isLoading: isLoadingBots, data: bots = [] } = useGetBots();

    useEffect(() => {
        dispatch(getCompanies());
    }, []);

    useEffect(() => {
        return () => {
            dispatch(unsetBot());
        };
    }, []);

    const handleClick = (bot) => {
        dispatch(setLoading());
        dispatch(addIntents([]));
        // dispatch(getResources(get(bot, "Company.id", get(bot, "companyId", ""))));
        dispatch(getFlows(bot.id));
        navigate(`/flows/${bot.id}`);
    };

    return (
        <div>
            <Layout showSidebar={false} screen={false}>
                <Renderize permission="view bot">
                    {isLoadingBots ? (
                        <div className="flex h-screen w-screen items-center justify-center">
                            <GridLoader color="#00B3C7" size={20} />
                        </div>
                    ) : (
                        <BotList
                            setBot={setBot}
                            setCurrentId={setCurrentId}
                            deleteFlow={deleteFlow}
                            setLoading={setLoading}
                            addIntents={addIntents}
                            getCompanies={getCompanies}
                            isLoading={isLoading}
                            companies={companies}
                            bots={bots}
                            handleClick={handleClick}
                            currentId={currentId}
                        />
                    )}
                </Renderize>
            </Layout>
        </div>
    );
};

export default Bots;
