import get from "lodash/get";
import { useState } from "react";
import shortid from "shortid";

import { UPDATE } from "../../../../constants";
import FormComboboxSelect from "../../../common/FormCombobox";
import { SkillIcon } from "../../../icons/ToolkitIcon";
import BubbleContainer from "../BubbleContainer";
import { useQuerySkills } from "./querySkill";
import { useStore } from "react-redux";

const findSelectedSkill = (bubble) => {
    const { skillId, skillName } = bubble?.BubbleSkill ?? {};
    return skillId && skillName ? { id: skillId, name: skillName } : null;
};

export const SkillBubble = (props) => {

    const bot = useStore((state) => state.bot).getState().bot;
    const brainId = get(bot, "properties.brain.id", "");
    const { data = [], isLoading } = useQuerySkills(brainId);
    const [skillSelected, setSkillSelected] = useState(findSelectedSkill(props.bubble));

    const skillsOptions = data.map((skill) => ({
        id: skill.id,
        name: skill.name,
    }));

    const toolkitChange = (skillOption) => {
        setSkillSelected(skillOption);
        update({
            skillId: skillOption.id,
            skillName: skillOption.name,
        });
    };

    function update(value) {
        const bubble = props.bubble;
        const bubbleAction = get(props, "bubble.action", UPDATE);
        let id = 0;

        switch (bubbleAction) {
            case "UPDATE":
                id = bubble.BubbleSkill.id
            break;
            default:
                id = shortid.generate();
                break;
        }
        const updateBubble = {
            ...bubble,
            action: bubbleAction,
            BubbleSkill: {
                id,
                ...(bubble.BubbleSkill ?? {}),
                ...value,
                action: bubbleAction,
            },
        };
        props.updateBubble(updateBubble);
    }

    return (
        <div className="flex text-gray-400">
            <BubbleContainer
                {...props}
                bubbleStyles={props.bubbleStyles}
                icon={
                    <span className="text-gray-400">
                        <SkillIcon />
                    </span>
                }
                title="Skill">
                <div className="mb-4 grid gap-4 px-4 pt-4">
                    <label htmlFor="title">
                        Skill
                        <FormComboboxSelect
                            handleChange={toolkitChange}
                            options={skillsOptions}
                            loading={isLoading}
                            placeholder="Selecciona tu skill"
                            value={skillSelected}
                            hasCleanFilter={false}
                            className={"bubble-form-input"}
                        />
                    </label>
                </div>
            </BubbleContainer>
        </div>
    );
};
