import React from "react";
import { useTranslation } from "react-i18next";
import orderBy from "lodash/orderBy";
import FormCombobox from "../../common/FormCombobox";
import Checkbox from "../../common/FormComponent/Checkbox";
import { MASK_TYPES } from "../../../constants";

const MaskSettings = (props) => {
    const { maskedSelected, setMaskedSelected, properties, handleMask, handleMaskSelect } = props;
    const { t } = useTranslation();

    return (
        <div className="grid gap-4 pt-3 text-sm font-semibold text-gray-400 text-opacity-75">
            <div className="flex h-9">
                <label
                    htmlFor="mask"
                    className="flex select-none items-center space-x-2 text-left font-sans text-sm font-semibold leading-tight text-gray-400">
                    <span>{t("Enmascarar")}</span>
                    <Checkbox type="checkbox" onChange={() => setMaskedSelected(!maskedSelected)} checked={maskedSelected} name="mask" />
                </label>
            </div>

            {maskedSelected && (
                <>
                    <label htmlFor="title">{t("bubbles.Tipo de máscara")}</label>
                    <FormCombobox
                        options={orderBy(MASK_TYPES, ["name"], ["asc"])}
                        value={MASK_TYPES.find((type) => type.id === properties?.bubbleMaskType)}
                        handleChange={(evt) => {
                            handleMaskSelect(evt);
                        }}
                        name={t("common.Enmascarar")}
                        background={"#fff"}
                        hasCleanFilter={false}
                        placeholder={t("common.Seleccionar tipo de máscara")}
                        disabled={!maskedSelected}
                    />
                    <label htmlFor="bubbleMask">
                        {t("bubbles.Máscara")}
                        <input
                            type="text"
                            name="bubbleMask"
                            value={properties?.bubbleMask}
                            placeholder={t("bubbles.Máscara")}
                            onChange={(event) => handleMask(event)}
                            className="form-input"
                            disabled={!properties?.bubbleMaskType}
                        />
                    </label>
                </>
            )}
        </div>
    );
};

export default MaskSettings;
