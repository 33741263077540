import axios from "axios";
import has from "lodash/has";

import store from "../services/Store";
import { setUnauthorization } from "../actions/unathorization";

const Http = axios.create({
    baseURL: process.env.REACT_APP_API,
});

export const HttpV2 = axios.create({
    baseURL: process.env.REACT_APP_API_V2,
});

export const JelouAPI = axios.create({
    baseURL: process.env.REACT_APP_JELOU_API,
});

export const JelouAppsAPI = axios.create({
    baseURL: process.env.REACT_APP_JELOU_APPS_API,
});

JelouAPI.interceptors.request.use((config) => {
    config.headers = {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
    };

    return config;
});

JelouAppsAPI.interceptors.request.use((config) => {
    config.headers = {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
    };

    return config;
});

Http.interceptors.request.use((config) => {
    config.headers = {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
    };

    return config;
});

// Set JWT to default headers
Http.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("jwt");

// Logout user when token has expired
Http.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (has(error, "response.status") && error.response.status === 401) {
            store.dispatch(setUnauthorization(true));
            return Promise.reject(error);
        }

        return Promise.reject(error);
    }
);

HttpV2.interceptors.request.use((config) => {
    config.headers = {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
    };

    return config;
});

// Set JWT to default headers
HttpV2.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("jwt");

// Logout user when token has expired
HttpV2.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (has(error, "response.status") && error.response.status === 401) {
            store.dispatch(setUnauthorization(true));
            return Promise.reject(error);
        }

        return Promise.reject(error);
    }
);

JelouAPI.interceptors.request.use((config) => {
    config.headers = {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
    };

    return config;
});

// Set JWT to default headers
JelouAPI.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("jwt");

// Logout user when token has expired
JelouAPI.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (has(error, "response.status") && error.response.status === 401) {
            store.dispatch(setUnauthorization(true));
            return Promise.reject(error);
        }

        return Promise.reject(error);
    }
);

JelouAppsAPI.interceptors.request.use((config) => {
    config.headers = {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
    };

    return config;
});

// Set JWT to default headers
JelouAppsAPI.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("jwt");

// Logout user when token has expired
JelouAppsAPI.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (has(error, "response.status") && error.response.status === 401) {
            store.dispatch(setUnauthorization(true));
            return Promise.reject(error);
        }

        return Promise.reject(error);
    }
);

Http.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (has(error, "response.status") && error.response.status === 401) {
            store.dispatch(setUnauthorization(true));
            return Promise.reject(error);
        }

        return Promise.reject(error);
    }
);

export default Http;
